import React from "react";
import styled from "styled-components";

import Colors from "../../../../Constants/Colors";

const StyledContentList = styled.div`
  position: sticky;
  top: 7rem;
  height: ${(props) =>
    // height for each item = 40, extra height for 'Contents' keyword = 25
    props.listLength ? `${props.listLength * 40 + 25}px` : "auto"};

  @media (max-width: 992px) {
    display: none;
  }

  .active {
    border-left: 2px solid ${Colors.font1};
    color: ${Colors.font1};
    font-weight: 500;
  }

  & > p {
    font-weight: 500;
    color: ${Colors.font1};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

  li {
    display: block;
    border-left: 2px solid ${Colors.borderInput};
    padding: 7px 12px;
    cursor: pointer;
    color: ${Colors.font2};
    max-width: max-content;

    &:hover {
      color: ${Colors.font1};
    }
  }
`;

function SideContentList({ idList, activeContentId }) {
  const contentIdHandler = (id) => {
    if (typeof id !== "string" || !id) return;

    const el = document.getElementById(id);
    if (!el) return;
    el.style.scrollMarginTop = "180px";
    el.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <StyledContentList listLength={idList.length}>
      <p>Contents</p>

      {idList.length ? (
        <ul>
          {idList.map((id) => (
            <li
              key={id}
              className={activeContentId === id ? "active" : ""}
              onClick={() => contentIdHandler(id)}
            >
              {id}
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </StyledContentList>
  );
}

export default SideContentList;
