import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SaveAndCloseButtun = styled.div`
  font-size: 1rem;
  color: ${Colors.font2};
  font-weight: 500;
  opacity: 0.7;
  &:hover {
    opacity: 0.9;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 1.7rem;
  display: flex;
  font-family: Poppins;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${Colors.font2};
  background: rgb(218, 222, 236);
`;
const List = styled.div`
  display: flex;
  min-width: 8rem;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background: ${Colors.backgroundPink};
  }
`;

const DownloadButton = styled.div`
  display: flex;
  width: 6.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  background: ${Colors.primary};
  color: ${Colors.white};
  & svg {
    color: ${Colors.white};
  }
  &:hover {
    background: ${Colors.hoverButtonColor};
  }
`;

export default {
  SaveAndCloseButtun,
  ProgressBar,
  List,
  DownloadButton,
};
