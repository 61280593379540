import React, { useEffect, useState } from "react";
import ProfileIcon from "../../../../Assets/assetsnew/user_profile.svg";
import TrophyIcon from "../../../../Assets/assetsnew/trophy.svg";
import CurrencyIcon from "../../../../Assets/assetsnew/setup-currency.svg";
import BusinessInfoIcon from "../../../../Assets/assetsnew/setup-business-info.svg";

import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  hideSetupInfo,
  fetchUserSetupData,
} from "../../../../store/service/AuthService";
import { message } from "antd";

import Joyride, { EVENTS, STATUS } from "react-joyride";
import { updateUserTourSetupData } from "../../../../store/service/AuthService";

const stepData = [
  {
    title: (
      <TextStyles.FontSize18px>Set up your account</TextStyles.FontSize18px>
    ),
    target: ".setup_your_user_info_tour",
    disableBeacon: true,
  },
  {
    title: (
      <TextStyles.FontSize18px>Set up your currency</TextStyles.FontSize18px>
    ),
    target: ".setup_your_currency_tour",
  },
  {
    title: <TextStyles.FontSize18px>Set up your brand</TextStyles.FontSize18px>,
    target: ".setup_your_brand_logo_tour",
  },
  {
    title: (
      <TextStyles.FontSize18px>Set up your business</TextStyles.FontSize18px>
    ),
    target: ".setup_your_business_info_tour",
  },
];

const StyledDashboardItemBoxContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.length > 3
      ? `repeat(${props.length}, 280px)`
      : `repeat(${props.length}, 340px)`};
  justify-content: center;
  gap: 2rem;
  padding: 1.8rem 0;
  text-align: center;

  @media (max-width: 1514px) {
    grid-template-columns: ${(props) =>
      props.length > 1 ? "repeat(2, 340px)" : "340px"};
    gap: 2.3rem;
  }

  @media (max-width: 768px) {
    grid-template-columns: 340px;
  }
`;

const DashboardItemBox = styled.div`
  padding: 28px 24px 15px;
  border-radius: 12px;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
  cursor: pointer;

  img {
    height: 50px;
  }
`;

export default function secondrow() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userSetupData = useSelector((state) => state.auth.userSetupData);

  const tourData = useSelector((state) => state.auth.userTourSetupData);

  const [run, setRun] = useState(tourData && tourData.dashboard_tour);

  const itemBoxLeft =
    userSetupData &&
    Object.values(userSetupData).filter((val) => val === false).length;

  const setupHandler = (data) => {
    const Obj = {
      [data]: true,
    };
    dispatch(hideSetupInfo(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchUserSetupData());
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  useEffect(() => {
    if (tourData && tourData.dashboard_tour) {
      setRun(tourData && tourData.dashboard_tour);
    } else {
      setRun(false);
    }
  }, [tourData]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
    if ([EVENTS.TOUR_END, EVENTS.SKIP].includes(type)) {
      dispatch(updateUserTourSetupData({ dashboard_tour: false }));
    }
  };

  return (
    <div className="mx-lg-4 mx-md-4 mx-sm-3 mx-2 mt-3 mt-lg-1">
      {userSetupData &&
        (!userSetupData.setup_account_info ||
          !userSetupData.setup_currency ||
          !userSetupData.setup_business_info) && (
          <TextStyles.GreyFont18px className="text-center text-md-start">
            Let’s get you set up with Zodot
          </TextStyles.GreyFont18px>
        )}

      {itemBoxLeft && itemBoxLeft > 0 ? (
        <StyledDashboardItemBoxContainer length={itemBoxLeft}>
          {userSetupData && !userSetupData.setup_account_info && (
            <DashboardItemBox
              onClick={() => {
                setupHandler("setup_account_info");
                history.push("/db/setting/account/user_info");
              }}
              className="setup_your_user_info_tour position-relative"
            >
              <ClearIcon
                sx={{
                  width: 25,
                  height: 25,
                  position: "absolute",
                  top: 10,
                  right: 20,
                  color: Colors.font2,
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setupHandler("setup_account_info");
                }}
              />

              <img
                src={ProfileIcon}
                alt="profileBtn"
                style={{ width: 50, height: 50 }}
              />

              <TextStyles.FontSize18px
                className="mt-3"
                style={{ fontWeight: 500 }}
              >
                Complete account setup
              </TextStyles.FontSize18px>

              <TextStyles.FontSize14px className="mt-2">
                Configure your user info and other details.
              </TextStyles.FontSize14px>
            </DashboardItemBox>
          )}

          {userSetupData && !userSetupData.setup_currency && (
            <DashboardItemBox
              onClick={() => {
                setupHandler("setup_currency");
                history.push("/db/setting/business/currency");
              }}
              className="setup_your_currency_tour position-relative"
            >
              <ClearIcon
                sx={{
                  width: 25,
                  height: 25,
                  position: "absolute",
                  top: 10,
                  right: 20,
                  color: Colors.font2,
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setupHandler("setup_currency");
                }}
              />

              <div>
                <img src={CurrencyIcon} alt="currencyBtn" />
              </div>

              <TextStyles.FontSize18px
                className="mt-3"
                style={{ fontWeight: 500 }}
              >
                Complete currency setup
              </TextStyles.FontSize18px>

              <TextStyles.FontSize14px className="mt-2">
                Configure your currency for <br />
                transactions.
              </TextStyles.FontSize14px>
            </DashboardItemBox>
          )}

          {userSetupData && !userSetupData.setup_brand_and_logo && (
            <DashboardItemBox
              onClick={() => {
                setupHandler("setup_brand_and_logo");
                history.push("/db/setting/business/brand_logo");
              }}
              className="setup_your_brand_logo_tour position-relative"
            >
              <ClearIcon
                sx={{
                  width: 25,
                  height: 25,
                  position: "absolute",
                  top: 10,
                  right: 20,
                  color: Colors.font2,
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setupHandler("setup_brand_and_logo");
                }}
              />

              <img src={TrophyIcon} alt="trophyBtn" />

              <TextStyles.FontSize18px
                className="mt-3"
                style={{ fontWeight: 500 }}
              >
                Complete brand setup
              </TextStyles.FontSize18px>

              <TextStyles.FontSize14px className="mt-2">
                Configure your brand's logo, its color theme and more.
              </TextStyles.FontSize14px>
            </DashboardItemBox>
          )}

          {userSetupData && !userSetupData.setup_business_info && (
            <DashboardItemBox
              onClick={() => {
                setupHandler("setup_business_info");
                history.push("/db/setting/business/business_info");
              }}
              className="setup_your_business_info_tour position-relative"
            >
              <ClearIcon
                sx={{
                  width: 25,
                  height: 25,
                  position: "absolute",
                  top: 10,
                  right: 20,
                  color: Colors.font2,
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setupHandler("setup_business_info");
                }}
              />

              <img src={BusinessInfoIcon} alt="BusinessInfoBtn" />

              <TextStyles.FontSize18px
                className="mt-3"
                style={{ fontWeight: 500 }}
              >
                Complete business Info
              </TextStyles.FontSize18px>

              <TextStyles.FontSize14px className="mt-2">
                Configure your business info and other details.
              </TextStyles.FontSize14px>
            </DashboardItemBox>
          )}
        </StyledDashboardItemBoxContainer>
      ) : (
        ""
      )}
      <Joyride
        steps={stepData}
        run={run}
        disableBeacon
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        scrollToFirstStep={false}
        callback={handleJoyrideCallback}
        scrollOffset={200}
        locale={{
          skip: "Skip All",
          next: "Next",
          nextLabelWithProgress: "Next ({step}/{steps})",
          last: "Finish",
        }}
        styles={{
          options: {
            primaryColor: Colors.primary,
            textColor: Colors.font1,
            zIndex: 50,
            overlayColor: "rgba(0, 0, 0, 0.2)",
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0)",
            width: 300,
          },
          beacon: {
            zIndex: 50,
          },
          overlay: {
            zIndex: 50,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: 0,
          },
          tooltipContent: {
            padding: "10px 0 0 0",
          },
        }}
        defaultStyles={{
          tooltipContent: {
            padding: "0px",
          },
        }}
      />
    </div>
  );
}
