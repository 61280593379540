import React from "react";
import { useParams } from "react-router-dom";

import SearchBox from "../SearchBox";
import Article from "./Article";
import Footer from "../../Footer/Footer";
import PageNotFound from "../../../Components/path404error/patherror";

import startedImg from "../../../Assets/frontendImages/feature-faq-started.svg";
import clientImg from "../../../Assets/frontendImages/feature-faq-client.svg";
import projectImg from "../../../Assets/frontendImages/feature-faq-project.svg";
import invoiceImg from "../../../Assets/frontendImages/feature-faq-invoice.svg";
import taskImg from "../../../Assets/frontendImages/feature-faq-task.svg";
import timeTrackingImg from "../../../Assets/frontendImages/feature-faq-time-tracking.svg";

import { articles } from "../helpCenterConfig";

const getStarted = {
  imagePath: startedImg,
  mainHeading: "Getting Started",
  description: "Get started with Zodot to enhance productivity.",
  questionHeading: "Set Up Your Business Account",
  questions: [
    {
      question: "How to Get Started",
      link: "/help-center/get-started/how-to-get-started",
    },
    {
      question: "Add Your User Information",
      link: "/help-center/get-started/add-your-user-information",
    },
    {
      question: "Add Your Business Info",
      link: "/help-center/get-started/add-your-business-info",
    },
    {
      question: "Configure Your Preferred Currency",
      link: "/help-center/get-started/configure-your-preferred-currency",
    },
    {
      question: "Set Up Your Brand",
      link: "/help-center/get-started/set-up-your-brand",
    },
  ],
};

const client = {
  imagePath: clientImg,
  mainHeading: "Clients",
  description: "Manage client records for seamless collaboration.",
  questionHeading: "Manage your Clients",
  questions: [
    {
      question: "How to Create a Client",
      link: "/help-center/clients/how-to-create-a-client",
    },
    // {
    //   question: "How to Edit My Client Details",
    //   link: "/help-center/clients/how-to-edit-my-client-details",
    // },
    // {
    //   question: "How to Delete and Archive my clients",
    //   link: "/help-center/clients/how-to-delete-and-archive-my-clients",
    // },
    // {
    //   question: "How to Add a Contact to an Existing Client in Zodot",
    //   link: "/help-center/clients/how-to-add-a-contact-to-an-existing-client",
    // },
  ],
};

const project = {
  imagePath: projectImg,
  mainHeading: "Projects",
  description: "Set up and manage your projects with ease.",
  questionHeading: "Manage your Projects",
  questions: [
    {
      question: "How to Create a Project",
      link: "/help-center/projects/how-to-create-a-project",
    },
    // {
    //   question: "How to List, Filter, and Sort the Projects",
    //   link: "/help-center/projects/how-to-list-filter-and-sort-the-projects",
    // },
  ],
};

const invoice = {
  imagePath: invoiceImg,
  mainHeading: "Invoices",
  description: "Create professional invoices in just a few clicks.",
  questionHeading: "Manage your Invoices",
  questions: [
    {
      question: "How to Create an Invoice for an Existing Project",
      link: "/help-center/invoices/how-to-create-an-invoice-for-an-existing-project",
    },
    {
      question: "How to Create an Invoice for Your New Project",
      link: "/help-center/invoices/how-to-create-an-invoice-for-your-new-project",
    },
    {
      question:
        "How to Create a Quick Invoice (Without Setting Up the Project)",
      link: "/help-center/invoices/how-to-create-a-quick-invoice",
    },
    // {
    //   question: "How to Add Taxes and Discounts on Invoices",
    //   link: "/help-center/invoices/how-to-add-taxes-and-discounts-on-invoices",
    // },
  ],
};

const task = {
  imagePath: taskImg,
  mainHeading: "Tasks",
  description: "Monitor tasks to keep projects on track.",
  questionHeading: "Manage your Tasks",
  questions: [
    {
      question: "How to Create a Task",
      link: "/help-center/tasks/how-to-create-a-task",
    },
  ],
};

const timeTracking = {
  imagePath: timeTrackingImg,
  mainHeading: "Time Tracking",
  description: "Track hours and generate reports for transparent billing.",
  questionHeading: "How Time Tracking Works in Zodot",
  questions: [
    {
      question: "How to Track your Activity",
      link: "/help-center/time-tracking/how-to-track-your-activity",
    },
  ],
};

function ArticleHelpCenter() {
  const { article } = useParams();

  if (!articles.includes(article)) return <PageNotFound />;

  const data = () => {
    if (article === "get-started") return getStarted;
    if (article === "clients") return client;
    if (article === "projects") return project;
    if (article === "invoices") return invoice;
    if (article === "tasks") return task;
    if (article === "time-tracking") return timeTracking;
  };

  return (
    <>
      <SearchBox />
      <Article data={data()} />
      <Footer />
    </>
  );
}

export default ArticleHelpCenter;
