import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import SendInvoice from "./SendInvoice";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceById } from "../../../../store/service/invoiceService";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";
import LoaderSpin from "../../Commons/LoaderSpin";

const ResendInvoice = (props) => {
  const propsData = props && props.location && props.location.state;
  const _id = propsData && propsData._id;
  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const isLoading = useSelector((state) => state.invoices.isLoading);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedInvoiceData) dispatch(fetchInvoiceById({ _id }));
  }, []);

  const handleBack = () => {
    history.goBack();
    // history.push("/db/invoices");
    // dispatch(invoiceActions.selectedInvoiceResetAction());
    setTimeout(() => {
      dispatch(invoiceActions.selectedInvoiceResetAction());
    }, 500);
  };

  return (
    <div
      style={{
        background: Colors.backgroundGrey,
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          background: Colors.white,
          zIndex: 200,
          borderBottom: "2px solid #e0dcdc",
        }}
        className="fixed-top"
      >
        <div className="px-2 py-3">
          <TextStyles.FontSize14px
            style={{
              position: "absolute",
              right: "0.75rem",
              top: "1.3rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <ClearIcon
                onClick={handleBack}
                sx={{
                  fontSize: 24,
                  color: Colors.font2,
                  cursor: "pointer",
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize24px className="text-center font-weight-500">
            Resend Invoice
          </TextStyles.FontSize24px>
        </div>
      </div>

      <div style={{ paddingTop: 100 }}>
        {isLoading && !selectedInvoiceData ? (
          <TextStyles.FontSize14px
            style={{
              width: "100%",
              height: "100%",
              minHeight: 460,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderSpin color={Colors.primary} />
          </TextStyles.FontSize14px>
        ) : (
          <SendInvoice />
        )}
      </div>
    </div>
  );
};

export default ResendInvoice;
