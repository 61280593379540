import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import PCEditProjectInfo from "../../ProposalReviews/PCEditProjectInfo";

import TextStyles from "../../../../../Constants/TextStyles";
import { diffDate } from "../../../Commons/utils";
import Colors from "../../../../../Constants/Colors";
import Styled from "../PageOne/PageOne";

import imageClient from "../../../../../Assets/assetsnew/image-client-new.svg";

const Project = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showProjectModal, setShowProjectModal] = useState(false);
  return (
    <div className="mt-4">
      <Styled.ReviewContentBox>
        <BorderColorOutlinedIcon
          className="edit-icon"
          onClick={() => setShowProjectModal(true)}
          sx={{
            cursor: "pointer",
            color: Colors.font2,
            opacity: 0.8,
            "&:hover": { opacity: 1 },
          }}
        />
        <div className="d-flex align-items-center gap-2 gap-md-3">
          <div style={{ maxWidth: 40 }}>
            <img className="w-100" src={imageClient} alt={`imageClient`} />
          </div>
          <TextStyles.FontSize18px className="fw-medium">
            {selectedData && selectedData.project_name}
          </TextStyles.FontSize18px>
        </div>
        <div className="d-flex gap-2 justify-content-start align-items-center mt-3">
          <TextStyles.FontSize16px className="fw-medium">
            Starts:
          </TextStyles.FontSize16px>
          <TextStyles.GreyFont14px className="d-flex" style={{ minWidth: 50 }}>
            <span className="three-dots">
              {moment(selectedData && selectedData.project_start_date).format(
                "DD MMM YYYY"
              )}
            </span>
          </TextStyles.GreyFont14px>
        </div>

          <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
            <TextStyles.FontSize16px className="fw-medium">
              Ends:
            </TextStyles.FontSize16px>
            <TextStyles.GreyFont14px
              className="d-flex"
              style={{ minWidth: 50 }}
            >
              <span className="three-dots">
                {selectedData.project_end_date ? moment(selectedData.project_end_date).format("DD MMM YYYY") : 'On going'}
              </span>
            </TextStyles.GreyFont14px>
          </div>

        {selectedData &&
          selectedData.project_end_date &&
          selectedData.project_start_date && (
            <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
              <TextStyles.FontSize16px className="fw-medium">
                Duration:
              </TextStyles.FontSize16px>
              <TextStyles.GreyFont14px
                className="d-flex"
                style={{ minWidth: 30 }}
              >
                <span className="three-dots">
                  {diffDate(
                    selectedData.project_end_date,
                    selectedData.project_start_date
                  )}
                </span>
              </TextStyles.GreyFont14px>
            </div>
          )}
      </Styled.ReviewContentBox>
      {showProjectModal && (
        <PCEditProjectInfo
          selectedData={selectedData}
          show={showProjectModal}
          handleClose={() => setShowProjectModal(false)}
        />
      )}
    </div>
  );
};

export default Project;
