import React from "react";
import TextStyles from "../../../../Constants/TextStyles";

import { useSelector } from "react-redux";

function firstrow() {
  const firstName = useSelector(
    (state) => state.auth.data && state.auth.data.firstName
  );

  return (
    <div className="mx-lg-4 mx-md-4 mx-sm-3 mx-2 pt-4">
      <TextStyles.FontSize14px className="d-flex justify-content-lg-start justify-content-xl-start justify-content-md-start justify-content-center fs-1">
        <span>Hello {firstName}!</span>
      </TextStyles.FontSize14px>
    </div>
  );
}

export default firstrow;
