import React from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";

const EditInvoiceTitle = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      invoice_title: selectedData && selectedData.invoice_title,
    },
  });

  const formsubmit = (data) => {
    const Obj = {
      invoice_title: data.invoice_title,
    };

    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    reset();
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight500px"
      fullscreen
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <TextStyles.FontSize20px className="font-weight-500">
            Invoice Label
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="me-0 me-sm-1 me-md-2 me-lg-3">
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="invoice_title" className="mb-2">
              {" "}
              Invoice label
            </label>
            <TextStyles.InputRectangle
              invalid={errors.invoice_title}
              type="text"
              id="invoice_title"
              name="invoice_title"
              placeholder="Invoice title"
              {...register("invoice_title", {
                required: "Invoice title can't be empty.",
                minLength: {
                  value: 3,
                  message: "Minimum length is 3 characters.",
                },
                maxLength: {
                  value: 40,
                  message: "Maximum length is 40 characters.",
                },
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Invoice title can't be empty.";
                  }
                  return true;
                },
              })}
            />
            {errors.invoice_title && (
              <TextStyles.InValidFeedback>
                {errors.invoice_title.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button>Save</TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditInvoiceTitle;
