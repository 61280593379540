import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import userInfoImg from "../../../../../Assets/frontendImages/faq-add-your-user-information.webp";
import Screenshot from "../../../../UIs/Screenshot";
import SideContentList from "../SideContentList";

function AddYourUserInformation({ idList }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          Add Your User Information
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Keeping your profile information updated ensures that your clients see
          the correct details when working with you.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          Follow these simple steps to update your profile details:
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px id={idList[0]} className="fw-semibold mb-1">
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Click on Settings in the sidebar.</li>
          <li className="mb-1">Navigate to the Account & Security tab.</li>
        </ul>

        <TextStyles.FontSize16px id={idList[1]} className="fw-semibold mb-1">
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Enter your First Name and Last Name (editable for all users).
          </li>
          <li className="mb-1">
            Update your Email Address (only if you signed up through email).
          </li>
          <li className="mb-1">
            Select Your Profession & Experience Level (Optional).
          </li>
          <li className="mb-1">
            Specify Your Work Style - Solo or work as a Team.
          </li>
        </ul>

        <TextStyles.FontSize16px id={idList[2]} className="fw-semibold mb-1">
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click "Update photo" to upload your profile picture.
          </li>
          <li className="mb-1">
            Click on the "Remove" button to delete your current photo.
          </li>
        </ul>

        <TextStyles.FontSize16px id={idList[3]} className="fw-semibold mb-1">
          4. {idList[3]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click the "Save changes" button to apply your updates.
          </li>
        </ul>

        <Screenshot src={userInfoImg} alt="add your user information" />

        <TextStyles.FontSize16px className="fw-semibold mb-1">
          Important Notes:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            If you signed up via Google or LinkedIn, you can change your First
            Name and Last Name, but your email address cannot be edited.
          </li>
          <li className="mb-1">
            If you signed up through email option, you can update all details,
            including your email.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mt-4 mb-3">
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, Your profile has been successfully updated!
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} />
    </>
  );
}

export default AddYourUserInformation;
