import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateLatePaymentPC } from "../../../../../../store/service/proposalAndContractService";
import LatePaymentModal from "../../PageModals/LatePaymentModal";
import Colors from "../../../../../../Constants/Colors";

const LatePayment = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading =
    selectedData &&
    selectedData.late_payment_fee &&
    selectedData.late_payment_fee.heading
      ? selectedData.late_payment_fee.heading
      : "Late Payment";

  const latePaymentType =
    selectedData &&
    selectedData.late_payment_fee &&
    selectedData.late_payment_fee.late_payment
      ? selectedData.late_payment_fee.late_payment
      : "Percentage";

  const latePaymentPercent =
    selectedData &&
    selectedData.late_payment_fee &&
    selectedData.late_payment_fee.late_payment_percentage
      ? selectedData.late_payment_fee.late_payment_percentage
      : "50";

  const latePaymentAmount =
    selectedData &&
    selectedData.late_payment_fee &&
    selectedData.late_payment_fee.late_payment_amount
      ? selectedData.late_payment_fee.late_payment_amount
      : "1.50";

  const isLatePaymentRequired =
    selectedData &&
    selectedData.late_payment_fee &&
    selectedData.late_payment_fee.required
      ? true
      : false;

  const toggleLatePaymentViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      late_payment_fee: {
        required: !isLatePaymentRequired,
        late_payment_deposit_require: !isLatePaymentRequired,
        late_payment: latePaymentType,
        late_payment_amount: latePaymentAmount,
        late_payment_percentage: latePaymentPercent,
      },
    };
    dispatch(updateLatePaymentPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isLatePaymentRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleLatePaymentViewHandler}>
            {isLatePaymentRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isLatePaymentRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${isLatePaymentRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isLatePaymentRequired ? "" : "disabled"}`}
        >
          In case of delayed payment of an invoice, the Contractor reserves the
          right to charge a late payment fee of
          <span className="ms-1 fw-semibold">
            {latePaymentType === "Percentage" &&
              `${latePaymentPercent}% per month`}

            {latePaymentType === "Amount" &&
              `${selectedData.currency.symbol}${latePaymentAmount} as late payment fee`}
          </span>
          . Acceptance of these service charges by Contractor does not waive any
          rights or remedies in the event of Client's breach of this Agreement.
          All payment obligations are non-cancellable, and fees paid are
          non-refundable.
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <LatePaymentModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default LatePayment;
