import React from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import SelectDueDates from "../../Commons/SelectDueDates";
import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./Taxes";
import moment from "moment";
import Colors from "../../../../Constants/Colors";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";
import CustomDatePickerOne from "../../Commons/DatePickers/CustomDatePickerOne";

const EditInvoiceDates = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();

  const {
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      issued: selectedData
        ? moment(selectedData.issued).toDate()
        : moment().toDate(),
      due_days:
        selectedData &&
        moment(selectedData.due_date).diff(moment(selectedData.issued), "d") ===
          0
          ? "upon receipt"
          : selectedData &&
            moment(selectedData.due_date).diff(
              moment(selectedData.issued),
              "d"
            ) === 7
          ? "7 days"
          : selectedData &&
            moment(selectedData.due_date).diff(
              moment(selectedData.issued),
              "d"
            ) === 15
          ? "15 days"
          : selectedData &&
            moment(selectedData.due_date).diff(
              moment(selectedData.issued),
              "d"
            ) === 30
          ? "30 days"
          : selectedData &&
            moment(selectedData.due_date).diff(
              moment(selectedData.issued),
              "d"
            ) === 45
          ? "45 days"
          : "Custom",

      custom_due_days:
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 0) ||
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 7) ||
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 15) ||
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 30) ||
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 45)
          ? selectedData &&
            moment(selectedData.due_date).diff(moment(selectedData.issued), "d")
          : null,
    },
  });

  const dueDays = watch("due_days");
  const customDueDays = watch("custom_due_days");

  const formsubmit = async (data) => {
    const Obj = {
      due_date:
        dueDays === "Custom" && data.custom_due_days
          ? moment(data.issued).add(data.custom_due_days, "d").toDate()
          : data.due_days === "7 days"
          ? moment(data.issued).add("7", "d").toDate()
          : data.due_days === "15 days"
          ? moment(data.issued).add("15", "d").toDate()
          : data.due_days === "30 days"
          ? moment(data.issued).add("30", "d").toDate()
          : data.due_days === "45 days"
          ? moment(data.issued).add("45", "d").toDate()
          : data.issued,
      issued: data.issued,
    };

    dispatch(
      invoiceActions.selectedInvoiceAction({
        due_date: Obj.due_date && moment(Obj.due_date).toISOString(),
        issued: Obj.issued && moment(Obj.issued).toISOString(),
      })
    );
    reset();
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight500px"
      fullscreen
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <TextStyles.FontSize20px className="font-weight-500">
            Invoice Dates
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="me-0 me-sm-2 me-md-3 me-lg-5">
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="due_days" className="mb-2">
              Due
            </label>

            <div className="d-flex align-items-center gap-3">
              <Controller
                control={control}
                name="due_days"
                render={({ field: { onChange, value } }) => (
                  <SelectDueDates
                    width="180px"
                    onChange={onChange}
                    defaultValue={value}
                  />
                )}
              />
              {dueDays === "Custom" && (
                <>
                  <TextStyles.FontSize14px className="mx-2">
                    After
                  </TextStyles.FontSize14px>
                  <Styled.inputBox
                    invalid={errors.custom_due_days}
                    style={{ maxWidth: 60 }}
                  >
                    <input
                      type="text"
                      name="custom_due_days"
                      style={{
                        width: 24,
                        textAlign: "center",
                        background: "transparent",
                      }}
                      placeholder="00"
                      {...register("custom_due_days", {
                        required:
                          dueDays === "Custom"
                            ? "Please enter custom due days."
                            : false,
                        min: {
                          value: 1,
                          message: "Please enter more than 0.",
                        },
                        pattern: {
                          value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                  </Styled.inputBox>
                  <TextStyles.FontSize14px className="ms-2">
                    {customDueDays === "1" ? "day" : "days"}
                  </TextStyles.FontSize14px>
                </>
              )}
            </div>
          </TextStyles.FontSize14px>
          {errors.custom_due_days && (
            <TextStyles.InValidFeedback className="mt-2">
              {errors.custom_due_days.message}
            </TextStyles.InValidFeedback>
          )}
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="issued_date" className="mb-2">
              Issued
            </label>
            <div>
              <Controller
                name={"issued"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <CustomDatePickerOne
                      onChange={onChange}
                      selected={value}
                      placeholder="Set issued date"
                      allowClear={true}
                    />
                  );
                }}
              />
            </div>
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button>Save</TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditInvoiceDates;
