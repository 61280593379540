import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Features from "./Features/Features";

import Footer from "../../Footer/Footer";
import AdditionalFeatures from "./AdditionalFeatures/AdditionalFeatures";
import SignupForm from "../../UIs/SignupForm";
import { Helmet } from "react-helmet";

const Agreements = () => {
  const isProduction =
    process.env.REACT_APP_PAGE_INDEXING_MODE === "production";
  return (
    <div>
      <Helmet>
        <title>Free Time Tracking and Invoicing Software For Freelancers</title>
        <meta
          content="Effortlessly track client project time with Zodot's feature. Enjoy daily and monthly views for easy work hour management."
          name="description"
        />
        {isProduction ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://zodot.co/features/time-tracking" />
      </Helmet>
      <LandingPage />
      <Features />
      <AdditionalFeatures />
      <SignupForm />
      <Footer />
    </div>
  );
};

export default Agreements;
