import React from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";
import { message } from "antd";
import { validateEmail } from "../../Commons/utils";

const AddContactModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );
  const {
    register,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm();

  const formsubmit = (item) => {
    const Obj = {
      contact_name: item.contact_name,
      email: item.email,
    };

    const oldClientContacts = [...selectedInvoiceData.client_id.contacts];
    const existedContact =
      oldClientContacts &&
      oldClientContacts.some(
        (contact) => contact.email.toLowerCase() === item.email.toLowerCase()
      );

    if (existedContact) {
      return message.error(`Email already existed!`);
    }

    const newClientContacts = [...oldClientContacts, Obj];
    const client_id = selectedInvoiceData &&
      selectedInvoiceData.client_id && {
        ...selectedInvoiceData.client_id,
        contacts: newClientContacts,
      };
    dispatch(invoiceActions.selectedInvoiceAction({ client_id }));
    reset();
    handleClose();
  };

  return (
    <Modal
      scrollable
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      centered
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <TextStyles.FontSize20px>Add contact</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="contact_name" className="mb-2">
              Name
            </label>
            <TextStyles.InputRectangle
              invalid={errors.contact_name}
              id="contact_name"
              placeholder="Name"
              name="contact_name"
              {...register(`contact_name`, {
                required: "Contact can't be empty.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Contact can't be empty.";
                  }
                  return true;
                },
              })}
            />
            {errors.contact_name && (
              <TextStyles.InValidFeedback>
                {errors.contact_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="email" className="mb-2">
              Email
            </label>
            <TextStyles.InputRectangle
              invalid={errors.email}
              id="email"
              placeholder="Email"
              name="email"
              {...register("email", {
                required: "Email is required.",
                validate: validateEmail,
                onBlur: (e) =>
                  setValue("email", e.target.value.trim().toLowerCase()),
              })}
            />
            {errors.email && (
              <TextStyles.InValidFeedback>
                {errors.email.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={!isDirty || !isValid ? true : false}>
              Add contact
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddContactModal;
