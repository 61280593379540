import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateNonSolicitPC } from "../../../../../../store/service/proposalAndContractService";
import NonSolicitModal from "../../PageModals/NonSolicitModal";
import Colors from "../../../../../../Constants/Colors";

const NonSolicit = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Non-Solicit";
  const defaultParagraph =
    "Contractor agrees that throughout the period of providing Services and for one year thereafter, they will refrain from encouraging or soliciting any employee, vendor, client, or contractor of the Client to depart from the Client for any reason.";

  const heading =
    selectedData && selectedData.non_solicit && selectedData.non_solicit.heading
      ? selectedData &&
        selectedData.non_solicit &&
        selectedData.non_solicit.heading
      : defaultHeading;
  const paragraph =
    selectedData &&
    selectedData.non_solicit &&
    selectedData.non_solicit.paragraph
      ? selectedData &&
        selectedData.non_solicit &&
        selectedData.non_solicit.paragraph
      : defaultParagraph;

  const isSolicitRequired =
    selectedData &&
    selectedData.non_solicit &&
    selectedData.non_solicit.required
      ? true
      : false;

  const toggleSolicitViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      non_solicit: {
        required: !isSolicitRequired,
        heading,
        paragraph,
      },
    };
    dispatch(updateNonSolicitPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isSolicitRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleSolicitViewHandler}>
            {isSolicitRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isSolicitRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span className={`fw-medium ${isSolicitRequired ? "" : "disabled"}`}>
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isSolicitRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <NonSolicitModal
          paragraph={paragraph}
          heading={heading}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default NonSolicit;
