import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateWarrantyPC } from "../../../../../../store/service/proposalAndContractService";
import WarrantyModal from "../../PageModals/WarrantyModal";
import Colors from "../../../../../../Constants/Colors";

const Warranty = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Warranty";
  const defaultParagraph = `Contractor hereby warrants that:(i) The Services will be conducted in a professional and workmanlike manner, free from any inconsistency with any other contractual obligations held by the Contractor;(ii) The Services, Inventions, and their development, use, production, distribution, or exploitation will not infringe, misappropriate, or violate any intellectual property or rights of any entity, including the Contractor;(iii) The Contractor possesses full rights to provide the Client with the assignments and rights as stipulated in this Agreement;(iv) The Contractor will comply with all applicable laws while performing the Services and adhering to the Terms and Conditions;(v) If the Contractor's work requires a license, the Contractor has obtained the necessary license, which remains in full force and effect.\n\nEXCEPT AS SPECIFIED IN THIS ATTACHMENT B, THE CONTRACTOR DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SUITABILITY, COMPLETENESS, OR RESULTS DERIVED FROM THE WORK. EXCEPT AS EXPRESSLY STATED HEREIN, ALL DELIVERABLES ARE PROVIDED ON AN "AS-IS" BASIS.`;

  const heading =
    selectedData && selectedData.warranty && selectedData.warranty.heading
      ? selectedData && selectedData.warranty && selectedData.warranty.heading
      : defaultHeading;
  const paragraph =
    selectedData && selectedData.warranty && selectedData.warranty.paragraph
      ? selectedData && selectedData.warranty && selectedData.warranty.paragraph
      : defaultParagraph;

  const isWarrantyRequired =
    selectedData && selectedData.warranty && selectedData.warranty.required
      ? true
      : false;

  const toggleWarrantyViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      warranty: {
        required: !isWarrantyRequired,
        heading,
        paragraph,
      },
    };
    dispatch(updateWarrantyPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isWarrantyRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleWarrantyViewHandler}>
            {isWarrantyRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isWarrantyRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span className={`fw-medium ${isWarrantyRequired ? "" : "disabled"}`}>
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isWarrantyRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <WarrantyModal
          paragraph={paragraph}
          heading={heading}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Warranty;
