import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const StyledUploadBrandLogoLabel = styled.label`
  width: 180px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: ${Colors.white};
  border: 2px solid ${Colors.borderInput};
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border: 2px solid ${Colors.primary};
  }

  &:active {
    transform: scale(0.96);
  }
`;

const StyledRemoveLogoBtn = styled.button`
  width: 180px;
  padding: 12px;
  background: ${Colors.white};
  border: 2px solid ${Colors.borderInput};
  border-radius: 12px;

  &:hover {
    border: 2px solid ${Colors.primary};
  }

  &:active {
    transform: scale(0.96);
  }

  &:disabled {
    border: 2px solid ${Colors.borderInput};
    transform: scale(1);
    cursor: default;
  }
`;
export default {
  StyledUploadBrandLogoLabel,
  StyledRemoveLogoBtn,
};
