import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateRetainRightsPC } from "../../../../../../store/service/proposalAndContractService";
import AuthorshipCreditRightModal from "../../PageModals/AuthorshipCreditRightModal";
import Colors from "../../../../../../Constants/Colors";

const RightToAuthorship = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Right to Authorship Credit";

  const heading =
    selectedData &&
    selectedData.rightToAuthorship &&
    selectedData.rightToAuthorship.heading
      ? selectedData &&
        selectedData.rightToAuthorship &&
        selectedData.rightToAuthorship.heading
      : defaultHeading;

  const paragraph = selectedData &&
  selectedData.rightToAuthorship &&
  selectedData.rightToAuthorship.paragraph ? selectedData.rightToAuthorship.paragraph : "";

  const retainRightsParagraph =
    "The parties acknowledge that the Contractor is the creator of the deliverables. The Client is not obligated to proactively display the Contractor's name alongside the deliverables but must not mislead others by attributing the creation of the deliverables to anyone other than the Contractor. The Client agrees that the Contractor may use the work product as part of their portfolio, websites, galleries, and other media solely for the purpose of showcasing the Contractor's work, without any other purpose.\n\nThe Contractor shall not publish any confidential or non-public work without obtaining the prior written consent of the Client.";

  const noRetainRightsParagraph =
    "Both parties mutually agree that the Client must appropriately acknowledge the Contractor as the creator of the deliverables when requested. The Client is not obligated to proactively display the Contractor's name alongside the deliverables, but the Client must not mislead others by attributing the creation of the deliverables to anyone other than the Contractor.\n\nThe Contractor shall refrain from publishing any confidential or non-public work without obtaining the prior written consent of the Client.";

  const hasRetainRights =
    selectedData &&
    selectedData.rightToAuthorship &&
    selectedData.rightToAuthorship.retain_rights
      ? selectedData.rightToAuthorship.retain_rights
      : false;

  const isRightToAuthorshipRequired =
    selectedData &&
    selectedData.rightToAuthorship &&
    selectedData.rightToAuthorship.required
      ? true
      : false;

  const toggleAuthorshipViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      rightToAuthorship: {
        required: !isRightToAuthorshipRequired,
        heading,
        paragraph: paragraph || noRetainRightsParagraph,
        retain_rights: hasRetainRights,
      },
    };
    dispatch(updateRetainRightsPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isRightToAuthorshipRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <>
      <Styled.ReviewContentBox>
        <div className="edit-delete-icons">
          <span onClick={toggleAuthorshipViewHandler}>
            {isRightToAuthorshipRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isRightToAuthorshipRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span
            className={`fw-medium ${
              isRightToAuthorshipRequired ? "" : "disabled"
            }`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isRightToAuthorshipRequired ? "" : "disabled"}`}
        >
          {paragraph ? (
            <div style={{ whiteSpace: "break-spaces" }}>
              {paragraph}
            </div>
          ) : hasRetainRights === true ? (
            <div>
              {retainRightsParagraph}
            </div>
          ) : (
            <div>
              {noRetainRightsParagraph}
            </div>
          )}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <AuthorshipCreditRightModal
          heading={heading}
          defaultHeading={defaultHeading}
          paragraph={paragraph}
          retainRightsParagraph={retainRightsParagraph}
          noRetainRightsParagraph={noRetainRightsParagraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default RightToAuthorship;
