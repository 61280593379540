import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import businessInfoImg from "../../../../../Assets/frontendImages/faq-add-your-business-info.png";
import Screenshot from "../../../../UIs/Screenshot";
import SideContentList from "../SideContentList";

function AddYourBusinessInfo({ idList }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          Add Your Business Information
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Configuring your business details in Zodot guarantees that your
          invoices, proposals, and client interactions reflect the correct
          information. Here’s how you can set it up seamlessly -
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px id={idList[0]} className="fw-semibold mb-1">
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Click on Settings in the sidebar.</li>
          <li className="mb-1">
            Click on Set up your Business info under the Business tab.
          </li>
        </ul>

        <TextStyles.FontSize16px id={idList[1]} className="fw-semibold mb-1">
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Select whether to operate under your name or a legally registered
            business name.
          </li>
          <li className="mb-1">
            Enter your name/business name, phone number, following the correct
            country code.
          </li>
          <li className="mb-1">
            Enter other details like your street address, suite number, city,
            state, zip code, and country. These details will appear on official
            documents like invoices and contracts.
          </li>
        </ul>

        <TextStyles.FontSize16px id={idList[2]} className="fw-semibold mb-1">
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-4">
          <li className="mb-1">
            Click on the "Save" button to save your business details.
          </li>
        </ul>

        <Screenshot src={businessInfoImg} alt="business info img" />

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, Your Business details have been successfully updated!
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} />
    </>
  );
}

export default AddYourBusinessInfo;
