import React, { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageOne";

import SignProposal from "../../ProposalReviews/Signatures/SignProposal";
import Colors from "../../../../../Constants/Colors";
import { findPrimaryContact } from "../../../Commons/utils";

const RestOne = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const [showModal, setShowModal] = useState(false);

  const primaryContact = findPrimaryContact(selectedData);

  return (
    <div
      className="mt-4"
      style={{
        padding: "1.25rem",
        border: "1px solid #b2b6c5",
        borderRadius: "0.75rem",
      }}
    >
      <TextStyles.FontSize18px className="fw-medium">
        Services
      </TextStyles.FontSize18px>
      <TextStyles.FontSize14px className="mt-2">
        Contractor agrees to render the services as outlined in Attachment A
        (referred to as the "Services"), and Client agrees to compensate
        Contractor as specified in Attachment A.
      </TextStyles.FontSize14px>
      <TextStyles.FontSize18px className="mt-4 fw-medium">
        Signatures
      </TextStyles.FontSize18px>
      <TextStyles.FontSize14px className="mt-2">
        This Agreement, along with Attachment A and Attachment B, is executed by
        the Parties on the later of the two dates below, thereby establishing
        the "Effective Date."
      </TextStyles.FontSize14px>
      <div className="row mt-5 mb-3">
        <div
          className="col-12 col-md-6"
          style={{
            padding: 20,
            minHeight: 227,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex gap-1 align-items-center">
            <TextStyles.FontSize18px>Client</TextStyles.FontSize18px>
            <TextStyles.FontSize14px>
              {primaryContact && `(${primaryContact.contact_name})`}
            </TextStyles.FontSize14px>
          </div>
          <div>
            <div className="d-flex align-items-end">
              <TextStyles.FontSize14px
                style={{
                  width: "55%",
                  marginRight: "10%",
                  fontSize: 30,
                  fontFamily: "licorice",
                  fontWeight: 400,
                }}
              >
                {selectedData &&
                  selectedData.client_signature &&
                  selectedData.client_signature.text && (
                    <div style={{ fontFamily: "licorice" }}>
                      {selectedData.client_signature.text}
                    </div>
                  )}
                {selectedData &&
                  selectedData.client_signature &&
                  selectedData.client_signature.image &&
                  selectedData.client_signature.image.image_name && (
                    <img
                      src={selectedData.client_signature.image.image_url}
                      alt="imageBtn"
                      style={{ maxHeight: 120, maxWidth: 150 }}
                    />
                  )}
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px style={{ width: "30%" }}>
                {selectedData &&
                  moment(
                    selectedData.client_signature &&
                      selectedData.client_signature.signature_date
                  ).format("DD MMM YYYY")}
              </TextStyles.FontSize14px>
            </div>
            <div className="d-flex">
              <TextStyles.FontSize14px
                style={{
                  width: "55%",
                  border: "solid 0.5px #b2b6c5",
                  marginRight: "10%",
                  marginTop: 3,
                  marginBottom: 5,
                }}
              />
              <TextStyles.FontSize14px
                style={{
                  width: "35%",
                  border: "solid 0.5px #b2b6c5",
                  marginTop: 3,
                  marginBottom: 5,
                }}
              />
            </div>
            <div className="d-flex">
              <TextStyles.FontSize14px
                style={{ width: "55%", marginRight: "10%" }}
              >
                Signature
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px style={{ width: "35%" }}>
                Date
              </TextStyles.FontSize14px>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <Styled.ReviewContentBox
            style={{
              minHeight: 227,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              border: "1px dashed #b2b6c5",
              borderRadius: 5,
            }}
          >
            <BorderColorOutlinedIcon
              className="edit-icon"
              onClick={() => setShowModal(true)}
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                "&:hover": { opacity: 1 },
              }}
            />
            <div className="d-flex gap-1 align-items-center">
              <TextStyles.FontSize18px>Contractor</TextStyles.FontSize18px>
              <TextStyles.FontSize14px>
                (
                {businessInfoData && businessInfoData.business_name
                  ? businessInfoData.business_name
                  : data && `${data.firstName} ${data.lastName}`}
                )
              </TextStyles.FontSize14px>
            </div>
            <div>
              <div className="d-flex mt-2">
                <div
                  style={{
                    width: "55%",
                    marginRight: "5%",
                    fontSize: 30,
                    fontFamily: "licorice",
                    fontWeight: 400,
                  }}
                >
                  {selectedData &&
                    selectedData.signature &&
                    selectedData.signature.text && (
                      <div style={{ fontFamily: "licorice" }}>
                        {selectedData.signature.text}
                      </div>
                    )}
                  {selectedData &&
                    selectedData.signature &&
                    selectedData.signature.image &&
                    selectedData.signature.image.image_name && (
                      <img
                        src={selectedData.signature.image.image_url}
                        alt="imageBtn"
                        style={{ maxHeight: 120, maxWidth: 150 }}
                      />
                    )}
                </div>
                <TextStyles.FontSize14px
                  style={{ width: "40%", alignSelf: "end" }}
                >
                  {selectedData &&
                    moment(
                      selectedData.signature &&
                        selectedData.signature.signature_date
                    ).format("DD MMM YYYY")}
                </TextStyles.FontSize14px>
              </div>
              <div className="d-flex">
                <TextStyles.FontSize14px
                  style={{
                    width: "55%",
                    border: "solid 0.5px #b2b6c5",
                    marginRight: "5%",
                    marginTop: 3,
                    marginBottom: 5,
                  }}
                />
                <TextStyles.FontSize14px
                  style={{
                    width: "30%",
                    border: "solid 0.5px #b2b6c5",
                    marginTop: 3,
                    marginBottom: 5,
                  }}
                />
              </div>
              <div className="d-flex">
                <TextStyles.FontSize14px
                  style={{ width: "55%", marginRight: "5%" }}
                >
                  Signature
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px style={{ width: "40%" }}>
                  Date
                </TextStyles.FontSize14px>
              </div>
            </div>
          </Styled.ReviewContentBox>
        </div>
      </div>
      {showModal && (
        <SignProposal
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default RestOne;
