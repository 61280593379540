import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateAddSexualHarassmentCluasePC } from "../../../../../../store/service/proposalAndContractService";
import AppropriateConductModal from "../../PageModals/AppropriateConductModal";
import Colors from "../../../../../../Constants/Colors";

const AppropriateConduct = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Appropriate Conduct";
  const defaultParagraph =
    "Client and Contractor are committed to maintaining a professional relationship free from any form of harassment or offensive conduct. If Contractor experiences any harassing behavior from Client or Client's staff during the term of this Agreement, Contractor will promptly notify Client and request appropriate corrective action. If the reported behavior persists after Contractor's second notice, it will be considered a breach of this Agreement, allowing Contractor to terminate the Agreement in accordance with the Term and Termination Clause. Contractor will be entitled to receive full payment for the ordered project or planned monthly service hours, without prejudice to any other rights or remedies available to Contractor under the law.";

  const heading =
    selectedData &&
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.heading
      ? selectedData.add_sexual_harassment_clause.heading
      : defaultHeading;

  const paragraph =
    selectedData &&
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.paragraph
      ? selectedData.add_sexual_harassment_clause.paragraph
      : defaultParagraph;

  const isAppropriateConductRequired =
    selectedData &&
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.required
      ? true
      : false;

  const toggleAppropriateConductViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      add_sexual_harassment_clause: {
        required: !isAppropriateConductRequired,
        heading,
        paragraph,
        add_sexual_harassment_clause_require: !isAppropriateConductRequired,
      },
    };
    dispatch(updateAddSexualHarassmentCluasePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isAppropriateConductRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleAppropriateConductViewHandler}>
            {isAppropriateConductRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isAppropriateConductRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span
            className={`fw-medium ${
              isAppropriateConductRequired ? "" : "disabled"
            }`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isAppropriateConductRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <AppropriateConductModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          paragraph={paragraph}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
        />
      )}
    </div>
  );
};
export default AppropriateConduct;
