import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const ViewInvoicePDFBox = styled.div`
  width: 100%;
  max-width: 892px;
  height: 100%;
  min-height: 5rem;
  border-radius: 8px 8px 0 0;
  border-top: 7px solid
    ${(props) => (props.bgColor ? `${props.bgColor}` : Colors.primary)};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  .react-pdf__Page {
    width: 100%;
    max-width: 892px;
    height: 100%;
  }
  .react-pdf__Page__canvas {
    width: 100% !important;
    max-width: 892px;
    height: auto !important;
  }
`;

const Oval = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${(props) =>
    props.bgColor ? `${props.bgColor}` : Colors.primary};
`;

const LoadingPdf = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;

  .dot-flashing {
    position: relative;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 5px;
    background-color: ${(props) =>
      props.color ? `${props.color}` : Colors.primary};
    color: ${(props) => (props.color ? `${props.color}` : Colors.primary)};
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -1rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 5px;
    background-color: ${(props) =>
      props.color ? `${props.color}` : Colors.primary};
    color: ${(props) => (props.color ? `${props.color}` : Colors.primary)};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 1rem;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 5px;
    background-color: ${(props) =>
      props.color ? `${props.color}` : Colors.primary};
    color: ${(props) => (props.color ? `${props.color}` : Colors.primary)};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${(props) =>
        props.color ? `${props.color}` : Colors.primary};
    }
    50%,
    100% {
      background-color: ${(props) =>
        props.color ? `${props.color}` : Colors.primary};
      opacity: 0.2;
    }
  }
`;

export default {
  ViewInvoicePDFBox,
  Oval,
  LoadingPdf,
};
