import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SuccessBtn from "../../../../Assets/assetsnew/success-symbol.svg";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { useDispatch } from "react-redux";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";
import { useLocation } from "react-router-dom";

const DoneModal = ({ show, handleClose, data, selectedInvoiceData }) => {
  const location = useLocation();
  const pathname = location && location.pathname;
  const pathnameArray = pathname && pathname.split("/");

  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeFullscreenModal"
    >
      <div className="d-flex justify-content-end">
        <ClearIcon
          sx={{
            fontSize: "27px",
            margin: "1rem",
            color: Colors.font2,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={() => {
            history.push("/db/invoices");
            dispatch(invoiceActions.selectedInvoiceResetAction());
          }}
        />
      </div>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={SuccessBtn} alt="successBtn" className="my-4" />
          <TextStyles.FontSize20px className="font-weight-500 mt-2">
            {`${
              pathnameArray[3] === "resend"
                ? "Invoice Sent Again"
                : "Invoice Sent"
            }`}
          </TextStyles.FontSize20px>
          <TextStyles.GreyFont14px className="mt-2">
            Check your inbox for a confirmation email
          </TextStyles.GreyFont14px>
          <TextStyles.GreyFont14px>{`(${data.email})`}</TextStyles.GreyFont14px>
          <TextStyles.Button style={{ width: "50%" }} className="mt-4">
            <div className="d-flex justify-content-center">
              <div
                style={{ color: Colors.white }}
                onClick={() => {
                  history.push("/db/home");
                  dispatch(invoiceActions.selectedInvoiceResetAction());
                }}
              >
                Go to my desk
              </div>
            </div>
          </TextStyles.Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DoneModal;
