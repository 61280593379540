import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Colors from "../../Constants/Colors";

const navigateTopicButtons = [
  {
    button: "Invoices",
    link: "/help-center/invoices",
  },
  {
    button: "Clients",
    link: "/help-center/clients",
  },
  {
    button: "Projects",
    link: "/help-center/projects",
  },
  {
    button: "Time Tracking",
    link: "/help-center/time-tracking",
  },
];

const StyledPopularTopics = styled.div`
  text-align: center;
  color: ${Colors.white};
  padding-bottom: 4.5rem;
  background-color: ${Colors.bgColorFrontend};
  margin-top: -2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  @media (max-width: 576px) {
    display: block;
    padding-bottom: 3rem;
  }
`;

const Goto = styled(Link)`
  text-decoration: none;
  color: #fff;
  background-color: ${Colors.frontendFont1};
  border-radius: 8px;
  padding: 5px 15px;
  font-size: 14px;
  transition: all 0.3s;

  &:hover {
    color: ${Colors.bgColorFrontend};
    background-color: #fff;
  }
`;

function PopularTopics() {
  return (
    <StyledPopularTopics>
      <p className="mb-3 mb-sm-0">Popular Topics</p>

      <div className="d-flex flex-wrap align-items-center justify-content-center gap-3">
        {navigateTopicButtons.map(({ button, link }) => (
          <Goto key={button} to={link}>
            {button}
          </Goto>
        ))}
      </div>
    </StyledPopularTopics>
  );
}

export default PopularTopics;
