import React from "react";
import styled from "styled-components";

const StyledScreenshot = styled.div`
  margin: 2rem 0 2.5rem;
  border-radius: 5px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
`;

function Screenshot({ src, altTag }) {
  return (
    <StyledScreenshot>
      <img className="w-100" src={src} alt={altTag} />
    </StyledScreenshot>
  );
}

export default Screenshot;
