import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import Styled from "../../projects/createProject/SetupStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import SelectServiceType from "../../Commons/SelectServiceType";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";

const EditLineItemService = ({
  show,
  handleClose,
  selectedData,
  selectedItem,
  selectedIndex,
}) => {
  const dispatch = useDispatch();
  const line_item_services = selectedData && selectedData.line_item_services;
  const {
    register,
    reset,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      service_name: selectedItem && selectedItem.service_name,
      service_rate: selectedItem && selectedItem.service_rate,
      service_rate_type: selectedItem && selectedItem.service_rate_type,
      service_rate_count: selectedItem && selectedItem.service_rate_count,
      desc: selectedItem && selectedItem.desc,
    },
  });

  const serviceRateType = watch("service_rate_type");
  const serviceRate = watch("service_rate");
  const serviceRateCount = watch("service_rate_count");

  let serviceTotal;
  if (serviceRateType === "Flat fee") {
    serviceTotal = serviceRate * 1;
  } else {
    serviceTotal = serviceRate * (serviceRateCount || 1);
  }

  useEffect(() => {
    if (serviceRateType === "Flat fee") setValue("service_rate_count", 1);
  }, [serviceRateType]);

  const formsubmit = (data) => {
    const Obj = {
      service_name: data.service_name,
      service_rate: data.service_rate,
      service_rate_type: data.service_rate_type
        ? data.service_rate_type
        : "Flat fee",
      service_rate_count:
        data.service_rate_count && serviceRateType !== "Flat fee"
          ? parseInt(data.service_rate_count)
          : 1,
      desc: data.desc,
    };
    const totalLineItemServices = [...line_item_services];
    totalLineItemServices[selectedIndex] = Obj;
    dispatch(
      invoiceActions.selectedInvoiceAction({
        line_item_services: totalLineItemServices,
      })
    );
    reset();
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight500px"
      fullscreen
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <TextStyles.FontSize20px className="font-weight-500">
            Edit Line Item
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="service_name" className="mb-2">
              Service name
            </label>
            <div className="d-flex flex-row align-items-center">
              <TextStyles.InputRectangle
                id="service_name"
                type="text"
                placeholder="Service name"
                name="service_name"
                {...register("service_name", {
                  required: {
                    value: true,
                    message: "Service name is required.",
                  },
                  minLength: {
                    value: 3,
                    message: "Service name must be at least 3 characters long.",
                  },
                })}
              />
            </div>
            {errors && errors.service_name && (
              <TextStyles.InValidFeedback>
                {errors.service_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="rate" className="mb-2">
              Rate
            </label>

            <div className="d-flex flex-wrap align-items-center">
              <Styled.inputBox
                style={{ maxWidth: 120, display: "flex", alignItems: "center" }}
                className="me-3"
              >
                <span>
                  {selectedData &&
                    selectedData.currency &&
                    selectedData.currency.symbol}
                </span>
                <input
                  style={{ width: 60 }}
                  type="number"
                  step="0.01"
                  min={"0"}
                  id="service_rate"
                  name="service_rate"
                  placeholder="00.00"
                  {...register("service_rate", {
                    required: {
                      value: true,
                      message: "Rate is required.",
                    },
                    min: {
                      value: 0.01,
                      message: "Rate must be greater than 0.01.",
                    },
                  })}
                />
              </Styled.inputBox>
              <div className="me-2">
                <Controller
                  control={control}
                  name="service_rate_type"
                  render={({ field: { onChange, value } }) => (
                    <SelectServiceType
                      placeholder="Select"
                      onChange={onChange}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
              {serviceRateType && serviceRateType !== "Flat fee" && (
                <TextStyles.FontSize14px className="d-flex flex-wrap align-items-center">
                  <CloseIcon
                    className="me-2"
                    sx={{ color: Colors.font2, fontSize: 20 }}
                  />
                  <Styled.inputBox style={{ maxWidth: 60 }} className="me-2">
                    <input
                      type="number"
                      step="0.01"
                      placeholder="00"
                      // defaultValue={1}
                      name="service_rate_count"
                      {...register("service_rate_count", {
                        required: {
                          value: true,
                          message: "Count is required.",
                        },
                        min: {
                          value: 1,
                          message: "Count must be greater than 0.",
                        },
                      })}
                      style={{ width: 30, textAlign: "center" }}
                    />
                  </Styled.inputBox>
                  <TextStyles.FontSize14px>
                    {serviceRateType === "Per hour"
                      ? "hours"
                      : serviceRateType === "Per day"
                      ? "days"
                      : serviceRateType === "Per item"
                      ? "items"
                      : serviceRateType === "Per word"
                      ? "words"
                      : ""}
                  </TextStyles.FontSize14px>
                </TextStyles.FontSize14px>
              )}
            </div>
            {errors && errors.service_rate && (
              <TextStyles.InValidFeedback className="mt-1">
                {errors.service_rate.message}
              </TextStyles.InValidFeedback>
            )}

            {errors && errors.service_rate_count && (
              <TextStyles.InValidFeedback className="mt-1">
                {errors.service_rate_count.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="description" className="mb-2">
              Description
            </label>
            <TextStyles.Textarea
              rows={7}
              id="description"
              name="desc"
              placeholder="Add a note..."
              {...register("desc", {
                // required: {
                //   value: true,
                //   message: "Description is required.",
                // },
                // minLength: {
                //   value: 20,
                //   message: "Description must be at least 20 character long.",
                // },
              })}
            />
            {/* {errors && errors.desc && (
              <TextStyles.InValidFeedback>
                {errors.desc.message}
              </TextStyles.InValidFeedback>
            )} */}
          </TextStyles.FontSize14px>

          <div
            className="mb-3"
            style={{
              width: "100%",
              height: "1px",
              border: "0.5px solid #edeef2",
            }}
          />
          <TextStyles.FontSize18px className="mb-3">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-start">
                  Total Service Charges
                </div>
              </div>
              <div className="col">
                <span
                  className="d-flex justify-content-end"
                  style={{ color: Colors.primary }}
                >
                  {selectedData &&
                    selectedData.currency &&
                    selectedData.currency.symbol}

                  {serviceTotal > 0
                    ? parseFloat(serviceTotal).toFixed(2)
                    : "00.00"}
                </span>
              </div>
            </div>
          </TextStyles.FontSize18px>
          <div
            className="mb-3"
            style={{
              width: "100%",
              height: "1px",
              border: "0.5px solid #edeef2",
            }}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button>Save</TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditLineItemService;
