import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SetupBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 6px;
  position: relative;
  border-top: 7px solid
    ${(props) => (props.bgColor ? props.bgColor : Colors.primary)};
  background-color: ${Colors.white};
  .review_box_padding {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .review_box_padding_left {
    padding-left: 3rem;
  }

  .review_box_padding_right {
    padding-right: 3rem;
  }

  .review_box_table_padding {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .edit_invoice_service .edit_invoice_serviceBtn {
    visibility: hidden;
  }
  .edit_invoice_service:hover .edit_invoice_serviceBtn {
    visibility: visible;
  }

  .edit_line_item_service .edit_line_item_serviceBtn {
    visibility: hidden;
  }

  .edit_line_item_service:hover .edit_line_item_serviceBtn {
    visibility: visible;
  }

  .edit_line_item_service .delete_line_item_serviceBtn {
    visibility: hidden;
  }
  .edit_line_item_service:hover .delete_line_item_serviceBtn {
    visibility: visible;
  }

  .invoice_taxes .edit_invoice_taxBtn {
    visibility: hidden;
  }
  .invoice_taxes:hover .edit_invoice_taxBtn {
    visibility: visible;
  }

  .invoice_taxes .delete_invoice_taxBtn {
    visibility: hidden;
  }
  .invoice_taxes:hover .delete_invoice_taxBtn {
    visibility: visible;
  }

  .invoice_discount .edit_invoice_discountBtn {
    visibility: hidden;
  }
  .invoice_discount:hover .edit_invoice_discountBtn {
    visibility: visible;
  }

  .invoice_discount .delete_invoice_discountBtn {
    visibility: hidden;
  }
  .invoice_discount:hover .delete_invoice_discountBtn {
    visibility: visible;
  }

  .invoice_notes .edit_invoice_NoteBtn {
    visibility: hidden;
  }

  .invoice_notes:hover .edit_invoice_NoteBtn {
    visibility: visible;
  }

  .invoice_notes .delete_invoice_NoteBtn {
    visibility: hidden;
  }

  .invoice_notes:hover .delete_invoice_NoteBtn {
    visibility: visible;
  }

  .invoice_business .edit_invoice_businessBtn {
    visibility: hidden;
  }

  .invoice_business:hover .edit_invoice_businessBtn {
    visibility: visible;
  }

  .invoice_client_info .edit_invoice_client_infoBtn {
    visibility: hidden;
  }

  .invoice_client_info:hover .edit_invoice_client_infoBtn {
    visibility: visible;
  }

  .edit_invoice_title .edit_invoice_titleBtn {
    visibility: hidden;
  }

  .edit_invoice_title:hover .edit_invoice_titleBtn {
    visibility: visible;
  }

  .edit_invoice_dates .edit_invoice_datesBtn {
    visibility: hidden;
  }

  .edit_invoice_dates:hover .edit_invoice_datesBtn {
    visibility: visible;
  }
  .edit_elient_info_box {
    min-height: 178px;
  }

  @media only screen and (max-width: 992px) and (min-width: 768px) {
    .review_box_padding {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .review_box_padding_left {
      padding-left: 2rem;
    }

    .review_box_padding_right {
      padding-right: 2rem;
    }
    .review_box_table_padding {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media only screen and (max-width: 768px) and (min-width: 576px) {
    .review_box_padding {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .review_box_padding_left {
      padding-left: 1rem;
    }

    .review_box_padding_right {
      padding-right: 1rem;
    }
    .review_box_table_padding {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }

  @media (max-width: 576px) {
    .review_box_padding {
      padding-left: 0rem;
      padding-right: 0rem;
    }
    .review_box_padding_left {
      padding-left: 0rem;
    }

    .review_box_padding_right {
      padding-right: 0rem;
    }
    .review_box_table_padding {
      padding-left: 0rem;
      padding-right: 0rem;
    }
    .edit_elient_info_box {
      min-height: auto;
    }
  }
`;

const inputBox = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  margin-right: 3px;
  background: ${Colors.white};
  height: 3.437rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.font2)};
`;

const Selected = styled.div`
  padding: 1rem 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: ${Colors.backgroundPink};
  color: ${Colors.primary};
`;

const NotSelected = styled(Selected)`
  color: ${Colors.font2} !important;
  background-color: ${Colors.tableHeadColor};
`;

const SelectorRectangle = styled.div`
  width: 6.625rem;
  height: 3.5rem;
  display: flex;
  border-radius: 1rem;
  border: solid 1px #f0eefa;
  background-color: ${Colors.white};
`;

const InputBox = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.font2)};
  background-color: ${(props) => (props.invalid ? "#e7d5d5" : Colors.white)};
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  min-width: fit-content;
  min-width: 6rem;
  padding: 0 1.5rem;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

const BrandLogoBox = styled.div`
  padding: 1rem 0;
  height: 9.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: ${(props) =>
    props.logoAlignment === "left"
      ? "start"
      : props.logoAlignment === "right"
      ? "end"
      : props.logoAlignment};
  border-radius: 0.75rem;
  box-sizing: border-box;
  position: relative;

  .brand_and_logo_tooltip {
    display: none;
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #edeef2;
    color: ${Colors.font1};
    padding: 9px 12px;
    border-radius: 6px;
    font-size: 0.875rem;
    white-space: nowrap;

    &::after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 8px;
      border-style: solid;
      border-color: #edeef2 transparent transparent transparent;
    }
  }

  &: hover .brand_and_logo_tooltip {
    display: inline-block;
  }
`;

export default {
  SetupBox,
  inputBox,
  Selected,
  NotSelected,
  SelectorRectangle,
  InputBox,
  NextButton,
  BrandLogoBox,
};
