import React from "react";

import SideContentList from "../SideContentList";

import TextStyles from "../../../../../Constants/TextStyles";

import newInvoiceImg from "../../../../../Assets/frontendImages/faq-new-invoice.webp";
import invoiceExistingProjectImg from "../../../../../Assets/frontendImages/faq-invoice-existing-project.webp";
import invoiceServicesImg from "../../../../../Assets/frontendImages/faq-invoice-services.webp";
import invoiceReviewImg from "../../../../../Assets/frontendImages/faq-invoice-review.webp";
import emailPreviewImg from "../../../../../Assets/frontendImages/faq-invoice-existing-project-review.webp";
import invoiceSendImg from "../../../../../Assets/frontendImages/faq-invoice-send.webp";
import Screenshot from "../../../../UIs/Screenshot";

function HowToCreateInvoiceForExistingProject({ idList, activeContentId }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          How to Create an Invoice for an Existing Project
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Generating an invoice for an existing project in Zodot is quick and
          hassle-free. With the right details in place, you can generate a
          professional invoice that reflects project costs, taxes, and payment
          terms.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          Follow these simple steps to create an invoice for an existing project
          in Zodot:
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px
          id={idList[0]}
          className="fw-semibold mb-1 contentHeading"
        >
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Log in to your Zodot account.</li>
          <li className="mb-1">
            From the left sidebar menu, click on Invoices.
          </li>
          <li className="mb-1">
            Click the “+ New Invoice” button to create a new invoice.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-1">
          Alternatively, you can also create an invoice from the top navbar:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click on the “+ Create” button in the top-right corner.
          </li>
          <li className="mb-1">Select Invoice from the dropdown menu.</li>
        </ul>

        <Screenshot src={newInvoiceImg} alt="new invoice img" />

        <TextStyles.FontSize16px
          id={idList[1]}
          className="fw-semibold mb-1 contentHeading"
        >
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            After clicking "New Invoice," you can choose your preferred method
            for creating the invoice.
          </li>
          <li className="mb-1">
            To generate an invoice for an ongoing project, navigate to the
            "Existing Projects" section.
          </li>
          <li className="mb-1">
            Click on "Select Project" to see a list of available projects and
            choose the relevant project from the list.
          </li>
          <li className="mb-1">Then, click on the next button.</li>
        </ul>

        <Screenshot
          src={invoiceExistingProjectImg}
          alt="existing project invoice img"
        />

        <TextStyles.FontSize16px
          id={idList[2]}
          className="fw-semibold mb-1 contentHeading"
        >
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            The services associated with the selected project will be
            automatically displayed.
          </li>
          <li className="mb-1">
            Modify the quantity for each service as needed.
          </li>
          <li className="mb-1">
            Review the total service charges for accuracy.
          </li>
          <li className="mb-1">Click "Next" to proceed.</li>
        </ul>

        <Screenshot src={invoiceServicesImg} alt="invoice services img" />

        <TextStyles.FontSize16px
          id={idList[3]}
          className="fw-semibold mb-3 contentHeading"
        >
          4. {idList[3]}
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          In this screen, you can review and edit the following details -
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Client Information </li>
          <li className="mb-1">Your Business Details</li>
          <li className="mb-1">Project Details </li>
          <li className="mb-1">Preferred Currency</li>
          <li className="mb-1">Invoice Issue and Due Date</li>
          <li className="mb-1">Your Branding</li>
          <li className="mb-1">Invoice Label</li>
        </ul>

        <TextStyles.FontSize16px className="mb-3">
          You can further customize the invoice by adding the following
          additional details as needed:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            <span className="fw-medium">Taxes:</span> Apply applicable taxes to
            ensure accurate billing.
          </li>
          <li className="mb-1">
            <span className="fw-medium">Discounts:</span> Include any special
            discounts or promotional offers.
          </li>
          <li className="mb-1">
            <span className="fw-medium">Line Items:</span> Add extra services or
            products beyond the predefined project scope.
          </li>
          <li className="mb-1">
            <span className="fw-medium">Notes for the Client:</span> Provide
            important details, payment instructions, or personalized messages to
            enhance clarity and professionalism.
          </li>
          <li className="mb-1">
            These options will help you to tailor the invoice to meet specific
            project and client requirements.{" "}
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-3">
          Once all details are finalized, you can download the invoice for your
          records or click on the “Next” button to share it with the client.
        </TextStyles.FontSize16px>

        <Screenshot src={invoiceReviewImg} alt="new invoice img" />

        <TextStyles.FontSize16px
          id={idList[4]}
          className="fw-semibold mb-1 contentHeading"
        >
          5. {idList[4]}
        </TextStyles.FontSize16px>

        <ul className="mb-4">
          <li className="mb-1">
            In the compose email section, the user can select or deselect
            recipients from existing contacts or add a new one as needed.
          </li>
          <li className="mb-1">
            Use a pre-saved email template, create a new one, or modify the
            default template to suit your requirements.
          </li>
          <li className="mb-1">
            Click "Preview Email" to review the message before sending.
          </li>

          <Screenshot src={emailPreviewImg} alt="new invoice img" />

          <li className="mb-1">
            Once satisfied, click "Send" to deliver the invoice email to the
            client.
          </li>
        </ul>

        <Screenshot src={invoiceSendImg} alt="invoice send img" />

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, Your invoice is generated for the existing project
          and linked to the project now.
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} activeContentId={activeContentId} />
    </>
  );
}

export default HowToCreateInvoiceForExistingProject;
