import React from "react";

import TextStyles from "../../../Constants/TextStyles";
import Colors from "../../../Constants/Colors";

function ToggleDefaultValues({ register }) {
  return (
    <TextStyles.CheckBox className="d-flex align-items-center gap-2">
      <input
        id="checkbox"
        className="form-check-input"
        type="checkbox"
        {...register("checkbox")}
      />
      <label
        htmlFor="checkbox"
        style={{ fontSize: 14, color: Colors.font1, marginTop: 2 }}
      >
        Use default values?
      </label>
    </TextStyles.CheckBox>
  );
}

export default ToggleDefaultValues;
