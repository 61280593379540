import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateResponsibilityPC } from "../../../../../../store/service/proposalAndContractService";
import ResponsibilityModal from "../../PageModals/ResponsibilityModal";
import Colors from "../../../../../../Constants/Colors";

const Responsibility = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Responsibility of Contractor";
  const defaultParagraph =
    "A. Contractor agrees to deliver the services in accordance with the Delivery Schedule provided for in Schedule A attached hereto. Contractor’s failure to meet the dates provided for in this Schedule A shall constitute a material breach of this Agreement.\n\nB. Contractor shall or shall cause one of its clients to attend Clients’ regularly scheduled meetings relating to the status of the Project. Moreover, Contractor shall provide Client with a written report each month on the status of the development of the services.";

  const heading =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.heading
      ? selectedData &&
        selectedData.responsibility &&
        selectedData.responsibility.heading
      : defaultHeading;
  const paragraph =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.paragraph
      ? selectedData &&
        selectedData.responsibility &&
        selectedData.responsibility.paragraph
      : defaultParagraph;

  const isResponsibilityRequired =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.required
      ? true
      : false;

  const toggleResponsibilityViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      responsibility: {
        required: !isResponsibilityRequired,
        heading,
        paragraph,
      },
    };
    dispatch(updateResponsibilityPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isResponsibilityRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleResponsibilityViewHandler}>
            {isResponsibilityRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isResponsibilityRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span
            className={`fw-medium ${
              isResponsibilityRequired ? "" : "disabled"
            }`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isResponsibilityRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <ResponsibilityModal
          paragraph={paragraph}
          heading={heading}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Responsibility;
