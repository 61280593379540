import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const PdfViewerContainer = styled.div`
  position: relative;
  background-color: ${Colors.white};
  // border-top: 7px solid ${Colors.primary};
  // box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    width: 100%;
    height: 100%;
    // border: none;
    background-color: ${Colors.white};
  }
`;

const Oval = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${(props) =>
    props.bgColor ? `${props.bgColor}` : Colors.primary};
`;

export default {
  PdfViewerContainer,
  Oval,
};
