import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const PrivacyData = (props) => {
  const selectedData = props && props.selectedData;

  const paragraph =
    selectedData &&
    selectedData.privacy_data &&
    selectedData.privacy_data.paragraph
      ? selectedData.privacy_data.paragraph
      : "";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.privacy_data &&
          selectedData.privacy_data.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox>
        <TextStyles.FontSize20px className="mb-3 fw-medium overflow-hidden">
          Data Privacy and Security
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg overflow-hidden">
          {paragraph ? (
            paragraph
          ) : (
            <>
              <div>
                <TextStyles.FontSize18px>
                  A. Definitions:
                </TextStyles.FontSize18px>
                <div className="mt-2">
                  <div className="mt-2 ms-3">
                    1. "Borrower Data" means all information, including Personal
                    Data, related to the Client ("Borrower").
                  </div>
                  <div className="mt-2 ms-3">
                    2. "Client Data" refers to Personal Data, Employment Data,
                    and other information provided by or processed for the
                    Client by the Contractor or third parties on behalf of the
                    Client.
                  </div>
                  <div className="mt-2 ms-3">
                    3. "Data Protection Laws" encompasses data privacy and
                    protection laws, including the European Community Data
                    Protection Directive and other applicable laws.
                  </div>
                  <div className="mt-2 ms-3">
                    4. "Notices" includes filings, communications, press
                    releases, or reports related to any Security Breach.
                  </div>
                  <div className="mt-2 ms-3">
                    5. "Personal Data" covers any information related to an
                    identified or identifiable natural person, including names,
                    addresses, Social Security numbers, financial information,
                    and health-related data.
                  </div>
                  <div className="mt-2 ms-3">
                    6. "Processing" refers to any operation performed on Client
                    Data, such as collection, storage, or disclosure.
                  </div>
                  <div className="mt-2 ms-3">
                    7. "Security Breach" indicates any unauthorized access, use,
                    or loss of Client Data compromising its security,
                    confidentiality, or integrity.
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <TextStyles.FontSize18px>
                  B. Data Processing:
                </TextStyles.FontSize18px>
                <TextStyles.FontSize14px className="mt-2">
                  Contractor will process Client Data only as required for the
                  Project's services or as instructed by the Client in writing.
                  For Employment Data, Contractor will adhere to Client policy
                  or an agreed-upon policy.
                </TextStyles.FontSize14px>
              </div>
              <div className="mt-3">
                <TextStyles.FontSize18px>
                  C. Data Security:
                </TextStyles.FontSize18px>
                <TextStyles.FontSize14px className="mt-2">
                  Contractor shall provide Client with data security system
                  information upon request. Contractor will comply with the
                  Client's applicable data security policies and implement
                  measures to ensure data security, confidentiality, and
                  prevention of unauthorized access.
                </TextStyles.FontSize14px>
              </div>
              <div className="mt-3">
                <TextStyles.FontSize18px>
                  D. European Community:
                </TextStyles.FontSize18px>
                <TextStyles.FontSize14px className="mt-2">
                  If Personal Data is transferred outside the European
                  Community, both parties will comply with applicable Data
                  Protection Laws, including executing data transfer agreements
                  or notifying local Data Protection Authorities.
                </TextStyles.FontSize14px>
              </div>
              <div className="mt-3">
                <TextStyles.FontSize18px>
                  E. Agreement with Third Parties:
                </TextStyles.FontSize18px>
                <TextStyles.FontSize14px className="mt-2">
                  Contractor will ensure that any third parties involved in
                  providing services maintain adequate data security measures
                  consistent with Contractor's obligations.
                </TextStyles.FontSize14px>
              </div>
              <div className="mt-3">
                <TextStyles.FontSize18px>
                  F. Security Breach Notification and Costs:
                </TextStyles.FontSize18px>
                <TextStyles.FontSize14px className="mt-2">
                  Contractor will promptly notify Client of any Security Breach
                  involving Client Data and take necessary actions to
                  investigate, prevent, and mitigate the impact. Contractor will
                  provide relevant information for Client's compliance with
                  applicable laws and reimburse Client for costs incurred due to
                  a Security Breach. Any voluntary actions will be equitably
                  allocated between the parties.
                </TextStyles.FontSize14px>
              </div>
            </>
          )}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default PrivacyData;
