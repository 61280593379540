import React, { useState, useRef } from "react";
import Styled from "./UsersStyles";
import TextStyles from "../../../../Constants/TextStyles";
import moment from "moment";
import Colors from "../../../../Constants/Colors";
import { Popover } from "antd";
import Person3TwoToneIcon from "@mui/icons-material/Person3TwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import FmdGoodTwoToneIcon from "@mui/icons-material/FmdGoodTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import ContactMailTwoToneIcon from "@mui/icons-material/ContactMailTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../../store/storage/adminSlice";
import SelectDate from "../../Commons/SelectDate";
import LoaderSpin from "../../Commons/LoaderSpin";
import {
  downloadUsers,
  fetchUsers,
} from "../../../../store/service/AdminService";
import { useEffect } from "react";
import PaginationUI from "../../Commons/Pagination";
import NoUsersImage from "../../../../Assets/assetsnew/no-users.svg";
import FilterCountry from "../../Commons/FilterCountry";
import { ReactTooltip } from "../../Commons/ReactTooltip";
import SearchComponent from "../../Commons/SearchComponent";
import { useForm, Controller } from "react-hook-form";
import SelectUserType from "../../Commons/SelectUserType";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreUsersInfo from "./MoreUsersInfo";
import SelectActiveAndInactiveUsers from "../../Commons/SelectActiveAndInactiveUsers";
import { filteredDateRangeFunction } from "../../Commons/utils";
import { message } from "antd";
import { CSVLink } from "react-csv";

const Users = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [downloadData, setDownloadData] = useState([]);
  const csvLinkRef = useRef(null);

  const { totalUsers, isLoading, totalUsersCount } = useSelector(
    (state) => state.admin
  );

  const toggleFilter = useSelector(
    (state) => state.admin.toggleActiveUsersFilter
  );

  const filteredData = useSelector(
    (state) => state.admin.filteredActiveUsersData
  );

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;

  const filterdCountry =
    filteredData && filteredData.filterdCountry
      ? filteredData.filterdCountry
      : null;

  const filterdUserType =
    filteredData && filteredData.filterdUserType
      ? filteredData.filterdUserType
      : "All users";

  const filterdUsers =
    filteredData && filteredData.filterdUsers
      ? filteredData.filterdUsers
      : "All users";

  const filterdSearchValue =
    filteredData && filteredData.filterdSearchValue
      ? filteredData.filterdSearchValue
      : null;

  const sortingData = useSelector((state) => state.admin.activeUserSortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "createdAt";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const { setValue, control, handleSubmit } = useForm({
    defaultValues: {
      search: filterdSearchValue ? filterdSearchValue : "",
    },
  });

  useEffect(() => {
    setValue("search", filterdSearchValue);
  }, [filterdSearchValue]);

  const toggleShowFilter = () => {
    dispatch(adminActions.toggleActiveUsersAction());
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      adminActions.sortingActiveUserAction({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      adminActions.sortingActiveUserAction({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [
    filteredData,
    itemsPerPage,
    // updateRefresh,
  ]);

  useEffect(() => {
    if (totalUsers && totalUsers.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [totalUsers]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const { start_time, end_time } =
      filteredDateRangeFunction(filterdDateRange);

    const Obj = {
      searchValue: filterdSearchValue,
      country: filterdCountry,
      users: filterdUsers,
      userType: filterdUserType,
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    dispatch(fetchUsers(Obj));
  }, [filteredData, page, itemsPerPage, sortingData]);

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_name">
            <Person3TwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "name"}
              value="name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_email">
            <EmailTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Email</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_email"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "email"}
              value="email"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_country">
            <FmdGoodTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Country</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_country"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "country"}
              value="country"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_createdAt">
            <CalendarMonthTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">CreatedAt</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_createdAt"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "createdAt"}
              value="createdAt"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_lastLogin">
            <AccessTimeTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Last LoggedIn</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_lastLogin"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "lastLogin"}
              value="lastLogin"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_userType">
            <ContactMailTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">User Type</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_userType"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "userType"}
              value="userType"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_status">
            <ContactMailTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Status</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_status"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "isActive"}
              value="isActive"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>

          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>

          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const sortingActiveHandler = (selectedData) => {
    dispatch(
      adminActions.sortingActiveUserAction({
        selectedSortingOne: selectedData,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  const formSubmit = (data) => {
    const searchValue = data && data.search && data.search.trim();
    dispatch(
      adminActions.filteredActiveUsersAction({
        filterdSearchValue: searchValue,
      })
    );
  };

  const resetSearchHandler = (data) => {
    dispatch(
      adminActions.filteredActiveUsersAction({
        filterdSearchValue: "",
      })
    );
  };

  const downloadUsersHandler = () => {
    const { start_time, end_time } =
      filteredDateRangeFunction(filterdDateRange);

    const Obj = {
      searchValue: filterdSearchValue,
      country: filterdCountry,
      users: filterdUsers,
      userType: filterdUserType,
      start_time,
      end_time,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };

    dispatch(downloadUsers(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        const totalUsers = data.payload.data;
        const formattedData = totalUsers.map((user) => {
          return {
            Name: user.name,
            Email: user.email,
            Country: user.country
              ? `${user.country.name}(${user.country.code})`
              : "",
            "Created At": moment(user.createdAt).format("DD/MM/YYYY"),
            "Last Logged In": moment(user.lastLogin).format("DD/MM/YYYY"),
            "User Type": user.userType,
            Status: user.isActive,
            Clients: user.totalClients,
            Projects: user.totalProjects,
            Invoices: user.totalInvoices,
            Tasks: user.totalTasks,
            Agreements: user.totalAgreements,
            Transactions: user.totalTransactions,
            "Timer Activities": user.totalActivities,
          };
        });
        setDownloadData(formattedData);
        setTimeout(() => {
          if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
          }
        }, 0);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-3">
        <TextStyles.FontSize16px className="font-weight-500 mt-3 mt-md-4">
          User Details
        </TextStyles.FontSize16px>
        <div className="d-flex justify-content-end align-items-center ms-auto">
          <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
            Filter
          </TextStyles.HoverEffectSpan>

          <div
            className="client-partion-rectangle mx-2"
            style={{
              width: "2px",
              height: "23px",
              backgroundColor: "#7d849e",
            }}
          />
          <TextStyles.FontSize16px>
            <Popover
              placement="bottomRight"
              // title={"Hello"}
              content={sortingContent}
              trigger="click"
              open={openSorting}
              onOpenChange={handleOpenSortingChange}
            >
              <TextStyles.HoverEffectSpan sort={true}>
                Sort
                <ArrowDropDownOutlinedIcon
                  sx={{
                    color: Colors.font2,
                    fontSize: 27,
                    transform: openSorting ? "rotate(180deg)" : "",
                  }}
                />
              </TextStyles.HoverEffectSpan>
            </Popover>
          </TextStyles.FontSize16px>

          <div
            style={{
              width: 40,
              height: 40,
              background: Colors.primary,
              borderRadius: "50%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={downloadUsersHandler}
          >
            <SaveAltIcon sx={{ color: Colors.white, fontSize: 25 }} />
          </div>

          <CSVLink
            data={downloadData}
            filename="user_data.csv"
            ref={csvLinkRef}
            style={{ display: "none" }}
          />
        </div>
      </div>
      {toggleFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>

            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  adminActions.filteredActiveUsersAction({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Select country
            </TextStyles.FontSize14px>

            <FilterCountry
              defaultValue={filterdCountry && filterdCountry}
              placeholder="Select country"
              onChange={(value) => {
                dispatch(
                  adminActions.filteredActiveUsersAction({
                    filterdCountry: value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Select users status
            </TextStyles.FontSize14px>
            <SelectActiveAndInactiveUsers
              defaultValue={filterdUsers && filterdUsers}
              placeholder="Select users status"
              onChange={(e) => {
                dispatch(
                  adminActions.filteredActiveUsersAction({
                    filterdUsers: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Select user type
            </TextStyles.FontSize14px>
            <SelectUserType
              defaultValue={filterdUserType && filterdUserType}
              placeholder="Select user type"
              onChange={(e) => {
                dispatch(
                  adminActions.filteredActiveUsersAction({
                    filterdUserType: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <form onSubmit={handleSubmit(formSubmit)}>
              <TextStyles.FontSize14px className="d-flex mb-2">
                Search user
              </TextStyles.FontSize14px>

              <Controller
                control={control}
                id="search"
                name="search"
                render={({ field: { onChange, value } }) => (
                  <SearchComponent
                    placeholder="Search user"
                    // onChange={onChange}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      onChange(inputValue);
                      if (!inputValue || inputValue.trim() === "") {
                        resetSearchHandler();
                      }
                    }}
                    defaultValue={value}
                  />
                )}
              />
            </form>
          </div>
        </div>
      )}

      <Styled.TableHead className="mt-3">
        <div className="d-flex gap-2 align-items-center font-weight-500">
          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "100%", minWidth: 30, maxWidth: 180 }}
          >
            <ReactTooltip anchorId="name" content="Sort by Name" />
            <span
              className="three-dots hover-name"
              id="name"
              role="button"
              onClick={() => sortingActiveHandler("name")}
            >
              Name
              {selectedSortingOne === "name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-name"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-name"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "100%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="email" content="Sort by Email" />
            <span
              className="three-dots hover-email"
              id="email"
              role="button"
              onClick={() => sortingActiveHandler("email")}
            >
              Email
              {selectedSortingOne === "email" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-email"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-email"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "100%", minWidth: 30, maxWidth: 140 }}
          >
            <ReactTooltip anchorId="country" content="Sort by Country" />
            <span
              className="three-dots hover-country"
              id="country"
              role="button"
              onClick={() => sortingActiveHandler("country")}
            >
              Country
              {selectedSortingOne === "country" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-country"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-country"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "100%", minWidth: 30, maxWidth: 120 }}
          >
            <ReactTooltip anchorId="createdAt" content="Sort by CreatedAt" />
            <span
              className="three-dots hover-createdAt"
              id="createdAt"
              role="button"
              onClick={() => sortingActiveHandler("createdAt")}
            >
              CreatedAt
              {selectedSortingOne === "createdAt" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "90%", minWidth: 30, maxWidth: 120 }}
          >
            <ReactTooltip
              anchorId="lastLogin"
              content="Sort by Last LoggedIn"
            />
            <span
              className="three-dots hover-lastLogin"
              id="lastLogin"
              role="button"
              onClick={() => sortingActiveHandler("lastLogin")}
            >
              Last LoggedIn
              {selectedSortingOne === "lastLogin" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-lastLogin"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-lastLogin"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "100%", minWidth: 30, maxWidth: 120 }}
          >
            <ReactTooltip anchorId="userType" content="Sort by User Type" />
            <span
              className="three-dots hover-userType"
              id="userType"
              role="button"
              onClick={() => sortingActiveHandler("userType")}
            >
              User Type
              {selectedSortingOne === "userType" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-userType"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-userType"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "100%", minWidth: 30, maxWidth: 110 }}
          >
            <ReactTooltip anchorId="status" content="Sort by Status" />
            <span
              className="three-dots hover-status"
              id="status"
              role="button"
              onClick={() => sortingActiveHandler("isActive")}
            >
              Status
              {selectedSortingOne === "status" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-status"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-status"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px
            className="d-flex justify-content-end"
            style={{
              width: "100%",
              maxWidth: 35,
              minWidth: 35,
            }}
          />
        </div>
      </Styled.TableHead>
      {totalUsers && totalUsers.length > 0 ? (
        totalUsers.map((item, index) => {
          return (
            <Styled.TableCell
              key={index}
              style={{
                borderRadius:
                  totalUsers.length - 1 === index ? "0 0 12px 12px" : "",
              }}
            >
              <div
                className={`d-flex gap-2 align-items-center w-100`}
                onClick={() => {
                  setShowModal(true);
                  setSelectedData(item);
                }}
              >
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start text-start"
                  style={{ width: "100%", minWidth: 30, maxWidth: 180 }}
                >
                  <span className="three-dots">{item && item.name}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "100%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.email}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "100%", minWidth: 30, maxWidth: 140 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.country &&
                      `${item.country.name}(${item.country.code})`}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "100%", minWidth: 30, maxWidth: 120 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.createdAt &&
                      moment(item && item.createdAt).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "100%", minWidth: 30, maxWidth: 120 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.lastLogin &&
                      moment(item && item.lastLogin).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "100%", minWidth: 30, maxWidth: 120 }}
                >
                  <span className="three-dots">{item && item.userType}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "100%", minWidth: 30, maxWidth: 110 }}
                >
                  <span
                    className="three-dots"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.2rem",
                      fontWeight: 600,
                      boxSizing: "border-box",
                      padding: "0.25rem 0.5rem",
                      maxHeight: "1.5rem",
                      background:
                        item.isActive === "Inactive"
                          ? "#FFF5E6"
                          : item.isActive === "Active"
                          ? "#DDF8EC"
                          : "",

                      color:
                        item.isActive === "Inactive"
                          ? "#f09528"
                          : item.isActive === "Active"
                          ? "#188652"
                          : "",
                    }}
                  >
                    {item && item.isActive}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end"
                  style={{
                    width: "100%",
                    maxWidth: 35,
                    minWidth: 35,
                  }}
                >
                  <VisibilityOutlinedIcon
                    sx={{
                      color: Colors.primary,
                      fontSize: 30,
                      background: Colors.white,
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </TextStyles.FontSize14px>
              </div>
            </Styled.TableCell>
          );
        })
      ) : isLoading && totalUsers && totalUsers.length === 0 ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isLoading &&
        totalUsers &&
        totalUsers.length === 0 && (
          <Styled.ListBox>
            <img src={NoUsersImage} alt="no-users-img" />
            <TextStyles.GreyFont16px className="font-weight-500 mt-2">
              There are no users yet.
            </TextStyles.GreyFont16px>
          </Styled.ListBox>
        )
      )}

      {totalUsersCount > itemsPerPage && (
        <div className="d-flex justify-content-center py-4">
          <PaginationUI
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(totalUsersCount / itemsPerPage)}
            page={page}
          />
        </div>
      )}
      {showModal && (
        <MoreUsersInfo
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default Users;
