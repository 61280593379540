import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Fuse from "fuse.js";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import { updateFaqs } from "../../store/storage/faqSlice";
import Colors from "../../Constants/Colors";

import formFrame1 from "../../Assets/frontendImages/search-faq-frame-1.webp";
import formFrame2 from "../../Assets/frontendImages/search-faq-frame-2.webp";

const faqs = [
  {
    ques: "How to Get Started",
    ans: "Zodot makes it easy for freelancers and small business owners to manage their business efficiently. Follow these simple steps to get started: When you first sign up or log in to Zodot, you will see a setup screen prompting you to complete your account setup.",
    link: "/help-center/get-started/how-to-get-started",
  },
  {
    ques: "Add Your User Information",
    ans: "Keeping your profile information updated in Zodot ensures that your clients see the correct details when working with you.",
    link: "/help-center/get-started/add-your-user-information",
  },
  {
    ques: "Add Your Business Information",
    ans: "Setting up your business information correctly in Zodot ensures that your invoices, proposals, and client communications display the right details. Follow these steps to configure your business details.",
    link: "/help-center/get-started/add-your-business-info",
  },
  {
    ques: "Configure Your Preferred Currency",
    ans: "Zodot allows users to set their preferred currency for transactions, invoices, and financial reports. Follow the below steps to set up your currency.",
    link: "/help-center/get-started/configure-your-preferred-currency",
  },
  {
    ques: "Set Up Your Brand",
    ans: "Branding is essential for making your business stand out. In Zodot, you can personalize your brand color and logo to reflect your identity across invoices, proposals, and emails.",
    link: "/help-center/get-started/set-up-your-brand",
  },

  {
    ques: "How to Create a Client",
    ans: "Creating a client in Zodot is simple and helps you keep track of client details effortlessly. By following the below steps, you can ensure your client database remains organized and easily accessible. Follow these steps to add a new client in Zodot.",
    link: "/help-center/clients/how-to-create-a-client",
  },

  {
    ques: "How to Create a Project",
    ans: "Creating a project in Zodot is quick and efficient, allowing you to manage work seamlessly. By following these steps, you can set up a structured project, assign tasks, and track progress with ease.",
    link: "/help-center/projects/how-to-create-a-project-in-zodot",
  },

  {
    ques: "How to Create an Invoice for an Existing Project",
    ans: "Generating an invoice for an existing project in Zodot is hassle-free. With just a few clicks, you can bill your client accurately, ensuring all project-related charges are accounted for. Follow these steps to streamline your invoicing process.",
    link: "/help-center/invoices/how-to-create-an-invoice-for-an-existing-project-in-zodot",
  },
  {
    ques: "How to Create an Invoice for Your New Project",
    ans: "When starting a new project, you can generate an invoice right away in Zodot. This ensures that your billing process is aligned with your project timeline, making financial management smoother. Here’s how to do it effortlessly.",
    link: "/help-center/invoices/how-to-create-an-invoice-for-your-new-project-in-zodot",
  },
  {
    ques: "How to Create a Quick Invoice",
    ans: "Zodot allows you to create a quick invoice without linking it to a project, making it ideal for one-time payments or simple transactions. Follow these steps to generate an invoice swiftly and get paid faster.",
    link: "/help-center/invoices/how-to-create-a-quick-invoice",
  },

  {
    ques: "How to Create a Task",
    ans: "Managing tasks efficiently is crucial for productivity. Zodot’s Task Management feature allows users to create, edit, and organize tasks seamlessly. Follow these steps to maximize your workflow.",
    link: "/help-center/tasks/how-to-create-a-task",
  },

  {
    ques: "How to Start Tracking Time",
    ans: "Tracking time in Zodot ensures accurate monitoring of work hours for better productivity and invoicing. With Zodot’s built-in time tracking, you can effortlessly log your working hours.",
    link: "/help-center/time-tracking/how-to-track-your-activity",
  },
];

const StyledSearchBox = styled.div`
  background-color: ${Colors.bgColorFrontend};
  color: #fff;
  text-align: center;
  padding: 40px 16px 64px;
`;

const SearchForm = styled.form`
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  flex-grow: 0;
  padding: 1rem 0.9rem;
  background-color: #fff;
  width: 100%;
  max-width: 32rem;
  position: relative;
  overflow: visible;

  @media (max-width: 992px) {
    overflow: hidden;
  }

  input {
    border: none;
    padding-left: 0.5rem;
    width: 100%;
  }

  .frame1,
  .frame2 {
    position: absolute;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .frame1 {
    max-width: 5rem;
    top: -6rem;
    right: -5rem;
  }

  .frame2 {
    max-width: 6rem;
    left: -11rem;
  }
`;

function SearchBox() {
  const { register, handleSubmit, setFocus, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { query } = useSelector((state) => state.faqs);

  const onSearchPage = pathname.includes("help-center/search");

  const fuse = new Fuse(faqs, {
    keys: ["ques"],
    minMatchCharLength: 2,
    threshold: 0.6,
  });

  const defaultFaqs = () => {
    dispatch(updateFaqs({ query: "", faqs }));
  };

  const searchHandler = (e) => {
    const query = e.target.value;
    if (!query) return defaultFaqs();

    const results = fuse.search(query);
    dispatch(updateFaqs({ query, faqs: results.map((result) => result.item) }));
  };

  const submitHandler = () => history.push("/help-center/search");

  const resetHandler = () => {
    setValue("query", "");
    defaultFaqs();
    setFocus("query");
  };

  const watchQuery = watch("query");

  useEffect(() => {
    if (!onSearchPage) return;
    setFocus("query");
  }, [onSearchPage, setFocus]);

  return (
    <StyledSearchBox>
      <h2 className="fs-1 fw-bold mb-4">Hi, how can we help?</h2>

      <SearchForm onSubmit={handleSubmit(submitHandler)}>
        <img className="frame1" src={formFrame1} alt="zodot form frame" />
        <img className="frame2" src={formFrame2} alt="zodot form frame" />

        <SearchIcon
          type="submit"
          onClick={submitHandler}
          sx={{ color: Colors.font2 }}
        />

        <input
          type="text"
          placeholder="Search..."
          defaultValue={onSearchPage ? query : ""}
          {...register("query", {
            required: true,
            onChange: searchHandler,
          })}
        />

        {((query && onSearchPage) || watchQuery) && (
          <ClearIcon
            type="reset"
            onClick={resetHandler}
            sx={{
              fontSize: 20,
              color: Colors.font2,
              "&:hover": {
                backgroundColor: Colors.backgroundPink,
                borderRadius: "50%",
              },
            }}
          />
        )}
      </SearchForm>
    </StyledSearchBox>
  );
}

export default SearchBox;
