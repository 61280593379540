import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const GoverningLaw = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.heading
      ? selectedData &&
        selectedData.governingLaw &&
        selectedData.governingLaw.heading
      : "Governing Law and Dispute Resolution";

  const paragraph =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.paragraph
      ? selectedData.governingLaw.paragraph
      : `This Agreement and any disputes arising hereunder shall be governed by the laws of Contractor's primary business location (the "Contractor's Jurisdiction"), without considering conflicts of law provisions. The Parties hereby consent to exclusive jurisdiction and venue in the courts situated in Contractor's jurisdiction.\n\nThe failure of either party to enforce its rights under this Agreement at any time shall not be deemed as a waiver of such rights.`;

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.governingLaw &&
          selectedData.governingLaw.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox>
        <TextStyles.FontSize20px className="mb-3 fw-medium overflow-hidden">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg overflow-hidden" style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default GoverningLaw;
