import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AutoCompleteSelectClient } from "../../../Commons/AutoComplete";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./SetupClientStyles";
import Colors from "../../../../../Constants/Colors";
import { invoiceActions } from "../../../../../store/storage/invoiceSlice";
import { useHistory } from "react-router-dom";

const SetupClient = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const createInvoiceMode = useSelector(
    (state) => state.invoices.createInvoiceMode
  );
  const createInvoiceClientMode = useSelector(
    (state) => state.invoices.createInvoiceClientMode
  );

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const client_id =
    selectedInvoiceData &&
    selectedInvoiceData.client_id &&
    selectedInvoiceData.client_id;

  const selectedExistingClientData =
    clientOptionData &&
    client_id &&
    clientOptionData
      .filter((client) => client._id === (client_id && client_id._id))
      .map((data) => {
        return { label: data.company_name, _id: data._id };
      })[0];

  const [selected, setSelected] = useState(null);
  const [selectExistingClient, setSelectExistingClient] = useState(
    selectedExistingClientData ? selectedExistingClientData : null
  );

  useEffect(() => {
    if (createInvoiceClientMode === "create_new_client") {
      setSelectExistingClient(null);
    }
    if (createInvoiceClientMode) {
      setSelected(createInvoiceClientMode);
    }
  }, []);

  const selectClientHandler = (value) => {
    const clientId = value && value._id;
    const client_id =
      clientOptionData &&
      clientOptionData.filter((client) => client._id === clientId)[0];
    const Obj = {
      client_id,
    };

    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    if (value) {
      setSelectExistingClient(value);
    } else {
      setSelectExistingClient(null);
    }
  };

  const nextButtonHandler = () => {
    if (
      selected === "select_existing_client" &&
      selectExistingClient &&
      createInvoiceMode === "quickInvoice"
    ) {
      dispatch(invoiceActions.createInvoiceClientModeAction(selected));
      return history.push("/db/invoices/create/quickInvoice");
    }
    if (
      selected === "select_existing_client" &&
      selectExistingClient &&
      createInvoiceMode === "cProjectInvoice"
    ) {
      dispatch(invoiceActions.createInvoiceClientModeAction(selected));
      return history.push("/db/invoices/create/setupProject");
    }
    if (selected === "create_new_client") {
      dispatch(invoiceActions.selectedInvoiceAction({ client_id: null }));
      dispatch(invoiceActions.createInvoiceClientModeAction(selected));
      return history.push("/db/invoices/create/createClient");
    }
  };

  return (
    <div className="d-flex justify-content-center px-2">
      <div>
        <TextStyles.FontSize22px className="text-center font-weight-500 my-4 my-md-5">
          Who's your client ?
        </TextStyles.FontSize22px>
        <div className="d-flex flex-wrap gap-5 justify-content-center align-items-center pb-5">
          <Styled.DashBoardContainer
            onClick={() => setSelected("select_existing_client")}
            style={{
              border:
                selected === "select_existing_client"
                  ? `2px solid ${Colors.primary}`
                  : "",
            }}
          >
            <TextStyles.FontSize20px className="mb-2 font-weight-500">
              Existing Clients
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px className="mb-3">
              Select a specific client from your existing client list.
            </TextStyles.FontSize14px>

            <AutoCompleteSelectClient
              placeholder="Choose a client"
              options={
                clientOptionData &&
                clientOptionData.map((data) => {
                  return { label: data.company_name, _id: data._id };
                })
              }
              onChange={selectClientHandler}
              defaultValue={selectExistingClient && selectExistingClient}
            />
          </Styled.DashBoardContainer>
          <Styled.DashBoardContainer
            onClick={() => setSelected("create_new_client")}
            style={{
              border:
                selected === "create_new_client"
                  ? `2px solid ${Colors.primary}`
                  : "",
            }}
          >
            <TextStyles.FontSize20px className="mb-2 font-weight-500">
              New Client
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px className="mb-3">
              Create a new client to generate an invoice for your services.
            </TextStyles.FontSize14px>
          </Styled.DashBoardContainer>
        </div>

        <Styled.SubmitButton
          disabled={
            selected === "select_existing_client" && selectExistingClient
              ? false
              : selected === "create_new_client"
              ? false
              : true
          }
          style={{
            minWidth: 120,
          }}
          onClick={nextButtonHandler}
        >
          Next
        </Styled.SubmitButton>
      </div>
    </div>
  );
};

export default SetupClient;
