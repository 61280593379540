import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const ProgressBar = styled.div`
  width: 100%;
  /* height: 1.687rem; */
  // margin-bottom: 6px;
  display: flex;
  font-family: Poppins;
  align-items: center;
  font-size: 0.875rem;
  color: ${Colors.font2};
  background: rgb(218, 222, 236);
`;

export default {
  ProgressBar,
};
