import React from "react";
import Footer from "../Footer/Footer";
import LandingPage from "./LandingPage/LandingPage";
import AboutPage from "./AboutPage/AboutPage";
import Features from "./Features/Features";
import BusinessModal from "./BusinessModel/BusinessModal";
import SuccessStory from "./SuccessStory/SuccessStory";
import { Helmet } from "react-helmet";

const WhyZodot = () => {
  const isProduction =
    process.env.REACT_APP_PAGE_INDEXING_MODE === "production";
  return (
    <div>
      <Helmet>
        <title>Why Zodot for Time Tracking, Reporting, and Invoicing</title>
        <meta
          content="Explore  how Zodot's time tracking enhances project estimation, streamlines workflows, simplifies invoicing, and ensures fast productivity."
          name="description"
        />
        {isProduction ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://zodot.co/reviews" />
      </Helmet>
      <LandingPage />
      <AboutPage />
      <Features />
      <BusinessModal />
      <SuccessStory />
      <Footer />
    </div>
  );
};

export default WhyZodot;
