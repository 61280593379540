import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import ClearIcon from "@mui/icons-material/Clear";

import TypingLoader from "../../../../Commons/TypingLoader";
import Colors from "../../../../../../Constants/Colors";

function RedirectingModal({ show, onClose, onCloseSignModal }) {
  const [seconds, setSeconds] = useState(7);
  const history = useHistory();

  useEffect(() => {
    if (seconds < 0) return history.push("/db/signup");

    const id = setInterval(() => {
      setSeconds((seconds) => --seconds);
    }, 1000);

    return () => clearInterval(id);
  }, [seconds]);

  return (
    <Modal
      show={show}
      centered
      onHide={onClose}
      fullscreen
      contentClassName="d-flex align-items-center justify-content-center position-relative"
    >
      <ClearIcon
        sx={{
          color: Colors.font2,
          cursor: "pointer",
          opacity: 0.7,
          "&:hover": {
            opacity: 1,
          },
          position: "absolute",
          top: 15,
          right: 20,
        }}
        onClick={() => {
          onClose();
          onCloseSignModal();
        }}
      />

      <p className="px-3 text-center">
        Redirecting to the signup page in {seconds}{" "}
        {seconds > 1 ? "seconds" : "second"}
      </p>

      <TypingLoader />
    </Modal>
  );
}

export default RedirectingModal;
