import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import RemoveModal from "../../../../Commons/RemoveModal";
import { deleteAddSectionTablePC } from "../../../../../../store/service/proposalAndContractService";
import EditAddSectionTableModal from "./EditAddSectionTableModal";
import Colors from "../../../../../../Constants/Colors";

import tableBtn from "../../../../../../Assets/assetsnew/table-image-new.svg";

const Table = ({ data, position, pc_id }) => {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const deleteHandler = () => {
    const Obj = {
      pc_id: pc_id,
      _id: data._id,
      position: position,
    };

    dispatch(deleteAddSectionTablePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div className="mt-4">
      <Styled.ReviewBox>
        <div className="edit-delete-wrapper">
          <BorderColorOutlinedIcon
            onClick={() => setEditModal(true)}
            sx={{
              cursor: "pointer",
              color: Colors.font2,
              opacity: 0.8,
              "&:hover": { opacity: 1 },
            }}
          />
          <CloseOutlinedIcon
            onClick={() => {
              setShowRemoveModal(true);
              setConfirmDialog({
                onConfirm: () => deleteHandler(),
              });
            }}
            sx={{
              cursor: "pointer",
              color: Colors.font2,
              marginLeft: 1,
              opacity: 0.8,
              "&:hover": { opacity: 1 },
            }}
          />
        </div>

        <div className="d-flex align-items-center gap-2 gap-md-3 mb-4">
          <img
            src={tableBtn}
            alt="tableImageBtn"
            style={{ maxWidth: "2.2rem" }}
          />

          <TextStyles.FontSize18px className="fw-medium">
            Table
          </TextStyles.FontSize18px>
        </div>

        <TextStyles.FontSize14px>
          <div className="mb-2 fw-medium">Table title</div>
          <Styled.Text className="mb-3">
            {data.table_title}
          </Styled.Text>

          <div className="mb-2 fw-medium">Table description</div>
          {data.input_array && (
            <div className="p-0 m-0 d-flex flex-column gap-3">
              {data.input_array.map((item, index) => (
                <div
                  className="d-flex flex-wrap flex-md-nowrap gap-3"
                  key={index}
                >
                  <Styled.Text className="w-100">{item.input_one}</Styled.Text>
                  <Styled.Text className="w-100">{item.input_two}</Styled.Text>
                </div>
              ))}
            </div>
          )}
        </TextStyles.FontSize14px>
      </Styled.ReviewBox>

      {editModal && (
        <EditAddSectionTableModal
          show={editModal}
          handleClose={() => setEditModal(false)}
          editData={data}
          position={position}
          pc_id={pc_id}
        />
      )}
      <RemoveModal
        title={"table"}
        confirmDialog={confirmDialog}
        showRemoveModal={showRemoveModal}
        handleCloseRemoveModal={handleCloseRemoveModal}
      />
    </div>
  );
};

export default Table;
