import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const Notices = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData && selectedData.notices && selectedData.notices.heading
      ? selectedData && selectedData.notices && selectedData.notices.heading
      : "Notices";

  const paragraph =
    selectedData && selectedData.notices && selectedData.notices.paragraph
      ? selectedData.notices.paragraph
      : `All notices required by this Agreement shall be sent either (i) via US mail or a nationally recognized carrier to the other Party's address on file or (ii) via email to the other Party's designated representative. Each Party is responsible for providing and updating, as needed, its mailing and email address for such notices. Notices sent by email shall be considered effective upon sending if no error or "bounce back" is received within twenty-four (24) hours of submission.`;

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.notices &&
          selectedData.notices.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox>
        <TextStyles.FontSize20px className="mb-3 fw-medium overflow-hidden">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg overflow-hidden" style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default Notices;
