import {
  deleteRequest,
  postRequest,
  putRequest,
  getRequest,
} from "../../axios/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createAndSendInvoice = createAsyncThunk(
  "invoices/create",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("invoices/create", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const saveInvoice = createAsyncThunk("invoices/save", async (data) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    postRequest("invoices/save", token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const downloadPdfInvoice = createAsyncThunk(
  "invoices/download",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest("invoices/download", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const resendInvoice = createAsyncThunk(
  "invoices/resend",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("invoices/resend", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchInvoices = createAsyncThunk(
  "invoices/fetch_invoices",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest("invoices/fetch_invoices", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchInvoiceById = createAsyncThunk(
  "invoices/fetch_invoice_by_id",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest("invoices/fetch_invoice_by_id", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteInvoice = createAsyncThunk(
  "invoices/delete_invoice",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      deleteRequest("invoices/delete_invoice", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const paidInvoice = createAsyncThunk(
  "invoices/paid_invoice",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/paid_invoice", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchInvoiceCounts = createAsyncThunk(
  "invoices/fetch_invoice_counts",
  async () => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest("invoices/fetch_invoice_counts", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
