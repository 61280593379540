import React from "react";
import { useLocation } from 'react-router-dom';
import PageNotFound from "../../Assets/frontendImages/page-not-found.svg";
import styles from "./patherror.module.css";

export default function Patherror() {
  const { pathname } = useLocation();
  const onInnerPage = pathname.startsWith('/db');

  return (
    <div className="container">
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${
          onInnerPage ? styles.page_404 : styles.innerPage
        }`}
      >
        <div className={styles.page_404_image}>
          <img
            src={PageNotFound}
            alt="pageNotFound"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <div className="d-flex flex-column justify-content-center mt-3 mt-md-4">
          <a href="/" className={styles.link_404}>
            Go To Home
          </a>
        </div>
      </div>
    </div>
  );
}
