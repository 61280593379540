import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../Constants/TextStyles";
import { updateRelationshipOfPartiesPC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import ToggleDefaultValues from "../../../Commons/ToggleDefaultValues";
import { fieldOptions } from "../../../Commons/utils";

const RelationshipOfPartiesModal = ({
  show,
  handleClose,
  heading,
  paragraph,
  defaultHeading,
  defaultParagraph,
}) => {
  const dispatch = useDispatch();
  const { isLoading, selectedData } = useSelector(
    (state) => state.proposalAndContract
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
      checkbox: false,
    },
  });

  const formsubmit = (data) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      relationship_of_parties: {
        required: true,
        heading: data.heading,
        paragraph: data.paragraph,
      },
    };
    dispatch(updateRelationshipOfPartiesPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const checked = watch("checkbox");

  useEffect(() => {
    setValue("heading", checked ? defaultHeading : heading);
    setValue("paragraph", checked ? defaultParagraph : paragraph);

    if (checked) clearErrors();
  }, [checked]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      centered={true}
      scrollable={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder="Add text here"
              id="headline"
              name="heading"
              {...register("heading", fieldOptions())}
            />

            {errors && errors.heading && (
              <TextStyles.InValidFeedback>
                {errors.heading.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={8}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph", fieldOptions())}
            />

            {errors && errors.paragraph && (
              <TextStyles.InValidFeedback>
                {errors.paragraph.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <ToggleDefaultValues register={register} />
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default RelationshipOfPartiesModal;
