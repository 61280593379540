import React from "react";

import TextStyles from "../../../../../Constants/TextStyles";
import newProjectImg from "../../../../../Assets/frontendImages/faq-new-project.webp";
import invoiceCreateProjectImg from "../../../../../Assets/frontendImages/faq-invoice-create-project.webp";
import invoiceAddServicesImg from "../../../../../Assets/frontendImages/faq-invoice-add-services.webp";
import SideContentList from "../SideContentList";
import Screenshot from "../../../../UIs/Screenshot";

function HowToCreateProject({ idList, activeContentId }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          How to Create a Project
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Creating a new project in Zodot is a straightforward process that
          helps you efficiently manage your work.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          Follow these steps to create a new project in Zodot -
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px
          id={idList[0]}
          className="fw-semibold mb-1 contentHeading"
        >
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            From the left sidebar menu, click on Projects.
          </li>
          <li className="mb-1">
            Click the “+ New Project” button to add a new project.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-1">
          Alternatively, you can also create a project from the top navbar:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click on the “+ Create” button in the top-right corner.
          </li>
          <li className="mb-1">Select Project from the dropdown menu.</li>
        </ul>

        <Screenshot src={newProjectImg} alt="new project img" />

        <TextStyles.FontSize16px
          id={idList[1]}
          className="fw-semibold mb-1 contentHeading"
        >
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-3">
            <span className="fw-medium">Enter Project Details</span>
            <ul className="list-unstyled">
              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Enter the name of your project (Example: "Website Development
                  for XYZ Corp.").
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Select an existing client from the list or click "New client"
                  to add a new one.
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Select your desired currency for the project (e.g., USD, EUR,
                  INR).
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>Set the start date of the project.</span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  If the project has a fixed end date, you can set that as well.
                  Otherwise, you can set the "Ongoing" status (optional).
                </span>
              </li>
            </ul>
          </li>

          <Screenshot
            src={invoiceCreateProjectImg}
            alt="invoice create project img"
          />

          <li className="mb-3">
            <span className="fw-medium">Add Services</span>
            <ul className="list-unstyled">
              <li className="d-flex gap-3">
                <span>-</span>
                <span>Click on the "Services" section to expand it.</span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Enter the name of the service you are providing (e.g., Web
                  Design and Development).
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Define the pricing structure (per hour, per day, fixed, etc.).
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>Provide a brief description of the service.</span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>To add more services, click "Add another service".</span>
              </li>
            </ul>
          </li>

          <li className="mb-1">
            <span className="fw-medium">Set Billing Schedule (Optional)</span>
            <ul className="list-unstyled">
              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Click on the "Billing Schedule" section to expand it.
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Set up invoice date, frequency, deposit amount and reminders
                  as per your requirements.
                </span>
              </li>
            </ul>
          </li>
        </ul>

        <TextStyles.FontSize16px
          id={idList[2]}
          className="fw-semibold mb-1 contentHeading"
        >
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Review all the entered details.</li>
          <li className="mb-1">
            Click the "Create project" button to save the project. A
            confirmation message will appear once the project is created.
          </li>
        </ul>

        <Screenshot
          src={invoiceAddServicesImg}
          alt="invoice add services img"
        />

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, You’ve set up your first project in Zodot!
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} activeContentId={activeContentId} />
    </>
  );
}

export default HowToCreateProject;
