import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./InvoiceSetupStyles";
import Colors from "../../../../../Constants/Colors";
import { invoiceActions } from "../../../../../store/storage/invoiceSlice";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

const InvoiceSetup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pathname =
    location && location.pathname.split("/").slice(0, -1).join("/");
  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );
  const selectedProject = selectedInvoiceData && selectedInvoiceData.project_id;
  const isProjectModified =
    selectedInvoiceData && selectedInvoiceData.isProjectModified;

  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: {
      serviceLists:
        selectedInvoiceData &&
        selectedInvoiceData.invoice_services &&
        !isProjectModified
          ? selectedInvoiceData.invoice_services
          : selectedProject.service_list &&
            selectedProject.service_list.map((d) => {
              return {
                service_name: d.service_name,
                service_rate: d.service_rate,
                service_rate_type: d.service_rate_type,
                service_rate_count: 1,
                desc: d.desc,
                checked: true,
              };
            }),
    },
  });

  const { fields } = useFieldArray({
    name: "serviceLists",
    control,
  });

  const serviceLists = watch("serviceLists");
  const isChecked =
    serviceLists && serviceLists.some((service) => service.checked === true);

  const onSubmit = async (data) => {
    const Obj = {
      currency:
        selectedInvoiceData && selectedInvoiceData.currency
          ? selectedInvoiceData.currency
          : selectedProject && selectedProject.currency,
      invoice_services: data.serviceLists.map((d) => {
        return {
          service_name: d.service_name,
          service_rate: d.service_rate,
          service_rate_type: d.service_rate_type,
          service_rate_count: d.service_rate_count
            ? parseFloat(d.service_rate_count)
            : 1,
          desc: d.desc,
          checked: d.checked,
        };
      }),
      line_item_services:
        selectedInvoiceData && selectedInvoiceData.line_item_services
          ? selectedInvoiceData && selectedInvoiceData.line_item_services
          : [],
      taxes:
        selectedInvoiceData && selectedInvoiceData.taxes
          ? selectedInvoiceData && selectedInvoiceData.taxes
          : [],
      invoice_title:
        selectedInvoiceData && selectedInvoiceData.invoice_title
          ? selectedInvoiceData && selectedInvoiceData.invoice_title
          : "Invoice",
      issued:
        selectedInvoiceData && selectedInvoiceData.issued
          ? moment(selectedInvoiceData.issued).toISOString()
          : moment().toISOString(),
      due_date:
        selectedInvoiceData && selectedInvoiceData.due_date
          ? moment(selectedInvoiceData.due_date).toISOString()
          : moment().toISOString(),
      isProjectModified: false,
    };
    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    history.push(`${pathname}/review`);
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <TextStyles.FontSize22px className="text-center font-weight-500 my-4 my-md-5">
        What do you want to invoice?
      </TextStyles.FontSize22px>

      <div className="d-flex justify-content-center align-items-center w-100">
        <div className="w-100" style={{ maxWidth: 650 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              {fields.map((item, index) => {
                const fieldName = `serviceLists[${index}]`;
                const rateCount = watch(`${fieldName}.service_rate_count`);
                let checked = watch(`${fieldName}.checked`);
                let total = item.service_rate * rateCount;

                return (
                  <div key={index}>
                    <Styled.SetupBox className="mb-4 font-weight-500">
                      <div className="d-flex justify-content-between gap-3 align-items-center three-dots w-100">
                        <TextStyles.CheckBox
                          className="form-check d-flex align-items-center three-dots"
                          style={{ minWidth: 100 }}
                          size={"1rem"}
                          paddingLeft={"0.6rem"}
                        >
                          <input
                            style={{ cursor: "pointer" }}
                            className="form-check-input"
                            type="checkbox"
                            id="checked"
                            name="checked"
                            {...register(`${fieldName}.checked`)}
                          />

                          <TextStyles.FontSize16px
                            className="form-check-label three-dots"
                            htmlFor="flexCheckChecked"
                            style={{
                              color:
                                checked === false ? Colors.font2 : Colors.font1,
                            }}
                          >
                            {item.service_name}
                          </TextStyles.FontSize16px>
                        </TextStyles.CheckBox>
                        <TextStyles.GreyFont16px
                          className="d-flex"
                          style={{ minWidth: 100 }}
                        >
                          <span className="three-dots">
                            {selectedProject &&
                              selectedProject.currency &&
                              selectedProject.currency.symbol}
                            {item.service_rate
                              ? parseFloat(item.service_rate).toFixed(2)
                              : "00.00"}
                            {item.service_rate_type === "Flat fee" && ""}
                            {item.service_rate_type === "Per hour" && "/hour"}
                            {item.service_rate_type === "Per day" && "/day"}
                            {item.service_rate_type === "Per item" && "/item"}
                            {item.service_rate_type === "Per word" && "/word"}
                          </span>
                        </TextStyles.GreyFont16px>
                      </div>

                      {checked === true && (
                        <>
                          <div className="mb-2 mt-3">
                            <TextStyles.GreyFont14px>
                              Quantity
                            </TextStyles.GreyFont14px>
                          </div>
                          <div className="d-flex justify-content-start align-items-center">
                            <Styled.inputBox style={{ maxWidth: 90 }}>
                              <input
                                readOnly={item.service_rate_type === "Flat fee"}
                                style={{ width: 40 }}
                                type="number"
                                id="service_rate_count"
                                min={"0"}
                                step="0.01"
                                name="service_rate_count"
                                placeholder="00.00"
                                {...register(`${fieldName}.service_rate_count`)}
                              />
                            </Styled.inputBox>
                            <TextStyles.FontSize14px
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              {item.service_rate_type === "Flat fee" && (
                                <TextStyles.FontSize14px>
                                  flat fee
                                </TextStyles.FontSize14px>
                              )}
                              {item.service_rate_type === "Per hour" && (
                                <TextStyles.FontSize14px>
                                  hour/s
                                </TextStyles.FontSize14px>
                              )}
                              {item.service_rate_type === "Per day" && (
                                <TextStyles.FontSize14px>
                                  day/s
                                </TextStyles.FontSize14px>
                              )}
                              {item.service_rate_type === "Per item" && (
                                <TextStyles.FontSize14px>
                                  item/s
                                </TextStyles.FontSize14px>
                              )}
                              {item.service_rate_type === "Per word" && (
                                <TextStyles.FontSize14px>
                                  word/s
                                </TextStyles.FontSize14px>
                              )}
                            </TextStyles.FontSize14px>
                          </div>
                          <TextStyles.FontSize18px className="d-flex justify-content-between align-items-center three-dots w-100 mt-3">
                            <div
                              className="three-dots"
                              style={{ minWidth: 100 }}
                            >
                              Total Service Charges
                            </div>
                            <div
                              className="three-dots"
                              style={{ color: Colors.primary, minWidth: 50 }}
                            >
                              {selectedProject &&
                                selectedProject.currency &&
                                selectedProject.currency.symbol}
                              {total > 0
                                ? parseFloat(total).toFixed(2)
                                : "00.00"}
                            </div>
                          </TextStyles.FontSize18px>
                        </>
                      )}
                    </Styled.SetupBox>
                  </div>
                );
              })}
            </div>
            <Styled.NextButton disabled={isChecked ? false : true}>
              Next
            </Styled.NextButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSetup;
