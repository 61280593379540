import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../Constants/TextStyles";
import { updateTerminationRequestPC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";

const FeedbackModal = ({ show, handleClose, heading, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const [numRequest, setNumRequest] = useState(
    selectedData &&
      selectedData.number_of_termination_request &&
      selectedData.number_of_termination_request.num_request
      ? selectedData.number_of_termination_request.num_request
      : "0"
  );

  const paragraph = `Client will have the opportunity to provide feedback up to ${numRequest} times and Contractor will make revisions up to ${numRequest} times based on the provided feedback.\n\na.Clients as well as contractors must establish a process for providing feedback, such as scheduled review meetings or periodic feedback sessions.\n\nb. Both the parties define the preferred method of feedback communication, whether it's in person, email, or through the project management system.\n\nc. Client is expected to provide feedback on deliverables or milestones within a specific time frame.`;

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
      num_request: numRequest,
    },
  });

  const numReq = watch("num_request");
  useEffect(() => {
    setNumRequest(numReq);
  }, [numReq]);

  useEffect(() => {
    setValue("paragraph", paragraph);
  }, [numRequest]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      number_of_termination_request: {
        required: true,
        num_request: item.num_request,
      },
    };

    dispatch(updateTerminationRequestPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              style={{ opacity: 0.5 }}
              {...register("heading")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={7}
              style={{ opacity: 0.5 }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              readOnly
            />
          </TextStyles.FontSize14px>

              <div className="d-flex gap-3 align-items-center mt-3">
                <TextStyles.FontSize14px>
                  Limited number of revision requests?
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="d-flex justify-content-end">
                  <TextStyles.InputRectangle
                    placeholder="0"
                    style={{
                      maxWidth: 50,
                      width: "100%",
                      height: "3.15rem",
                    }}
                    id="num_request"
                    type="text"
                    name="num_request"
                    {...register("num_request", {
                      required:
                           "Number of request is required.",
                      min: {
                        value: 1,
                        message: "Please enter minimum requests more than 0.",
                      },
                      pattern: {
                        value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                        message: "Please enter a valid number",
                      },
                    })}
                  />
                </TextStyles.FontSize14px>
              </div>
              {errors.num_request && (
                <TextStyles.InValidFeedback>
                  {errors.num_request.message}
                </TextStyles.InValidFeedback>
              )}
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default FeedbackModal;
