import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import SearchBox from "./SearchBox";
import Footer from "../Footer/Footer";

import TextStyles from "../../Constants/TextStyles";
import Colors from "../../Constants/Colors";

const LinkFAQ = styled(Link)`
  display: inline-block;
  border: 1px solid ${Colors.borderInput};
  border-radius: 12px;
  padding: 1rem;
  text-decoration: none;
  color: ${Colors.font2};

  &:hover {
    border: 1px solid ${Colors.bgColorFrontend};
  }
`;

function SearchResult({ query, hasResult = true }) {
  return (
    <TextStyles.GreyFont16px className={hasResult ? "mt-4" : "my-4"}>
      {hasResult ? "Search results" : "No result found"} for:
      <span className="text-dark fw-medium"> {query}</span>
    </TextStyles.GreyFont16px>
  );
}

function SearchResults() {
  const { query, filteredFaqs } = useSelector((state) => state.faqs);

  return (
    <>
      <SearchBox />

      <div className="container">
        {filteredFaqs.length ? (
          <>
            {query.length > 0 && <SearchResult query={query} />}

            <div
              className={`d-flex flex-column gap-4 mb-5 ${
                query ? "mt-4" : "mt-5"
              } `}
            >
              {filteredFaqs.map((faq) => (
                <LinkFAQ to={faq.link} key={faq.ques}>
                  <span className="d-block fw-medium mb-2 text-dark">
                    {faq.ques}
                  </span>
                  <span className="d-block" style={{ maxWidth: "45rem" }}>
                    {faq.ans}
                  </span>
                </LinkFAQ>
              ))}
            </div>
          </>
        ) : (
          <SearchResult query={query} hasResult={false} />
        )}
      </div>

      <Footer />
    </>
  );
}

export default SearchResults;
