import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import SearchBox from "../../SearchBox";
import Footer from "../../../Footer/Footer";
import PageNotFound from "../../.././../Components/path404error/patherror";
import HowToGetStarted from "./getting-started/HowToGetStarted";
import AddYourUserInformation from "./getting-started/AddYourUserInformation";
import ConfigureYourPreferredCurrency from "./getting-started/ConfigureYourPreferredCurrency";
import SetupYourBrand from "./getting-started/SetupYourBrand";
import AddYourBusinessInfo from "./getting-started/AddYourBusinessInfo";
import HowToCreateClient from "./clients/HowToCreateClient";
// import HowToEditClientDetails from "./clients/HowToEditClientDetails";
// import HowToDeleteAndArchiveClient from "./clients/HowToDeleteAndArchiveClient";
// import HowToAddContactToExistingClient from "./clients/HowToAddContactToExistingClient";
import HowToCreateProject from "./projects/HowToCreateProject";
// import HowToListFilterAndSortProjects from "./projects/HowToListFilterAndSortProjects";
import HowToCreateInvoiceForExistingProject from "./invoices/HowToCreateInvoiceForExistingProject";
import HowToCreateInvoiceForNewProject from "./invoices/HowToCreateInvoiceForNewProject";
import HowToCreateQuickInvoice from "./invoices/HowToCreateQuickInvoice";
import HowToCreateTask from "./tasks/HowToCreateTask";
import HowToTrackActivity from "./time-tracking/HowToTrackActivity";
import BreadCrumbs from "../../BreadCrumbs";

import {
  articles,
  addUserInformationContentList,
  addYourBusinessInfoContentList,
  createClientContentList,
  createProjectContentList,
  createTaskContentList,
  invoiceExistingProjectContentList,
  invoiceNewProjectContentList,
  quickInvoiceContentList,
  setUpBrandContentList,
  timeTrackingContentList,
} from "../../helpCenterConfig";

const faqHeadings = {
  "how-to-get-started": true,
  "add-your-user-information": true,
  "configure-your-preferred-currency": true,
  "set-up-your-brand": true,
  "add-your-business-info": true,

  "how-to-create-a-client": true,
  // "how-to-edit-my-client-details": true,
  // "how-to-delete-and-archive-my-clients": true,
  // "how-to-add-a-contact-to-an-existing-client": true,

  "how-to-create-a-project": true,
  // "how-to-list-filter-and-sort-the-projects": true,

  "how-to-create-an-invoice-for-an-existing-project": true,
  "how-to-create-an-invoice-for-your-new-project": true,
  "how-to-create-a-quick-invoice": true,
  // "how-to-add-taxes-and-discounts-on-invoices": true,

  "how-to-create-a-task": true,

  "how-to-track-your-activity": true,
};

const QuestionPage = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 12px;
  display: grid;
  grid-template-columns: auto 350px;
  gap: 3rem;
  padding-bottom: 3rem;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 1.8rem;
  }
`;

function ArticleQuestionPage() {
  const { article, faq } = useParams();
  const [activeContentId, setActiveContentId] = useState("");

  if (!articles.includes(article) || !faqHeadings[faq]) return <PageNotFound />;

  useEffect(() => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const contentId = entry.target.getAttribute("id");
          setActiveContentId(contentId);
        }
      });
    };

    const options = {
      root: null,
      rootMargin: "-200px 0px -200px 0px",
    };

    const contentHeadingObserver = new IntersectionObserver(callback, options);
    const contentHeadings = document.querySelectorAll(".contentHeading");
    contentHeadings.forEach((heading) =>
      contentHeadingObserver.observe(heading)
    );

    return () => contentHeadingObserver.disconnect();
  }, []);

  return (
    <>
      <SearchBox />

      <div className="container-fluid my-4" style={{ maxWidth: 1320 }}>
        <BreadCrumbs />
      </div>

      <QuestionPage>
        {article === "get-started" && faq === "how-to-get-started" && (
          <HowToGetStarted />
        )}

        {article === "get-started" && faq === "add-your-user-information" && (
          <AddYourUserInformation idList={addUserInformationContentList} />
        )}

        {article === "get-started" && faq === "add-your-business-info" && (
          <AddYourBusinessInfo idList={addYourBusinessInfoContentList} />
        )}

        {article === "get-started" &&
          faq === "configure-your-preferred-currency" && (
            <ConfigureYourPreferredCurrency />
          )}

        {article === "get-started" && faq === "set-up-your-brand" && (
          <SetupYourBrand idList={setUpBrandContentList} />
        )}

        {article === "clients" && faq === "how-to-create-a-client" && (
          <HowToCreateClient
            idList={createClientContentList}
            activeContentId={activeContentId}
          />
        )}

        {/* {article === "clients" && faq === "how-to-edit-my-client-details" && (
        <HowToEditClientDetails />
      )}

      {article === "clients" &&
        faq === "how-to-delete-and-archive-my-clients" && (
          <HowToDeleteAndArchiveClient />
        )}

      {article === "clients" &&
        faq === "how-to-add-a-contact-to-an-existing-client" && (
          <HowToAddContactToExistingClient />
        )} */}

        {article === "projects" && faq === "how-to-create-a-project" && (
          <HowToCreateProject
            idList={createProjectContentList}
            activeContentId={activeContentId}
          />
        )}

        {/* {article === "projects" &&
        faq === "how-to-list-filter-and-sort-the-projects" && (
          <HowToListFilterAndSortProjects />
        )} */}

        {article === "invoices" &&
          faq === "how-to-create-an-invoice-for-an-existing-project" && (
            <HowToCreateInvoiceForExistingProject
              idList={invoiceExistingProjectContentList}
              activeContentId={activeContentId}
            />
          )}

        {article === "invoices" &&
          faq === "how-to-create-an-invoice-for-your-new-project" && (
            <HowToCreateInvoiceForNewProject
              idList={invoiceNewProjectContentList}
              activeContentId={activeContentId}
            />
          )}

        {article === "invoices" && faq === "how-to-create-a-quick-invoice" && (
          <HowToCreateQuickInvoice
            idList={quickInvoiceContentList}
            activeContentId={activeContentId}
          />
        )}

        {/* {article === "invoices" &&
        faq === "how-to-add-taxes-and-discounts-on-invoices" && (
          <HowToListFilterAndSortProjects />
        )} */}

        {article === "tasks" && faq === "how-to-create-a-task" && (
          <HowToCreateTask
            idList={createTaskContentList}
            activeContentId={activeContentId}
          />
        )}

        {article === "time-tracking" &&
          faq === "how-to-track-your-activity" && (
            <HowToTrackActivity
              idList={timeTrackingContentList}
              // activeContentId={activeContentId}
            />
          )}
      </QuestionPage>

      <Footer />
    </>
  );
}

export default ArticleQuestionPage;
