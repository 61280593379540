import React, { useState } from "react";
import Styled from "./DeletedUsersStyles";
import TextStyles from "../../../../Constants/TextStyles";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import { message } from "antd";
import Colors from "../../../../Constants/Colors";
import { Popover } from "antd";
import Person3TwoToneIcon from "@mui/icons-material/Person3TwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import FmdGoodTwoToneIcon from "@mui/icons-material/FmdGoodTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import ContactMailTwoToneIcon from "@mui/icons-material/ContactMailTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../../../store/storage/adminSlice";
import SelectDate from "../../Commons/SelectDate";
import LoaderSpin from "../../Commons/LoaderSpin";
import {
  deleteDeletedUser,
  fetchDeletedUsers,
} from "../../../../store/service/AdminService";
import { useEffect } from "react";
import PaginationUI from "../../Commons/Pagination";
import NoUsersImage from "../../../../Assets/assetsnew/no-users.svg";
import FilterCountry from "../../Commons/FilterCountry";
import { ReactTooltip } from "../../Commons/ReactTooltip";
import SearchComponent from "../../Commons/SearchComponent";
import { useForm, Controller } from "react-hook-form";
import SelectUserType from "../../Commons/SelectUserType";

import DeleteModal from "./DeleteModal";
import { filteredDateRangeFunction } from "../../Commons/utils";

const DeletedUsers = () => {
  const dispatch = useDispatch();

  const { deletedUsers, isLoading, totalDeletedUsers } = useSelector(
    (state) => state.admin
  );

  const toggleFilter = useSelector(
    (state) => state.admin.toggleDeletedUsersFilter
  );

  const filteredData = useSelector(
    (state) => state.admin.filteredDeletedUsersData
  );

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;

  const filterdCountry =
    filteredData && filteredData.filterdCountry
      ? filteredData.filterdCountry
      : null;

  const filterdUserType =
    filteredData && filteredData.filterdUserType
      ? filteredData.filterdUserType
      : "All users";

  const filterdSearchValue =
    filteredData && filteredData.filterdSearchValue
      ? filteredData.filterdSearchValue
      : null;

  const sortingData = useSelector(
    (state) => state.admin.deletedUserSortingData
  );

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "createdAt";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const { setValue, control, handleSubmit } = useForm({
    defaultValues: {
      search: filterdSearchValue ? filterdSearchValue : "",
    },
  });

  useEffect(() => {
    setValue("search", filterdSearchValue);
  }, [filterdSearchValue]);

  const toggleShowFilter = () => {
    dispatch(adminActions.toggleDeletedUsersAction());
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      adminActions.sortingDeletedUserAction({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      adminActions.sortingDeletedUserAction({
        selectedSortingTwo: event.target.value,
      })
    );
  };
  //delete user
  const [updateRefresh, setUpdateRefresh] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [
    filteredData,
    itemsPerPage,
    // updateRefresh,
  ]);

  useEffect(() => {
    if (deletedUsers && deletedUsers.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [deletedUsers]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const { start_time, end_time } =
      filteredDateRangeFunction(filterdDateRange);

    const Obj = {
      searchValue: filterdSearchValue,
      country: filterdCountry,
      userType: filterdUserType,
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    dispatch(fetchDeletedUsers(Obj));
  }, [filteredData, page, itemsPerPage, sortingData, updateRefresh]);

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_name">
            <Person3TwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "name"}
              value="name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_email">
            <EmailTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Email</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_email"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "email"}
              value="email"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_country">
            <FmdGoodTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Country</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_country"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "country"}
              value="country"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_createdAt">
            <CalendarMonthTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">CreatedAt</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_createdAt"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "createdAt"}
              value="createdAt"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_updatedAt">
            <AccessTimeTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">DeletedAt</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_updatedAt"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "updatedAt"}
              value="updatedAt"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_userType">
            <ContactMailTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">User Type</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_userType"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "userType"}
              value="userType"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>

          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>

          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const sortingActiveHandler = (selectedData) => {
    dispatch(
      adminActions.sortingDeletedUserAction({
        selectedSortingOne: selectedData,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  const formSubmit = (data) => {
    const searchValue = data && data.search && data.search.trim();
    dispatch(
      adminActions.filteredDeletedUsersAction({
        filterdSearchValue: searchValue,
      })
    );
  };

  const resetSearchHandler = (data) => {
    dispatch(
      adminActions.filteredDeletedUsersAction({
        filterdSearchValue: "",
      })
    );
  };

  const deleteUserHandler = (data) => {
    const obj = {
      _id: data && data._id,
    };

    dispatch(deleteDeletedUser(obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleClose();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-3">
        <TextStyles.FontSize16px className="font-weight-500 mt-3 mt-md-4">
          User Details
        </TextStyles.FontSize16px>
        <div className="d-flex justify-content-end align-items-center ms-auto">
          <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
            Filter
          </TextStyles.HoverEffectSpan>

          <div
            className="client-partion-rectangle mx-2"
            style={{
              width: "2px",
              height: "23px",
              backgroundColor: "#7d849e",
            }}
          />
          <TextStyles.FontSize16px>
            <Popover
              placement="bottomRight"
              // title={"Hello"}
              content={sortingContent}
              trigger="click"
              open={openSorting}
              onOpenChange={handleOpenSortingChange}
            >
              <TextStyles.HoverEffectSpan sort={true}>
                Sort
                <ArrowDropDownOutlinedIcon
                  sx={{
                    color: Colors.font2,
                    fontSize: 27,
                    transform: openSorting ? "rotate(180deg)" : "",
                  }}
                />
              </TextStyles.HoverEffectSpan>
            </Popover>
          </TextStyles.FontSize16px>
        </div>
      </div>
      {toggleFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>

            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  adminActions.filteredDeletedUsersAction({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Select country
            </TextStyles.FontSize14px>

            <FilterCountry
              defaultValue={filterdCountry && filterdCountry}
              placeholder="Select country"
              onChange={(value) => {
                dispatch(
                  adminActions.filteredDeletedUsersAction({
                    filterdCountry: value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Select user type
            </TextStyles.FontSize14px>
            <SelectUserType
              defaultValue={filterdUserType && filterdUserType}
              placeholder="Select user type"
              onChange={(e) => {
                dispatch(
                  adminActions.filteredDeletedUsersAction({
                    filterdUserType: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <form onSubmit={handleSubmit(formSubmit)}>
              <TextStyles.FontSize14px className="d-flex mb-2">
                Search user
              </TextStyles.FontSize14px>

              <Controller
                control={control}
                id="search"
                name="search"
                render={({ field: { onChange, value } }) => (
                  <SearchComponent
                    placeholder="Search user"
                    // onChange={onChange}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      onChange(inputValue);
                      if (!inputValue || inputValue.trim() === "") {
                        resetSearchHandler();
                      }
                    }}
                    defaultValue={value}
                  />
                )}
              />
            </form>
          </div>
        </div>
      )}

      <Styled.TableHead className="mt-3">
        <div className="d-flex gap-2 align-items-center font-weight-500">
          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "120%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="name" content="Sort by Name" />
            <span
              className="three-dots hover-name"
              id="name"
              role="button"
              onClick={() => sortingActiveHandler("name")}
            >
              Name
              {selectedSortingOne === "name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-name"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-name"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "160%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="email" content="Sort by Email" />
            <span
              className="three-dots hover-email"
              id="email"
              role="button"
              onClick={() => sortingActiveHandler("email")}
            >
              Email
              {selectedSortingOne === "email" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-email"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-email"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "90%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="country" content="Sort by Country" />
            <span
              className="three-dots hover-country"
              id="country"
              role="button"
              onClick={() => sortingActiveHandler("country")}
            >
              Country
              {selectedSortingOne === "country" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-country"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-country"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "90%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="createdAt" content="Sort by CreatedAt" />
            <span
              className="three-dots hover-createdAt"
              id="createdAt"
              role="button"
              onClick={() => sortingActiveHandler("createdAt")}
            >
              CreatedAt
              {selectedSortingOne === "createdAt" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "90%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="updatedAt" content="Sort by DeletedAt" />
            <span
              className="three-dots hover-updatedAt"
              id="updatedAt"
              role="button"
              onClick={() => sortingActiveHandler("updatedAt")}
            >
              DeletedAt
              {selectedSortingOne === "updatedAt" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-updatedAt"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-updatedAt"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "90%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="userType" content="Sort by User Type" />
            <span
              className="three-dots hover-userType"
              id="userType"
              role="button"
              onClick={() => sortingActiveHandler("userType")}
            >
              User Type
              {selectedSortingOne === "userType" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-userType"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-userType"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px
            className="d-flex justify-content-end"
            style={{
              width: "60%",
              maxWidth: 35,
              minWidth: 35,
            }}
          />
        </div>
      </Styled.TableHead>
      {deletedUsers && deletedUsers.length > 0 ? (
        deletedUsers.map((item, index) => {
          return (
            <Styled.TableCell
              key={index}
              style={{
                borderRadius:
                  deletedUsers.length - 1 === index ? "0 0 12px 12px" : "",
              }}
            >
              <div className={`d-flex gap-2 align-items-center w-100`}>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start text-start"
                  style={{ width: "120%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.name}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "160%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.email}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.country &&
                      `${item.country.name}(${item.country.code})`}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.createdAt &&
                      moment(item && item.createdAt).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.updatedAt &&
                      moment(item && item.updatedAt).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "90%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.userType}</span>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-end"
                  style={{
                    width: "60%",
                    maxWidth: 35,
                    minWidth: 35,
                  }}
                >
                  <DeleteIcon
                    onClick={(e) => {
                      setShow(true);
                      setConfirmDialog({
                        onConfirm: () => deleteUserHandler(item),
                      });
                    }}
                    sx={{
                      color: Colors.red,
                      fontSize: 30,
                      background: Colors.white,
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 35,
                      height: 30,
                    }}
                  />
                </TextStyles.FontSize14px>
              </div>
            </Styled.TableCell>
          );
        })
      ) : isLoading && deletedUsers && deletedUsers.length === 0 ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isLoading &&
        deletedUsers &&
        deletedUsers.length === 0 && (
          <Styled.ListBox>
            <img src={NoUsersImage} alt="no-users-img" />
            <TextStyles.GreyFont18px className="font-weight-500 mt-2">
              There are no deleted users yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}

      {totalDeletedUsers > itemsPerPage && (
        <div className="d-flex justify-content-center py-4">
          <PaginationUI
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(totalDeletedUsers / itemsPerPage)}
            page={page}
          />
        </div>
      )}

      <DeleteModal
        show={show}
        handleClose={handleClose}
        confirmDialog={confirmDialog}
      />
    </div>
  );
};

export default DeletedUsers;
