import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { fetchProjectForOptions } from "../../../store/service/projectService";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import NoAgreementImage from "../../../Assets/assetsnew/no-agreement.svg";

import { Popover } from "antd";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import {
  approvedPcByUser,
  deletePC,
  dismissedPcByUser,
  fetchPC,
} from "../../../store/service/proposalAndContractService";
import { pcActions } from "../../../store/storage/proposalAndContractSlice";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import moment from "moment";
import DeleteModal from "../Commons/DeleteModal";
import { message } from "antd";

import Colors from "../../../Constants/Colors";
import TextStyles from "../../../Constants/TextStyles";
import Styled from "./AgreementsStyles";

import SelectClients from "../Commons/SelectClients";
import PcStatus from "../Commons/PcStatus";
import PcDocumenttType from "../Commons/PcDocumentType";

import Pagination from "../Commons/Pagination";
import { ReactTooltip } from "../Commons/ReactTooltip";
import SelectDate from "../Commons/SelectDate";
import generatePdfDocument from "./AgreementPdf/GeneratePdfDocument";

import Joyride, { EVENTS, STATUS } from "react-joyride";
import { updateUserTourSetupData } from "../../../store/service/AuthService";
import LoaderSpin from "../Commons/LoaderSpin";
import { filteredDateRangeFunction } from "../Commons/utils";

const steps = [
  {
    title: (
      <TextStyles.FontSize18px>
        Create your first agreement
      </TextStyles.FontSize18px>
    ),
    target: ".create_pc_tour",
    content: (
      <TextStyles.GreyFont14px className="d-flex text-start">
        Create proposals or contracts for your projects and get them approved.
      </TextStyles.GreyFont14px>
    ),
    disableBeacon: true,
  },
];

export default function Agreements() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const brandAndLogoData = useSelector((state) => state.auth.brandAndLogoData);
  const pcData = useSelector((state) => state.proposalAndContract.pcData);
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const totalPcCount = useSelector(
    (state) => state.proposalAndContract.totalPcCount
  );
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const toggleFilter = useSelector(
    (state) => state.proposalAndContract.toggleFilter
  );

  const filteredData = useSelector(
    (state) => state.proposalAndContract.filteredData
  );

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;

  const filteredClient =
    filteredData && filteredData.filteredClient
      ? filteredData.filteredClient
      : null;

  const filteredPcStatus =
    filteredData && filteredData.filteredPcStatus
      ? filteredData.filteredPcStatus
      : null;

  const filteredPcDoctType =
    filteredData && filteredData.filteredPcDoctType
      ? filteredData.filteredPcDoctType
      : null;

  const sortingData = useSelector(
    (state) => state.proposalAndContract.sortingData
  );

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "createdAt";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const [deleteData, setDeleteData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const [updateRefresh, setUpdateRefresh] = useState(false);

  const toggleShowFilter = () => {
    dispatch(pcActions.toggleAction());
  };

  const AddAgreementHandler = (data) => {
    history.push("/db/agreements/create/setup");
    dispatch(pcActions.createPcDocType(data));
  };

  // tour related coding
  const tourData = useSelector((state) => state.auth.userTourSetupData);
  const [run, setRun] = useState(tourData && tourData.pc_tour);

  useEffect(() => {
    if (tourData && tourData.pc_tour) {
      setRun(tourData && tourData.pc_tour);
    } else {
      setRun(false);
    }
  }, [tourData]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
    if ([EVENTS.TOUR_END, EVENTS.SKIP].includes(type)) {
      dispatch(updateUserTourSetupData({ pc_tour: false }));
    }
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      pcActions.sortingAction({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      pcActions.sortingAction({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [filteredData, itemsPerPage]);

  useEffect(() => {
    if (pcData && pcData.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [pcData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const { start_time, end_time } =
      filteredDateRangeFunction(filterdDateRange);

    const Obj = {
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      client_id: filteredClient !== 0 ? filteredClient : null,
      status: filteredPcStatus !== "All statuses" ? filteredPcStatus : null,
      section:
        filteredPcDoctType !== "All documents" ? filteredPcDoctType : null,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    dispatch(fetchPC(Obj));
  }, [updateRefresh, filteredData, page, itemsPerPage, sortingData]);

  const EditPcHandler = (data) => {
    dispatch(pcActions.editPositionPcData(data));
    if (data.section === "Contract") {
      history.push(`/db/agreements/${data._id}/reviewContract`);
    } else {
      history.push(`/db/agreements/${data._id}/reviewProposal`);
    }
  };

  const ViewPcHandler = (data) => {
    history.push(`/db/agreements/view/${data._id}`);
  };

  const deletePcHandler = (data) => {
    const Obj = {
      pc_id: data._id,
    };
    dispatch(deletePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseDeleteModal();
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const dismissedPcHandler = (data) => {
    const Obj = {
      pc_id: data._id,
      status: "Dismissed",
    };
    dispatch(dismissedPcByUser(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const approvedPcHandler = (data) => {
    const Obj = {
      pc_id: data._id,
      status: "Approved",
    };
    dispatch(approvedPcByUser(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchProjectForOptions());
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const donwloadPDFHandler = async (data) => {
    await generatePdfDocument(
      data,
      userData,
      businessInfoData,
      brandAndLogoData
    );
  };

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="company_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Client name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_name">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Project name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_start_date">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Start date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_start_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_start_date"}
              value="project_start_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_end_date">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">End date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_end_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_end_date"}
              value="project_end_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        {/* <Styled.Sorting>
          <label className="form-check-label" htmlFor="createdAt">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Created date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="createdAt"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "createdAt"}
              value="createdAt"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting> */}
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="pc_status">
            <AdjustOutlinedIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Status</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="pc_status"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "status"}
              value="status"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const setSortingActiveHandler = (selectedSort) => {
    dispatch(
      pcActions.sortingAction({
        selectedSortingOne: selectedSort,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div className="px-lg-4 px-md-4 px-sm-3 px-2">
        <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-4">
          <div className="d-flex gap-3 flex-wrap justify-content-center justify-content-md-start align-items-center create_pc_tour">
            <Styled.Boxes
              onClick={() => AddAgreementHandler("Create proposal")}
            >
              <AddOutlinedIcon sx={{ fontSize: 22, color: Colors.white }} />
              <span className="fw-medium" style={{ fontSize: 16 }}>
                Proposal
              </span>
            </Styled.Boxes>
            <Styled.Boxes
              onClick={() => AddAgreementHandler("Create contract")}
            >
              <AddOutlinedIcon sx={{ fontSize: 22, color: Colors.white }} />
              <span className="fw-medium" style={{ fontSize: 16 }}>
                Contract
              </span>
            </Styled.Boxes>
            <Styled.Boxes
              onClick={() =>
                AddAgreementHandler("Create proposal and contract")
              }
            >
              <AddOutlinedIcon sx={{ fontSize: 22, color: Colors.white }} />
              <span className="fw-medium" style={{ fontSize: 16 }}>
                Proposal and Contract
              </span>
            </Styled.Boxes>
          </div>
          <div className="d-flex justify-content-end align-items-center ms-auto">
            <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
              Filter
            </TextStyles.HoverEffectSpan>
            <TextStyles.GreyFont20px className="mx-2">
              |
            </TextStyles.GreyFont20px>
            <TextStyles.FontSize16px>
              <Popover
                placement="bottomRight"
                // title={"Hello"}
                content={sortingContent}
                trigger="click"
                open={openSorting}
                onOpenChange={handleOpenSortingChange}
              >
                <TextStyles.HoverEffectSpan sort={true}>
                  Sort
                  <ArrowDropDownOutlinedIcon
                    sx={{
                      color: Colors.font2,
                      fontSize: 27,
                      transform: openSorting ? "rotate(180deg)" : "",
                    }}
                  />
                </TextStyles.HoverEffectSpan>
              </Popover>
            </TextStyles.FontSize16px>
          </div>
        </div>
        {toggleFilter && (
          <div className="d-flex gap-3 flex-wrap justify-content-start mb-4">
            <div>
              <TextStyles.FontSize14px className="d-flex mb-2">
                Date range
              </TextStyles.FontSize14px>

              <SelectDate
                defaultValue={filterdDateRange && filterdDateRange}
                onChange={(value) => {
                  dispatch(
                    pcActions.filterAction({
                      filterdDateRange: value,
                    })
                  );
                }}
              />
            </div>
            <div>
              <TextStyles.FontSize14px className="d-flex mb-2">
                Client
              </TextStyles.FontSize14px>
              <SelectClients
                options={clientOptionData}
                defaultValue={filteredClient && filteredClient}
                onChange={(e) =>
                  dispatch(
                    pcActions.filterAction({
                      filteredClient: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div>
              <TextStyles.FontSize14px className="d-flex mb-2">
                Status
              </TextStyles.FontSize14px>
              <PcStatus
                defaultValue={filteredPcStatus && filteredPcStatus}
                onChange={(e) =>
                  dispatch(
                    pcActions.filterAction({
                      filteredPcStatus: e.target.value,
                    })
                  )
                }
              />
            </div>
            <div>
              <TextStyles.FontSize14px className="d-flex mb-2">
                Documents type
              </TextStyles.FontSize14px>
              <PcDocumenttType
                defaultValue={filteredPcDoctType && filteredPcDoctType}
                onChange={(e) =>
                  dispatch(
                    pcActions.filterAction({
                      filteredPcDoctType: e.target.value,
                    })
                  )
                }
              />
            </div>
          </div>
        )}
        <Styled.TableHead className="mt-3">
          <div
            className="d-flex gap-2 align-items-center"
            style={{ fontWeight: 500 }}
          >
            <TextStyles.FontSize14px
              className="d-flex justify-content-start align-items-center w-100"
              style={{ minWidth: 40 }}
            >
              <ReactTooltip anchorId="project" content="Sort by project" />
              <span
                className="three-dots hover-project"
                id="project"
                role="button"
                onClick={() => setSortingActiveHandler("project_name")}
              >
                Project
                {selectedSortingOne === "project_name" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-project"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-project"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              className="d-flex justify-content-start align-items-center w-100"
              style={{ minWidth: 40 }}
            >
              <ReactTooltip anchorId="client" content="Sort by client" />
              <span
                className="three-dots hover-company"
                id="client"
                role="button"
                onClick={() => setSortingActiveHandler("company_name")}
              >
                Client
                {selectedSortingOne === "company_name" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-company"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-company"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              className="d-flex justify-content-start align-items-center w-100"
              style={{ minWidth: 40, maxWidth: 150 }}
            >
              <ReactTooltip anchorId="startDate" content="Sort by starts" />
              <span
                className="three-dots hover-start-date"
                id="startDate"
                role="button"
                onClick={() => setSortingActiveHandler("project_start_date")}
              >
                Start date
                {selectedSortingOne === "project_start_date" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-start-date"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-start-date"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              className="d-flex justify-content-start align-items-center w-100"
              style={{ minWidth: 40, maxWidth: 150 }}
            >
              <ReactTooltip anchorId="endDate" content="Sort by ends" />
              <span
                className="three-dots hover-end-date"
                id="endDate"
                role="button"
                onClick={() => setSortingActiveHandler("project_end_date")}
              >
                End date
                {selectedSortingOne === "project_end_date" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-end-date"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-end-date"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              className="d-flex justify-content-start align-items-center w-100"
              style={{ minWidth: 40, maxWidth: 150 }}
            >
              <ReactTooltip anchorId="status" content="Sort by status" />
              <span
                className="three-dots hover-status"
                id="status"
                role="button"
                onClick={() => setSortingActiveHandler("status")}
              >
                Status
                {selectedSortingOne === "status" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-status"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-status"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="proposal_actions" />
          </div>
        </Styled.TableHead>
        {pcData && pcData.length > 0 ? (
          pcData.map((data, index) => {
            return (
              <Styled.TableCell
                key={index}
                style={{
                  borderRadius:
                    pcData.length - 1 === index ? "0 0 12px 12px" : "",
                }}
                onClick={() => {
                  if (data.status === "Approved") {
                    ViewPcHandler(data);
                  } else {
                    EditPcHandler(data);
                  }
                }}
              >
                <div className="d-flex gap-2 align-items-center w-100">
                  <div
                    className="d-flex flex-column justify-content-start align-items-center w-100"
                    style={{ minWidth: 40 }}
                  >
                    <TextStyles.FontSize14px className="d-flex text-start w-100">
                      <span className="three-dots"> {data.project_name}</span>
                    </TextStyles.FontSize14px>

                    <TextStyles.GreyFont14px className="d-flex text-start w-100">
                      <span className="three-dots">{data.section}</span>
                    </TextStyles.GreyFont14px>
                  </div>

                  <TextStyles.FontSize14px
                    className="d-flex justify-content-start align-items-center w-100"
                    style={{ minWidth: 40 }}
                  >
                    <span className="three-dots">
                      {data.client_id && data.client_id.company_name}
                    </span>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px
                    className="d-flex justify-content-start align-items-center w-100"
                    style={{ minWidth: 40, maxWidth: 150 }}
                  >
                    <span className="three-dots">
                      {" "}
                      {data.project_start_date &&
                        moment(data.project_start_date).format("DD MMM YYYY")}
                    </span>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px
                    className="d-flex justify-content-start align-items-center w-100"
                    style={{ minWidth: 40, maxWidth: 150 }}
                  >
                    <span className="three-dots">
                      {" "}
                      {data.project_end_date &&
                        moment(data.project_end_date).format("DD MMM YYYY")}
                    </span>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px
                    className="d-flex justify-content-start align-items-center w-100"
                    style={{ minWidth: 40, maxWidth: 150 }}
                  >
                    <TextStyles.FontSize14px
                      className="three-dots"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "3px",
                        fontWeight: 600,
                        boxSizing: "border-box",
                        padding: "4px 8px",
                        maxHeight: "24px",
                        background:
                          data.status === "Sent"
                            ? Colors.backgroundPink
                            : data.status === "Dismissed"
                            ? "#ffeded"
                            : data.status === "Draft"
                            ? "#fafafa"
                            : data.status === "Approved"
                            ? "#DDF8EC"
                            : "",

                        color:
                          data.status === "Sent"
                            ? Colors.primary
                            : data.status === "Dismissed"
                            ? "#f74040"
                            : data.status === "Draft"
                            ? Colors.font2
                            : data.status === "Approved"
                            ? "#188652"
                            : "",
                      }}
                    >
                      <span className="three-dots">{data.status}</span>
                    </TextStyles.FontSize14px>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px className="proposal_actions">
                    {data.status !== "Dismissed" && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!isLoading) {
                            dismissedPcHandler(data);
                          }
                        }}
                        className="d-none d-md-flex"
                      >
                        <ReactTooltip
                          anchorId={`dismiss${index}`}
                          content="Dismiss"
                        />
                        <div id={`dismiss${index}`}>
                          <HighlightOffOutlinedIcon
                            sx={{
                              fontSize: 30,
                              background: Colors.white,
                              color: Colors.primary,
                              cursor: "pointer",
                              borderRadius: "6px",
                              padding: "2px 3px",
                              height: "30px",
                            }}
                          />
                        </div>
                      </span>
                    )}

                    {data.status === "Dismissed" && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!isLoading) {
                            approvedPcHandler(data);
                          }
                        }}
                      >
                        <ReactTooltip
                          anchorId={`approve${index}`}
                          content="Approve"
                        />
                        <div id={`approve${index}`}>
                          <CheckCircleRoundedIcon
                            fontSize="large"
                            sx={{
                              background: Colors.white,
                              cursor: "pointer",
                              borderRadius: "6px",
                              padding: "2px 6px",
                              height: "30px",
                              color: Colors.primary,
                            }}
                          />
                        </div>
                      </span>
                    )}
                    <span
                      className="d-none d-md-flex"
                      onClick={(e) => {
                        e.stopPropagation();
                        donwloadPDFHandler(data);
                      }}
                    >
                      <ReactTooltip
                        anchorId={`export${index}`}
                        content="Download"
                      />
                      <div className="d-none d-md-flex" id={`export${index}`}>
                        <SaveAltOutlinedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "35px",
                            width: "35px",
                            height: "30px",
                            color: Colors.primary,
                            background: Colors.white,
                            padding: "2px 6px",
                            borderRadius: " 6px",
                          }}
                        />
                      </div>
                    </span>
                    <span
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{
                          background: Colors.white,
                          cursor: "pointer",
                          borderRadius: "6px",
                          padding: "2px 4px",
                          height: "30px",
                          color: "grey",
                        }}
                      />
                    </span>
                    <ul
                      data-bs-toggle="modal"
                      data-bs-target="#DeleteModal"
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {data.status !== "Approved" && (
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                EditPcHandler(data);
                              }}
                            >
                              Edit
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}

                      <li>
                        <button className="dropdown-item">
                          <TextStyles.FontSize14px
                            onClick={(e) => {
                              e.stopPropagation();
                              ViewPcHandler(data);
                            }}
                          >
                            View
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteModal(true);
                            setDeleteData(data);
                            setConfirmDialog({
                              onConfirm: () => deletePcHandler(data),
                            });
                          }}
                        >
                          <TextStyles.FontSize14px>
                            Delete
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                      {data.status === "Sent" && (
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={(e) => {
                              e.stopPropagation();
                              approvedPcHandler(data);
                            }}
                          >
                            <TextStyles.FontSize14px>
                              Approve
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}
                      {data.status !== "Dismissed" && (
                        <li className="d-flex d-md-none">
                          <button
                            className="dropdown-item"
                            onClick={(e) => {
                              e.stopPropagation();
                              dismissedPcHandler(data);
                            }}
                          >
                            <TextStyles.FontSize14px>
                              {" "}
                              Dismiss
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}

                      {/* <li className="d-flex d-md-none">
                        <button
                          className="dropdown-item"
                          onClick={(e) => {
                            e.stopPropagation();
                            donwloadPDFHandler(data);
                          }}
                        >
                          <TextStyles.FontSize14px>
                            Download
                          </TextStyles.FontSize14px>
                        </button>
                      </li> */}
                    </ul>
                  </TextStyles.FontSize14px>
                </div>
              </Styled.TableCell>
            );
          })
        ) : isLoading && (pcData === null || pcData.length === 0) ? (
          <Styled.ListBox>
            <LoaderSpin color={Colors.primary} />
          </Styled.ListBox>
        ) : (
          !isLoading &&
          (pcData === null || pcData.length === 0) && (
            <Styled.ListBox>
              <img src={NoAgreementImage} alt="no-agreement-img" />
              <TextStyles.GreyFont18px className="mt-2">
                There are no agreements yet.
              </TextStyles.GreyFont18px>
            </Styled.ListBox>
          )
        )}
      </div>

      <Joyride
        steps={steps}
        run={run}
        disableBeacon
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        scrollToFirstStep={false}
        callback={handleJoyrideCallback}
        scrollOffset={200}
        locale={{
          back: "Previous",
          close: "Close",
          last: "Finish",
          next: "Next",
          skip: "Skip",
        }}
        styles={{
          options: {
            primaryColor: Colors.primary,
            textColor: Colors.font1,
            zIndex: 50,
            overlayColor: "rgba(0, 0, 0, 0.2)",
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0)",
          },
          beacon: {
            zIndex: 50,
          },
          overlay: {
            zIndex: 50,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: 0,
          },
          tooltipContent: {
            padding: "10px 0 0 0",
          },
        }}
      />

      {totalPcCount > itemsPerPage && (
        <div className="d-flex justify-content-center py-4">
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(totalPcCount / itemsPerPage)}
            page={page}
          />
        </div>
      )}
      <DeleteModal
        title={
          deleteData.section === "Proposal"
            ? "proposal"
            : deleteData.section === "Proposal and contract"
            ? "proposal and contract"
            : ""
        }
        confirmDialog={confirmDialog}
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </div>
  );
}
