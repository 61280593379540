import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import newInvoiceImg from "../../../../../Assets/frontendImages/faq-new-invoice.webp";
import invoiceNewProjectImg from "../../../../../Assets/frontendImages/faq-invoice-new-project.webp";
import invoiceExistingClientImg from "../../../../../Assets/frontendImages/faq-invoice-existing-client.webp";
import invoiceCreateProjectImg from "../../../../../Assets/frontendImages/faq-invoice-create-project.webp";
import invoiceAddServicesImg from "../../../../../Assets/frontendImages/faq-invoice-add-services.webp";
import invoiceServicePreview from "../../../../../Assets/frontendImages/faq-invoice-services-preview.webp";
import invoiceReviewImg from "../../../../../Assets/frontendImages/faq-invoice-new-project-review.webp";
import emailPreviewImg from "../../../../../Assets/frontendImages/faq.invoice-email-preview.webp";
import invoiceSendImg from "../../../../../Assets/frontendImages/faq-invoice-new-project-send.webp";
import SideContentList from "../SideContentList";
import Screenshot from "../../../../UIs/Screenshot";

function HowToCreateInvoiceForNewProject({ idList, activeContentId }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          How to Create an Invoice for Your New Project
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Generating an invoice for a new project in Zodot is quick and
          hassle-free. With the right details in place, you can generate a
          professional invoice that reflects project costs, taxes, and payment
          terms.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          Follow these steps to generate an invoice for a new project in Zodot:
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px
          id={idList[0]}
          className="fw-semibold mb-1 contentHeading"
        >
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Log in to your Zodot account.</li>
          <li className="mb-1">
            From the left sidebar menu, click on Invoices.
          </li>
          <li className="mb-1">
            Click the “+ New Invoice” button to create a new invoice.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-1">
          Alternatively, you can also create an invoice from the top navbar:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click on the “+ Create” button in the top-right corner.
          </li>
          <li className="mb-1">Select Invoice from the dropdown menu.</li>
        </ul>

        <Screenshot src={newInvoiceImg} alt="new invoice img" />

        <TextStyles.FontSize16px
          id={idList[1]}
          className="fw-semibold mb-1 contentHeading"
        >
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            After clicking "New Invoice," you can choose your preferred method
            for creating the invoice.
          </li>
          <li className="mb-1">
            To generate an invoice for a new project, navigate to the "New
            Projects" section.
          </li>
          <li className="mb-1">
            Type the name of your project in the provided field.(For example, if
            your project is named "Digital Marketing," enter "Digital Marketing"
            in the text box).
          </li>
          <li className="mb-1">
            Click the "Next" button at the bottom right corner to proceed to the
            Next step.
          </li>
        </ul>

        <Screenshot src={invoiceNewProjectImg} alt="invoice new project img" />

        <TextStyles.FontSize16px
          id={idList[2]}
          className="fw-semibold mb-1 contentHeading"
        >
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Once a project is selected, the next step is to assign a client to
            the invoice.
          </li>
          <li className="mb-1">
            If the client is already in your system, click the "Existing
            Clients" option and select the client from the existing list.
          </li>
          <li className="mb-1">
            If the client is not listed, click on the "New Client" option.
          </li>
          <li className="mb-1">
            You will be prompted to enter the client's details, including name,
            email, and billing information.
          </li>
          <li className="mb-1">
            Once a client is selected or added, click "Next" to continue.
          </li>
          <li className="mb-1">
            The "Next" button will remain disabled until a client is chosen.
          </li>
        </ul>

        <Screenshot
          src={invoiceExistingClientImg}
          alt="invoice existing client img"
        />

        <TextStyles.FontSize16px
          id={idList[3]}
          className="fw-semibold mb-1 contentHeading"
        >
          4. {idList[3]}
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-1">
          Once you've selected or created a client, the next step is to define
          the project details, services, and billing schedule:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            <span className="fw-semibold">Enter Project Details</span>
            <ul className="list-unstyled ps-3">
              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Enter the name of your project (Example: "Website Development
                  for XYZ Corp.").
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Select an existing client from the list or click "New client"
                  to add a new one.
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Select your desired currency for the project (e.g., USD, EUR,
                  INR).
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>Set the start date of the project.</span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  If the project has a fixed end date, you can set that as well.
                  Otherwise, you can set the "Ongoing" status (optional).
                </span>
              </li>
            </ul>
          </li>
        </ul>

        <Screenshot
          src={invoiceCreateProjectImg}
          alt="invoice create project img"
        />

        <ul className="mb-3">
          <li className="mb-1">
            <span className="fw-semibold">Add Services</span>
            <ul className="list-unstyled ps-3">
              <li className="d-flex gap-3">
                <span>-</span>
                <span>Click on the "Services" section to expand it.</span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Enter the name of the service you are providing (e.g., Web
                  Design and Development).
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Define the pricing structure (per hour, per day, fixed, etc.).
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>Provide a brief description of the service.</span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>To add more services, click "Add another service".</span>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="mb-3">
          <li className="mb-1">
            <span className="fw-semibold">Set Billing Schedule (Optional)</span>
            <ul className="list-unstyled ps-3">
              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Click on the "Billing Schedule" section to expand it.
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Set up invoice date, frequency, deposit amount and reminders
                  as per your requirements.
                </span>
              </li>

              <li className="d-flex gap-3">
                <span>-</span>
                <span>
                  Review all the entered details & Click "Next" to proceed.
                </span>
              </li>
            </ul>
          </li>
        </ul>

        <Screenshot
          src={invoiceAddServicesImg}
          alt="invoice add services img"
        />

        <TextStyles.FontSize16px
          id={idList[4]}
          className="fw-semibold mb-1 contentHeading"
        >
          5. {idList[4]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            The services associated with the selected project will be
            automatically displayed.
          </li>
          <li className="mb-1">
            Modify the quantity for each service as needed.
          </li>
          <li className="mb-1">
            Review the total service charges for accuracy.
          </li>
          <li className="mb-1">Click "Next" to proceed.</li>
        </ul>

        <Screenshot
          src={invoiceServicePreview}
          alt="invoice services preview img"
        />

        <TextStyles.FontSize16px
          id={idList[5]}
          className="fw-semibold mb-1 contentHeading"
        >
          6. {idList[5]}
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-1">
          In this screen, you can review and edit the following details -
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Client Information</li>
          <li className="mb-1">Your Business Details</li>
          <li className="mb-1">Project Details</li>
          <li className="mb-1">Preferred Currency</li>
          <li className="mb-1">Invoice Issue and Due Date</li>
          <li className="mb-1">Your Branding</li>
          <li className="mb-1">Invoice Label</li>
        </ul>

        <TextStyles.FontSize16px className="mb-3">
          You can further customize the invoice by adding the following
          additional details as needed:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            <span className="fw-semibold">Taxes: </span>
            Apply applicable taxes to ensure accurate billing.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Discounts: </span>
            Include any special discounts or promotional offers.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Line Items: </span>
            Add extra services or products beyond the predefined project scope.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Notes for the Client: </span>
            Provide important details, payment instructions, or personalized
            messages to enhance clarity and professionalism.{" "}
          </li>
          <li className="mb-1">
            These options will help you to tailor the invoice to meet specific
            project and client requirements.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-3">
          Once all details are finalized, you can download the invoice for your
          records or click on the “Next” button to share it with the client.
        </TextStyles.FontSize16px>

        <Screenshot src={invoiceReviewImg} alt="new invoice img" />

        <TextStyles.FontSize16px
          id={idList[6]}
          className="fw-semibold mb-1 contentHeading"
        >
          7. {idList[6]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            In the compose email section, the user can select or deselect
            recipients from existing contacts or add a new one as needed.
          </li>
          <li className="mb-1">
            Use a pre-saved email template, create a new one, or modify the
            default template to suit your requirements.
          </li>
          <li className="mb-1">
            Click "Preview Email" to review the message before sending.
          </li>

          <div className="my-5">
            <Screenshot
              className="w-100"
              src={emailPreviewImg}
              alt="new invoice img"
            />
          </div>

          <li className="mb-1">
            Once satisfied, click "Send" to deliver the invoice email to the
            client.
          </li>
        </ul>

        <Screenshot src={invoiceSendImg} alt="new invoice img" />

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, Your invoice is generated for the new project and
          linked to the project now.
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} activeContentId={activeContentId} />
    </>
  );
}

export default HowToCreateInvoiceForNewProject;
