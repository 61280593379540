export const articles = [
  "get-started",
  "clients",
  "projects",
  "invoices",
  "tasks",
  "time-tracking",
];

export const addUserInformationContentList = [
  "Go to Account & Security Settings",
  "Update Your Information",
  "Upload or Change Your Profile Picture",
  "Save Your Changes",
];

export const addYourBusinessInfoContentList = [
  "Go to Business Settings",
  "Enter Your Business Details",
  "Save Your Settings",
];

export const setUpBrandContentList = [
  "Navigate to Set Up Your Brand",
  "Customize Your Brand",
  "Upload Your Brand Logo",
  "Choose Your Brand Logo Alignment",
  "Preview Your Branding",
];

export const createClientContentList = [
  "Navigate to the Clients Section",
  "Enter Client Information",
  "Save the Client",
];

export const createProjectContentList = [
  "Navigate to the Projects Section",
  "Enter Project Information",
  "Save the Project",
];

export const invoiceExistingProjectContentList = [
  "Navigate to the Invoices Section",
  "Select an Existing Project",
  "Choose Services & Set Quantities",
  "Review & Edit  Invoice Details",
  "Email Compose Box",
];

export const invoiceNewProjectContentList = [
  "Navigate to the Invoices Section",
  `Select the "New Project" Option`,
  "Selecting a Client for Your Invoice",
  "Define the Project Details (After Selecting a Client)",
  "Choose Services & Set Quantities",
  "Review & Edit  Invoice Details",
  "Email Compose Box",
];

export const quickInvoiceContentList = [
  "Navigate to the Invoices Section",
  `Select the "Quick Invoice" Option`,
  "Selecting a Client for Your Invoice",
  "Adding Service Details to Your Invoice",
  "Review & Edit  Invoice Details",
  "Email Compose Box",
];

export const createTaskContentList = [
  "Navigate to the Tasks and Notes Section",
  "Creating a New Task",
];

export const timeTrackingContentList = [
  "Navigate to the Time Tracking Section",
  "Start the Timer",
  "Add an Activity and Assign It to a Project",
];
