import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AutoCompleteSelectProject } from "../../../Commons/AutoComplete";

import Styled from "./SelectInvoiceStyles";
import { invoiceActions } from "../../../../../store/storage/invoiceSlice";
import Colors from "../../../../../Constants/Colors";
import TextStyles from "../../../../../Constants/TextStyles";

const SelectInvoice = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const exitingProjectData = location && location.state && location.state.data;
  const reminder_id = location && location.state && location.state.reminder_id;

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const createInvoiceMode = useSelector(
    (state) => state.invoices.createInvoiceMode
  );

  const [selected, setSelected] = useState(null);
  const [selectedExistProject, setSelectedExistProject] = useState(null);
  const [selectedExistProjectChange, setSelectedExistProjectChange] =
    useState(false);
  const [enteredProjectName, setEnteredProjectName] = useState("");

  useEffect(() => {
    const exitingProject =
      exitingProjectData &&
      projectOptionsData &&
      projectOptionsData
        .filter((data) => data._id === location.state.data._id)
        .map((d) => {
          return {
            _id: d._id ? d._id : null,
            title: d && d.project_name ? d.project_name : null,
            client_id:
              d && d.client_id && d.client_id._id ? d.client_id._id : null,
            client_name:
              d && d.client_id && d.client_id.company_name
                ? d.client_id.company_name
                : null,
          };
        })[0];
    setSelectedExistProject(exitingProject);
    setSelected("eProjectInvoice");
  }, [exitingProjectData]);

  useEffect(() => {
    if (
      createInvoiceMode === "eProjectInvoice" &&
      selectedInvoiceData &&
      selectedInvoiceData.project_id &&
      selectedInvoiceData.client_id
    ) {
      setSelectedExistProject({
        _id:
          selectedInvoiceData && selectedInvoiceData.project_id
            ? selectedInvoiceData.project_id._id
            : null,
        title:
          selectedInvoiceData && selectedInvoiceData.project_id
            ? selectedInvoiceData.project_id.project_name
            : null,
        client_id:
          selectedInvoiceData && selectedInvoiceData.client_id
            ? selectedInvoiceData.client_id._id
            : null,
        client_name:
          selectedInvoiceData && selectedInvoiceData.client_id
            ? selectedInvoiceData.client_id.company_name
            : null,
      });
    }
    if (
      createInvoiceMode === "cProjectInvoice" &&
      selectedInvoiceData &&
      selectedInvoiceData.project_id
    ) {
      setEnteredProjectName(selectedInvoiceData.project_id.project_name);
    }
    if (createInvoiceMode) {
      setSelected(createInvoiceMode);
    }
  }, [createInvoiceMode, selectedInvoiceData]);

  useEffect(() => {
    if (selectedExistProjectChange) {
      dispatch(invoiceActions.createInvoiceModeAction(selected));
    }
  }, [selectedExistProjectChange]);

  const selectExistingProjectHandler = (value) => {
    if (value) {
      setSelectedExistProject(value);
      setSelectedExistProjectChange(true);
    } else {
      setSelectedExistProject(null);
      setSelectedExistProjectChange(false);
    }
  };

  const enteredProjectHandler = (e) => {
    let name = e.target.value.trim();
    if (name && name.length > 2) {
      setEnteredProjectName(e.target.value);
    } else {
      setEnteredProjectName("");
    }
  };

  const nextButtonHandler = () => {
    if (selectedExistProject && selected === "eProjectInvoice") {
      const projectId = selectedExistProject && selectedExistProject._id;
      const selectedProject =
        projectOptionsData &&
        projectOptionsData.filter((data) => data._id === projectId)[0];
      const selectedClient = selectedProject && selectedProject.client_id;
      const Obj = {
        project_id: selectedProject,
        client_id: selectedClient,
        reminder_id: reminder_id,
      };

      if (createInvoiceMode !== "eProjectInvoice") {
        dispatch(invoiceActions.createInvoiceModeAction(selected));
      }
      dispatch(invoiceActions.selectedInvoiceAction(Obj));
      history.push("/db/invoices/create/setup");
    } else if (enteredProjectName && selected === "cProjectInvoice") {
      const Obj = {
        project_id: { project_name: enteredProjectName },
      };
      if (createInvoiceMode !== "cProjectInvoice") {
        dispatch(invoiceActions.createInvoiceModeAction(selected));
      }
      dispatch(invoiceActions.selectedInvoiceAction(Obj));
      history.push("/db/invoices/create/setupClient");
    } else if (selected === "quickInvoice") {
      if (createInvoiceMode !== "quickInvoice") {
        dispatch(invoiceActions.createInvoiceModeAction(selected));
      }
      history.push("/db/invoices/create/setupClient");
    }
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <TextStyles.FontSize22px className="text-center font-weight-500 my-4 my-md-5">
        What do you want to invoice?
      </TextStyles.FontSize22px>

      <div className="d-flex flex-wrap gap-5 justify-content-center align-items-center pb-5">
        <Styled.DashBoardContainer
          onClick={() => setSelected("eProjectInvoice")}
          style={{
            border:
              selected === "eProjectInvoice"
                ? `2px solid ${Colors.primary}`
                : "",
          }}
        >
          <TextStyles.FontSize20px className="mb-2" style={{ fontWeight: 500 }}>
            Existing Projects
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px className="mb-3">
            Create professional invoices for your existing projects.
          </TextStyles.FontSize14px>
          <AutoCompleteSelectProject
            placeholder="Select project"
            options={
              projectOptionsData &&
              projectOptionsData.map((d) => {
                return {
                  _id: d._id ? d._id : null,
                  title: d && d.project_name ? d.project_name : null,
                  client_id:
                    d && d.client_id && d.client_id._id
                      ? d.client_id._id
                      : null,
                  client_name:
                    d && d.client_id && d.client_id.company_name
                      ? d.client_id.company_name
                      : null,
                };
              })
            }
            onChange={selectExistingProjectHandler}
            label="Select project"
            defaultValue={selectedExistProject && selectedExistProject}
          />
        </Styled.DashBoardContainer>
        <Styled.DashBoardContainer
          onClick={() => setSelected("cProjectInvoice")}
          style={{
            border:
              selected === "cProjectInvoice"
                ? `2px solid ${Colors.primary}`
                : "",
          }}
        >
          <TextStyles.FontSize20px className="mb-2" style={{ fontWeight: 500 }}>
            New Project
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px className="mb-3">
            Create a detailed invoice for your new project.
          </TextStyles.FontSize14px>
          <TextStyles.InputRectangle
            type="text"
            placeholder="Project name"
            name="project_name"
            defaultValue={enteredProjectName}
            onChange={enteredProjectHandler}
          />
          <TextStyles.FontSize14px>
            Required<span style={{ color: Colors.red }}>*</span>
          </TextStyles.FontSize14px>
        </Styled.DashBoardContainer>
        <Styled.DashBoardContainer
          onClick={() => {
            setSelected("quickInvoice");
          }}
          style={{
            border:
              selected === "quickInvoice" ? `2px solid ${Colors.primary}` : "",
          }}
        >
          <TextStyles.FontSize20px className="mb-2" style={{ fontWeight: 500 }}>
            Quick Invoice
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px>
            Create a quick invoice without creating a project.
          </TextStyles.FontSize14px>
        </Styled.DashBoardContainer>
      </div>

      <Styled.SubmitButton
        disabled={
          selected === "eProjectInvoice" &&
          selectedExistProject !== null &&
          selectedExistProject !== undefined
            ? false
            : selected === "cProjectInvoice" && enteredProjectName !== ""
            ? false
            : selected === "quickInvoice"
            ? false
            : true
        }
        onClick={nextButtonHandler}
      >
        Next
      </Styled.SubmitButton>
    </div>
  );
};

export default SelectInvoice;
