import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import getStartedImg from "../../Assets/frontendImages/feature-faq-started.svg";
import clientImg from "../../Assets/frontendImages/feature-faq-client.svg";
import projectImg from "../../Assets/frontendImages/feature-faq-project.svg";
import invoiceImg from "../../Assets/frontendImages/feature-faq-invoice.svg";
import taskImg from "../../Assets/frontendImages/feature-faq-task.svg";
import timeTrackingImg from "../../Assets/frontendImages/feature-faq-time-tracking.svg";

import TextStyles from "../../Constants/TextStyles";
import Colors from "../../Constants/Colors";

const ourFeatures = [
  {
    image: getStartedImg,
    heading: "Getting Started",
    desc: "Get started with Zodot to enhance productivity.",
    link: "/help-center/get-started",
  },
  {
    image: clientImg,
    heading: "Clients",
    desc: "Manage client records for seamless collaboration.",
    link: "/help-center/clients",
  },
  {
    image: projectImg,
    heading: "Projects",
    desc: "Set up and manage your projects with ease.",
    link: "/help-center/projects",
  },
  {
    image: invoiceImg,
    heading: "Invoices",
    desc: "Create professional invoices in just a few clicks.",
    link: "/help-center/invoices",
  },
  {
    image: taskImg,
    heading: "Tasks",
    desc: "Monitor tasks to keep projects on track.",
    link: "/help-center/tasks",
  },
  {
    image: timeTrackingImg,
    heading: "Time Tracking",
    desc: "Track hours and generate reports for transparent billing.",
    link: "/help-center/time-tracking",
  },
];

const StyledOurFeatures = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 280px);
  gap: 2rem;
  justify-content: center;
  padding: 4rem 1rem;
  text-align: center;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 250px);
  }

  @media (max-width: 576px) {
    grid-template-columns: 250px;
    padding: 2.5rem 1rem;
  }
`;

const StyledFeatureItem = styled(Link)`
  text-decoration: none;
  color: ${Colors.font1};
  border: 1px solid ${Colors.borderInput};
  border-radius: 10px;
  padding: 1.8rem 2.2rem;
  transition: all 0.3s;

  img {
    max-height: 3.3rem;
    margin-bottom: 1.5rem;
  }

  .heading {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 8px;
  }

  &:hover {
    border: 1px solid ${Colors.bgColorFrontend};
    .heading {
      color: ${Colors.primary};
    }
  }
`;

function OurFeatures() {
  return (
    <StyledOurFeatures>
      {ourFeatures.map((feature) => (
        <StyledFeatureItem to={feature.link} key={feature.heading}>
          <img src={feature.image} alt={`zodot's ${feature.heading} icon`} />

          <p className="heading">{feature.heading}</p>

          <TextStyles.FontSize14px>{feature.desc}</TextStyles.FontSize14px>
        </StyledFeatureItem>
      ))}
    </StyledOurFeatures>
  );
}

export default OurFeatures;
