import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import LocationCityTwoToneIcon from "@mui/icons-material/LocationCityTwoTone";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import NoClientImage from "../../../Assets/assetsnew/no-client.svg";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { Popover } from "antd";
import CreateClients from "./createClients/createClinets.jsx";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClients,
  archiveClient,
  unarchiveClient,
  deleteClient,
  fetchClientsForOption,
} from "../../../store/service/clientService";

import ArchiveModal from "../Commons/ArchiveModal";
import DeleteModal from "../Commons/DeleteModal";

import { message } from "antd";

import Colors from "../../../Constants/Colors";
import TextStyles from "../../../Constants/TextStyles";
import Styled from "./clientStyles";
import SelectClientStatus from "../Commons/SelectClientStatus";
import Pagination from "../Commons/Pagination";
import { ReactTooltip } from "../Commons/ReactTooltip";
import moment from "moment/moment.js";
import Joyride, { EVENTS, STATUS } from "react-joyride";
import { useWindowSize } from "../Commons/utils.js";
import { updateUserTourSetupData } from "../../../store/service/AuthService";
import LoaderSpin from "../Commons/LoaderSpin.js";
import { clientActions } from "../../../store/storage/clientSlice";

const stepData = [
  {
    title: (
      <TextStyles.FontSize18px>
        Create your first client
      </TextStyles.FontSize18px>
    ),
    target: ".create_client_tour",
    content: (
      <TextStyles.GreyFont14px className="d-flex text-start">
        Manage client information and interactions.
      </TextStyles.GreyFont14px>
    ),
    disableBeacon: true,
    placement: "bottom-start",
  },
  {
    title: (
      <TextStyles.FontSize18px>
        Create your first project
      </TextStyles.FontSize18px>
    ),
    target: ".projects_tour",
    content: (
      <TextStyles.GreyFont14px className="d-flex text-start">
        Track and manage your projects.
      </TextStyles.GreyFont14px>
    ),
    placement: "right-start",
  },
];

export default function clients() {
  const [width] = useWindowSize();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.clients.isLoading);
  const clientData = useSelector((state) => state.clients.clientData);
  const clientsTotalCount = useSelector(
    (state) => state.clients.clientsTotalCount
  );
  const userCurrency = useSelector((state) => state.auth.userCurrency);
  const currencySymbol =
    userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const clientStatus = useSelector((state) => state.clients.clientStatus);

  const sortingData = useSelector((state) => state.clients.sortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "company_name";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "asc";

  const EditClientHandler = (data) => {
    dispatch(clientActions.clientDataAtPosition(data));
    history.push(`/db/clients/${data._id}/address`);
  };

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseArchiveModal = () => setShowArchiveModal(false);

  const [updateRefresh, setUpdateRefresh] = useState(false);

  const steps = width > 992 ? stepData : stepData.slice(0, stepData.length - 1);

  // tour related coding
  const tourData = useSelector((state) => state.auth.userTourSetupData);
  const [run, setRun] = useState(tourData && tourData.client_tour);

  useEffect(() => {
    if (tourData && tourData.client_tour) {
      setRun(tourData && tourData.client_tour);
    } else {
      setRun(false);
    }
  }, [tourData]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
    if ([EVENTS.TOUR_END, EVENTS.SKIP].includes(type)) {
      dispatch(updateUserTourSetupData({ client_tour: false }));
    }
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      clientActions.sortingAction({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      clientActions.sortingAction({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setPage(1);
  }, [clientStatus]);

  useEffect(() => {
    const Obj = {
      status: clientStatus,
      page: page,
      perPage: itemsPerPage,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };

    dispatch(fetchClients(Obj));
  }, [updateRefresh, clientStatus, page, itemsPerPage, sortingData]);

  useEffect(() => {
    dispatch(fetchClientsForOption());
  }, [updateRefresh]);

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="client_org_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Client name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="client_org_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="client_city">
            <LocationCityTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">City</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="client_city"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "city"}
              value="city"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="totalProjects">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Total projects</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="totalProjects"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "totalProjects"}
              value="totalProjects"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="last_email_sent_date">
            <AccessTimeTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Last emailed</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="last_email_sent_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "last_email_sent_date"}
              value="last_email_sent_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="totalIncomes">
            <PaidTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Income</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="totalIncomes"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "totalIncomes"}
              value="totalIncomes"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const setSortingActiveHandler = (selectedSort) => {
    dispatch(
      clientActions.sortingAction({
        selectedSortingOne: selectedSort,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  const archiveClientHandler = (data) => {
    const Obj = {
      client_id: data._id,
    };

    dispatch(archiveClient(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseArchiveModal();
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const unarchiveClientHandler = (data) => {
    const Obj = {
      client_id: data._id,
    };

    dispatch(unarchiveClient(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleCloseArchiveModal();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const deleteClientHandler = (id) => {
    const Obj = {
      client_id: id,
    };
    dispatch(deleteClient(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else message.error(`${data.error.message}`);
    });
  };

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div className="px-lg-4 px-md-4 px-sm-3 px-2">
        <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-3">
          <Styled.NewCreate
            onClick={() => setShow(true)}
            className="create_client_tour"
          >
            <span className="ms-2">
              <AddOutlinedIcon sx={{ fontSize: 22, color: Colors.white }} />
            </span>
            <span className="fw-medium" style={{ fontSize: 16 }}>
              {" "}
              New client{" "}
            </span>
          </Styled.NewCreate>
          <div className="d-flex justify-content-end align-items-center ms-auto">
            <TextStyles.HoverEffectSpan active={true}>
              <SelectClientStatus
                defaultValue={clientStatus}
                onChange={(e) =>
                  dispatch(clientActions.clientStatusAction(e.target.value))
                }
              />
            </TextStyles.HoverEffectSpan>

            <div
              className="client-partion-rectangle mx-2"
              style={{
                width: "2px",
                height: "23px",
                backgroundColor: "#7d849e",
              }}
            />
            <TextStyles.FontSize16px>
              <Popover
                placement="bottomRight"
                content={sortingContent}
                trigger="click"
                open={openSorting}
                onOpenChange={handleOpenSortingChange}
              >
                <TextStyles.HoverEffectSpan sort={true}>
                  Sort
                  <ArrowDropDownOutlinedIcon
                    sx={{
                      color: Colors.font2,
                      fontSize: 27,
                      transform: openSorting ? "rotate(180deg)" : "",
                    }}
                  />
                </TextStyles.HoverEffectSpan>
              </Popover>
            </TextStyles.FontSize16px>
          </div>
        </div>

        <Styled.TableHead className="mt-3">
          <div className="d-flex gap-2 align-items-center">
            <TextStyles.FontSize14px
              style={{
                width: "140%",
                display: "flex",
                textAlign: "start",
                minWidth: 30,
              }}
            >
              <ReactTooltip anchorId="company_name" content="Sort by name" />
              <span
                className="three-dots hover-company"
                id="company_name"
                role="button"
                onClick={() => setSortingActiveHandler("company_name")}
              >
                Client
                {selectedSortingOne === "company_name" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-company"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-company"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{
                width: "100%",
                display: "flex",
                textAlign: "start",
                minWidth: 30,
              }}
            >
              <ReactTooltip anchorId="city" content="Sort by city" />
              <span
                id="city"
                className="three-dots hover-city"
                role="button"
                onClick={() => setSortingActiveHandler("city")}
              >
                City
                {selectedSortingOne === "city" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-city"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-city"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{ width: "80%", minWidth: 30 }}
              className="d-flex align-items-center justify-content-center"
            >
              <ReactTooltip
                anchorId="projects"
                content="Sort by total projects"
              />
              <span
                className="three-dots hover-project"
                id="projects"
                role="button"
                onClick={() => setSortingActiveHandler("totalProjects")}
              >
                Total projects
                {selectedSortingOne === "totalProjects" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-project"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-project"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{ width: "100%", minWidth: 30 }}
              className="d-flex align-items-center justify-content-center"
            >
              <ReactTooltip
                anchorId="email"
                content="Sort by last email sent"
              />
              <span
                id="email"
                className="three-dots hover-email"
                role="button"
                onClick={() => setSortingActiveHandler("last_email_sent_date")}
              >
                Last email sent
                {selectedSortingOne === "last_email_sent_date" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-email"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-email"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{ width: "100%", minWidth: 30 }}
              className="d-flex align-items-center justify-content-center"
            >
              <ReactTooltip
                anchorId="income"
                content="Sort by income received"
              />
              <span
                className="three-dots hover-income"
                id="income"
                role="button"
                onClick={() => setSortingActiveHandler("totalIncomes")}
              >
                Income received
                {selectedSortingOne === "totalIncomes" ? (
                  <>
                    {selectedSortingTwo === "desc" ? (
                      <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    ) : (
                      <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                    )}
                  </>
                ) : (
                  <>
                    {selectedSortingTwo === "asc" ? (
                      <ArrowUpwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-income"
                      />
                    ) : (
                      <ArrowDownwardRoundedIcon
                        sx={{
                          fontSize: "1.2rem",
                        }}
                        className="visible-income"
                      />
                    )}
                  </>
                )}
              </span>
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px
              style={{
                width: "80%",
                minWidth: "70px",
                maxWidth: "90px",
                display: "flex",
                justifyContent: "space-between",
              }}
            />
          </div>
        </Styled.TableHead>

        {clientData && clientData.length > 0 ? (
          clientData.map((data, index) => {
            return (
              <Styled.TableCell
                key={index}
                style={{
                  borderRadius:
                    clientData.length - 1 === index ? "0 0 12px 12px" : "",
                }}
                onClick={() => EditClientHandler(data)}
              >
                <div className="d-flex gap-2 align-items-center w-100">
                  <TextStyles.FontSize14px
                    style={{
                      width: "140%",
                      display: "flex",
                      textAlign: "start",
                      minWidth: 30,
                    }}
                  >
                    <span className="three-dots w-75">{data.company_name}</span>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px
                    style={{
                      width: "100%",
                      display: "flex",
                      textAlign: "start",
                      minWidth: 30,
                    }}
                  >
                    <span className="three-dots">{data.city}</span>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px
                    style={{ width: "80%", minWidth: 30 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="three-dots">{data.totalProjects}</span>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px
                    style={{ width: "100%", minWidth: 30 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="three-dots">
                      {data.last_email_sent_date &&
                        moment(data.last_email_sent_date).format("DD MMM YYYY")}
                    </span>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px
                    style={{ width: "100%", minWidth: 30 }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <span className="three-dots">
                      {currencySymbol}
                      {parseFloat(data.totalIncomes).toFixed(2)}
                    </span>
                  </TextStyles.FontSize14px>

                  <TextStyles.FontSize14px
                    style={{
                      position: "relative",
                      width: "80%",
                      minWidth: "70px",
                      maxWidth: "90px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      id={`edit_client${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        EditClientHandler(data);
                      }}
                    >
                      <BorderColorOutlinedIcon
                        sx={{
                          color: Colors.success,
                          cursor: "pointer",
                          fontSize: 30,
                          background: Colors.white,
                          padding: "2px 6px",
                          borderRadius: "6px",
                          width: 35,
                          height: 30,
                        }}
                      />
                    </div>

                    <ReactTooltip
                      anchorId={`edit_client${index}`}
                      content="Edit client"
                    />

                    <div
                      className="dropdown"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <button
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <MoreHorizIcon
                          fontSize="large"
                          sx={{
                            background: Colors.white,
                            cursor: "pointer",
                            borderRadius: "6px",
                            padding: "2px 4px",
                            height: "30px",
                            color: "grey",
                          }}
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {!data.archive && !data.deleted && (
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowArchiveModal(true);
                                setConfirmDialog({
                                  onConfirm: () => archiveClientHandler(data),
                                });
                              }}
                            >
                              <TextStyles.FontSize14px>
                                Archive client
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        )}

                        {data.archive && (
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={(e) => {
                                e.stopPropagation();
                                unarchiveClientHandler(data);
                              }}
                            >
                              <TextStyles.FontSize14px>
                                Unarchive client
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        )}

                        <li>
                          <button
                            className="dropdown-item"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowDeleteModal(true);
                              setConfirmDialog({
                                onConfirm: () => deleteClientHandler(data._id),
                              });
                            }}
                            disabled={isLoading}
                          >
                            <TextStyles.FontSize14px>
                              Delete client
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </TextStyles.FontSize14px>
                </div>
              </Styled.TableCell>
            );
          })
        ) : isLoading && (clientData === null || clientData.length === 0) ? (
          <Styled.ListBox>
            <LoaderSpin color={Colors.primary} />
          </Styled.ListBox>
        ) : (
          !isLoading &&
          (clientData === null || clientData.length === 0) && (
            <Styled.ListBox>
              <img src={NoClientImage} alt="no-client-img" />
              <TextStyles.GreyFont18px className="mt-2">
                There are no clients yet.
              </TextStyles.GreyFont18px>
            </Styled.ListBox>
          )
        )}
      </div>
      <Joyride
        steps={steps}
        run={run}
        disableBeacon
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        scrollToFirstStep={false}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        locale={{
          skip: "Skip All",
          next: "Next",
          nextLabelWithProgress: "Next ({step}/{steps})",
        }}
        styles={{
          options: {
            primaryColor: Colors.primary,
            textColor: Colors.font1,
            zIndex: 50,
            overlayColor: "rgba(0, 0, 0, 0.2)",
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0)",
          },

          beacon: {
            zIndex: 50,
            left: 50,
          },
          overlay: {
            zIndex: 50,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: 0,
          },
          tooltipContent: {
            padding: "10px 0 0 0",
          },
        }}
      />

      <div className="d-flex justify-content-center py-5">
        {clientsTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(clientsTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>
      {show && (
        <CreateClients
          show={show}
          handleClose={() => setShow(false)}
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
        />
      )}
      {showArchiveModal && (
        <ArchiveModal
          title="client"
          confirmDialog={confirmDialog}
          showArchiveModal={showArchiveModal}
          handleCloseArchiveModal={handleCloseArchiveModal}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          title="client"
          confirmDialog={confirmDialog}
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
}
