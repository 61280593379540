import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const SetupBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem 1.25rem;
  border-radius: 0.75rem;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.white};
  transition: all 0.8s ease;
  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const inputBox = styled.div`
  width: 100%;
  height: 3.15rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  /* padding: 1rem; */
  margin: 6px 0 9px;
  background: ${Colors.white};
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.borderInput)};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const NextButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  min-width: fit-content;
  min-width: 6rem;
  padding: 0 1.5rem;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

export default {
  SetupBox,
  inputBox,
  NextButton,
};
