import React from "react";
import businessInfoImg from "../../../../Assets/assetsnew/businessInfo.svg";
import currencyImg from "../../../../Assets/assetsnew/currency_setup.svg";
import brandImg from "../../../../Assets/assetsnew/brand-image.svg";

import Styled from "../SettingStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Business = () => {
  const history = useHistory();
  const userCurrency = useSelector((state) => state.auth.userCurrency);

  return (
    <div
      className="pt-4"
      style={{ backgroundColor: Colors.backgroundGrey, height: "100vh" }}
    >
      <div className="d-flex flex-wrap justify-content-center gap-5">
        <Styled.SettingRectangle
          onClick={() => {
            history.push("/db/setting/business/business_info");
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <img src={businessInfoImg} alt="arrowBtn" style={{ width: 60 }} />
          </div>
          <TextStyles.FontSize18px className="text-center font-weight-500 mt-3 mb-2">
            Set Up Your Business Info
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont15px className="text-center font-weight-500">
            Add business name, phone number, and address etc.
          </TextStyles.GreyFont15px>
        </Styled.SettingRectangle>

        <Styled.SettingRectangle
          onClick={() => {
            history.push("/db/setting/business/currency");
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <img src={currencyImg} alt="currencyBtn" style={{ width: 80 }} />
          </div>
          <TextStyles.FontSize18px className="text-center font-weight-500 mt-4 mb-2">
            Set Up Your Currency
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont15px className="d-flex justify-content-center font-weight-500 align-items-bottom">
            <span className="me-2">Set currency:</span>
            <span style={{ color: Colors.primary }}>
              {userCurrency &&
                userCurrency.currency &&
                userCurrency.currency.code}
            </span>
          </TextStyles.GreyFont15px>
        </Styled.SettingRectangle>

        <Styled.SettingRectangle
          onClick={() => {
            history.push("/db/setting/business/brand_logo");
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <img src={brandImg} alt="arrowBtn" style={{ width: 75 }} />
          </div>
          <TextStyles.FontSize18px className="text-center font-weight-500 mt-3 mb-2">
            Set Up Your Brand
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont15px className="text-center font-weight-500">
            Add brand color, logo, and logo alignment.
          </TextStyles.GreyFont15px>
        </Styled.SettingRectangle>
      </div>
    </div>
  );
};

export default Business;
