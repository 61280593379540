import React from "react";
import { useHistory } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import Setup from "./Setup/Setup.jsx";
import ProposalReview from "../ProposalReviews/Review";
import ContractReview from "../ContractReviews/Review";
import Send from "../Send/Send.jsx";
import Styled from "./CreateAgreementsStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { BackButton } from "../../Commons/BackAndForwardButton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ProtectedRoute from "../../../auth/ProtectedRoute.js";
import { pcActions } from "../../../../store/storage/proposalAndContractSlice.js";

export default function CreateProposal() {
  const dispatch = useDispatch();
  const { createDocType } = useSelector((state) => state.proposalAndContract);
  const history = useHistory();
  const location = useLocation();
  const pathname = location && location.pathname.split("/")[4];

  const backBtn = () => {
    // will change to pathname /db/agreements
    history.push("/db/agreements");
    dispatch(pcActions.resetPcDataAction());
  };

  return (
    <div
      style={{
        background: Colors.backgroundGrey,
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          background: Colors.white,
          zIndex: 200,
        }}
        className="fixed-top"
      >
        <div className="px-2 py-3 d-flex align-items-center">
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={backBtn}
          >
            <ClearIcon
              sx={{
                fontSize: 27,
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
            />
          </div>

          <TextStyles.FontSize20px
            className="d-flex justify-content-center w-100"
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: 24,
              minWidth: 200,
            }}
          >
            <span className="three-dots mx-2">
              {createDocType === "Create proposal" && "Create Proposal"}
              {createDocType === "Create contract" && "Create Contract"}
              {createDocType === "Create proposal and contract" &&
                "Create Proposal and Contract"}
            </span>
          </TextStyles.FontSize20px>
        </div>

          <Styled.ProgressBar className="fw-medium">
            <div
              className="w-100"
              style={{
                height: "100%",
                backgroundColor: Colors.primary,
                color: Colors.white,
                justifyContent: "center",
                display: "flex",
                minWidth: "5rem",
                alignItems: "center",
              }}
            >
              <span className="three-dots mx-2 py-1">Setup</span>
            </div>
            {createDocType !== "Create contract" && (
              <div
                className="w-100"
                style={
                  pathname === "reviewProposal" ||
                  pathname === "reviewContract" ||
                  pathname === "send"
                    ? {
                        height: "100%",
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "5rem",
                        alignItems: "center",
                      }
                    : {
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "5rem",
                      }
                }
              >
                <span className="three-dots mx-2 py-1">Review Proposal</span>
              </div>
            )}

            {createDocType !== "Create proposal" && (
              <div
                className="w-100"
                style={
                  pathname === "reviewContract" || pathname === "send"
                    ? {
                        height: "100%",
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "5rem",
                        alignItems: "center",
                      }
                    : {
                        justifyContent: "center",
                        display: "flex",
                        minWidth: "5rem",
                      }
                }
              >
                <span className="three-dots mx-2 py-1">Review Contract</span>
              </div>
            )}
            <div
              className="w-100"
              style={
                pathname === "send"
                  ? {
                      height: "100%",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                      alignItems: "center",
                    }
                  : {
                      justifyContent: "center",
                      display: "flex",
                      minWidth: "5rem",
                    }
              }
            >
              <span className="three-dots mx-2 py-1">Send</span>
            </div>
          </Styled.ProgressBar>
      </div>

      <div style={{ paddingTop: 100 }}>
        {pathname !== "setup" && (
          <div
            style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
            onClick={() => history.goBack()}
          >
            <BackButton />
          </div>
        )}
        {pathname === "setup" && (
          <ProtectedRoute
            path="/db/agreements/create/setup"
            exact
            component={Setup}
          />
        )}
        {pathname === "reviewProposal" && (
          <ProtectedRoute
            path="/db/agreements/create/reviewProposal"
            exact
            component={ProposalReview}
          />
        )}
        {pathname === "reviewContract" && (
          <ProtectedRoute
            path="/db/agreements/create/reviewContract"
            exact
            component={ContractReview}
          />
        )}
        {pathname === "send" && (
          <ProtectedRoute
            path="/db/agreements/create/send"
            exact
            component={Send}
          />
        )}
      </div>
    </div>
  );
}
