import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateOwnershipRightPC } from "../../../../../../store/service/proposalAndContractService";
import OwnerShipModal from "../../PageModals/OwnerShipModal";
import Colors from "../../../../../../Constants/Colors";

const OwnershipRights = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Ownership and Licenses";

  const heading =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.heading
      ? selectedData &&
        selectedData.ownership_right &&
        selectedData.ownership_right.heading
      : defaultHeading;

  const paragraphMadeForHire = `The Contractor agrees that all deliverables and materials created under this Agreement, including presentations, writings, ideas, concepts, designs, text, plans, and other materials, shall be treated as if they were a "work for hire" and shall be owned by the Client upon full payment of all fees due to the Contractor as per this Agreement. In the event that any material is not considered a "work made for hire," the Contractor hereby assigns all ownership, including any patent, copyright, trade secret, and other proprietary rights, title, and interest in such deliverables and materials to the Client. The Contractor agrees to execute any documents reasonably requested by the Client to facilitate the acquisition and protection of such rights. The Contractor acknowledges that they have no interest in any materials submitted to the Client, including any security interest therein. The Contractor hereby releases any interest they may have (if any) in such materials that could arise by operation of law. Unless otherwise agreed upon in writing and solely as necessary for the performance of this Agreement, the Contractor shall have no rights to license, sell, or use the deliverables or materials developed under this Agreement, or any part thereof.`;

  const paragraphLicensed = `As per this Agreement, the Client acknowledges that the Contractor shall retain full ownership rights to all deliverables and materials produced, encompassing presentations, writings, ideas, concepts, designs, text, plans, and any other materials developed while providing services for the Client. Whether created individually or jointly, on or off the Client's premises, during or after working hours, all ownership rights shall remain vested with the Contractor. Upon the Client's complete payment of all fees owed to the Contractor in accordance with this Agreement, the Contractor hereby grants the Client a license to use the work product. This license allows the Client to utilize the deliverables in the specified manner(s) without any requirement for attribution.`;

  const defaultOwnershipText =
    "The work product is granted a global, irrevocable, non-exclusive license that is royalty-free, sub-licensable, and non-attribution. This license permits the use, modification, creation of derivative works, or any other form of exploitation of the work product without the need for attribution.";

  const paragraph =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.paragraph
      ? selectedData.ownership_right.paragraph
      : paragraphMadeForHire;

  const ownershipText =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.ownership_text
      ? selectedData.ownership_right.ownership_text
      : defaultOwnershipText;

  const isOwnerShipRequired =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.required
      ? true
      : false;

  const toggleOwnershipViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      ownership_right: {
        required: !isOwnerShipRequired,
        heading,
        paragraph,
        ownership:
          selectedData &&
          selectedData.ownership_right &&
          selectedData.ownership_right.ownership
            ? selectedData.ownership_right.ownership
            : "Made for hire",
        ownership_text: ownershipText,
      },
    };
    dispatch(updateOwnershipRightPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isOwnerShipRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <>
      <Styled.ReviewContentBox>
        <div className="edit-delete-icons">
          <span onClick={toggleOwnershipViewHandler}>
            {isOwnerShipRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isOwnerShipRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span
            className={`fw-medium ${isOwnerShipRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isOwnerShipRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          <div>{paragraph}</div>
          <div>
            {selectedData &&
            selectedData.ownership_right &&
            selectedData.ownership_right.ownership === "Licensed"
              ? selectedData.ownership_right.ownership_text
              : ""}
          </div>
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <OwnerShipModal
          paragraphMadeForHire={paragraphMadeForHire}
          paragraphLicensed={paragraphLicensed}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
          defaultHeading={defaultHeading}
          paragraph={paragraph}
          ownershipText={ownershipText}
          defaultOwnershipText={defaultOwnershipText}
        />
      )}
    </>
  );
};

export default OwnershipRights;
