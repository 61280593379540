import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const ReviewContentBox = styled.div`
  width: 100%;
  position: relative;
  height: auto;
  padding: 1.25rem;
  border: 1px solid #b2b6c5;
  border-radius: 0.75rem;

  .edit-icon,
  .edit-delete-icons {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: ${Colors.white};
  }

  .edit-delete-icons {
    padding: 0 0 8px 12px;
  }

  &:hover .edit-icon,
  &:hover .edit-delete-icons {
    display: flex;
    align-items: center;
  }

  .disabled {
    opacity: 0.5;
  }

  @media (max-width: 576px) {
    padding: 1rem 0.5rem;
  }
`;

const EditButtonToolTip = styled.div`
  /* display: none; */
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6.25rem;
  font-size: 0.875rem;
  cursor: pointer;
  color: ${(props) => props.color || Colors.white};
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  padding: 1rem;
  border-radius: 8px;
`;

const EditServiceContainer = styled.div`
  .edit_services_imgBtn {
    visibility: hidden;
  }
  :hover .edit_services_imgBtn {
    visibility: visible;
  }
`;

const EditContainer = styled.div`
  cursor: pointer;
  .edit_imgBtn {
    visibility: hidden;
  }
  :hover .edit_imgBtn {
    visibility: visible;
  }
`;

export default {
  ReviewContentBox,
  EditButtonToolTip,
  EditServiceContainer,
  EditContainer,
};
