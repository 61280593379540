import React from "react";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "./PageOne";

import imageClient from "../../../../../../Assets/assetsnew/image-client-new.svg";

const Project = (props) => {
  const selectedData = props && props.selectedData;
  return (
    <Styled.ReviewContentBox className="mt-4 d-flex align-items-center gap-2 gap-md-3">
      <div style={{ maxWidth: 40 }}>
        <img className="w-100" src={imageClient} alt="project-icon" />
      </div>
      <TextStyles.FontSize18px className="fw-medium">
        {selectedData && selectedData.project_name}
      </TextStyles.FontSize18px>
    </Styled.ReviewContentBox>
  );
};

export default Project;
