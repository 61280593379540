import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const SubmitButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  min-width: fit-content;
  min-width: 6rem;
  padding: 0 1.5rem;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }
  &:disabled {
    opacity: 0.75 !important;
  }
`;

const DashBoardContainer = styled.div`
  width: 100%;
  max-width: 22rem;
  min-width: 22rem;
  height: 100%;
  min-height: 14rem;
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: 2px solid transparent;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.2);
  background-color: ${Colors.white};
  cursor: pointer;
  &:hover {
    border: 2px solid ${Colors.primary};
  }

  @media (max-width: 576px) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export default {
  SubmitButton,
  DashBoardContainer,
};
