import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "../../../../Assets/assetsnew/frontend_logo_with_name.webp";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import "./NavBar.css";
import Colors from "../../../../Constants/Colors";
import { authActions } from "../../../../store/storage/authSlice";

const NavBar = (props) => {
  let urlElements = window.location.href.split("/");
  const [isHovered, setIsHovered] = useState(false);
  const [isTop, setIsTop] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const token = localStorage.getItem("token");

  const logoutHandler = () => {
    dispatch(authActions.logout());
    history.push("/");
  };

  useEffect(() => {
    const handleScroll = () => {
      const isOnTop = window.scrollY > props.scroll;
      setIsTop(isOnTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="container-fluid">
      <nav className={isTop ? "Nav Nav--sticky" : "Nav"}>
        <div className="Nav-to">
          <NavLink to="/">
            <img
              src={logo}
              alt="Logo"
              width={200}
              height={50}
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "contain",
              }}
            />
          </NavLink>
        </div>

        <ul className="Nav-mainMenu u-reset-ul">
          <li className="Nav-Submenu">
            <button
              style={{
                color:
                  urlElements[3] === "features"
                    ? `${Colors.brandColorFrontend}`
                    : "",
              }}
              className={
                isHovered
                  ? "Nav-link Nav-link--suite u-reset-button is-active"
                  : "Nav-link Nav-link--suite u-reset-button"
              }
              onMouseEnter={() => setIsHovered(true)}
            >
              Features
              <ArrowDropDownSharpIcon />
            </button>
            <ul
              className={`Nav-featuresMenu u-reset-ul ${
                isHovered ? "block" : "d-none"
              }`}
              onMouseLeave={() => setIsHovered(false)}
            >
              <li onClick={() => setIsHovered(false)}>
                <NavLink
                  to="/features/task-management"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link"
                >
                  Task management
                </NavLink>
              </li>
              <li onClick={() => setIsHovered(false)}>
                <NavLink
                  to="/features/invoicing"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link"
                >
                  Invoicing
                </NavLink>
              </li>
              <li onClick={() => setIsHovered(false)}>
                <NavLink
                  to="/features/agreements"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link"
                >
                  Agreements
                </NavLink>
              </li>
              <li onClick={() => setIsHovered(false)}>
                <NavLink
                  to="/features/transactions"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link"
                >
                  Transactions
                </NavLink>
              </li>
              <li onClick={() => setIsHovered(false)}>
                <NavLink
                  to="/features/statements"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link"
                >
                  Statements
                </NavLink>
              </li>
              <li onClick={() => setIsHovered(false)}>
                <NavLink
                  to="/features/time-tracking"
                  activeClassName="is-active"
                  style={{ textDecoration: "none" }}
                  className="Nav-link"
                >
                  Time tracking
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink
              className="Nav-link Nav-link--bordered"
              activeClassName="is-active"
              style={{ textDecoration: "none" }}
              to="/reviews"
            >
              Reviews
            </NavLink>
          </li>
          <li>
            <NavLink
              className="Nav-link Nav-link--bordered"
              activeClassName="is-active"
              style={{ textDecoration: "none" }}
              to="/why-zodot"
            >
              Why Zodot?
            </NavLink>
          </li>
        </ul>

        <ul className="Nav-webApp u-reset-ul">
          {!token ? (
            <>
              <li>
                {isTop ? (
                  <NavLink className="Nav-webApp-link--signUp" to="/db/signup">
                    Sign up for free
                  </NavLink>
                ) : (
                  <span
                    className="text-white pe-none"
                    style={{ fontSize: "1.125rem" }}
                  >
                    Have an account?
                  </span>
                )}
              </li>

              <li>
                <NavLink className="Nav-webApp-link--signIn" to={"/db/login"}>
                  Log in
                </NavLink>
              </li>
            </>
          ) : (
            <>
              <NavLink className="Nav-webApp-link--signUp" to="/db/home">
                Go to dashboard
              </NavLink>

              <button
                className="Nav-webApp-link--signIn"
                onClick={logoutHandler}
              >
                Log out
              </button>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
