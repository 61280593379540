import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateAcceptancePC } from "../../../../../../store/service/proposalAndContractService";
import AcceptanceModal from "../../PageModals/AcceptanceModal";
import Colors from "../../../../../../Constants/Colors";

const Acceptance = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Acceptance";
  const defaultParagraph =
    "The Client's authorized representative possesses the authority to enter into this Agreement on behalf of the Client. The Client commits to cooperating and providing the Contractor with all necessary resources to complete the Services as requested, including the specified format and timeline. The Contractor affirms their expertise and capability to fulfill all agreed-upon tasks for the Client in a professional and timely manner. They will strive to meet all established deadlines and exceed the Client's expectations for the Services to the best of their abilities.";

  const heading =
    selectedData && selectedData.acceptance && selectedData.acceptance.heading
      ? selectedData &&
        selectedData.acceptance &&
        selectedData.acceptance.heading
      : defaultHeading;
  const paragraph =
    selectedData && selectedData.acceptance && selectedData.acceptance.paragraph
      ? selectedData &&
        selectedData.acceptance &&
        selectedData.acceptance.paragraph
      : defaultParagraph;

  const isAcceptanceRequired =
    selectedData && selectedData.acceptance && selectedData.acceptance.required
      ? true
      : false;

  const toggleAcceptanceViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      acceptance: {
        required: !isAcceptanceRequired,
        heading,
        paragraph,
      },
    };
    dispatch(updateAcceptancePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isAcceptanceRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleAcceptanceViewHandler}>
            {isAcceptanceRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isAcceptanceRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span
            className={`fw-medium ${isAcceptanceRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isAcceptanceRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <AcceptanceModal
          paragraph={paragraph}
          heading={heading}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Acceptance;
