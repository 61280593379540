import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { postRequest } from "../../../../axios/axios";
import TextStyles from "../../../../Constants/TextStyles";
import LoaderSpin from "../../Commons/LoaderSpin";
import Colors from "../../../../Constants/Colors";
import Styled from "./DetailsStyles";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { getFileFromUrl } from "../../Commons/utils";

const Details = () => {
  const { id } = useParams();
  const [file, setFile] = useState(null);

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    viewInvoiceDetailsHandler();
  }, [id]);

  const viewInvoiceDetailsHandler = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    postRequest(`invoices/view_invoice/details`, token, {
      _id: id,
    })
      .then((data) => {
        if (data.data.success) {
          setIsLoading(false);
          setError(false);
          setFile(data.data.data);
        }
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }
        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  const downloadPdfHandler = () => {
    let filename = file.invoice_no;
    getFileFromUrl(file.file, filename).then((res) => {
      const href = URL.createObjectURL(res);
      const aElement = document.createElement("a");
      aElement.href = href;
      aElement.setAttribute("download", `Invoice${filename}`);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
  };

  return (
    <div className="d-flex justify-content-center align-items-center px-2 px-sm-3 px-md-4 px-lg-4">
      <div
        style={{
          width: "100%",
          maxWidth: "800px",
          paddingBottom: 80,
        }}
      >
        {isLoading ? (
          <TextStyles.FontSize14px
            style={{
              width: "100%",
              height: "100%",
              minHeight: 650,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderSpin color={Colors.primary} />
          </TextStyles.FontSize14px>
        ) : error ? (
          <TextStyles.FontSize14px
            style={{
              width: "100%",
              height: "100%",
              minHeight: 650,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextStyles.FontSize16px
              style={{
                padding: "12px 20px",
                borderRadius: 9,
                border: `2px solid ${Colors.red}`,
                color: Colors.red,
              }}
            >
              Sorry, we couldn't find your invoice.
            </TextStyles.FontSize16px>
          </TextStyles.FontSize14px>
        ) : (
          <Styled.PdfViewerContainer>
            <div
              style={{
                position: "absolute",
                top: 15,
                right: 35,
                zIndex: 100,
              }}
            >
              <Styled.Oval
                onClick={downloadPdfHandler}
                bgColor={Colors.primary}
              >
                <SaveAltIcon sx={{ color: Colors.white, fontSize: 27 }} />
              </Styled.Oval>
            </div>
            <iframe
              // src={`${file}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`}
              src={`${file && file.file}#toolbar=0&navpanes=0&scrollbar=0`}
              // src={file}
              // style={{
              //   border: "none",
              //   backgroundColor: "#f0f0f0",
              // }}
              // border={0}
              title="PDF Viewer"
              width="100%"
              height="100%"
            />
          </Styled.PdfViewerContainer>
        )}
      </div>
    </div>
  );
};

export default Details;
