import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateChangesPC } from "../../../../../../store/service/proposalAndContractService";
import ChangesModal from "../../PageModals/ChangesModal";
import Colors from "../../../../../../Constants/Colors";

const Changes = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Changes";
  const defaultParagraph =
    "Any material changes to the Services, including work to be performed and related fees must be approved by the prior written consent of both parties.";

  const heading =
    selectedData && selectedData.changes && selectedData.changes.heading
      ? selectedData.changes.heading
      : defaultHeading;

  const paragraph =
    selectedData && selectedData.changes && selectedData.changes.paragraph
      ? selectedData && selectedData.changes && selectedData.changes.paragraph
      : defaultParagraph;

  const isChangesRequired =
    selectedData && selectedData.changes && selectedData.changes.required
      ? true
      : false;

  const toggleChangesViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      changes: {
        required: !isChangesRequired,
        heading,
        paragraph,
      },
    };
    dispatch(updateChangesPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isChangesRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleChangesViewHandler}>
            {isChangesRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isChangesRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span className={`fw-medium ${isChangesRequired ? "" : "disabled"}`}>
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isChangesRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <ChangesModal
          paragraph={paragraph}
          heading={heading}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Changes;
