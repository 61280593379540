import React from "react";
import moment from "moment";

import TextStyles from "../../../../../../Constants/TextStyles";
import { diffDate } from "../../../../Commons/utils";
import Styled from "../PageOne/PageOne";
import imageClient from "../../../../../../Assets/assetsnew/image-client-new.svg";

const Project = (props) => {
  const selectedData = props && props.selectedData;

  return (
    <Styled.ReviewContentBox className="mt-4">
      <div className="d-flex align-items-center gap-2 gap-md-3">
        <div style={{ maxWidth: 40 }}>
          <img className="w-100" src={imageClient} alt={`imageClient`} />
        </div>
        <TextStyles.FontSize18px className="fw-medium">
          {selectedData && selectedData.project_name}
        </TextStyles.FontSize18px>
      </div>
      <div className="d-flex gap-2 justify-content-start align-items-center mt-3">
        <TextStyles.FontSize16px className="fw-medium">
          Starts:
        </TextStyles.FontSize16px>
        <TextStyles.GreyFont14px className="d-flex" style={{ minWidth: 50 }}>
          <span className="three-dots">
            {moment(selectedData && selectedData.project_start_date).format(
              "DD MMM YYYY"
            )}
          </span>
        </TextStyles.GreyFont14px>
      </div>
        <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
          <TextStyles.FontSize16px className="fw-medium">
            Ends:
          </TextStyles.FontSize16px>
          <TextStyles.GreyFont14px className="d-flex" style={{ minWidth: 50 }}>
            <span className="three-dots">
              {selectedData && selectedData.project_end_date ? moment(selectedData.project_end_date).format("DD MMM YYYY") : 'On going'}
            </span>
          </TextStyles.GreyFont14px>
        </div>
      {selectedData &&
        selectedData.project_end_date &&
        selectedData.project_start_date && (
          <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
            <TextStyles.FontSize16px className="fw-medium">
              Duration:
            </TextStyles.FontSize16px>
            <TextStyles.GreyFont14px
              className="d-flex"
              style={{ minWidth: 30 }}
            >
              <span className="three-dots">
                {diffDate(
                  selectedData.project_end_date,
                  selectedData.project_start_date
                )}
              </span>
            </TextStyles.GreyFont14px>
          </div>
        )}
    </Styled.ReviewContentBox>
  );
};

export default Project;
