import React, { useState } from "react";
import servicesvg from "../../../../../Assets/assetsnew/service.svg";

import EditServices from "./EditServices.jsx";

import Styled from "./ServicesStyles";
import TextStyles from "../../../../../Constants/TextStyles";

import Colors from "../../../../../Constants/Colors.js";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

const iconStyles = {
  cursor: "pointer",
  color: Colors.font2,
  opacity: 0.8,
  "&:hover": {
    opacity: 1,
  },
};

const Services = ({ selectedData }) => {
  const [showModal, setShowModal] = useState(false);
  const service_list = selectedData && selectedData.service_list;
  return (
    <div className="mt-4">
      <Styled.ReviewBox>
        <BorderColorOutlinedIcon
          onClick={() => setShowModal(true)}
          sx={iconStyles}
        />
        <div className="d-flex gap-2 gap-md-3 align-items-center mb-3 w-100">
          <img src={servicesvg} alt="serviceBtn" />
          <TextStyles.FontSize18px className="d-flex" style={{ minWidth: 50 }}>
            <span className="three-dots fw-medium"> Services Summary</span>
          </TextStyles.FontSize18px>
        </div>

        {service_list &&
          service_list.map((item, index) => {
            return (
              <Styled.EditServiceContainer key={index}>
                <div
                  className="my-3 w-100"
                  style={{
                    height: "0.5px",
                    backgroundColor: "#a9abb3",
                  }}
                />
                <div className="row">
                  <div className="col-12 col-sm-9">
                    <TextStyles.FontSize16px className="d-flex mb-1">
                      <span className="three-dots fw-medium"> {item.service_name}</span>
                    </TextStyles.FontSize16px>
                    <TextStyles.GreyFont14px className="mb-1">
                      <span className="three-dots">
                        {selectedData &&
                          selectedData.currency &&
                          selectedData.currency.symbol}
                        {item.service_rate
                          ? parseFloat(item.service_rate).toFixed(2)
                          : "00.00"}
                        {item.service_rate_type !== "Flat fee"
                          ? ` ${item.service_rate_type.toLocaleLowerCase()}`
                          : ` ${item.service_rate_type.toLocaleLowerCase()}`}
                      </span>
                    </TextStyles.GreyFont14px>
                    <TextStyles.GreyFont14px className="d-flex mb-1">
                      {item.desc}
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
              </Styled.EditServiceContainer>
            );
          })}
      </Styled.ReviewBox>
      {showModal && (
        <EditServices
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default Services;
