import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import newClientImg from "../../../../../Assets/frontendImages/faq-new-client.webp";
import createClientImg from "../../../../../Assets/frontendImages/faq-create-client.webp";
import SideContentList from "../SideContentList";
import Screenshot from "../../../../UIs/Screenshot";

function HowToCreateClient({ idList, activeContentId }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          How to Create a Client
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Adding a new client in Zodot is a simple process that helps you manage
          client information efficiently.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          Follow these step to create a new client in Zodot -
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px
          id={idList[0]}
          className="fw-semibold mb-1 contentHeading"
        >
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            From the left sidebar menu, click on Clients.
          </li>
          <li className="mb-1">
            Click the “+ New Client” button to add a new client.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-1">
          Alternatively, you can also create a client from the top navbar:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click on the “+ Create” button in the top-right corner.
          </li>
          <li className="mb-1">Select Client from the dropdown menu.</li>
        </ul>

        <Screenshot src={newClientImg} alt="new client img" />

        <TextStyles.FontSize16px
          id={idList[1]}
          className="fw-semibold mb-1 contentHeading"
        >
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-2">
          In the next step, enter the following details -
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            <span className="fw-semibold">Company Name</span> – Enter the
            official name of the client’s company.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Contact Name</span> – Provide the
            primary contact person’s full name.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Email Address</span> – Input a valid
            email address for client communication.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Street Address</span> (Optional) – Add
            the client’s street address if applicable.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Suite (Optional)</span> – Include the
            suite or unit number if necessary.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">City (Optional)</span> – Specify the
            city where the client is located.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Zip Code (Optional)</span> – Enter the
            postal or ZIP code for accurate location details.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">State (Optional)</span> – Mention the
            state or region as needed.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Country (Optional)</span> – Select the
            client’s country to complete the address details.
          </li>
        </ul>

        <TextStyles.FontSize16px
          id={idList[2]}
          className="fw-semibold mb-1 contentHeading"
        >
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Once all relevant details are filled in, save the client profile. A
            confirmation message will appear once the client is created.
          </li>
          <li className="mb-1">
            After that, the client will be added to your list and you can manage
            their projects, invoices, and interactions from the Clients section.
          </li>
        </ul>

        <Screenshot src={createClientImg} alt="createClientImg" />

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, You’ve set up your first client in Zodot!
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} activeContentId={activeContentId} />
    </>
  );
}

export default HowToCreateClient;
