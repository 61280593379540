import React from "react";
import { useForm, Controller } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import Styled from "./CreateClientStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import SelectCountry from "../../../Commons/SelectCountry";
import { invoiceActions } from "../../../../../store/storage/invoiceSlice";
import { useHistory } from "react-router-dom";
import Constant from "../../../Commons/Constant";
import { checkClientExistedByEmail } from "../../../../../store/service/clientService";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";
import { validateEmail } from "../../../Commons/utils";

const CreateClient = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector((state) => state.clients.isLoading);

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const client_id =
    selectedInvoiceData &&
    selectedInvoiceData.client_id &&
    selectedInvoiceData.client_id;

  const createInvoiceMode = useSelector(
    (state) => state.invoices.createInvoiceMode
  );

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: client_id &&
      client_id._id === null && {
        company_name: client_id && client_id.company_name,
        contact_name: client_id && client_id.contact_name,
        contact_email: client_id && client_id.contact_email,
        street: client_id && client_id.street,
        suite: client_id && client_id.suite,
        city: client_id && client_id.city,
        zip_code: client_id && client_id.zip_code,
        state: client_id && client_id.state,
        country:
          client_id &&
          client_id.country &&
          Constant.countryOptions.filter(
            (data) => data.name === client_id.country
          )[0],
      },
  });

  const formSubmit = async (data) => {
    const Obj = {
      _id: null,
      company_name: data.company_name,
      contact_name: data.contact_name,
      contact_email: data.contact_email,
      street: data.street,
      suite: data.suite,
      city: data.city,
      zip_code: data.zipcode,
      country: data.country ? data.country.name : null,
      state: data.state,
      contacts: [
        { contact_name: data.contact_name, email: data.contact_email },
      ],
    };
    dispatch(
      checkClientExistedByEmail({ contact_email: data.contact_email })
    ).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(invoiceActions.selectedInvoiceAction({ client_id: Obj }));
        if (createInvoiceMode === "quickInvoice") {
          return history.push("/db/invoices/create/quickInvoice");
        }
        if (createInvoiceMode === "cProjectInvoice") {
          return history.push("/db/invoices/create/setupProject");
        }
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };
  return (
    <div className="d-flex justify-content-center px-2">
      <div style={{ maxWidth: 600, width: "100%" }}>
        <TextStyles.FontSize22px className="text-center font-weight-500 my-4">
          Create client
        </TextStyles.FontSize22px>
        <Styled.SetupBox className=" mb-4">
          <form onSubmit={handleSubmit(formSubmit)}>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="compnay_name" className="mb-2">
                Company name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.company}
                id="company_name"
                type="text"
                placeholder="Company name"
                name="company_name"
                {...register("company_name", {
                  required: "Company name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Company name is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.company_name && (
                <TextStyles.InValidFeedback>
                  {errors.company_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="contact_name" className="mb-2">
                Contact name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.name}
                id="contact_name"
                type="text"
                placeholder="Contact name"
                name="contact_name"
                {...register("contact_name", {
                  required: "Contact name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Name is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.contact_name && (
                <TextStyles.InValidFeedback>
                  {errors.contact_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="contact_email" className="mb-2">
                Contact email
              </label>
              <TextStyles.InputRectangle
                invalid={errors.email}
                id="contact_email"
                type="email"
                placeholder="Contact email"
                name="contact_email"
                {...register("contact_email", {
                  required: "Contact email is required.",
                  validate: validateEmail,
                  onBlur: (e) =>
                    setValue("email", e.target.value.trim().toLowerCase()),
                })}
              />
              {errors.contact_email && (
                <TextStyles.InValidFeedback>
                  {errors.contact_email.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="street" className="mb-2">
                    Street
                  </label>
                  <TextStyles.InputRectangle
                    id="street"
                    type="text"
                    placeholder="Street"
                    name="street"
                    {...register("street")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="suite" className="mb-2">
                    Suite
                  </label>
                  <TextStyles.InputRectangle
                    id="suite"
                    type="text"
                    placeholder="Suite"
                    name="suite"
                    {...register("suite")}
                  />
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="city" className="mb-2">
                    City
                  </label>
                  <TextStyles.InputRectangle
                    id="city"
                    type="text"
                    placeholder="City"
                    name="city"
                    {...register("city")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="zipcode" className="mb-2">
                    Zip code
                  </label>
                  <TextStyles.InputRectangle
                    id="zipcode"
                    type="text"
                    placeholder="Zip code"
                    name="zip_code"
                    {...register("zip_code")}
                  />
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="state" className="mb-2">
                    State
                  </label>
                  <TextStyles.InputRectangle
                    id="state"
                    type="text"
                    placeholder="State"
                    name="state"
                    {...register("state")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="country" className="mb-2">
                    Country
                  </label>
                  <div>
                    <Controller
                      control={control}
                      id="country"
                      name="country"
                      render={({ field: { onChange, value } }) => (
                        <SelectCountry
                          placeholder="Select country"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div>
              <Styled.NextButton disabled={isLoading}>
                {isLoading ? <LoaderSpin /> : "Next"}
              </Styled.NextButton>
            </div>
          </form>
        </Styled.SetupBox>
      </div>
    </div>
  );
};

export default CreateClient;
