import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import TextStyles from "../../../../../Constants/TextStyles";
import { updateAddNonCompletePC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import ToggleDefaultValues from "../../../Commons/ToggleDefaultValues";
import { fieldOptions } from "../../../Commons/utils";

const NonCompeteModal = ({
  show,
  handleClose,
  heading,
  paragraph,
  defaultHeading,
  defaultParagraph,
}) => {
  const dispatch = useDispatch();
  const { isLoading, selectedData } = useSelector(
    (state) => state.proposalAndContract
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      heading,
      paragraph: paragraph,
      checkbox: false,
    },
  });

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      add_non_complete: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
      },
    };
    dispatch(updateAddNonCompletePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const checked = watch("checkbox");

  useEffect(() => {
    setValue("heading", checked ? defaultHeading : heading);
    setValue("paragraph", checked ? defaultParagraph : paragraph);

    if (checked) clearErrors();
  }, [checked]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              id="headline"
              name="heading"
              placeholder="Add text here"
              {...register("heading", fieldOptions())}
            />

            {errors.heading && (
              <TextStyles.InValidFeedback>
                {errors.heading.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={7}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph", fieldOptions())}
            />

            {errors.paragraph && (
              <TextStyles.InValidFeedback>
                {errors.paragraph.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <ToggleDefaultValues register={register} />

          <TextStyles.FontSize14px className="mt-4">
            <ErrorOutlineOutlinedIcon sx={{ color: "#ff9900", fontSize: 20 }} />
            <span className="ms-2">
              We strongly advise freelancers against signing non-compete
              agreements that restrict their ability to pursue or access work
              opportunities during and after the agreement's term. If a hiring
              party insists on a non-compete, it is recommended to seek legal
              counsel from an attorney.
            </span>
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NonCompeteModal;
