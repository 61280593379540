import React from "react";
import { Modal } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import poweredByZodotLogo from "../../../../Assets/assetsnew/poweredByZodot.png";

const EmailPreview = ({ show, handleClose, text, subject }) => {
  const { data, businessInfoData, brandAndLogoData } = useSelector(
    (state) => state.auth
  );

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight600px"
      fullscreen
    >
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <TextStyles.FontSize20px style={{ fontWeight: 500, fontSize: 24 }}>
          Email Preview
        </TextStyles.FontSize20px>
        <ClearIcon
          sx={{
            color: Colors.font2,
            fontSize: 30,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={handleClose}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex mb-2 gap-1">
          <TextStyles.FontSize14px>From:</TextStyles.FontSize14px>
          <TextStyles.GreyFont14px
            className="d-flex w-100"
            style={{ minWidth: 100 }}
          >
            <span className="three-dots">
              {businessInfoData && businessInfoData.business_name
                ? `${businessInfoData.business_name} via Zodot`
                : `${data.firstName} ${data.lastName} via Zodot`}
            </span>
          </TextStyles.GreyFont14px>
        </div>

        <div className="d-flex mb-2 gap-1">
          <TextStyles.FontSize14px>Subject:</TextStyles.FontSize14px>
          <TextStyles.GreyFont14px
            className="d-flex w-100"
            style={{ minWidth: 100 }}
          >
            <span className="three-dots">{subject}</span>
          </TextStyles.GreyFont14px>
        </div>

        <div
          className="p-3 p-sm-4 mt-4 rounded-3"
          style={{
            borderTop: `7px solid ${
              brandAndLogoData && brandAndLogoData.brand_color
                ? brandAndLogoData.brand_color
                : Colors.primary
            }`,
            boxShadow: "0px 0px 9px 0px lightgrey",
          }}
        >
          {brandAndLogoData &&
            brandAndLogoData.brand_logo &&
            brandAndLogoData.brand_logo.file_url && (
              <div
                className={`mt-2 mb-4 ${
                  brandAndLogoData.logo_alignment === "left"
                    ? "text-start"
                    : brandAndLogoData.logo_alignment === "right"
                    ? "text-end"
                    : "text-center"
                }
                `}
              >
                <img
                  src={brandAndLogoData.brand_logo.file_url}
                  alt="logoImg"
                  style={{ maxHeight: 100 }}
                />
              </div>
            )}

          <TextStyles.FontSize14px
            className="mt-3 pe-0 pe-sm-5"
            style={{ whiteSpace: "pre-line" }}
          >
            {text}
          </TextStyles.FontSize14px>

          <div
            className="my-4"
            style={{ backgroundColor: Colors.borderInput, height: 1 }}
          />

          <div className="text-center">
            <img
              style={{ maxWidth: 125 }}
              src={poweredByZodotLogo}
              alt="powered by zodot logo"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmailPreview;
