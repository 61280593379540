import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { message } from "antd";

import ClearIcon from "@mui/icons-material/Clear";
import signatureImg from "../../../../../../Assets/assetsnew/signature-small.svg";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LoaderSpin from "../../../../Commons/LoaderSpin";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "./SignatureStyles";
import { findPrimaryContact, getFileFromUrl } from "../../../../Commons/utils";

import { putRequest } from "../../../../../../axios/axios";
import Colors from "../../../../../../Constants/Colors";
import RedirectingModal from "./RedirectingModal";

const SignProposal = ({
  show,
  handleClose,
  selectedData,
  setSelectedData,
  brandAndLogoData,
  token,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cnfSignature, setCnfSignature] = useState(false);
  const [signature, setSignature] = useState(
    selectedData.client_signature && selectedData.client_signature.text
      ? selectedData.client_signature.text
      : ""
  );
  const [redirecting, setRedirecting] = useState(false);

  const primaryContact = findPrimaryContact(selectedData);

  const [files, setFiles] = useState([]);
  const [fileErrors, setfileErrors] = useState([]);
  const MAX_SIZE = 2000000;

  useEffect(() => {
    if (
      selectedData.client_signature &&
      selectedData.client_signature.image &&
      selectedData.client_signature.image.image_name &&
      selectedData.client_signature.image.image_url
    ) {
      let filename = selectedData.client_signature.image.image_name;
      let indexofUnderscore = filename.indexOf("_");
      let file_name = filename.substring(indexofUnderscore + 1);
      getFileFromUrl(
        selectedData.client_signature.image.image_url,
        file_name
      ).then((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        setFiles([file]);
      });
    }
  }, []);

  const deleteSignature = () => {
    setSignature("");
    setFiles([]);
  };

  const addSignature = (e) => {
    setFiles([]);
    setSignature(e.target.value);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
    },
    maxSize: MAX_SIZE,
    multiple: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setSignature("");
      setfileErrors(rejectedFiles.length > 0 ? rejectedFiles[0].errors : []);
      // showText = files.length === 0;
    },
    onDragEnter: () => {
      setFiles([]);
      // setfileErrors([]);
    },
  });

  const imageErrors = {
    FILESIZE: "More than 2MB in size",
    FILETYPE: "Not an image file",
  };

  const getErrorMessage = () => {
    switch (fileErrors[0].code) {
      case "file-invalid-type":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.FILETYPE}
          </p>
        );
      case "file-too-large":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.FILESIZE}
          </p>
        );
      default:
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            File error
          </p>
        );
    }
  };

  const submitHandler = () => {
    let formData = new FormData();
    formData.set("pc_id", selectedData._id);
    formData.set("text", signature);
    formData.set("token", token);
    formData.append("image", files[0]);
    setIsLoading(true);
    putRequest("pc/proposal_contract/client_signature", {}, formData)
      .then((data) => {
        if (data.data.success === true) {
          setSignature("");
          setFiles([]);
          setIsLoading(false);
          setSelectedData(data.data.data);
          setRedirecting(true);
          message.success(`${data.data.data.section} approved successfully!`);
        } else {
          setIsLoading(true);
          message.error(`${data.error.message}!`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(`${error.message}!`);
      });
  };

  return (
    <>
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <TextStyles.FontSize20px>Signature</TextStyles.FontSize20px>
        <ClearIcon
          sx={{
            color: Colors.font2,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={handleClose}
        />
      </Modal.Header>

      <Modal.Body className="modal-body">
        <Styled.SignatureImageBox>
          <TextStyles.FontSize14px>Your signature</TextStyles.FontSize14px>
          {(signature || files.length > 0) && (
            <div className="d-flex justify-content-end me-3">
              <i
                className="bi bi-x-circle-fill"
                style={{ color: "grey", cursor: "pointer" }}
                onClick={deleteSignature}
              />
            </div>
          )}

          {files.length > 0 && (
            <div className="d-flex justify-content-center">
              <img
                alt="Preview"
                key={files[0].preview}
                src={files[0].preview}
                style={{
                  maxWidth: "100%",
                  height: "120px",
                }}
              />
            </div>
          )}
          {files.length === 0 && fileErrors.length > 0 && getErrorMessage()}
          {files.length === 0 && fileErrors.length === 0 && (
            <div className="d-flex align-items-center">
              <img
                src={signatureImg}
                alt="signatureBtn"
                style={{
                  width: 50,
                  height: 54,
                }}
              />
              <div
                style={{
                  fontFamily: "licorice",
                  fontWeight: 400,
                  fontSize: 36,
                  marginLeft: 20,
                }}
              >
                {signature}
              </div>
            </div>
          )}
        </Styled.SignatureImageBox>

        <div className="d-flex flex-wrap gap-3 justify-content-center align-items-center mt-3">
          <TextStyles.FontSize14px>
            Type your name to sign
          </TextStyles.FontSize14px>

          <TextStyles.InputRectangle
            style={{ maxWidth: 180 }}
            id="signature"
            placeholder="Type signature"
            name="signature"
            value={signature}
            onChange={(e) => addSignature(e)}
          />
        </div>

        <TextStyles.FontSize14px className="text-center my-2">
          Or
        </TextStyles.FontSize14px>

        <div
          className="d-flex align-items-center justify-content-center mb-3"
          style={{ cursor: "pointer" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <CloudUploadIcon
            sx={{
              fontSize: 30,
              color:
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary,
            }}
          />
          <TextStyles.GreyFont14px className="ms-2">
            Upload your signature
          </TextStyles.GreyFont14px>
        </div>

        <TextStyles.CheckBox
          className="d-flex gap-2"
          checkedColor={brandAndLogoData && brandAndLogoData.brand_color}
        >
          <input
            style={{ cursor: "pointer" }}
            className="form-check-input"
            type="checkbox"
            id="checkbox"
            name="checkbox"
            checked={cnfSignature}
            onChange={() => setCnfSignature(!cnfSignature)}
          />
          <label htmlFor="checkbox" style={{ fontSize: 13 }}>
            <span className="fw-medium me-1">
              I, {primaryContact && primaryContact.contact_name},
            </span>
            hereby confirm that I have thoroughly reviewed and accepted the terms of the {selectedData && selectedData.section.toLowerCase()}. The below confirmation serves as my official and legally recognized approval of the outlined terms.
          </label>
        </TextStyles.CheckBox>
      </Modal.Body>
      
      <Modal.Footer>
        <Styled.Button
          backgroundColor={brandAndLogoData && brandAndLogoData.brand_color}
          onClick={submitHandler}
          disabled={
            (signature.trim().length < 1 && files.length < 1) ||
            fileErrors.length > 0 ||
            isLoading ||
            !cnfSignature
              ? true
              : false
          }
        >
          {(signature.trim().length > 0 || files.length > 0) && isLoading ? (
            <LoaderSpin />
          ) : (
            " Confirm signature"
          )}
        </Styled.Button>
      </Modal.Footer>
    </Modal>

      {redirecting && (
        <RedirectingModal
          show={redirecting}
          onClose={() => setRedirecting(false)}
          onCloseSignModal={handleClose}
        />
      )}
    </>
  );
};

export default SignProposal;
