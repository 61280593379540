import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import RemoveModal from "../../../../Commons/RemoveModal";
import { deleteAddSectionColumnPC } from "../../../../../../store/service/proposalAndContractService";
import EditAddSectionColumnModal from "./EditAddsectionColumnModal";
import Colors from "../../../../../../Constants/Colors";

import columnImage from "../../../../../../Assets/assetsnew/column-image-new.svg";

const iconStyles = {
  cursor: "pointer",
  color: Colors.font2,
  opacity: 0.8,
  "&:hover": {
    opacity: 1,
  },
};

const Column = ({ data, position, pc_id }) => {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const deleteHandler = () => {
    const Obj = {
      pc_id: pc_id,
      _id: data._id,
      position: position,
    };

    dispatch(deleteAddSectionColumnPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div className="mt-4">
      <Styled.ReviewBox>
        <div className="edit-delete-wrapper">
          <BorderColorOutlinedIcon
            onClick={() => setEditModal(true)}
            sx={iconStyles}
          />
          <CloseOutlinedIcon
            onClick={() => {
              setShowRemoveModal(true);
              setConfirmDialog({
                onConfirm: () => deleteHandler(),
              });
            }}
            sx={{ ...iconStyles, marginLeft: 1 }}
          />
        </div>

        <div className="d-flex align-items-center gap-3 mb-4">
          <img
            src={columnImage}
            alt="columnImage"
            style={{ maxWidth: "2.2rem" }}
          />

          <TextStyles.FontSize18px className="fw-medium">
            Column
          </TextStyles.FontSize18px>
        </div>

        <TextStyles.FontSize14px>
          <div className="mb-2 fw-medium">Column title</div>
          <Styled.Text className="mb-3">
            {data.column_title}
          </Styled.Text>

          <div className="mb-2 fw-medium">Column description</div>
          <div className="d-flex flex-wrap flex-md-nowrap gap-3">
            <Styled.Text className="w-100">{data.input.input_one}</Styled.Text>
            <Styled.Text className="w-100">{data.input.input_two}</Styled.Text>
            <Styled.Text className="w-100">
              {data.input.input_three}
            </Styled.Text>
          </div>
        </TextStyles.FontSize14px>
      </Styled.ReviewBox>

      {editModal && (
        <EditAddSectionColumnModal
          show={editModal}
          handleClose={() => setEditModal(false)}
          editData={data}
          pc_id={pc_id}
          position={position}
        />
      )}
      <RemoveModal
        title={"column"}
        confirmDialog={confirmDialog}
        showRemoveModal={showRemoveModal}
        handleCloseRemoveModal={handleCloseRemoveModal}
      />
    </div>
  );
};

export default Column;
