import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import EditBillingSchedule from "../../ProposalReviews/BillingSchedules/EditBillingSchedule";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import TextStyles from "../../../../../Constants/TextStyles";

import Styled from "../PageOne/PageOne";
import Colors from "../../../../../Constants/Colors";
import calendarsvg from "../../../../../Assets/assetsnew/calendar.svg";

const BillingSchedules = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const billing_scheduled = selectedData && selectedData.billing_scheduled;
  const [showBillingSchedule, setShowBillingSchedule] = useState(false);

  return (
    <div className="mt-4">
      <Styled.ReviewContentBox>
        <BorderColorOutlinedIcon
          className="edit-icon"
          onClick={() => setShowBillingSchedule(true)}
          sx={{
            cursor: "pointer",
            color: Colors.font2,
            opacity: 0.8,
            "&:hover": { opacity: 1 },
          }}
        />

        <div className="d-flex align-items-center gap-2 gap-md-3">
          <div style={{ maxWidth: "2.2rem" }}>
            <img className="w-100" src={calendarsvg} alt="calendarsvg" />
          </div>

          <div>
            <TextStyles.FontSize18px className="three-dots fw-medium">
              Billing Schedule
            </TextStyles.FontSize18px>

            <TextStyles.GreyFont14px className="three-dots">
              {billing_scheduled &&
              billing_scheduled.invoice_frequency &&
              billing_scheduled.invoice_frequency === "Once"
                ? "Contractor bills Client once"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Weekly"
                ? "Contractor bills Client weekly"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Bi-weekly"
                ? "Contractor bills Client Bi-weekly"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Monthly"
                ? "Contractor bills Client monthly"
                : ""}
              {billing_scheduled &&
              billing_scheduled.deposit &&
              billing_scheduled.deposit.require_deposit === true
                ? ", plus deposit"
                : ""}
            </TextStyles.GreyFont14px>
          </div>
        </div>

        <div
          className="my-3"
          style={{
            width: "100%",
            height: "0.5px",
            backgroundColor: "#b2b6c5",
          }}
        />

        {billing_scheduled &&
          billing_scheduled.deposit &&
          billing_scheduled.deposit.require_deposit === true &&
          billing_scheduled.deposit.value && (
            <div>
              <div>
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <TextStyles.FontSize16px
                    className="d-flex"
                    style={{ minWidth: 50 }}
                  >
                    <span className="three-dots fw-medium">Deposit</span>
                  </TextStyles.FontSize16px>
                  <TextStyles.FontSize16px
                    className="d-flex justify-content-end"
                    style={{ minWidth: 50 }}
                  >
                    <span className="three-dots fw-medium">
                      {selectedData &&
                        selectedData.currency &&
                        selectedData.currency.symbol}
                      {billing_scheduled &&
                        billing_scheduled.deposit &&
                        billing_scheduled.deposit.require_deposit === true &&
                        billing_scheduled.deposit.value &&
                        parseFloat(billing_scheduled.deposit.value).toFixed(2)}
                    </span>
                  </TextStyles.FontSize16px>
                </div>
                <TextStyles.GreyFont14px className="mt-2">
                  Deposit will be subtracted from subsequent invoices.
                </TextStyles.GreyFont14px>

                <TextStyles.GreyFont14px className="mt-2"></TextStyles.GreyFont14px>
              </div>
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "0.5px",
                  backgroundColor: "#b2b6c5",
                }}
              />
            </div>
          )}

        <div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <TextStyles.FontSize16px className="fw-medium">
              {billing_scheduled &&
              billing_scheduled.invoice_frequency &&
              billing_scheduled.invoice_frequency === "Once"
                ? "Final invoice"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Weekly"
                ? "Billed Weekly"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Bi-weekly"
                ? "Billed Bi-weekly"
                : billing_scheduled &&
                  billing_scheduled.invoice_frequency &&
                  billing_scheduled.invoice_frequency === "Monthly"
                ? "Billed Monthly"
                : ""}
            </TextStyles.FontSize16px>
          </div>
          {billing_scheduled &&
            billing_scheduled.invoice_frequency === "Once" && (
              <TextStyles.GreyFont14px>
                {billing_scheduled.issue_invoice &&
                  moment(billing_scheduled.issue_invoice).format("DD MMM YYYY")}
              </TextStyles.GreyFont14px>
            )}

          {billing_scheduled &&
          billing_scheduled.invoice_frequency === "Weekly" &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.invoices_type === "Never" ? (
            <div className="d-flex flex-wrap gap-2">
              <TextStyles.FontSize14px>First invoice:</TextStyles.FontSize14px>
              <TextStyles.GreyFont14px>
                {billing_scheduled.weekly_invoice.start_date &&
                  moment(billing_scheduled.weekly_invoice.start_date).format(
                    "DD MMM YYYY"
                  )}
              </TextStyles.GreyFont14px>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Weekly" &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.invoices_type === "After" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.weekly_invoice.start_date &&
                    moment(billing_scheduled.weekly_invoice.start_date).format(
                      "DD MMM YYYY"
                    )}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.weekly_invoice.end_date &&
                    moment(billing_scheduled.weekly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.weekly_invoice.num_invoices &&
                    ` (After ${billing_scheduled.weekly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Weekly" &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.invoices_type === "On" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.weekly_invoice.start_date &&
                    moment(billing_scheduled.weekly_invoice.start_date).format(
                      "DD MMM YYYY"
                    )}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.weekly_invoice.end_date &&
                    moment(billing_scheduled.weekly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.weekly_invoice.num_invoices &&
                    ` (After ${billing_scheduled.weekly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : (
            ""
          )}

          {billing_scheduled &&
          billing_scheduled.invoice_frequency === "Bi-weekly" &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.invoices_type === "Never" ? (
            <div className="d-flex flex-wrap gap-2">
              <TextStyles.FontSize14px>First invoice:</TextStyles.FontSize14px>
              <TextStyles.GreyFont14px>
                {billing_scheduled.biWeekly_invoice.start_date &&
                  moment(billing_scheduled.biWeekly_invoice.start_date).format(
                    "DD MMM YYYY"
                  )}
              </TextStyles.GreyFont14px>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Bi-weekly" &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.invoices_type === "After" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.biWeekly_invoice.start_date &&
                    moment(
                      billing_scheduled.biWeekly_invoice.start_date
                    ).format("DD MMM YYYY")}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.biWeekly_invoice.end_date &&
                    moment(billing_scheduled.biWeekly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.biWeekly_invoice.num_invoices &&
                    ` (After ${billing_scheduled.biWeekly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Bi-weekly" &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.invoices_type === "On" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.biWeekly_invoice.start_date &&
                    moment(
                      billing_scheduled.biWeekly_invoice.start_date
                    ).format("DD MMM YYYY")}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.biWeekly_invoice.end_date &&
                    moment(billing_scheduled.biWeekly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.biWeekly_invoice.num_invoices &&
                    ` (After ${billing_scheduled.biWeekly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : (
            ""
          )}

          {billing_scheduled &&
          billing_scheduled.invoice_frequency === "Monthly" &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.invoices_type === "Never" ? (
            <div className="d-flex flex-wrap gap-2">
              <TextStyles.FontSize14px>First invoice:</TextStyles.FontSize14px>
              <TextStyles.GreyFont14px>
                {billing_scheduled.monthly_invoice.start_date &&
                  moment(billing_scheduled.monthly_invoice.start_date).format(
                    "DD MMM YYYY"
                  )}
              </TextStyles.GreyFont14px>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Monthly" &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.invoices_type === "After" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.monthly_invoice.start_date &&
                    moment(billing_scheduled.monthly_invoice.start_date).format(
                      "DD MMM YYYY"
                    )}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.monthly_invoice.end_date &&
                    moment(billing_scheduled.monthly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}
                  {billing_scheduled.monthly_invoice.num_invoices &&
                    ` (After ${billing_scheduled.monthly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : billing_scheduled &&
            billing_scheduled.invoice_frequency === "Monthly" &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.invoices_type === "On" ? (
            <div className="d-flex gap-1 flex-column">
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>
                  First invoice:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.monthly_invoice.start_date &&
                    moment(billing_scheduled.monthly_invoice.start_date).format(
                      "DD MMM YYYY"
                    )}
                </TextStyles.GreyFont14px>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <TextStyles.FontSize14px>Last invoice:</TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {billing_scheduled.monthly_invoice.end_date &&
                    moment(billing_scheduled.monthly_invoice.end_date).format(
                      "DD MMM YYYY"
                    )}

                  {billing_scheduled.monthly_invoice.num_invoices &&
                    ` (After ${billing_scheduled.monthly_invoice.num_invoices} invoices)`}
                </TextStyles.GreyFont14px>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {showBillingSchedule && (
          <EditBillingSchedule
            selectedData={selectedData}
            show={showBillingSchedule}
            handleClose={() => setShowBillingSchedule(false)}
          />
        )}
      </Styled.ReviewContentBox>
    </div>
  );
};

export default BillingSchedules;
