import React, { useState } from "react";
import { useSelector } from "react-redux";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageOne";

import PCEditClientInfo from "../../ProposalReviews/PCEditClientInfo";
import PCEditBusinessInfo from "../../ProposalReviews/PCEditBusinessInfo";
import Colors from "../../../../../Constants/Colors";
import { findPrimaryContact } from "../../../Commons/utils";

const Client = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);

  const [showClientModal, setShowClientModal] = useState(false);
  const [showBusinessModal, setShowBusinessModal] = useState(false);

  const primaryContact = findPrimaryContact(selectedData);

  const {
    street,
    suite,
    city,
    state,
    country,
    zip_code,
    tax_id_label,
    tax_id_number,
  } = (selectedData && selectedData.client_id) || {};

  const clientAddress = [street, suite, city, zip_code, state, country]
    .filter((elem) => elem)
    .join(", ");

  const {
    street: businessStreet,
    suite: businessSuite,
    city: businessCity,
    state: businessState,
    country: businessCountry,
    zip_code: businessZipcode,
    tax_id_label: businessTaxIDLabel,
    tax_id_number: businessTaxIDNumber,
  } = businessInfoData || {};

  const businessAddress = [
    businessStreet,
    businessSuite,
    businessCity,
    businessZipcode,
    businessState,
    businessCountry,
  ]
    .filter((elem) => elem)
    .join(", ");

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">
          <Styled.ReviewContentBox style={{ minHeight: 210 }} className="mt-4">
            <BorderColorOutlinedIcon
              className="edit-icon"
              onClick={() => setShowClientModal(true)}
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                "&:hover": { opacity: 1 },
              }}
            />
            <TextStyles.FontSize18px
              className="fw-medium"
              style={{ color: Colors.primary }}
            >
              Client
            </TextStyles.FontSize18px>
            <TextStyles.FontSize18px className="mt-2 fw-medium">
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.company_name}
            </TextStyles.FontSize18px>
            <TextStyles.GreyFont14px className="mt-2">
              <span className="fw-medium">Name: </span>
              {primaryContact && primaryContact.contact_name}
            </TextStyles.GreyFont14px>

            <TextStyles.GreyFont14px className="mt-1">
              <span className="fw-medium">Email: </span>
              {primaryContact && primaryContact.email}
            </TextStyles.GreyFont14px>

            {clientAddress ? (
              <TextStyles.GreyFont14px className="mt-1">
                <span className="fw-medium">Address: </span>
                {clientAddress}
              </TextStyles.GreyFont14px>
            ) : (
              ""
            )}

            {tax_id_label ? (
              <TextStyles.GreyFont14px className="mt-1">
                <span className="fw-medium">Tax ID Label: </span>
                {tax_id_label}
              </TextStyles.GreyFont14px>
            ) : (
              ""
            )}

            {tax_id_number ? (
              <TextStyles.GreyFont14px className="mt-1">
                <span className="fw-medium">Tax ID Number: </span>
                {tax_id_number}
              </TextStyles.GreyFont14px>
            ) : (
              ""
            )}
          </Styled.ReviewContentBox>
        </div>
        <div className="col-12 col-md-6">
          <Styled.ReviewContentBox style={{ minHeight: 210 }} className="mt-4">
            <div className="d-flex justify-content-center">
              <BorderColorOutlinedIcon
                className="edit-icon"
                onClick={() => setShowBusinessModal(true)}
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": { opacity: 1 },
                }}
              />
            </div>

            <TextStyles.FontSize18px
              className="fw-medium"
              style={{ color: Colors.primary }}
            >
              Contractor
            </TextStyles.FontSize18px>
            <TextStyles.FontSize18px className="mt-2 fw-medium">
              {businessInfoData && businessInfoData.business_name
                ? businessInfoData.business_name
                : `${data.firstName} ${data.lastName}`}
            </TextStyles.FontSize18px>

            <TextStyles.GreyFont14px className="mt-2">
              <span className="fw-medium">Email: </span>
              {data && data.email}
            </TextStyles.GreyFont14px>

            {businessAddress ? (
              <TextStyles.GreyFont14px className="mt-1">
                <span className="fw-medium">Address: </span>
                {businessAddress}
              </TextStyles.GreyFont14px>
            ) : (
              ""
            )}

            {businessTaxIDLabel ? (
              <TextStyles.GreyFont14px className="mt-1">
                <span className="fw-medium">Tax ID Label: </span>
                {businessTaxIDLabel}
              </TextStyles.GreyFont14px>
            ) : (
              ""
            )}

            {businessTaxIDNumber ? (
              <TextStyles.GreyFont14px className="mt-1">
                <span className="fw-medium">Tax ID Number: </span>
                {businessTaxIDNumber}
              </TextStyles.GreyFont14px>
            ) : (
              ""
            )}
          </Styled.ReviewContentBox>
        </div>
      </div>
      {showClientModal && (
        <PCEditClientInfo
          selectedData={selectedData}
          show={showClientModal}
          handleClose={() => setShowClientModal(false)}
        />
      )}
      {showBusinessModal && (
        <PCEditBusinessInfo
          show={showBusinessModal}
          handleClose={() => setShowBusinessModal(false)}
          data={data}
          businessInfoData={businessInfoData}
        />
      )}
    </div>
  );
};

export default Client;
