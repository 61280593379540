import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import { BackButton } from "../../../../Commons/BackAndForwardButton";

import Styled from "./CreateInvoiceStyles";
import TextStyles from "../../../../../../Constants/TextStyles";
import Colors from "../../../../../../Constants/Colors";
import { useLocation } from "react-router-dom";
import ProtectedRoute from "../../../../../auth/ProtectedRoute";
import InvoiceSetup from "../../../../invoices/CreateInvoice/InvoiceSetup/InvoiceSetup";
import { invoiceActions } from "../../../../../../store/storage/invoiceSlice";

import ReviewInvoice from "../../../../invoices/ReviewInvoice/ReviewInvoice";
import SendInvoice from "../../../../invoices/SendInvoice/SendInvoice";
import DiscardChangesModal from "../../../../invoices/InvoiceModals/DiscardChangesModal";

import { saveInvoice } from "../../../../../../store/service/invoiceService";
import { message } from "antd";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

import { Popover } from "antd";
import LoaderSpin from "../../../../Commons/LoaderSpin";

const CreateInvoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pathname = location && location.pathname.split("/")[6];

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const selectedProject = useSelector((state) => state.project.selectedProject);

  // modals
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  // select invoice
  const [openSaveInvoiceAs, setOpenInvoiceSaveAs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenSaveInvoiceAsChange = (newOpen) => {
    setOpenInvoiceSaveAs(newOpen);
  };

  const discardChangesHandler = () => {
    dispatch(invoiceActions.selectedInvoiceResetAction());
    history.push(`/db/projects/${selectedProject._id}/invoices`);
  };

  const saveInvoiceHandler = (status) => {
    const Obj = {
      ...selectedInvoiceData,
      status: status,
    };
    setIsLoading(true);
    dispatch(saveInvoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setIsLoading(false);
        history.push(`/db/projects/${selectedProject._id}/invoices`);
        dispatch(invoiceActions.selectedInvoiceResetAction());
        message.success(`${data.payload.message}`);
      } else {
        setIsLoading(false);
        message.error(`${data.error.message}!`);
      }
    });
  };

  const saveInvoiceAsContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.List
          onClick={() => {
            setOpenInvoiceSaveAs(false);
            saveInvoiceHandler("Draft");
          }}
        >
          Save Draft
        </Styled.List>
        <Styled.List
          onClick={() => {
            setOpenInvoiceSaveAs(false);
            saveInvoiceHandler("Sent");
          }}
        >
          Save & Mark Sent
        </Styled.List>
      </TextStyles.FontSize14px>
    );
  };

  return (
    <div
      style={{
        background: Colors.backgroundGrey,
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          background: Colors.white,
          zIndex: 200,
        }}
        className="fixed-top"
      >
        <div className="px-2 py-3">
          <TextStyles.FontSize14px
            className="d-none d-sm-flex"
            style={{
              position: "absolute",
              left: "0.75rem",
              top: "1.3rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ClearIcon
              onClick={() => {
                if (
                  selectedInvoiceData &&
                  ((selectedInvoiceData.invoice_services &&
                    selectedInvoiceData.invoice_services.length > 0) ||
                    (selectedInvoiceData.line_item_services &&
                      selectedInvoiceData.line_item_services.length > 0))
                ) {
                  setShowDiscardModal(true);
                  setConfirmDialog({
                    onConfirm: () => discardChangesHandler(),
                  });
                } else {
                  history.push(`/db/projects/${selectedProject._id}/invoices`);
                }
              }}
              sx={{
                fontSize: 24,
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize24px className="text-center font-weight-500">
            Create Invoice
          </TextStyles.FontSize24px>
          <TextStyles.FontSize14px
            className="d-none d-sm-flex"
            style={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            {selectedInvoiceData &&
              ((selectedInvoiceData.invoice_services &&
                selectedInvoiceData.invoice_services.length > 0) ||
                (selectedInvoiceData.line_item_services &&
                  selectedInvoiceData.line_item_services.length > 0)) && (
                <TextStyles.FontSize14px>
                  <Popover
                    placement="bottomRight"
                    // title={"Hello"}
                    content={saveInvoiceAsContent}
                    trigger="click"
                    open={openSaveInvoiceAs}
                    onOpenChange={handleOpenSaveInvoiceAsChange}
                  >
                    <Styled.DownloadButton>
                      {isLoading ? (
                        <LoaderSpin />
                      ) : (
                        <div className="d-flex">
                          <span className="ps-2">Save</span>
                          <ArrowDropDownOutlinedIcon
                            sx={{
                              color: Colors.font2,
                              fontSize: 24,
                              transform: openSaveInvoiceAs
                                ? "rotate(180deg)"
                                : "",
                            }}
                          />
                        </div>
                      )}
                    </Styled.DownloadButton>
                  </Popover>
                </TextStyles.FontSize14px>
              )}
          </TextStyles.FontSize14px>
        </div>
        <div className="d-flex">
          <Styled.ProgressBar>
            <div
              className="col"
              style={{
                height: "100%",
                backgroundColor: Colors.primary,
                color: Colors.white,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Setup
            </div>
            <div
              className="col"
              style={
                pathname === "review" || pathname === "send"
                  ? {
                      height: "100%",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : { justifyContent: "center", display: "flex" }
              }
            >
              Review
            </div>
            <div
              className="col"
              style={
                pathname === "send"
                  ? {
                      height: "100%",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : { justifyContent: "center", display: "flex" }
              }
            >
              Send
            </div>
          </Styled.ProgressBar>
        </div>
      </div>

      <div style={{ paddingTop: 100 }}>
        {pathname !== "setup" && (
          <div
            style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
            onClick={() => history.goBack()}
          >
            <BackButton />
          </div>
        )}
        {pathname === "setup" && (
          <ProtectedRoute
            path="/db/projects/:id/invoices/create/setup"
            exact
            component={InvoiceSetup}
          />
        )}
        {pathname === "review" && (
          <ProtectedRoute
            path="/db/projects/:id/invoices/create/review"
            exact
            component={ReviewInvoice}
          />
        )}
        {pathname === "send" && (
          <ProtectedRoute
            path="/db/projects/:id/invoices/create/send"
            exact
            component={SendInvoice}
          />
        )}
        {showDiscardModal && (
          <DiscardChangesModal
            show={showDiscardModal}
            confirmDialog={confirmDialog}
            handleClose={() => setShowDiscardModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default CreateInvoice;
