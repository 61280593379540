import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import createTaskImg from "../../../../../Assets/frontendImages/faq-create-task.webp";
import newTaskImg from "../../../../../Assets/frontendImages/faq-new-task.webp";
import SideContentList from "../SideContentList";
import Screenshot from "../../../../UIs/Screenshot";

function HowToCreateTask({ idList, activeContentId }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          How to Create a Task
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Managing tasks efficiently is crucial for productivity. Therefore,
          Zodot allows its users to create, edit, and organize their tasks
          seamlessly. Here is a step-by-step guide on creating task in Zodot -
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px
          id={idList[0]}
          className="fw-semibold mb-1 contentHeading"
        >
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Log in to your Zodot account.</li>
          <li className="mb-1">
            From the left sidebar menu, click on Tasks and Notes.
          </li>
        </ul>

        <Screenshot src={newTaskImg} alt="new task img" />

        <TextStyles.FontSize16px
          id={idList[1]}
          className="fw-semibold mb-1 contentHeading"
        >
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            You can add a task through the “New Task” Button.
          </li>
          <li className="mb-1">
            Click on the New Task button, type your task and press Enter to add
            it.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-1 fw-semibold">
          Alternatively,
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">You can create a task from the top navbar.</li>
          <li className="mb-1">
            Click on the “+ Create” button on the top-right corner.
          </li>
          <li className="mb-1">Select Task from the dropdown menu.</li>
          <li className="mb-1">
            Fill in the task details, such as name, project, client, and due
            date.
          </li>
          <li className="mb-1">Click Save to add the task.</li>
        </ul>

        <Screenshot src={createTaskImg} alt="create task img" />

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, Your task is now created. You can track your progress
          and update the status as you complete the tasks.
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} activeContentId={activeContentId} />
    </>
  );
}

export default HowToCreateTask;
