import React from "react";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Colors from "../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    fontWeight: "500 !important",
    borderRadius: "6px !important",
    fontFamily: "Inter !important",
    fontSize: "1rem !important",
    height: "3rem !important",
    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  rootError: {
    border: `1px solid ${Colors.red}`,
    fontWeight: "500 !important",
    borderRadius: "6px !important",
    fontFamily: "Inter !important",
    fontSize: "1rem !important",
    height: "3rem !important",
    "& .MuiAutocomplete-input": {
      fontFamily: "Inter",
      fontSize: "1rem",
      color: Colors.frontendFont2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.frontendFont2,
    fontWeight: "500 !important",
    marginBottom: "2px !important",
    fontSize: "1rem !important",
    fontFamily: "Inter !important",

    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const Items = [
  { id: "1", query: "General Questions" },
  { id: "2", query: "Technical Help" },
  { id: "3", query: "Pricing Details" },
  { id: "4", query: "Payment and Licensing" },
  { id: "5", query: "Product Feedback" },
  { id: "6", query: "Report a Bug" },
  { id: "7", query: "Privacy Policy or Other Privacy Related Matter" },
];

const SelectQuery = (props) => {
  const classes = useStyles();
  return (
    <Select
      classes={{
        select: classes.select,
      }}
      className={props.invalid ? classes.rootError : classes.root}
      labelId="demo-simple-select-standard-label"
      id="demo-simple-select-standard"
      value={props.defaultValue ? props.defaultValue : ""}
      onChange={props.onChange}
      sx={{ width: "100%" }}
      MenuProps={{
        PaperProps: { sx: { maxHeight: 300 } },
        // disableScrollLock: true,
      }}
      renderValue={(selected) => {
        if (!selected) {
          return <>{props.placeholder}</>;
        }
        return selected;
      }}
      displayEmpty
    >
      {Items &&
        Items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={item.query}
              className={classes.listMenu}
            >
              {item.query}
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default SelectQuery;
