import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { message } from "antd";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import Styled from "./PageModals";

import { updateDepositPC } from "../../../../../store/service/proposalAndContractService";

const DepositModal = ({ show, handleClose }) => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const dispatch = useDispatch();

  const [depositAmount, setDepositAmount] = useState(
    selectedData.billing_scheduled &&
      selectedData.billing_scheduled.deposit &&
      selectedData.billing_scheduled.deposit.require_deposit === true
      ? selectedData.billing_scheduled.deposit.value
      : 0
  );

  const currencySymbol =
    selectedData && selectedData.currency && selectedData.currency.symbol;

  const depositParagraph = `Upon the acceptance of this Agreement, a deposit of ${currencySymbol}${depositAmount} is required before work will commence.`;

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      paragraph: depositParagraph,
      depositAmount,
    },
  });

  const handleDeposit = (data) => {
    const obj = {
      _id: selectedData._id,
      deposit: {
        required: true,
      },
      depositAmount: data.depositAmount,
    };

    dispatch(updateDepositPC(obj)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        message.success("Deposit amount updated successfully!");
        handleClose();
      } else {
        message.error("Failed to update deposit amount!");
      }
    });
  };

  const amount = watch("depositAmount");

  useEffect(() => {
    setDepositAmount(amount);
    setValue("paragraph", depositParagraph);
  }, [amount, depositAmount]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        style={{ height: "100%" }}
        className="modal-content"
        onSubmit={handleSubmit(handleDeposit)}
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              id="headline"
              name="heading"
              defaultValue="Deposit"
              style={{ opacity: 0.6 }}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={6}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              style={{ opacity: 0.6 }}
              readOnly
            />
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-2">
            Deposit Amount
          </TextStyles.FontSize14px>

          <Styled.inputBox style={{ maxWidth: "10rem", fontSize: 15 }}>
            <span>{currencySymbol}</span>
            <input
              className="w-100"
              type="number"
              placeholder="00.00"
              {...register("depositAmount")}
            />
          </Styled.inputBox>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button>Update</TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DepositModal;
