import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import configureCurrencyImg from "../../../../../Assets/frontendImages/faq-configure-your-currency.webp";
import Screenshot from "../../../../UIs/Screenshot";

function ConfigureYourPreferredCurrency() {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          Configure Your Preferred Currency
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Zodot allows its users to set their preferred currency for invoices,
          transactions and financial reports.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-1">
          Follow the below steps to set up your currency:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Click on Settings in the sidebar.</li>
          <li className="mb-1">Navigate to the Business tab.</li>
          <li className="mb-1">Go to the Set Up Your Currency Tab.</li>
          <li className="mb-1">
            Click on the dropdown and set your desired currency.
          </li>

          <Screenshot src={configureCurrencyImg} alt="configure currency img" />

          <li className="mb-1">
            After selecting your preferred currency, the currency will be
            automatically saved and applied to your transactions and invoices.
          </li>
        </ul>

        <TextStyles.FontSize16px className="fw-semibold mb-1">
          Important Notes -
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Make sure to select the correct currency before creating invoices to
            ensure accuracy.
          </li>
          <li className="mb-1">
            Currency changes will affect new invoices and transactions but will
            not modify the old records.
          </li>
          <li className="mb-1">
            If your desired currency is not listed, please contact Zodot support
            for assistance.
          </li>
        </ul>

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, Your preferred currency has been successfully
          updated!
        </TextStyles.FontSize16px>
      </div>

      <div></div>
    </>
  );
}

export default ConfigureYourPreferredCurrency;
