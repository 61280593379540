import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageModals";
import { updateLatePaymentPC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import { inputValue } from "../../../Commons/utils";

const LatePaymentModal = ({ show, handleClose, heading, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const [amount, setAmount] = useState(
    selectedData &&
      selectedData.late_payment_fee &&
      selectedData.late_payment_fee.late_payment === "Amount"
      ? selectedData.late_payment_fee.late_payment_amount
      : "50.00"
  );

  const [percentage, setPercentage] = useState(
    selectedData &&
      selectedData.late_payment_fee &&
      selectedData.late_payment_fee.late_payment === "Percentage"
      ? selectedData.late_payment_fee.late_payment_percentage
      : "1.50"
  );

  const [paymentMethod, setPaymentMethod] = useState(
    selectedData &&
      selectedData.late_payment_fee &&
      selectedData.late_payment_fee.late_payment
      ? selectedData.late_payment_fee.late_payment
      : "Percentage"
  );

  const currencySymbol =
    selectedData && selectedData.currency ? selectedData.currency.symbol : "";

  const paragraph = `In case of delayed payment of an invoice, the Contractor reserves the right to charge a late payment fee of ${
    paymentMethod === "Amount"
      ? `${currencySymbol}${inputValue(amount)} as late payment fee`
      : `${inputValue(percentage)}% per month`
  }. Acceptance of these service charges by Contractor does not waive any rights or remedies in the event of Client's breach of this Agreement. All payment obligations are non-cancellable, and fees paid are non-refundable.`;

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
      late_payment: paymentMethod,
      late_payment_amount: amount,
      late_payment_percentage: percentage,
    },
  });

  const latePayment = watch("late_payment");
  const late_payment_percentage = watch("late_payment_percentage");
  const late_payment_amount = watch("late_payment_amount");

  useEffect(() => {
    setPaymentMethod(latePayment);
    setPercentage(late_payment_percentage);
    setAmount(late_payment_amount);
  }, [latePayment, late_payment_amount, late_payment_percentage]);

  useEffect(() => {
    setValue("paragraph", paragraph);
  }, [amount, percentage, paymentMethod]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      late_payment_fee: {
        required: true,
        late_payment_deposit_require: true,
        late_payment: item.late_payment,

        late_payment_amount: inputValue(item.late_payment_amount),
        late_payment_percentage: inputValue(item.late_payment_percentage),
      },
    };

    dispatch(updateLatePaymentPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              style={{ opacity: 0.5 }}
              {...register("heading")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={6}
              style={{ opacity: 0.5 }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              readOnly
            />
          </TextStyles.FontSize14px>

          <TextStyles.GreyFont14px className="d-flex gap-3 justify-content-between align-items-center">
            <div className="d-flex" style={{ minWidth: 100 }}>
              <span className="three-dots">Late payment fee?</span>
            </div>
          </TextStyles.GreyFont14px>

          <div className="mt-3 d-flex align-items-center gap-4">
            <TextStyles.RadioButton>
              <input
                className="form-check-input me-2"
                type="radio"
                id="late-payment-amount"
                value="Amount"
                checked={latePayment === "Amount"}
                {...register("late_payment")}
              />

              <label htmlFor="late-payment-amount">
                <TextStyles.FontSize14px>Amount</TextStyles.FontSize14px>
              </label>
            </TextStyles.RadioButton>

            <Styled.inputBox style={{ maxWidth: 95, fontSize: 15 }}>
              <span>{currencySymbol}</span>
              <input
                className="w-100 text-end"
                type="text"
                id="late_payment_amount"
                placeholder="00.00"
                {...register("late_payment_amount", {
                  pattern: {
                    value: /^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                    message: "Please enter a valid number.",
                  },
                })}
              />
            </Styled.inputBox>
          </div>

          {errors.late_payment_amount && (
            <TextStyles.InValidFeedback className="mt-2">
              {errors.late_payment_amount.message}
            </TextStyles.InValidFeedback>
          )}

          <div className="mt-3 d-flex align-items-center gap-4">
            <TextStyles.RadioButton>
              <input
                className="form-check-input me-2"
                type="radio"
                value="Percentage"
                checked={latePayment === "Percentage"}
                id="late-payment-percentage"
                {...register("late_payment")}
              />

              <label htmlFor="late-payment-percentage">
                <TextStyles.FontSize14px>Percentage</TextStyles.FontSize14px>
              </label>
            </TextStyles.RadioButton>

            <Styled.inputBox style={{ maxWidth: 86, fontSize: 15 }}>
              <input
                className="w-100 text-end"
                type="text"
                id="late_payment_percentage"
                placeholder="00.00"
                {...register("late_payment_percentage", {
                  pattern: {
                    value: /^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                    message: "Please enter a valid number.",
                  },
                })}
              />
              <span>%</span>
            </Styled.inputBox>
          </div>

          {errors.late_payment_percentage && (
            <TextStyles.InValidFeedback className="mt-2">
              {errors.late_payment_percentage.message}
            </TextStyles.InValidFeedback>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default LatePaymentModal;
