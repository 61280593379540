import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import LoaderSpin from "../../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../../Constants/TextStyles";

import { createAddSectionTextPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

const AddSectionTextModal = ({ show, handleClose, selectedData, position }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  const formsubmit = (data) => {
    const Obj = {
      pc_id: selectedData._id,
      text_title: data.text_title,
      textarea: data.textarea,
      position: position,
    };

    dispatch(createAddSectionTextPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header
          className="d-flex justify-content-between align-items-center"
        >
          <TextStyles.FontSize20px>Add Text</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="modal-body">
          <TextStyles.FontSize14px className="mb-2">
            <label htmlFor="text_title" className="mb-2">
              Title
            </label>
            <TextStyles.InputRectangle
              id="text_title"
              invalid={errors.text_title}
              type="text"
              placeholder="Write title"
              name="text_title"
              {...register("text_title", {
                required: "Title is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Title is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.text_title && (
              <TextStyles.InValidFeedback>
                {errors.text_title.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-2">
            <label htmlFor="textarea" className="mb-2">
              Description
            </label>
            <TextStyles.Textarea
              invalid={errors.textarea}
              rows="8"
              id="textarea"
              placeholder="Write description"
              name="textarea"
              {...register("textarea", {
                required: "This field is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "This field is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.textarea && (
              <TextStyles.InValidFeedback>
                {errors.textarea.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              <div className="d-flex justify-content-center">Cancel</div>
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddSectionTextModal;
