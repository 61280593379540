import {
  deleteRequest,
  postRequest,
  putRequest,
  getRequest,
} from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.FETCH_CLIENT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchClientsForOption = createAsyncThunk(
  "clients/fetchClientsForOption",
  async () => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_CLIENT.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchClientForAProject = createAsyncThunk(
  "clients/fetchClientForAProject",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_CLIENT_FOR_A_PROJECT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_CLIENT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const getClientById = createAsyncThunk(
  "clients/fetch-client",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_CLIENT_BY_ID.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const archiveClient = createAsyncThunk(
  "clients/archiveClient",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.ARCHIVE_CLIENT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const unarchiveClient = createAsyncThunk(
  "clients/unarchiveClient",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UNARCHIVE_CLIENT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteClient = createAsyncThunk("clients/deleteClient", (data) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    deleteRequest(URL.DELETE_CLIENT.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const editClient = createAsyncThunk(
  "clients/editClient",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.EDIT_CLIENT_ADDRESS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createContact = createAsyncThunk(
  "clients/createContact",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_CONTACT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const editContact = createAsyncThunk(
  "clients/editContact",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_CONTACT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteContact = createAsyncThunk(
  "clients/deleteContact",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_CONTACT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const insertNotes = createAsyncThunk("notes_update", async (data) => {
  let token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    postRequest("clients/notes_update", token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const fetchClientCounts = createAsyncThunk(
  "clients/fetchClientCount",
  async () => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest("clients/client_counts", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const checkClientExistedByEmail = createAsyncThunk(
  "clients/check_client",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CHECK_CLIENT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
