import React from "react";
import Footer from "../Footer/Footer";
import LandingPage from "./LandingPage/LandingPage";
import ClientReviews from "./CustomerReviews/ClientReviews";
import Workload from "../HomePage/Workload/Workload";
import { Helmet } from "react-helmet";

const Reviews = () => {
  const isProduction =
    process.env.REACT_APP_PAGE_INDEXING_MODE === "production";
  return (
    <div>
      <Helmet>
        <title> Zodot User Reviews & Customer Success Stories</title>
        <meta
          content="Discover how Zodot empowers self employed people, startups, and teams of all sizes to reach their goals with seamless time tracking and invoicing solutions."
          name="description"
        />
        {isProduction ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://zodot.co/reviews" />
      </Helmet>
      <LandingPage />
      <ClientReviews />
      <Workload />
      <Footer />
    </div>
  );
};

export default React.memo(Reviews);
