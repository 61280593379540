import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const ContractorTaxId = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.contractor_tax_id &&
    selectedData.contractor_tax_id.heading
      ? selectedData &&
        selectedData.contractor_tax_id &&
        selectedData.contractor_tax_id.heading
      : "Contractor's Taxpayer I.D. Number";
  const paragraph =
    selectedData &&
    selectedData.contractor_tax_id &&
    selectedData.contractor_tax_id.paragraph
      ? selectedData &&
        selectedData.contractor_tax_id &&
        selectedData.contractor_tax_id.paragraph
      : "The taxpayer I.D. number of the Contractor is [_________] (SSN/BIN if you are a Canadian citizen). The Contractor is licensed to perform the agreed-upon services enumerated herein and covenants that it maintains all valid licenses, permits, and registrations to perform the same as required by law.";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.contractor_tax_id &&
          selectedData.contractor_tax_id.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox>
        <TextStyles.FontSize20px className="mb-3 fw-medium overflow-hidden">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg overflow-hidden">{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default ContractorTaxId;
