import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import { updateRetainRightsPC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import SwitchButton from "../../../Commons/SwitchButton";
import { fieldOptions } from "../../../Commons/utils";

const AuthorshipCreditRightModal = ({
  show,
  handleClose,
  heading,
  paragraph,
  defaultHeading,
  retainRightsParagraph,
  noRetainRightsParagraph,
}) => {
  const dispatch = useDispatch();
  const { isLoading, selectedData } = useSelector(
    (state) => state.proposalAndContract
  );

  const [selector, setSelector] = useState(
    selectedData &&
      selectedData.rightToAuthorship &&
      selectedData.rightToAuthorship.retain_rights === true
      ? true
      : false
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph || noRetainRightsParagraph,
    },
  });

  const toggleSelectorHandler = () => {
    setSelector((prev) => !prev);

    setValue("heading", defaultHeading);
    setValue(
      "paragraph",
      !selector ? retainRightsParagraph : noRetainRightsParagraph
    );

    clearErrors();
  };

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      rightToAuthorship: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
        retain_rights: selector === true ? true : false,
      },
    };

    dispatch(updateRetainRightsPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder="Add text here"
              id="headline"
              name="heading"
              // style={{ opacity: 0.5 }}
              {...register("heading", fieldOptions())}
              // readOnly
            />

            {errors.heading && (
              <TextStyles.InValidFeedback>
                {errors.heading.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={8}
              // style={{ opacity: 0.5 }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph", fieldOptions())}
              // readOnly
            />

            {errors.paragraph && (
              <TextStyles.InValidFeedback>
                {errors.paragraph.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="d-flex gap-3 justify-content-between align-items-center">
            <div className="d-flex">
              Do you wish to retain the right to include work attribution in
              your portfolio?
            </div>
            <div className="d-flex justify-content-end">
              <SwitchButton
                defaultChecked={selector}
                onChange={toggleSelectorHandler}
              />
            </div>
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AuthorshipCreditRightModal;
