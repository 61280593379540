import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateEntireContractPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

import EntireContractModal from "../../PageModals/EntireContractModal";

const EntireContract = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Entire Contract";
  const defaultParagraph =
    "This Agreement, together with Attachment B and any exhibits, schedules, or attachments, prevails over any prior oral or written arrangements and may only be modified by a future written agreement signed by both Parties.";

  const heading =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.heading
      ? selectedData.entire_contract.heading
      : defaultHeading;

  const paragraph =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.paragraph
      ? selectedData.entire_contract.paragraph
      : defaultParagraph;

  const isEntireContractRequired =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.required
      ? true
      : false;

  const toggleEntireContractViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      entire_contract: {
        required: !isEntireContractRequired,
        heading,
        paragraph,
      },
    };
    dispatch(updateEntireContractPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isEntireContractRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleEntireContractViewHandler}>
            {isEntireContractRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isEntireContractRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span
            className={`fw-medium ${
              isEntireContractRequired ? "" : "disabled"
            }`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isEntireContractRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <EntireContractModal
          heading={heading}
          paragraph={paragraph}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default EntireContract;
