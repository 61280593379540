import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import BreadCrumbs from "../BreadCrumbs";

import Colors from "../../../Constants/Colors";

const QuestionBox = styled.div`
  border: 1px solid ${Colors.borderInput};
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 3rem;
`;

const StyledLink = styled(Link)`
  color: ${Colors.font1};
  text-decoration: none;
  border-radius: 6px;
  padding: 0.7rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${Colors.backgroundPink};
    color: ${Colors.primary};
  }
`;

function Article({
  data: { imagePath, mainHeading, description, questionHeading, questions },
}) {
  return (
    <section className="container py-3">
      <BreadCrumbs />

      <img
        className="my-4"
        style={{ maxHeight: "2.5rem" }}
        src={imagePath}
        alt={`Zodot's ${mainHeading}  icon`}
      />

      <h4>{mainHeading}</h4>
      <p className="mb-4">{description}</p>

      <QuestionBox>
        <h5
          className="m-0 p-3 mb-2"
          style={{ borderBottom: `1px solid ${Colors.borderInput}` }}
        >
          {questionHeading}
        </h5>

        <ul className="list-unstyled m-0">
          {questions.length &&
            questions.map(({ question, link }) => (
              <li key={question}>
                <StyledLink to={link}>
                  <span>{question}</span>
                  <ArrowForwardIosIcon fontSize="inherit" />
                </StyledLink>
              </li>
            ))}
        </ul>
      </QuestionBox>
    </section>
  );
}

export default Article;
