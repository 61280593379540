import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import timerListImg from "../../../../../Assets/frontendImages/faq-timer-list.webp";
import trackTimeImg from "../../../../../Assets/frontendImages/faq-track-time.webp";
import Screenshot from "../../../../UIs/Screenshot";
import SideContentList from "../SideContentList";

function HowToTrackActivity({ idList }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          How to Track your Activity
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          With Zodot's Time Tracking feature, you can effortlessly track,
          manage, and optimize your work hours—ensuring accuracy and efficiency
          in every project.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          Here is a step by step guide on how to track your activity on Zodot -
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px id={idList[0]} className="fw-semibold mb-1">
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Log in to your Zodot account.</li>
          <li className="mb-1">
            From the left sidebar menu, click on the Time Tracking tab.
          </li>
        </ul>

        <TextStyles.FontSize16px id={idList[1]} className="fw-semibold mb-1">
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            To start the timer, click on the Play button in the Time Tracking
            section.
          </li>
          <li className="mb-1">
            Alternatively, you can start the timer from the top navigation bar
            by clicking on the Play button.
          </li>
        </ul>

        <Screenshot src={trackTimeImg} alt="track time img" />

        <TextStyles.FontSize16px id={idList[2]} className="fw-semibold mb-1">
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Enter the activity name and click on add to save it.
          </li>
          <li className="mb-1">
            Once you’ve saved the activity, you can also select it from the
            dropdown.
          </li>
          <li className="mb-1">
            After that, click Assign to project and choose the relevant project
            for the activity.
          </li>
        </ul>

        <Screenshot src={timerListImg} alt="track time img" />

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, you have successfully started tracking your activity
          in Zodot!
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} />
    </>
  );
}

export default HowToTrackActivity;
