import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateGoverningLawPC } from "../../../../../../store/service/proposalAndContractService";
import GoverningLawModal from "../../PageModals/GoverningLawModal";
import Colors from "../../../../../../Constants/Colors";

const GoverningLaw = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Governing Law and Dispute Resolution";
  const defaultParagraph = `This Agreement and any disputes arising hereunder shall be governed by the laws of Contractor's primary business location (the "Contractor's Jurisdiction"), without considering conflicts of law provisions. The Parties hereby consent to exclusive jurisdiction and venue in the courts situated in Contractor's jurisdiction.\n\nThe failure of either party to enforce its rights under this Agreement at any time shall not be deemed as a waiver of such rights.`;

  const heading =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.heading
      ? selectedData &&
        selectedData.governingLaw &&
        selectedData.governingLaw.heading
      : defaultHeading;

  const paragraph =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.paragraph
      ? selectedData.governingLaw.paragraph
      : defaultParagraph;

  const isGoverningLawRequired =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.required
      ? true
      : false;

  const toggleGoverningLawViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      governingLaw: {
        required: !isGoverningLawRequired,
        heading,
        paragraph,
      },
    };
    dispatch(updateGoverningLawPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isGoverningLawRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleGoverningLawViewHandler}>
            {isGoverningLawRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isGoverningLawRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span
            className={`fw-medium ${isGoverningLawRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isGoverningLawRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <GoverningLawModal
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
          paragraph={paragraph}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
        />
      )}
    </div>
  );
};

export default GoverningLaw;
