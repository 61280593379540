import React from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";

import Colors from "../../Constants/Colors";

const BreadCrumbLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  color: ${Colors.font1};

  pointer-events: ${(props) =>
    props.index === props.length ? "none" : "auto"};

  &:hover {
    color: ${(props) =>
      props.index === props.length ? Colors.font1 : Colors.primary};
  }
`;

function BreadCrumbs() {
  const { pathname } = useLocation();
  const breadCrumbs = pathname.slice(1).split("/");

  return (
    <ul className="list-unstyled d-flex flex-wrap align-items-center m-0">
      {breadCrumbs.map((breadCrumb, i) => {
        const length = breadCrumbs.length - 1;

        return (
          <li key={breadCrumb}>
            <BreadCrumbLink
              to={
                i === 0
                  ? `/${breadCrumbs[i]}`
                  : i === 1
                  ? `/${breadCrumbs[0]}/${breadCrumbs[i]}`
                  : i === 2
                  ? `/${breadCrumbs[0]}/${breadCrumbs[1]}/${breadCrumbs[i]}`
                  : ""
              }
              className="text-capitalize"
              index={i}
              length={length}
            >
              {i === 0 && "Zodot "}
              {breadCrumb.replaceAll("-", " ")}
            </BreadCrumbLink>
            &nbsp;&nbsp;
            {i !== length && <span className="fs-6">»</span>}
            &nbsp;&nbsp;
          </li>
        );
      })}
    </ul>
  );
}

export default BreadCrumbs;
