import moment from "moment";
import { useState, useEffect } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return size;
};

export const diffDate = (end_date, start_date) => {
  let endDate = moment(end_date).format("DD MMM YYYY");
  let startDate = moment(start_date).format("DD MMM YYYY");
  let diff = moment(endDate).add(1, "d").diff(moment(startDate), "d");
  let days;
  if (diff === 1) {
    days = "1 day";
  } else if (diff === -1) {
    days = "- 1 day";
  } else if (diff === 0) {
    days = "0 day";
  } else {
    days = `${diff} days`;
  }
  return days;
};

export const getFileFromUrl = async (url, name) => {
  try {
    let response = await fetch(url);
    let data = await response.blob();
    const file = new File([data], name, {
      type: data.type,
    });
    return file;
  } catch (error) {
    console.log(error);
  }
};

export const convertSecondsToHHMMSS = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return {
    hours,
    minutes,
    seconds,
    formattedTime: `${formattedHours}:${formattedMinutes}:${formattedSeconds}`,
  };
};

export const convertSecondsToHHMM = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return {
    hours,
    minutes,
    formattedTime: `${hours >= 1 ? formattedHours : "0"} ${
      hours > 1 ? "hrs" : hours === 1 ? "hr" : "hr"
    } ${minutes > 0 ? formattedMinutes : ""} ${
      minutes > 1 ? "mins" : minutes === 1 ? "min" : ""
    }`,
  };
};

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
};

export const findPrimaryContact = (selectedData) => {
  const primaryContactData =
    selectedData &&
    selectedData.client_email &&
    selectedData.client_id &&
    selectedData.client_id.contacts
      ? selectedData.client_id.contacts.filter(
          (d) => d.email === selectedData.client_email
        )
      : selectedData.client_id.contacts;

  let primaryContact;
  if (primaryContactData.length === 1) {
    primaryContact = primaryContactData && primaryContactData[0];
    return primaryContact;
  } else if (primaryContactData.length > 1) {
    primaryContact =
      selectedData &&
      selectedData.client_id &&
      selectedData.client_id.contacts &&
      selectedData.client_id.contacts[0];
    return primaryContact;
  } else {
    primaryContact = null;
    return primaryContact;
  }
};

export const fieldOptions = (
  requiredMessage = "This field is required.",
  maxLength,
  maxLengthError = `Maximum ${maxLength} ${
    maxLength > 1 ? "characters" : "character"
  } required.`,
  minLength = 1,
  minLengthError = requiredMessage
) => {
  return {
    required: requiredMessage,
    validate: (value) => {
      if (typeof value !== "string") return "Only 'numbers' not allowed!";
      const length = value.trim().length;

      if (length < minLength) return minLengthError;
      if (length > maxLength) return maxLengthError;

      return true;
    },
  };
};

export function inputValue(input) {
  if (!input || !input.trim().length) return "00.00";
  if (!isFinite(input.trim())) return "00.00";
  return Number(input).toFixed(2);
}

export const validateEmail = (value) => {
  const trimmedValue = value.trim();
  const emailPattern = /^[A-Za-z0-9+._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2}/i;
  return (
    emailPattern.test(trimmedValue) || "Please enter a valid email address."
  );
};

export const filteredDateRangeFunction = (filterdDateRange) => {
  let start_time = null;
  let end_time = null;

  if (filterdDateRange && typeof filterdDateRange === "object") {
    start_time = moment(filterdDateRange[1]).endOf("days").toDate();
    end_time = moment(filterdDateRange[0]).startOf("days").toDate();
  } else {
    if (filterdDateRange === "Last 7 days") {
      start_time = moment().endOf("days").toDate();
      end_time = moment(start_time).subtract(6, "d").startOf("days").toDate();
    } else if (filterdDateRange === "Last 15 days") {
      start_time = moment().endOf("days").toDate();
      end_time = moment(start_time).subtract(14, "d").startOf("days").toDate();
    } else if (filterdDateRange === "Last 30 days") {
      start_time = moment().endOf("days").toDate();
      end_time = moment(start_time).subtract(29, "d").startOf("days").toDate();
    } else if (filterdDateRange === "Last 45 days") {
      start_time = moment().endOf("days").toDate();
      end_time = moment(start_time).subtract(44, "d").startOf("days").toDate();
    } else if (filterdDateRange === "Last 60 days") {
      start_time = moment().endOf("days").toDate();
      end_time = moment(start_time).subtract(59, "d").startOf("days").toDate();
    } else if (filterdDateRange === "Last 90 days") {
      start_time = moment().endOf("days").toDate();
      end_time = moment(start_time).subtract(89, "d").startOf("days").toDate();
    } else if (filterdDateRange === "This month") {
      start_time = moment().endOf("month").toDate();
      end_time = moment().startOf("month").toDate();
    } else if (filterdDateRange === "Last month") {
      start_time = moment().subtract(1, "months").endOf("month").toDate();
      end_time = moment().subtract(1, "months").startOf("month").toDate();
    } else if (filterdDateRange === "This year") {
      start_time = moment().endOf("year").toDate();
      end_time = moment().startOf("year").toDate();
    } else if (filterdDateRange === "Last year") {
      start_time = moment().subtract(1, "years").endOf("year").toDate();
      end_time = moment().subtract(1, "years").startOf("year").toDate();
    }
  }

  return { start_time, end_time };
};
