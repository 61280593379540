import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import Styled from "../CreateInvoice/InvoiceSetup/InvoiceSetupStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";

const EditInvoiceService = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const { register, reset, control, handleSubmit, watch } = useForm({
    defaultValues: {
      serviceLists:
        selectedData &&
        selectedData.invoice_services &&
        selectedData.invoice_services,
    },
  });
  const { fields } = useFieldArray({
    name: "serviceLists",
    control,
  });

  const formsubmit = (data) => {
    const Obj = {
      invoice_services: data.serviceLists.map((d) => {
        return {
          service_name: d.service_name,
          service_rate: d.service_rate,
          service_rate_type: d.service_rate_type,
          service_rate_count: d.service_rate_count
            ? parseFloat(d.service_rate_count)
            : 1,
          desc: d.desc,
          checked: d.checked,
        };
      }),
    };

    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    reset();
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight500px"
      fullscreen
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <TextStyles.FontSize20px className="font-weight-500">
            {selectedData &&
              selectedData.project_id &&
              selectedData.project_id.project_name}
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          {fields.map((item, index) => {
            const fieldName = `serviceLists[${index}]`;
            const rateCount = watch(`${fieldName}.service_rate_count`);
            let checked = watch(`${fieldName}.checked`);
            let total = item.service_rate * rateCount;
            return (
              <div key={index} className="mt-2 font-weight-500">
                <div className="d-flex justify-content-between gap-2 align-items-center three-dots w-100">
                  <TextStyles.CheckBox
                    className="form-check d-flex align-items-center three-dots"
                    style={{ minWidth: 100 }}
                    size={"1.2rem"}
                  >
                    <input
                      style={{ cursor: "pointer" }}
                      className="form-check-input"
                      type="checkbox"
                      id="checked"
                      name="checked"
                      {...register(`${fieldName}.checked`)}
                    />
                    <TextStyles.FontSize16px
                      className="form-check-label three-dots"
                      htmlFor="flexCheckChecked"
                      style={{
                        color: checked === false ? Colors.font2 : Colors.font1,
                      }}
                    >
                      {item.service_name}
                    </TextStyles.FontSize16px>
                  </TextStyles.CheckBox>
                  <TextStyles.GreyFont16px
                    className="d-flex"
                    style={{ minWidth: 100 }}
                  >
                    <span className="three-dots">
                      {selectedData &&
                        selectedData.currency &&
                        selectedData.currency.symbol}
                      {item.service_rate
                        ? parseFloat(item.service_rate).toFixed(2)
                        : "00.00"}{" "}
                      {item.service_rate_type === "Flat fee" && ""}
                      {item.service_rate_type === "Per hour" && "/hour"}
                      {item.service_rate_type === "Per day" && "/day"}
                      {item.service_rate_type === "Per item" && "/item"}
                      {item.service_rate_type === "Per word" && "/word"}
                    </span>
                  </TextStyles.GreyFont16px>
                </div>

                {checked === true && (
                  <>
                    <div className="mb-2 mt-3">
                      <TextStyles.GreyFont14px>
                        Quantity
                      </TextStyles.GreyFont14px>
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                      <Styled.inputBox style={{ maxWidth: 90 }}>
                        <input
                          readOnly={item.service_rate_type === "Flat fee"}
                          style={{ width: 40 }}
                          type="number"
                          id="service_rate"
                          min={"0"}
                          step="0.01"
                          name="service_rate_count"
                          placeholder="00.00"
                          {...register(`${fieldName}.service_rate_count`)}
                        />
                      </Styled.inputBox>

                      <TextStyles.FontSize14px
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {item.service_rate_type === "Flat fee" && (
                          <TextStyles.FontSize14px>
                            flat fee
                          </TextStyles.FontSize14px>
                        )}
                        {item.service_rate_type === "Per hour" && (
                          <TextStyles.FontSize14px>
                            hour/s
                          </TextStyles.FontSize14px>
                        )}
                        {item.service_rate_type === "Per day" && (
                          <TextStyles.FontSize14px>
                            day/s
                          </TextStyles.FontSize14px>
                        )}
                        {item.service_rate_type === "Per item" && (
                          <TextStyles.FontSize14px>
                            item/s
                          </TextStyles.FontSize14px>
                        )}
                        {item.service_rate_type === "Per word" && (
                          <TextStyles.FontSize14px>
                            word/s
                          </TextStyles.FontSize14px>
                        )}
                      </TextStyles.FontSize14px>
                    </div>
                    <TextStyles.FontSize18px className="d-flex justify-content-between gap-2 align-items-center three-dots w-100 mt-3">
                      <div className="three-dots" style={{ minWidth: 100 }}>
                        Total Service Charges
                      </div>
                      <div
                        className="three-dots"
                        style={{ color: Colors.primary, minWidth: 50 }}
                      >
                        {selectedData &&
                          selectedData.currency &&
                          selectedData.currency.symbol}
                        {total > 0 ? parseFloat(total).toFixed(2) : "00.00"}
                      </div>
                    </TextStyles.FontSize18px>
                  </>
                )}
                <div
                  className="my-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #f6f6f6",
                  }}
                />
              </div>
            );
          })}
        </Modal.Body>

        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button>Save</TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditInvoiceService;
