import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import newInvoiceImg from "../../../../../Assets/frontendImages/faq-new-invoice.webp";
import quickInvoiceImg from "../../../../../Assets/frontendImages/faq-quick-invoice.webp";

import quickInvoiceServiceImg from "../../../../../Assets/frontendImages/faq-quick-invoice-service.webp";
import quickInvoiceReview from "../../../../../Assets/frontendImages/faq-quick-invoice-review.webp";
import emailPreviewImg from "../../../../../Assets/frontendImages/faq.invoice-email-preview.webp";
import invoiceSendImg from "../../../../../Assets/frontendImages/faq-invoice-new-project-send.webp";

import invoiceNewClientImg from "../../../../../Assets/frontendImages/faq-quick-invoice-new-client.webp";
import invoiceCreateClientImg from "../../../../../Assets/frontendImages/faq-quick-invoice-create-client.webp";
import SideContentList from "../SideContentList";
import Screenshot from "../../../../UIs/Screenshot";

function HowToCreateQuickInvoice({ idList, activeContentId }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          How to Create a Quick Invoice (Without Creating Project)
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Generate a quick invoice in Zodot without the need to create a
          project. Simply enter the necessary details, add line items, and send
          the invoice instantly for seamless billing.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          Follow these steps to generate a Quick invoice in Zodot:
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px
          id={idList[0]}
          className="fw-semibold mb-1 contentHeading"
        >
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Log in to your Zodot account.</li>
          <li className="mb-1">
            From the left sidebar menu, click on Invoices.
          </li>
          <li className="mb-1">
            Click the “+ New Invoice” button to create a new invoice.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-1">
          Alternatively, you can also create an invoice from the top navbar:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click on the “+ Create” button in the top-right corner.
          </li>
          <li className="mb-1">Select Invoice from the dropdown menu.</li>
        </ul>

        <Screenshot src={newInvoiceImg} alt="new invoice img" />

        <TextStyles.FontSize16px
          id={idList[1]}
          className="fw-semibold mb-1 contentHeading"
        >
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            After clicking "New Invoice," you can choose your preferred method
            for creating the invoice.
          </li>
          <li className="mb-1">
            To generate a quick invoice, navigate to the Quick Invoice" section.
          </li>
          <li className="mb-1">
            Then, Click the "Next" button at the bottom right corner to proceed
            to the Next step.
          </li>
        </ul>

        <Screenshot src={quickInvoiceImg} alt="quick invoice img" />

        <TextStyles.FontSize16px
          id={idList[2]}
          className="fw-semibold mb-1 contentHeading"
        >
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            After clicking on the Quick Invoice, the next step is to assign a
            client to the invoice.
          </li>
          <li className="mb-1">
            If the client is already in your system, click the "Existing
            Clients" option and select the client from the existing list.
          </li>
          <li className="mb-1">
            If the client is not listed, click on the "New Client" option.
          </li>
          <li className="mb-1">
            You will be prompted to enter the client's details, including name,
            email, and billing information.
          </li>
          <li className="mb-1">
            Once a client is selected or added, click "Next" to continue.
          </li>
          <li className="mb-1">
            The "Next" button will remain disabled until a client is chosen.
          </li>
        </ul>

        <Screenshot src={invoiceNewClientImg} alt="new invoice img" />

        <Screenshot src={invoiceCreateClientImg} alt="new invoice img" />

        <TextStyles.FontSize16px
          id={idList[3]}
          className="fw-semibold mb-1 contentHeading"
        >
          4. {idList[3]}
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-1">
          After selecting or creating a client, the next step is to define the
          services you are invoicing for.
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            <span className="fw-semibold">Enter Service Name</span> – Input the
            name of the service you are providing.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Set the Rate</span> – Define the
            pricing based on a flat fee, hourly rate, daily rate, per item, or
            per word count.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Add a Description</span> – Include any
            relevant details about the service for clarity.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Review Total Service Charges</span> –
            The total amount will automatically update based on the selected
            rates.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Add More Services (Optional)</span> –
            Click "Add another service" to include multiple services in a single
            invoice.
          </li>
          <li className="mb-1">
            <span className="fw-semibold">Proceed to the Next Step</span> – Once
            all details are entered, click "Next" to continue.
          </li>
        </ul>

        <Screenshot
          src={quickInvoiceServiceImg}
          alt="quick invoice service img"
        />

        <TextStyles.FontSize16px
          id={idList[4]}
          className="fw-semibold mb-1 contentHeading"
        >
          5. {idList[4]}
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-1">
          In this screen, you can review and edit the following details -
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Client Information</li>
          <li className="mb-1">Your Business Details</li>
          <li className="mb-1">Project Details </li>
          <li className="mb-1">Preferred Currency</li>
          <li className="mb-1">Invoice Issue and Due Date</li>
          <li className="mb-1">Your Branding</li>
          <li className="mb-1">Invoice Label</li>
        </ul>

        <TextStyles.FontSize16px className="mb-3">
          You can further customize the invoice by adding the following
          additional details as needed:
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            <span className="fw-medium">Taxes: </span>
            Apply applicable taxes to ensure accurate billing.
          </li>
          <li className="mb-1">
            <span className="fw-medium">Discounts: </span>
            Include any special discounts or promotional offers.
          </li>
          <li className="mb-1">
            <span className="fw-medium">Line Items: </span>
            Add extra services or products beyond the predefined project scope.
          </li>
          <li className="mb-1">
            <span className="fw-medium">Notes for the Client: </span>
            Provide important details, payment instructions, or personalized
            messages to enhance clarity and professionalism.
          </li>
          <li className="mb-1">
            These options will help you to tailor the invoice to meet specific
            project and client requirements.
          </li>
        </ul>

        <TextStyles.FontSize16px className="mb-3">
          Once all details are finalized, you can download the invoice for your
          records or click on the “Next” button to share it with the client.
        </TextStyles.FontSize16px>

        <Screenshot src={quickInvoiceReview} alt="new invoice img" />

        <TextStyles.FontSize16px
          id={idList[5]}
          className="fw-semibold mb-1 contentHeading"
        >
          6. {idList[5]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            In the compose email section, the user can select or deselect
            recipients from existing contacts or add a new one as needed.
          </li>
          <li className="mb-1">
            Use a pre-saved email template, create a new one, or modify the
            default template to suit your requirements.
          </li>
          <li className="mb-1">
            Click "Preview Email" to review the message before sending.
          </li>

          <Screenshot src={emailPreviewImg} alt="new invoice img" />

          <li className="mb-1">
            Once satisfied, click "Send" to deliver the invoice email to the
            client.
          </li>
        </ul>

        <Screenshot src={invoiceSendImg} alt="new invoice img" />

        <TextStyles.FontSize16px>
          <span role="img" aria-labelledby="check emoji">
            ✅
          </span>{" "}
          Congratulations, Your Quick invoice is generated now.
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} activeContentId={activeContentId} />
    </>
  );
}

export default HowToCreateQuickInvoice;
