import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { message } from "antd";

import LoaderSpin from "../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../Constants/TextStyles";

import { updateOwnershipRightPC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import { fieldOptions } from "../../../Commons/utils";

const OwnerShipModal = ({
  show,
  handleClose,
  heading,
  defaultHeading,
  paragraph,
  paragraphMadeForHire,
  paragraphLicensed,
  ownershipText,
  defaultOwnershipText,
}) => {
  const dispatch = useDispatch();
  const { isLoading, selectedData } = useSelector(
    (state) => state.proposalAndContract
  );

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      heading,
      paragraph,
      ownership:
        selectedData &&
        selectedData.ownership_right &&
        selectedData.ownership_right.ownership,
      ownership_text: ownershipText,
    },
  });

  const ownershipRight = watch("ownership");

  const changeTextHandler = (ownershipType) => {
    clearErrors();

    setValue("heading", defaultHeading);
    setValue(
      "paragraph",
      ownershipType === "Licensed" ? paragraphLicensed : paragraphMadeForHire
    );
    setValue("ownership_text", defaultOwnershipText);
  };

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData._id,
      ownership_right: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
        ownership: item.ownership,
        ownership_text:
          ownershipRight === "Licensed" ? item.ownership_text : null,
      },
    };
    dispatch(updateOwnershipRightPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder="Add text here"
              id="headline"
              name="heading"
              {...register("heading", fieldOptions())}
            />

            {errors.heading && (
              <TextStyles.InValidFeedback>
                {errors.heading.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={7}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph", fieldOptions())}
            />

            {errors.paragraph && (
              <TextStyles.InValidFeedback>
                {errors.paragraph.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize18px className="mb-2">
            Options
          </TextStyles.FontSize18px>

            <label htmlFor="ownership_right" className="mb-2">
              Ownership rights
            </label>
            <TextStyles.RadioButton className="form-check d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                id="ownership"
                value="Made for hire"
                checked={ownershipRight === "Made for hire" ? true : false}
                name="ownership"
                {...register("ownership")}
                onClick={() => changeTextHandler("Made for hire")}
              />
              <label className="form-check-label" htmlFor="ownership">
                <TextStyles.FontSize14px>
                  Made for hire (work I deliver is owned by client)
                </TextStyles.FontSize14px>
              </label>
            </TextStyles.RadioButton>
            <TextStyles.RadioButton className="form-check d-flex align-items-center mt-2">
              <input
                className="form-check-input"
                type="radio"
                id="ownership"
                value="Licensed"
                checked={ownershipRight === "Licensed" ? true : false}
                name="ownership"
                {...register("ownership")}
                onClick={() => changeTextHandler("Licensed")}
              />
              <label className="form-check-label" htmlFor="ownership">
                <TextStyles.FontSize14px>
                  Licensed (I license my work to the client)
                </TextStyles.FontSize14px>
              </label>
            </TextStyles.RadioButton>
            {ownershipRight === "Licensed" && (
              <TextStyles.FontSize14px style={{ paddingLeft: 30, maxWidth: 500 }}>
                <div className="my-2">
                  Which rights to use your work product would you like to grant
                  your client?
                </div>
                <TextStyles.Textarea
                  className="lh-lg"
                  rows={5}
                  placeholder="Add text here..."
                  name="ownership_text"
                  {...register("ownership_text", fieldOptions())}
                />

                {errors.ownership_text && (
                  <TextStyles.InValidFeedback>
                    {errors.ownership_text.message}
                  </TextStyles.InValidFeedback>
                )}
              </TextStyles.FontSize14px>
            )}
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default OwnerShipModal;
