import React from "react";
import moment from "moment";

import {
  Page,
  Text,
  View,
  Document,
  Image,
  Font,
  Link,
  StyleSheet,
} from "@react-pdf/renderer";

import Colors from "../../../../Constants/Colors";
import clientIcon from "../../../../Assets/assetsnew/clientIcon-new.png";
import servicesIcon from "../../../../Assets/assetsnew/servicesIcon.png";
import billingIcon from "../../../../Assets/assetsnew/billingIcon.png";
import signatureIcon from "../../../../Assets/assetsnew/signatureIcon.png";
import docIcon from "../../../../Assets/assetsnew/docIcon-new.png";

import sectionAddTextIcon from "../../../../Assets/assetsnew/section-add-text.png";
import sectionAddImageIcon from "../../../../Assets/assetsnew/section-add-image.png";
import sectionAddTableIcon from "../../../../Assets/assetsnew/section-add-table.png";
import sectionAddColumnIcon from "../../../../Assets/assetsnew/section-add-column.png";
import sectionAddFileIcon from "../../../../Assets/assetsnew/section-add-file.png";

import NatoSansRegular from "../../../../Assets/fonts/NotoSans-Regular.ttf";
import NatoSansMedium from "../../../../Assets/fonts/NotoSans-Medium.ttf";
import NatoSansSemiBold from "../../../../Assets/fonts/NotoSans-SemiBold.ttf";
import NatoSansBold from "../../../../Assets/fonts/NotoSans-Bold.ttf";
import PoppinsItalic from "../../../../Assets/fonts/Poppins-Italic.ttf";
import PoppinsRegular from "../../../../Assets/fonts/Poppins-Regular.ttf";
import PoppinsMedium from "../../../../Assets/fonts/Poppins-Medium.ttf";
import PoppinsSemiBold from "../../../../Assets/fonts/Poppins-SemiBold.ttf";
import PoppinsBold from "../../../../Assets/fonts/Poppins-Bold.ttf";
import licoriceRegular from "../../../../Assets/fonts/Licorice-Regular.ttf";
import { diffDate, findPrimaryContact } from "../../Commons/utils";
import pdfSupportedCurrency from "../../Commons/pdfSupportedCurrency";

Font.register({
  family: "licorice",
  fonts: [{ src: licoriceRegular, fontWeight: 400 }],
});

Font.register({
  family: "NatoSans",
  fonts: [
    { src: NatoSansRegular, fontWeight: 400 },
    { src: NatoSansMedium, fontWeight: 500 },
    { src: NatoSansSemiBold, fontWeight: 600 },
    { src: NatoSansBold, fontWeight: 700 },
  ],
});

Font.register({
  family: "Poppins",
  fonts: [
    { src: PoppinsRegular, fontWeight: 400 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsSemiBold, fontWeight: 600 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsItalic, fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  page: (brandColor) => {
    return {
      fontSize: 11,
      color: Colors.font1,
      borderTop: `4px solid ${brandColor}`,
      padding: 40,
      boxShadow: "0 2px 4px 2px rgba(0, 0, 0, 0.1)",
      fontFamily: "Poppins",
    };
  },

  container: { marginTop: 40 },

  horizontalLine: {
    width: "100%",
    height: 1,
    backgroundColor: "#bbb",
    marginTop: 15,
    marginBottom: 15,
  },

  input: {
    border: `1px solid #ddd`,
    borderRadius: 8,
    padding: 12,
    color: Colors.font2,
    fontSize: 11,
  },

  currencySymbol: {
    fontFamily: "NatoSans",
  },

  signature: {
    fontFamily: "licorice",
    fontSize: 28,
  },
});

const AgreementPdf = ({
  selectedData,
  data,
  businessInfoData,
  brandAndLogoData,
}) => {
  const primaryContact = findPrimaryContact(selectedData);

  const { code, symbol } = (selectedData && selectedData.currency) || {};
  const projectCurrency = pdfSupportedCurrency(code) ? symbol : code || "";

  const {
    street,
    suite,
    city,
    state,
    country,
    zip_code,
    tax_id_label,
    tax_id_number,
  } = (selectedData && selectedData.client_id) || {};

  const clientAddress = [street, suite, city, state, country, zip_code]
    .filter((addressItem) => addressItem)
    .join(", ");

  const {
    street: businessStreet,
    suite: businessSuite,
    city: businessCity,
    state: businessState,
    country: businessCountry,
    zip_code: businessZipcode,
    tax_id_label: businessTaxIdLabel,
    tax_id_number: businessTaxIdNumber,
  } = businessInfoData || {};

  const businessAddress = [
    businessStreet,
    businessSuite,
    businessCity,
    businessState,
    businessCountry,
    businessZipcode,
  ]
    .filter((addressItem) => addressItem)
    .join(", ");

  const invoiceBillingFrequency =
    (selectedData &&
      selectedData.billing_scheduled &&
      selectedData.billing_scheduled.invoice_frequency) ||
    "";

  const acceptanceHeading =
    selectedData && selectedData.acceptance && selectedData.acceptance.heading
      ? selectedData.acceptance.heading
      : "Acceptance";

  const acceptanceParagraph =
    selectedData && selectedData.acceptance && selectedData.acceptance.paragraph
      ? selectedData.acceptance.paragraph
      : "The Client's authorized representative possesses the authority to enter into this Agreement on behalf of the Client. The Client commits to cooperating and providing the Contractor with all necessary resources to complete the Services as requested, including the specified format and timeline. The Contractor affirms their expertise and capability to fulfill all agreed-upon tasks for the Client in a professional and timely manner. They will strive to meet all established deadlines and exceed the Client's expectations for the Services to the best of their abilities.";

  const responsibilityHeading =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.heading
      ? selectedData &&
        selectedData.responsibility &&
        selectedData.responsibility.heading
      : "Responsibility of Contractor";

  const responsibilityParagraph =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.paragraph
      ? selectedData &&
        selectedData.responsibility &&
        selectedData.responsibility.paragraph
      : "A. Contractor agrees to deliver the services in accordance with the Delivery Schedule provided for in Schedule A attached hereto. Contractor’s failure to meet the dates provided for in this Schedule A shall constitute a material breach of this Agreement.\n\nB. Contractor shall or shall cause one of its clients to attend Clients’ regularly scheduled meetings relating to the status of the Project. Moreover, Contractor shall provide Client with a written report each month on the status of the development of the services.";

  const compensationHeading =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.heading
      ? selectedData &&
        selectedData.compensation &&
        selectedData.compensation.heading
      : "Compensation";

  const compensationParagraph =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.paragraph
      ? selectedData.compensation.paragraph
      : `Client will pay the Contractor a compensation at the decided rate (Per hour, day, item or word), based on invoices submitted by Contractor to the Client on basis (Once, weekly, bi-weekly, monthly) within the due date of receipt of said invoices. Contractor acknowledges and agrees that Contractor must accurately and truthfully report to the Client the number of working hours per each pay period (the “Payroll Worksheet”).`;

  const warrantyHeading =
    selectedData && selectedData.warranty && selectedData.warranty.heading
      ? selectedData.warranty.heading
      : "Warranty";

  const warrantyParagraph =
    selectedData && selectedData.warranty && selectedData.warranty.paragraph
      ? selectedData.warranty.paragraph
      : `Contractor hereby warrants that:(i) The Services will be conducted in a professional and workmanlike manner, free from any inconsistency with any other contractual obligations held by the Contractor;(ii) The Services, Inventions, and their development, use, production, distribution, or exploitation will not infringe, misappropriate, or violate any intellectual property or rights of any entity, including the Contractor;(iii) The Contractor possesses full rights to provide the Client with the assignments and rights as stipulated in this Agreement;(iv) The Contractor will comply with all applicable laws while performing the Services and adhering to the Terms and Conditions;(v) If the Contractor's work requires a license, the Contractor has obtained the necessary license, which remains in full force and effect.\n\nEXCEPT AS SPECIFIED IN THIS ATTACHMENT B, THE CONTRACTOR DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SUITABILITY, COMPLETENESS, OR RESULTS DERIVED FROM THE WORK. EXCEPT AS EXPRESSLY STATED HEREIN, ALL DELIVERABLES ARE PROVIDED ON AN "AS-IS" BASIS.
  `;

  const confidentialHeading =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.heading
      ? selectedData.confidential.heading
      : "Confidentiality & Non-Disclosure";

  const confidentialParagraph =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.paragraph
      ? selectedData.confidential.paragraph
      : `Both parties shall maintain strict confidentiality of all Confidential Information (as defined in the subsequent sentence) shared by either party under this Agreement. "Confidential Information" includes non-public information marked as confidential by the disclosing party and any information that should reasonably be considered confidential in the context of disclosure, whether or not marked as "Confidential". If a party is legally required to disclose Confidential Information due to a judicial or governmental order, it shall promptly notify the other party to explore alternative legal remedies for maintaining confidentiality. Each party receiving Confidential Information is responsible for any breach caused by its employees, affiliates, representatives, or agents. The receiving party agrees to indemnify and hold the disclosing party harmless from any liabilities, claims, damages, losses, costs, and expenses resulting from such a breach. The obligations under this section shall remain in effect for three years following the termination of this Agreement.
`;

  const ownershipHeading =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.heading
      ? selectedData.ownership_right.heading
      : "Ownership and Licenses";

  const paragraphMadeForHire =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.paragraph &&
    selectedData.ownership_right.ownership === "Made for hire"
      ? selectedData.ownership_right.paragraph
      : `The Contractor agrees that all deliverables and materials created under this Agreement, including presentations, writings, ideas, concepts, designs, text, plans, and other materials, shall be treated as if they were a "work for hire" and shall be owned by the Client upon full payment of all fees due to the Contractor as per this Agreement. In the event that any material is not considered a "work made for hire," the Contractor hereby assigns all ownership, including any patent, copyright, trade secret, and other proprietary rights, title, and interest in such deliverables and materials to the Client. The Contractor agrees to execute any documents reasonably requested by the Client to facilitate the acquisition and protection of such rights. The Contractor acknowledges that they have no interest in any materials submitted to the Client, including any security interest therein. The Contractor hereby releases any interest they may have (if any) in such materials that could arise by operation of law. Unless otherwise agreed upon in writing and solely as necessary for the performance of this Agreement, the Contractor shall have no rights to license, sell, or use the deliverables or materials developed under this Agreement, or any part thereof.`;

  const paragraphLicensed =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.paragraph &&
    selectedData.ownership_right.ownership === "Licensed"
      ? selectedData.ownership_right.paragraph
      : `As per this Agreement, the Client acknowledges that the Contractor shall retain full ownership rights to all deliverables and materials produced, encompassing presentations, writings, ideas, concepts, designs, text, plans, and any other materials developed while providing services for the Client. Whether created individually or jointly, on or off the Client's premises, during or after working hours, all ownership rights shall remain vested with the Contractor. Upon the Client's complete payment of all fees owed to the Contractor in accordance with this Agreement, the Contractor hereby grants the Client a license to use the work product. This license allows the Client to utilize the deliverables in the specified manner(s) without any requirement for attribution.
`;

  const ownershipParagraph =
    selectedData &&
    selectedData.ownership_right &&
    selectedData.ownership_right.ownership === "Licensed"
      ? paragraphLicensed
      : paragraphMadeForHire;

  const nonSolicitHeading =
    selectedData && selectedData.non_solicit && selectedData.non_solicit.heading
      ? selectedData.non_solicit.heading
      : "Non-Solicit";

  const nonSolicitParagraph =
    selectedData &&
    selectedData.non_solicit &&
    selectedData.non_solicit.paragraph
      ? selectedData.non_solicit.paragraph
      : "Contractor agrees that throughout the period of providing Services and for one year thereafter, they will refrain from encouraging or soliciting any employee, vendor, client, or contractor of the Client to depart from the Client for any reason.";

  const nonCompleteHeading =
    selectedData &&
    selectedData.add_non_complete &&
    selectedData.add_non_complete.heading
      ? selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.heading
      : "Non-Compete";

  const nonCompleteParagraph =
    selectedData &&
    selectedData.add_non_complete &&
    selectedData.add_non_complete.paragraph
      ? selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.paragraph
      : "Contractor agrees that throughout the duration of providing Services and for [XX] months after the termination of Services or expiration of this Agreement, within a [XX-mile radius of Client's primary business location], Contractor shall refrain from engaging in any activities directly and substantially competitive with Client's current business, which is narrowly defined as [description of Client's primary current business].";

  const relationShipHeading =
    selectedData &&
    selectedData.relationship_of_parties &&
    selectedData.relationship_of_parties.heading
      ? selectedData.relationship_of_parties.heading
      : "Relationship of Parties";

  const relationShipParagraph =
    selectedData &&
    selectedData.relationship_of_parties &&
    selectedData.relationship_of_parties.paragraph
      ? selectedData.relationship_of_parties.paragraph
      : "This Agreement does not establish a partnership, joint venture, agency, or employment relationship between Contractor and Client. Both Parties acknowledge that Contractor is an independent contractor throughout the duration of this Agreement.";

  const termAndTerminationHeading =
    selectedData &&
    selectedData.term_and_termination &&
    selectedData.term_and_termination.heading
      ? selectedData.term_and_termination.heading
      : "Term & Termination";

  const termAndTerminationParagraph =
    selectedData &&
    selectedData.term_and_termination &&
    selectedData.term_and_termination.heading
      ? selectedData.term_and_termination.paragraph
      : "Additionally, either Party may terminate the Agreement immediately if the other Party fails to remedy a breach within 5 days of receiving written notice of such breach.  If Client terminates the Agreement without cause, Client agrees to compensate Contractor for services provided and expenses already incurred up to the termination date, including documented non- cancellable commitments. Contractor will make efforts to minimize these costs and expenses. Termination will not affect Client's rights granted under this Agreement. Client must pay all undisputed amounts owed to the Contractor upon termination. If Client hasn't paid undisputed fees for services or materials provided by Contractor as of the termination date, Client may not use such materials until payment is made in full. Any provisions in this Agreement that imply survival shall remain in effect after termination or expiration. Notwithstanding other terms, Contractor retains a perfected security interest in the deliverables or materials until Client pays all undisputed amounts in full. Contractor will release and waive the security interest upon receipt of full payment for all undisputed amounts.";

  const contractorTaxIdHeading =
    selectedData &&
    selectedData.contractor_tax_id &&
    selectedData.contractor_tax_id.heading
      ? selectedData.contractor_tax_id.heading
      : "Contractor's Taxpayer I.D. Number";

  const contractorTaxIdParagraph =
    selectedData &&
    selectedData.contractor_tax_id &&
    selectedData.contractor_tax_id.paragraph
      ? selectedData.contractor_tax_id.paragraph
      : "The taxpayer I.D. number of the Contractor is [_________] (SSN/BIN if you are a Canadian citizen). The Contractor is licensed to perform the agreed-upon services enumerated herein and covenants that it maintains all valid licenses, permits, and registrations to perform the same as required by law.";

  const healthInsuranceHeading =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.heading
      ? selectedData.health_insurance.heading
      : "Health Insurance and Business Liability Insurance";

  const healthInsuranceParagraph =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.paragraph
      ? selectedData.health_insurance.paragraph
      : "In its capacity of an independent contractor, Contractor agrees that the Client is not obligated to procure business liability insurance for the Contractor and/or medical insurance for Contractor and/or Contractor’s family and that Contractor may procure such medical and/or business liability insurance at Contractor’s expense without any claim for reimbursement from the Client.";

  const latePaymentHeading =
    selectedData &&
    selectedData.late_payment_fee &&
    selectedData.late_payment_fee.heading
      ? selectedData.late_payment_fee.heading
      : "Late Payment";

  const changesHeading =
    selectedData && selectedData.changes && selectedData.changes.heading
      ? selectedData.changes.heading
      : "Changes";

  const changesParagraph =
    selectedData && selectedData.changes && selectedData.changes.paragraph
      ? selectedData.changes.paragraph
      : "Any material changes to the Services, including work to be performed and related fees must be approved by the prior written consent of both parties.";

  const liabilityLimitationHeading =
    selectedData &&
    selectedData.liabilityLimitation &&
    selectedData.liabilityLimitation.heading
      ? selectedData.liabilityLimitation.heading
      : "Indemnification and Limitation of Liability";

  const liabilityLimitationParagraph =
    selectedData &&
    selectedData.liabilityLimitation &&
    selectedData.liabilityLimitation.paragraph
      ? selectedData.liabilityLimitation.paragraph
      : `Contractor shall indemnify, defend, and hold the Client harmless from any and all claims, actions, damages, and liabilities (excluding attorneys’ fees, costs, and expenses) arising from: (i) Contractor’s gross negligence, (ii) any claim that the materials or deliverables, or any portion thereof, indeed infringe upon or violate any proprietary rights of any third party, including but not limited to patent, copyright, and trade secret rights, or (iii) a breach or alleged breach of any of Contractor’s representations, warranties, or agreements herein.\n\nClient shall indemnify, defend, and hold the Contractor harmless from any and all claims, actions, damages, liabilities, costs, and expenses (including reasonable attorneys’ fees) arising in any manner caused by:(i) Client’s gross negligence,(ii) any claim that Client provided content, or any portion thereof, indeed infringes upon or violates any proprietary rights of any third party, including but not limited to patent, copyright, and trade secret rights, or(iii) a breach or alleged breach of any of Client’s representations, warranties, or agreements herein.\n\nTo the fullest extent permitted by applicable law, Contractor shall not be liable to the Client for any incidental, consequential, indirect, special, punitive, or exemplary damages (including damages for lost profit, loss of business, or similar) arising from or relating to this Attachment B or this Agreement, Contractor’s performance hereunder, or disruption of any of the foregoing, even if the Client has been advised of the possibility of such damages and regardless of the cause of action, whether sounding in contract, tort, breach of warranty, or otherwise. To the fullest extent permitted by applicable law, Contractor’s aggregate liability under this Attachment B and this Agreement shall in no event exceed the aggregate compensation paid by the Client to the Contractor under this Agreement.`;

  const rightToAuthorshipHeading =
    selectedData &&
    selectedData.rightToAuthorship &&
    selectedData.rightToAuthorship.heading
      ? selectedData.rightToAuthorship.heading
      : "Right to Authorship Credit";

  const governingLawHeading =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.heading
      ? selectedData.governingLaw.heading
      : "Governing Law and Dispute Resolution";

  const governingLawParagraph =
    selectedData &&
    selectedData.governingLaw &&
    selectedData.governingLaw.paragraph
      ? selectedData.governingLaw.paragraph
      : `This Agreement and any disputes arising hereunder shall be governed by the laws of Contractor's primary business location (the "Contractor's Jurisdiction"), without considering conflicts of law provisions. The Parties hereby consent to exclusive jurisdiction and venue in the courts situated in Contractor's jurisdiction.\n\nThe failure of either party to enforce its rights under this Agreement at any time shall not be deemed as a waiver of such rights.`;

  const forceMajeureHeading =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.heading
      ? selectedData.forceMejeure.heading
      : "Force Majeure";

  const forceMajeureParagraph =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.paragraph
      ? selectedData.forceMejeure.paragraph
      : `If a party's failure or omission in fulfilling any obligation under this Agreement is due to causes beyond their reasonable control, and such causes cannot be overcome through reasonable diligence, including but not limited to strikes, riots, war, acts of terrorism, acts of God, severe illness, invasion, fire, explosion, floods, and acts of government or governmental agencies or instrumentalities, it shall not be considered a breach of this Agreement or result in any liability for that party.`;

  const noticeHeading =
    selectedData && selectedData.notices && selectedData.notices.heading
      ? selectedData.notices.heading
      : "Notices";

  const noticeParagraph =
    selectedData && selectedData.notices && selectedData.notices.paragraph
      ? selectedData.notices.paragraph
      : `All notices required by this Agreement shall be sent either (i) via US mail or a nationally recognized carrier to the other Party's address on file or (ii) via email to the other Party's designated representative. Each Party is responsible for providing and updating, as needed, its mailing and email address for such notices. Notices sent by email shall be considered effective upon sending if no error or "bounce back" is received within twenty-four (24) hours of submission.`;

  const appropriateConductHeading =
    selectedData &&
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.heading
      ? selectedData.add_sexual_harassment_clause.heading
      : "Appropriate Conduct";

  const appropriateConductPara =
    selectedData &&
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.paragraph
      ? selectedData.add_sexual_harassment_clause.paragraph
      : "Client and Contractor are committed to maintaining a professional relationship free from any form of harassment or offensive conduct. If Contractor experiences any harassing behavior from Client or Client's staff during the term of this Agreement, Contractor will promptly notify Client and request appropriate corrective action. If the reported behavior persists after Contractor's second notice, it will be considered a breach of this Agreement, allowing Contractor to terminate the Agreement in accordance with the Term and Termination Clause. Contractor will be entitled to receive full payment for the ordered project or planned monthly service hours, without prejudice to any other rights or remedies available to Contractor under the law.";

  const miscellaneousHeading =
    selectedData &&
    selectedData.miscellaneous &&
    selectedData.miscellaneous.heading
      ? selectedData.miscellaneous.heading
      : "Miscellaneous";

  const miscellaneousParagraph =
    selectedData &&
    selectedData.miscellaneous &&
    selectedData.miscellaneous.paragraph
      ? selectedData.miscellaneous.paragraph
      : "1. Section and subsection headings in this Agreement are for convenience only and will not be used in interpreting the Agreement. Neither party will be considered the drafter of this Agreement for interpreting any ambiguity.\n\n2.This Agreement may be signed with manual or facsimile signatures and in counterparts, each of which will be considered an original, and together they will constitute one and the same instrument. If any provision is found illegal or unenforceable, it will be revised to give maximum effect to its original intent or eliminated if not permissible, while the rest of the Agreement remains enforceable.\n\nSeverability: If any provision of this Agreement is held invalid or unenforceable by a court of competent jurisdiction, such invalidity shall not affect the validity or operation of any other provision, and the invalid provision shall be severed from the Agreement.\n\nAssignability: This Agreement and the rights and obligations with respect to Contractor are personal and may not be assigned by Contractor without prior written consent from Client. Clients may assign this Agreement to a successor or purchaser of a portion of its assets.";

  const entireContractHeading =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.heading
      ? selectedData.entire_contract.heading
      : "Entire Contract";

  const entireContractParagraph =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.paragraph
      ? selectedData.entire_contract.paragraph
      : "This Agreement, together with Attachment B and any exhibits, schedules, or attachments, prevails over any prior oral or written arrangements and may only be modified by a future written agreement signed by both Parties.";

  const clientDetails = (to = "To") => (
    <>
      <Text
        style={{
          fontSize: 14,
          marginBottom: 10,
          color: Colors.primary,
        }}
      >
        {to}
      </Text>

      <Text style={{ fontSize: 14, marginBottom: "2px" }}>
        {selectedData &&
          selectedData.client_id &&
          selectedData.client_id.company_name}
      </Text>

      {selectedData &&
        selectedData.client_id &&
        selectedData.client_id.contacts && (
          <Text>{primaryContact && primaryContact.contact_name}</Text>
        )}

      {selectedData &&
        selectedData.client_id &&
        selectedData.client_id.contacts && (
          <Text
            style={{
              marginTop: 10,
              marginBottom: 2,
              fontSize: 11,
              color: Colors.font2,
            }}
          >
            <Text style={{ color: Colors.font1 }}>Email: </Text>
            {primaryContact && primaryContact.email}
          </Text>
        )}

      {clientAddress.length > 0 && (
        <Text
          style={{
            marginBottom: 2,
            fontSize: 11,
            color: Colors.font2,
            lineHeight: 1.3,
          }}
        >
          <Text style={{ color: Colors.font1 }}>Address: </Text>
          {clientAddress}
        </Text>
      )}

      <Text
        style={{
          marginBottom: 2,
          fontSize: 11,
          color: Colors.font2,
        }}
      >
        {tax_id_label && tax_id_label.length > 0 && (
          <>
            <Text style={{ color: Colors.font1 }}>Tax ID Label: </Text>
            <Text>{tax_id_label}</Text>
          </>
        )}
      </Text>

      <Text
        style={{
          marginBottom: 2,
          fontSize: 11,
          color: Colors.font2,
        }}
      >
        {tax_id_number && tax_id_number.length > 0 && (
          <>
            <Text style={{ color: Colors.font1 }}>Tax ID Number: </Text>
            <Text>{tax_id_number}</Text>
          </>
        )}
      </Text>
    </>
  );

  const contractorDetails = (from = "From") => (
    <>
      <Text
        style={{
          fontSize: 14,
          color: Colors.primary,
          marginBottom: 10,
        }}
      >
        {from}
      </Text>

      <Text style={{ fontSize: 14 }}>
        {businessInfoData && businessInfoData.business_name !== undefined
          ? businessInfoData.business_name
          : data && `${data.firstName} ${data.lastName}`}
      </Text>

      <Text
        style={{
          marginBottom: 2,
          marginTop: 10,
          fontSize: 11,
          color: Colors.font2,
        }}
      >
        <Text style={{ color: Colors.font1 }}>Email: </Text>
        {data && data.email}
      </Text>

      {businessAddress.length > 0 && (
        <Text
          style={{
            marginBottom: 2,
            fontSize: 11,
            color: Colors.font2,
            lineHeight: 1.3,
          }}
        >
          <Text style={{ color: Colors.font1 }}>Address: </Text>
          {businessAddress}
        </Text>
      )}

      <Text
        style={{
          marginBottom: 2,
          fontSize: 11,
          color: Colors.font2,
        }}
      >
        {businessTaxIdLabel && businessTaxIdLabel.length > 0 && (
          <>
            <Text style={{ color: Colors.font1 }}>Tax ID Label: </Text>
            <Text>{businessTaxIdLabel}</Text>
          </>
        )}
      </Text>

      <Text
        style={{
          marginBottom: 2,
          fontSize: 11,
          color: Colors.font2,
        }}
      >
        {businessTaxIdNumber && businessTaxIdNumber.length > 0 && (
          <>
            <Text style={{ color: Colors.font1 }}>Tax ID Number: </Text>
            <Text>{businessTaxIdNumber}</Text>
          </>
        )}
      </Text>
    </>
  );

  const servicesSummary = (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        wrap={false}
      >
        <Image src={servicesIcon} style={{ width: 30, height: "auto" }} />

        <Text
          style={{
            fontSize: 16,
            fontWeight: 500,
            marginLeft: 12,
          }}
        >
          Services summary
        </Text>
      </View>

      {selectedData &&
        selectedData.service_list &&
        selectedData.service_list.map((item, i) => {
          return (
            <View key={i} wrap={false}>
              <View style={styles.horizontalLine} />

              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  marginBottom: 6,
                }}
              >
                {item.service_name}
              </Text>

              <Text
                style={{
                  color: Colors.font2,
                  fontSize: 11,
                  marginBottom: 6,
                }}
              >
                <Text style={styles.currencySymbol}>
                  {projectCurrency}
                </Text>

                {item.service_rate
                  ? parseFloat(item.service_rate).toFixed(2)
                  : "00.00"}

                {item.service_rate_type !== "Flat fee"
                  ? ` ${item.service_rate_type.toLocaleLowerCase()}`
                  : ` ${item.service_rate_type.toLocaleLowerCase()}`}
              </Text>

              <Text
                style={{
                  color: Colors.font2,
                  fontSize: 11,
                }}
              >
                {item.desc}
              </Text>
            </View>
          );
        })}
    </>
  );

  const billingSchedule = (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        wrap={false}
      >
        <Image src={billingIcon} style={{ width: 30, height: "auto" }} />
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "12px",
          }}
        >
          <Text
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginBottom: 5,
            }}
          >
            Billing schedule
          </Text>

          <Text
            style={{
              color: Colors.font2,
              fontSize: "11px",
            }}
          >
            Contract bills Client{" "}
            {invoiceBillingFrequency === "Once" ? "Once" : ""}
            {invoiceBillingFrequency === "Weekly" ? "Weekly" : ""}
            {invoiceBillingFrequency === "Bi-weekly" ? "Bi-weekly" : ""}
            {invoiceBillingFrequency === "Monthly" ? "monthly" : ""}
            {selectedData &&
            selectedData.billing_scheduled &&
            selectedData.billing_scheduled.deposit &&
            selectedData.billing_scheduled.deposit.require_deposit === true
              ? ", plus deposit"
              : ""}
          </Text>
        </View>
      </View>

      {selectedData &&
        selectedData.billing_scheduled &&
        selectedData.billing_scheduled.deposit &&
        selectedData.billing_scheduled.deposit.require_deposit && (
          <View style={styles.horizontalLine} />
        )}

      {selectedData &&
        selectedData.billing_scheduled &&
        selectedData.billing_scheduled.deposit &&
        selectedData.billing_scheduled.deposit.require_deposit &&
        selectedData.billing_scheduled.deposit.value && (
          <View wrap={false}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: 14,
                marginBottom: 8,
              }}
            >
              <Text style={{ fontWeight: 500 }}>Deposit</Text>

              <Text style={{ fontWeight: 500 }}>
                <Text style={styles.currencySymbol}>
                  {projectCurrency}
                </Text>

                {selectedData &&
                  selectedData.billing_scheduled &&
                  selectedData.billing_scheduled.deposit &&
                  selectedData.billing_scheduled.deposit.require_deposit ===
                    true &&
                  selectedData.billing_scheduled.deposit.value &&
                  parseFloat(
                    selectedData.billing_scheduled.deposit.value
                  ).toFixed(2)}
              </Text>
            </View>

            <Text style={{ color: Colors.font2, fontSize: 11 }}>
              Deposit will be subtracted from subsequent invoices.
            </Text>
          </View>
        )}

      <View style={styles.horizontalLine} />

      <View wrap={false}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 8,
          }}
        >
          {invoiceBillingFrequency === "Once"
            ? "Final invoice"
            : invoiceBillingFrequency === "Weekly"
            ? "Billed Weekly"
            : invoiceBillingFrequency === "Bi-weekly"
            ? "Billed Bi-weekly"
            : invoiceBillingFrequency === "Monthly"
            ? "Billed Monthly"
            : ""}
        </Text>

        {invoiceBillingFrequency === "Once" && (
          <Text
            style={{
              color: Colors.font2,
              fontSize: "11px",
            }}
          >
            {selectedData &&
              selectedData.billing_scheduled.issue_invoice &&
              moment(selectedData.billing_scheduled.issue_invoice).format(
                "DD MMM YYYY"
              )}
          </Text>
        )}

        {invoiceBillingFrequency === "Weekly" &&
          selectedData.billing_scheduled.weekly_invoice &&
          selectedData.billing_scheduled.weekly_invoice.invoices_type ===
            "Never" && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "11px" }}>First invoice:</Text>
              <Text
                style={{
                  color: Colors.font2,
                  fontSize: "11px",
                  marginLeft: "3px",
                }}
              >
                {selectedData &&
                  selectedData.billing_scheduled.weekly_invoice.start_date &&
                  moment(
                    selectedData &&
                      selectedData.billing_scheduled.weekly_invoice.start_date
                  ).format("DD MMM YYYY")}
              </Text>
            </View>
          )}

        {invoiceBillingFrequency === "Weekly" &&
          selectedData.billing_scheduled.weekly_invoice &&
          selectedData.billing_scheduled.weekly_invoice.invoices_type ===
            "After" && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px" }}>First invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.weekly_invoice.start_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.weekly_invoice.start_date
                    ).format("DD MMM YYYY")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px" }}>Last invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.weekly_invoice.end_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.weekly_invoice.end_date
                    ).format("DD MMM YYYY")}
                  {selectedData &&
                    selectedData.billing_scheduled.weekly_invoice
                      .num_invoices &&
                    ` (After ${
                      selectedData &&
                      selectedData.billing_scheduled.weekly_invoice.num_invoices
                    } invoices)`}
                </Text>
              </View>
            </View>
          )}

        {invoiceBillingFrequency === "Weekly" &&
          selectedData.billing_scheduled.weekly_invoice &&
          selectedData.billing_scheduled.weekly_invoice.invoices_type ===
            "On" && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px" }}>First invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.weekly_invoice.start_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.weekly_invoice.start_date
                    ).format("DD MMM YYYY")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: "11px" }}>Last invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.weekly_invoice.end_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.weekly_invoice.end_date
                    ).format("DD MMM YYYY")}
                  {selectedData &&
                    selectedData.billing_scheduled.weekly_invoice
                      .num_invoices &&
                    ` (After ${
                      selectedData &&
                      selectedData.billing_scheduled.weekly_invoice.num_invoices
                    } invoices)`}
                </Text>
              </View>
            </View>
          )}

        {invoiceBillingFrequency === "Bi-weekly" &&
          selectedData.billing_scheduled.biWeekly_invoice &&
          selectedData.billing_scheduled.biWeekly_invoice.invoices_type ===
            "Never" && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "11px" }}>First invoice:</Text>
              <Text
                style={{
                  color: Colors.font2,
                  fontSize: "11px",
                  marginLeft: "3px",
                }}
              >
                {selectedData &&
                  selectedData.billing_scheduled.biWeekly_invoice.start_date &&
                  moment(
                    selectedData &&
                      selectedData.billing_scheduled.biWeekly_invoice.start_date
                  ).format("DD MMM YYYY")}
              </Text>
            </View>
          )}

        {invoiceBillingFrequency === "Bi-weekly" &&
          selectedData.billing_scheduled.biWeekly_invoice &&
          selectedData.billing_scheduled.biWeekly_invoice.invoices_type ===
            "After" && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px" }}>First invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.biWeekly_invoice
                      .start_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.biWeekly_invoice
                          .start_date
                    ).format("DD MMM YYYY")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: "11px" }}>Last invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.biWeekly_invoice.end_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.biWeekly_invoice.end_date
                    ).format("DD MMM YYYY")}
                  {selectedData &&
                    selectedData.billing_scheduled.biWeekly_invoice
                      .num_invoices &&
                    ` (After ${
                      selectedData &&
                      selectedData.billing_scheduled.biWeekly_invoice
                        .num_invoices
                    } invoices)`}
                </Text>
              </View>
            </View>
          )}

        {invoiceBillingFrequency === "Bi-weekly" &&
          selectedData.billing_scheduled.biWeekly_invoice &&
          selectedData.billing_scheduled.biWeekly_invoice.invoices_type ===
            "On" && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px" }}>First invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.biWeekly_invoice
                      .start_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.biWeekly_invoice
                          .start_date
                    ).format("DD MMM YYYY")}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: "11px" }}>Last invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.biWeekly_invoice.end_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.biWeekly_invoice.end_date
                    ).format("DD MMM YYYY")}
                  {selectedData &&
                    selectedData.billing_scheduled.biWeekly_invoice
                      .num_invoices &&
                    ` (After ${
                      selectedData &&
                      selectedData.billing_scheduled.biWeekly_invoice
                        .num_invoices
                    } invoices)`}
                </Text>
              </View>
            </View>
          )}

        {invoiceBillingFrequency === "Monthly" &&
          selectedData.billing_scheduled.monthly_invoice &&
          selectedData.billing_scheduled.monthly_invoice.invoices_type ===
            "Never" && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: "11px" }}>First invoice:</Text>
              <Text
                style={{
                  color: Colors.font2,
                  fontSize: "11px",
                  marginLeft: "3px",
                }}
              >
                {selectedData &&
                  selectedData.billing_scheduled.monthly_invoice.start_date &&
                  moment(
                    selectedData &&
                      selectedData.billing_scheduled.monthly_invoice.start_date
                  ).format("DD MMM YYYY")}
              </Text>
            </View>
          )}

        {invoiceBillingFrequency === "Monthly" &&
          selectedData.billing_scheduled.monthly_invoice &&
          selectedData.billing_scheduled.monthly_invoice.invoices_type ===
            "After" && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px" }}>First invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.monthly_invoice.start_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.monthly_invoice
                          .start_date
                    ).format("DD MMM YYYY")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: "11px" }}>Last invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.monthly_invoice.end_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.monthly_invoice.end_date
                    ).format("DD MMM YYYY")}
                  {selectedData &&
                    selectedData.billing_scheduled.monthly_invoice
                      .num_invoices &&
                    ` (After ${
                      selectedData &&
                      selectedData.billing_scheduled.monthly_invoice
                        .num_invoices
                    } invoices)`}
                </Text>
              </View>
            </View>
          )}

        {invoiceBillingFrequency === "Monthly" &&
          selectedData.billing_scheduled.monthly_invoice &&
          selectedData.billing_scheduled.monthly_invoice.invoices_type ===
            "On" && (
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: "11px" }}>First invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.monthly_invoice.start_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.monthly_invoice
                          .start_date
                    ).format("DD MMM YYYY")}
                </Text>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 5,
                }}
              >
                <Text style={{ fontSize: "11px" }}>Last invoice:</Text>
                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    marginLeft: "3px",
                  }}
                >
                  {selectedData &&
                    selectedData.billing_scheduled.monthly_invoice.end_date &&
                    moment(
                      selectedData &&
                        selectedData.billing_scheduled.monthly_invoice.end_date
                    ).format("DD MMM YYYY")}
                  {selectedData &&
                    selectedData.billing_scheduled.monthly_invoice
                      .num_invoices &&
                    ` (After ${
                      selectedData &&
                      selectedData.billing_scheduled.monthly_invoice
                        .num_invoices
                    } invoices)`}
                </Text>
              </View>
            </View>
          )}
      </View>
    </>
  );

  const projectNameAndDates = (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Image src={clientIcon} style={{ width: 35, height: "auto" }} />
        <Text style={{ fontSize: 16, marginLeft: 12 }}>
          {selectedData && selectedData.project_name}
        </Text>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Text style={{ fontWeight: 500 }}>Starts:</Text>
        <Text style={{ fontSize: 11, color: Colors.font2, marginLeft: "8px" }}>
          {moment(selectedData && selectedData.project_start_date).format(
            "DD MMM YYYY"
          )}
        </Text>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <Text style={{ fontWeight: 500 }}>Ends:</Text>
        <Text style={{ fontSize: 11, color: Colors.font2, marginLeft: "8px" }}>
          {selectedData && selectedData.project_end_date
            ? moment(selectedData.project_end_date).format("DD MMM YYYY")
            : "On going"}
        </Text>
      </View>

      {selectedData &&
        selectedData.project_end_date &&
        selectedData.project_start_date && (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text style={{ fontWeight: 500 }}>Durations:</Text>
            <Text
              style={{
                fontSize: 11,
                color: Colors.font2,
                marginLeft: "8px",
              }}
            >
              {diffDate(
                selectedData.project_end_date,
                selectedData.project_start_date
              )}
            </Text>
          </View>
        )}
    </>
  );

  const termsAndConditions = (heading, paragraph) => (
    <View style={{ marginTop: 26 }} wrap={false}>
      <Text style={{ fontSize: 14, fontWeight: "500" }}>{heading}</Text>

      <Text
        style={{
          fontSize: 11,
          lineHeight: 1.4,
          marginTop: 10,
        }}
        wrap={false}
      >
        {paragraph}
      </Text>
    </View>
  );

  return (
    <Document>
      <Page
        wrap
        style={styles.page(
          brandAndLogoData && brandAndLogoData.brand_color
            ? brandAndLogoData.brand_color
            : Colors.primary
        )}
      >
        {brandAndLogoData &&
          brandAndLogoData.brand_logo &&
          brandAndLogoData.brand_logo.file_url && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent:
                  brandAndLogoData.logo_alignment === "left"
                    ? "flex-start"
                    : brandAndLogoData.logo_alignment === "right"
                    ? "flex-end"
                    : "center",
              }}
            >
              <Image
                src={`${brandAndLogoData.brand_logo.file_url}`}
                style={{ maxWidth: 170, maxHeight: 70 }}
              />
            </View>
          )}

        <View style={styles.container}>
          <View>{projectNameAndDates}</View>

          <View style={styles.horizontalLine} />

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ width: "48%" }}>{clientDetails()}</View>

            <View style={{ width: "48%" }}>{contractorDetails()}</View>
          </View>
        </View>

        {selectedData &&
          selectedData.add_section_text_one &&
          selectedData.add_section_text_one.map((text, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddTextIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>Text</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Text title
                  </Text>
                  <Text style={styles.input}>{text.text_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Text description
                  </Text>
                  <Text style={styles.input}>{text.textarea}</Text>
                </View>
              </View>
            );
          })}

        {selectedData &&
          selectedData.add_section_image_one &&
          selectedData.add_section_image_one.map((img, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddImageIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>Image</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Image title
                  </Text>
                  <Text style={styles.input}>{img.image_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Image description
                  </Text>
                  <Text style={styles.input}>{img.image_text}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Attached image
                  </Text>

                  <View
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: `1px dashed #bbb`,
                      borderRadius: 12,
                      padding: 10,
                    }}
                  >
                    <Image
                      src={img.image_url}
                      style={{
                        width: "70%",
                        height: "auto",
                      }}
                    />
                  </View>
                </View>
              </View>
            );
          })}

        {selectedData &&
          selectedData.add_section_table_one &&
          selectedData.add_section_table_one.map((tab, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddTableIcon}
                    style={{ width: 30, height: "auto" }}
                    wrap={false}
                  />
                  <Text style={{ marginLeft: 8 }}>Table</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Table title
                  </Text>
                  <Text style={styles.input}>{tab.table_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Table description
                  </Text>

                  {tab.input_array &&
                    tab.input_array.map((input, i) => {
                      return (
                        <View
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              ...styles.input,
                              width: "100%",
                              margin: "0 10px 10px 0",
                            }}
                          >
                            {input.input_one}
                          </Text>
                          <Text
                            style={{
                              ...styles.input,
                              width: "100%",
                              marginBottom: 10,
                            }}
                          >
                            {input.input_two}
                          </Text>
                        </View>
                      );
                    })}
                </View>
              </View>
            );
          })}

        {selectedData &&
          selectedData.add_section_column_one &&
          selectedData.add_section_column_one.map((col, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddColumnIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>Column</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Column title
                  </Text>
                  <Text style={styles.input}>{col.column_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Column description
                  </Text>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        ...styles.input,
                        width: "100%",
                        marginRight: 10,
                      }}
                    >
                      {col.input && col.input.input_one && col.input.input_one}
                    </Text>

                    <Text
                      style={{
                        ...styles.input,
                        width: "100%",
                        marginRight: 10,
                      }}
                    >
                      {col.input && col.input.input_two && col.input.input_two}
                    </Text>

                    <Text style={{ ...styles.input, width: "100%" }}>
                      {col.input &&
                        col.input.input_three &&
                        col.input.input_three}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}

        {selectedData &&
          selectedData.add_section_file_one &&
          selectedData.add_section_file_one.map((file, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddFileIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>File</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    File title
                  </Text>
                  <Text style={styles.input}>{file.file_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Attached files
                  </Text>

                  {file &&
                    file.files.map((fileItem, i) => {
                      return (
                        <Link
                          key={i}
                          // src={fileItem.file_url}
                          style={{
                            backgroundColor: "#e5eaf0",
                            color: Colors.primary,
                            borderRadius: 12,
                            padding: 16,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 6,
                            textDecoration: "none",
                          }}
                        >
                          <Image
                            src={docIcon}
                            style={{ width: 8, height: "auto", marginRight: 8 }}
                          />

                          <Text style={{ fontSize: "11px" }}>
                            {`${fileItem.file_name.split("_")[1]} - ${(
                              fileItem.size /
                              1024 /
                              1024
                            ).toFixed(4)} MB`}
                          </Text>
                        </Link>
                      );
                    })}
                </View>
              </View>
            );
          })}

        <View style={styles.container}>{servicesSummary}</View>

        <View style={styles.container}>{billingSchedule}</View>

        {selectedData &&
          selectedData.add_section_text_two &&
          selectedData.add_section_text_two.map((text, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddTextIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>Text</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Text title
                  </Text>
                  <Text style={styles.input}>{text.text_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Text description
                  </Text>
                  <Text style={styles.input}>{text.textarea}</Text>
                </View>
              </View>
            );
          })}

        {selectedData &&
          selectedData.add_section_image_two &&
          selectedData.add_section_image_two.map((img, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddImageIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>Image</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Image title
                  </Text>
                  <Text style={styles.input}>{img.image_title}</Text>
                </View>

                <View style={{ marginTop: 14 }}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Image description
                  </Text>
                  <Text style={styles.input}>{img.image_text}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Attached image
                  </Text>

                  <View
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: `1px dashed #bbb`,
                      borderRadius: 12,
                      padding: 10,
                    }}
                  >
                    <Image
                      src={img.image_url}
                      style={{
                        width: "70%",
                        height: "auto",
                      }}
                    />
                  </View>
                </View>
              </View>
            );
          })}

        {selectedData &&
          selectedData.add_section_table_two &&
          selectedData.add_section_table_two.map((tab, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddTableIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>Table</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Table title
                  </Text>
                  <Text style={styles.input}>{tab.table_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Table description
                  </Text>

                  {tab.input_array &&
                    tab.input_array.map((input, i) => {
                      return (
                        <View
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              ...styles.input,
                              width: "100%",
                              margin: "0 10px 10px 0",
                            }}
                          >
                            {input.input_one}
                          </Text>
                          <Text
                            style={{
                              ...styles.input,
                              width: "100%",
                              marginBottom: 10,
                            }}
                          >
                            {input.input_two}
                          </Text>
                        </View>
                      );
                    })}
                </View>
              </View>
            );
          })}

        {selectedData &&
          selectedData.add_section_column_two &&
          selectedData.add_section_column_two.map((col, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddTableIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>Column</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Column title
                  </Text>
                  <Text style={styles.input}>{col.column_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Column description
                  </Text>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        ...styles.input,
                        width: "100%",
                        marginRight: 10,
                      }}
                    >
                      {col.input && col.input.input_one && col.input.input_one}
                    </Text>

                    <Text
                      style={{
                        ...styles.input,
                        width: "100%",
                        marginRight: 10,
                      }}
                    >
                      {col.input && col.input.input_two && col.input.input_two}
                    </Text>

                    <Text style={{ ...styles.input, width: "100%" }}>
                      {col.input &&
                        col.input.input_three &&
                        col.input.input_three}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}

        {selectedData &&
          selectedData.add_section_file_two &&
          selectedData.add_section_file_two.map((file, i) => {
            return (
              <View key={i} style={styles.container}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 16,
                  }}
                  wrap={false}
                >
                  <Image
                    src={sectionAddFileIcon}
                    style={{ width: 30, height: "auto" }}
                  />
                  <Text style={{ marginLeft: 8 }}>File</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    File title
                  </Text>
                  <Text style={styles.input}>{file.file_title}</Text>
                </View>

                <View style={{ marginTop: 14 }} wrap={false}>
                  <Text
                    style={{
                      fontWeight: "500",
                      marginBottom: "5px",
                    }}
                  >
                    Attached files
                  </Text>

                  {file &&
                    file.files.map((fileItem, i) => {
                      return (
                        <Link
                          key={i}
                          // src={fileItem.file_url}
                          style={{
                            backgroundColor: "#e5eaf0",
                            color: Colors.primary,
                            borderRadius: 12,
                            padding: 16,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 6,
                          }}
                        >
                          <Image
                            src={docIcon}
                            style={{ width: 8, height: "auto", marginRight: 8 }}
                          />

                          <Text style={{ fontSize: "11px" }}>
                            {`${fileItem.file_name.split("_")[1]} - ${(
                              fileItem.size /
                              1024 /
                              1024
                            ).toFixed(4)} MB`}
                          </Text>
                        </Link>
                      );
                    })}
                </View>
              </View>
            );
          })}

        <View style={styles.container} wrap={false}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image src={signatureIcon} style={{ width: 30, height: "auto" }} />
            <Text style={{ fontSize: 16, marginLeft: 8 }}>Signature</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              height: 120,
            }}
          >
            <View style={{ width: "50%", alignSelf: "flex-end" }}>
              {selectedData &&
                selectedData.client_signature &&
                selectedData.client_signature.text && (
                  <Text style={styles.signature}>
                    {selectedData.client_signature.text}
                  </Text>
                )}

              {selectedData &&
                selectedData.client_signature &&
                selectedData.client_signature.image &&
                selectedData.client_signature.image.image_name && (
                  <Image
                    src={selectedData.client_signature.image.image_url}
                    style={{ width: "100px" }}
                  />
                )}
            </View>

            <View
              style={{
                width: "50%",
                alignSelf: "flex-end",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {selectedData &&
                selectedData.signature &&
                selectedData.signature.text && (
                  <Text style={styles.signature}>
                    {selectedData.signature.text}
                  </Text>
                )}

              {selectedData &&
                selectedData.signature &&
                selectedData.signature.image &&
                selectedData.signature.image.image_name && (
                  <Image
                    src={selectedData.signature.image.image_url}
                    style={{ width: "100px" }}
                  />
                )}
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "2px",
            }}
          >
            <Text style={{ fontSize: "13px" }}>
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.company_name}
            </Text>
            <Text style={{ fontSize: "13px" }}>
              {businessInfoData && businessInfoData.business_name
                ? businessInfoData.business_name
                : data && `${data.firstName} ${data.lastName}`}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <Text style={{ color: Colors.font2, fontSize: "11px" }}>
              {primaryContact && primaryContact.contact_name}
            </Text>
            <Text style={{ color: Colors.font2, fontSize: "11px" }}>
              {businessInfoData &&
              businessInfoData.business_name !==
                `${data.firstName} ${data.lastName}`
                ? data && `${data.firstName} ${data.lastName}`
                : ""}
            </Text>
          </View>
        </View>
      </Page>

      {selectedData &&
        selectedData.section &&
        (selectedData.section === "Proposal and contract" ||
          selectedData.section === "Contract") && (
          <Page
            wrap
            style={styles.page(
              brandAndLogoData && brandAndLogoData.brand_color
                ? brandAndLogoData.brand_color
                : Colors.primary
            )}
          >
            <Text style={{ fontSize: 18, fontWeight: "500" }}>
              Service contract
            </Text>

            {selectedData && selectedData.project_name && (
              <Text style={{ fontSize: 12, fontWeight: 500, marginTop: 24 }}>
                {selectedData.project_name}
              </Text>
            )}

            <Text
              style={{ marginTop: 24, fontSize: 11, lineHeight: 1.4 }}
              wrap={false}
            >
              This Agreement (the "Contract") is made and entered into by the
              parties listed below (the "Parties"). This offer is valid until
              the close of business on
              {selectedData && selectedData.contract_expire_date && (
                <Text style={{ fontWeight: 600 }}>
                  {" "}
                  {moment(selectedData.contract_expire_date).format(
                    "DD MMM YYYY"
                  )}{" "}
                </Text>
              )}
              and it must be accepted in writing by counter-signing this
              Agreement by the specified date to be considered valid.
            </Text>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 24,
              }}
              wrap={false}
            >
              <View style={{ width: "48%" }}>{clientDetails("Client")}</View>

              <View style={{ width: "48%" }}>
                {contractorDetails("Contractor")}
              </View>
            </View>

            <View style={{ marginTop: 24 }} wrap={false}>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "500",
                  marginBottom: 10,
                }}
              >
                Services
              </Text>

              <Text
                style={{
                  color: Colors.font2,
                  fontSize: "11px",
                  lineHeight: 1.3,
                }}
              >
                Contractor agrees to render the services as outlined in
                Attachment A (referred to as the "Services"), and Client agrees
                to compensate Contractor as specified in Attachment A.
              </Text>

              <View style={{ marginTop: 24 }} wrap={false}>
                <Text
                  style={{ fontSize: 14, fontWeight: "500", marginBottom: 10 }}
                >
                  Signatures
                </Text>

                <Text
                  style={{
                    color: Colors.font2,
                    fontSize: "11px",
                    lineHeight: 1.3,
                    marginBottom: 20,
                  }}
                >
                  This Agreement, along with Attachment A and Attachment B, is
                  executed by the Parties on the later of the two dates below,
                  thereby establishing the "Effective Date."
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    height: 150,
                  }}
                >
                  <View
                    style={{
                      width: "48%",
                      padding: "14px 14px 14px 0",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "13px",
                          marginRight: "2px",
                        }}
                      >
                        Client
                      </Text>

                      <Text
                        style={{
                          color: Colors.font2,
                          fontSize: "11px",
                          lineHeight: 1.3,
                        }}
                      >
                        {primaryContact && `(${primaryContact.contact_name})`}
                      </Text>
                    </View>

                    <View
                      style={{
                        marginTop: "auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ width: "48%" }}>
                        <View
                          style={{
                            height: 120,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          {selectedData &&
                            selectedData.client_signature &&
                            selectedData.client_signature.text && (
                              <Text style={styles.signature}>
                                {selectedData.client_signature.text}
                              </Text>
                            )}

                          {selectedData &&
                            selectedData.client_signature &&
                            selectedData.client_signature.image &&
                            selectedData.client_signature.image.image_name && (
                              <Image
                                src={
                                  selectedData.client_signature.image.image_url
                                }
                                style={{ width: 100, height: "auto" }}
                              />
                            )}
                        </View>

                        <View
                          style={{ ...styles.horizontalLine, margin: "4px 0" }}
                        />

                        <Text>Signature</Text>
                      </View>

                      <View style={{ width: "48%" }}>
                        <View
                          style={{
                            height: 120,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          {selectedData && (
                            <Text
                              style={{ fontSize: "11px", color: Colors.font2 }}
                            >
                              {moment(
                                selectedData.client_signature &&
                                  selectedData.client_signature.signature_date
                              ).format("DD MMM YYYY")}
                            </Text>
                          )}
                        </View>

                        <View
                          style={{ ...styles.horizontalLine, margin: "4px 0" }}
                        />

                        <Text>Date</Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "48%",
                      border: `1px dashed #bbb`,
                      borderRadius: 12,
                      padding: 14,
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Text style={{ fontSize: "13px", marginRight: "2px" }}>
                        Contractor
                      </Text>

                      <Text
                        style={{
                          color: Colors.font2,
                          fontSize: "11px",
                          lineHeight: 1.3,
                        }}
                      >
                        {businessInfoData && businessInfoData.business_name
                          ? `(${businessInfoData.business_name})`
                          : data && `(${data.firstName} ${data.lastName})`}
                      </Text>
                    </View>

                    <View
                      style={{
                        marginTop: "auto",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ width: "48%" }}>
                        <View
                          style={{
                            height: 120,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          {selectedData &&
                            selectedData.signature &&
                            selectedData.signature.text && (
                              <Text style={styles.signature}>
                                {selectedData.signature.text}
                              </Text>
                            )}

                          {selectedData &&
                            selectedData.signature &&
                            selectedData.signature.image &&
                            selectedData.signature.image.image_name && (
                              <Image
                                src={selectedData.signature.image.image_url}
                                style={{ width: "100px", height: "auto" }}
                              />
                            )}
                        </View>

                        <View
                          style={{ ...styles.horizontalLine, margin: "4px 0" }}
                        />

                        <Text>Signature</Text>
                      </View>

                      <View style={{ width: "48%" }}>
                        <View
                          style={{
                            height: 120,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          {selectedData && (
                            <Text style={{ fontSize: 11, color: Colors.font2 }}>
                              {moment(
                                selectedData.signature &&
                                  selectedData.signature.signature_date
                              ).format("DD MMM YYYY")}
                            </Text>
                          )}
                        </View>

                        <View
                          style={{ ...styles.horizontalLine, margin: "4px 0" }}
                        />

                        <Text>Date</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        )}

      {selectedData &&
        selectedData.section &&
        (selectedData.section === "Proposal and contract" ||
          selectedData.section === "Contract") && (
          <Page
            wrap
            style={styles.page(
              brandAndLogoData && brandAndLogoData.brand_color
                ? brandAndLogoData.brand_color
                : Colors.primary
            )}
          >
            <Text style={{ fontSize: 18, fontWeight: "500" }}>
              Attachment A : Services
            </Text>

            <View style={{ marginTop: 24 }}>{projectNameAndDates}</View>

            <View style={styles.container}>{servicesSummary}</View>

            <View style={styles.container}>{billingSchedule}</View>
          </Page>
        )}

      {selectedData &&
        selectedData.section &&
        (selectedData.section === "Proposal and contract" ||
          selectedData.section === "Contract") && (
          <Page
            wrap
            style={styles.page(
              brandAndLogoData && brandAndLogoData.brand_color
                ? brandAndLogoData.brand_color
                : Colors.primary
            )}
          >
            <Text style={{ fontSize: 18, fontWeight: "500" }}>
              Attachment B : Terms and Conditions
            </Text>

            {selectedData &&
              selectedData.acceptance &&
              selectedData.acceptance.required &&
              termsAndConditions(acceptanceHeading, acceptanceParagraph)}

            {selectedData &&
              selectedData.responsibility &&
              selectedData.responsibility.required &&
              termsAndConditions(
                responsibilityHeading,
                responsibilityParagraph
              )}

            {selectedData &&
              selectedData.compensation &&
              selectedData.compensation.required &&
              termsAndConditions(compensationHeading, compensationParagraph)}

            {selectedData &&
              selectedData.warranty &&
              selectedData.warranty.required &&
              termsAndConditions(warrantyHeading, warrantyParagraph)}

            {selectedData &&
              selectedData.confidential &&
              selectedData.confidential.required &&
              termsAndConditions(confidentialHeading, confidentialParagraph)}

            {selectedData &&
              selectedData.ownership_right &&
              selectedData.ownership_right.required &&
              termsAndConditions(ownershipHeading, ownershipParagraph)}

            {selectedData &&
              selectedData.non_solicit &&
              selectedData.non_solicit.required &&
              termsAndConditions(nonSolicitHeading, nonSolicitParagraph)}

            {selectedData &&
              selectedData.add_non_complete &&
              selectedData.add_non_complete.required &&
              termsAndConditions(nonCompleteHeading, nonCompleteParagraph)}

            {selectedData &&
              selectedData.relationship_of_parties &&
              selectedData.relationship_of_parties.required &&
              termsAndConditions(relationShipHeading, relationShipParagraph)}

            {selectedData &&
              selectedData.term_and_termination &&
              selectedData.term_and_termination.required && (
                <View style={{ marginTop: 26 }} wrap={false}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    {termAndTerminationHeading}
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    Either Party has the right to terminate this Agreement at
                    any time by providing
                    <Text style={{ fontWeight: 600 }}>
                      {` ${
                        selectedData &&
                        selectedData.term_and_termination &&
                        selectedData.term_and_termination
                          .project_termination_notice_period
                      } `}
                    </Text>
                    written notice, with or without cause.
                  </Text>

                  <Text style={{ fontSize: 11, lineHeight: 1.4 }}>
                    {termAndTerminationParagraph}
                  </Text>
                </View>
              )}

            {selectedData &&
              selectedData.contractor_tax_id &&
              selectedData.contractor_tax_id.required &&
              termsAndConditions(
                contractorTaxIdHeading,
                contractorTaxIdParagraph
              )}

            {selectedData &&
              selectedData.health_insurance &&
              selectedData.health_insurance.required &&
              termsAndConditions(
                healthInsuranceHeading,
                healthInsuranceParagraph
              )}

            {selectedData &&
              selectedData.cancellation_fee &&
              selectedData.cancellation_fee.required && (
                <View style={{ marginTop: 26 }} wrap={false}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    Cancellation Fee
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    If the Client terminates this Agreement earlier without
                    cause, Contractor will charge a cancellation fee of{" "}
                    <Text style={{ fontWeight: 600 }}>
                      <Text style={styles.currencySymbol}>
                        {projectCurrency}
                      </Text>
                      {parseFloat(
                        selectedData.cancellation_fee.cancellation_fee_amount ||
                          0
                      ).toFixed(2)}
                    </Text>
                    .
                  </Text>
                </View>
              )}

            {selectedData &&
              selectedData.payment_terms &&
              selectedData.payment_terms.required && (
                <View style={{ marginTop: 26 }} wrap={false}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    Payment Terms
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    Client acknowledges the significance of prompt payments to
                    independent contractors and aims to sustain a constructive
                    working relationship with the Contractor for seamless
                    project progress. Payments for each invoice submitted by the
                    Contractor to the Client shall be settled within
                    <Text style={{ fontWeight: 600 }}>
                      {` ${
                        selectedData &&
                        selectedData.payment_terms &&
                        selectedData.payment_terms.payment_due
                      } `}
                    </Text>
                    of receipt. In the event of delayed payments, the Contractor
                    retains the right to suspend work until the payment is
                    received.
                  </Text>
                </View>
              )}

            {selectedData &&
              selectedData.late_payment_fee &&
              selectedData.late_payment_fee.required && (
                <View style={{ marginTop: 26 }} wrap={false}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    {latePaymentHeading}
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    In case of delayed payment of an invoice, the Contractor
                    reserves the right to charge a late payment fee of
                    {selectedData &&
                      selectedData.late_payment_fee &&
                      selectedData.late_payment_fee.late_payment ===
                        "Percentage" && (
                        <Text style={{ fontWeight: 600 }}>
                          {` ${selectedData.late_payment_fee.late_payment_percentage}% per month`}
                        </Text>
                      )}
                    {selectedData &&
                      selectedData.late_payment_fee &&
                      selectedData.late_payment_fee.late_payment ===
                        "Amount " && (
                        <Text style={{ fontWeight: 600 }}>
                          <Text style={styles.currencySymbol}>
                            {projectCurrency}
                          </Text>
                          {selectedData.late_payment_fee.late_payment_amount} as
                          late payment fee
                        </Text>
                      )}
                    . Acceptance of these service charges by Contractor does not
                    waive any rights or remedies in the event of Client's breach
                    of this Agreement. All payment obligations are
                    non-cancellable, and fees paid are non-refundable.
                  </Text>
                </View>
              )}

            {selectedData &&
              selectedData.deposit &&
              selectedData.deposit.required && (
                <View style={{ marginTop: 26 }} wrap={false}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    Deposit
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    Upon the acceptance of this Agreement, a deposit of{" "}
                    <Text style={{ fontWeight: 600 }}>
                      <Text style={styles.currencySymbol}>
                        {projectCurrency}
                      </Text>
                      {parseFloat(
                        selectedData.billing_scheduled.deposit.value || 0
                      ).toFixed(2)}
                    </Text>{" "}
                    is required before work will commence.
                  </Text>
                </View>
              )}

            {selectedData &&
              selectedData.reimbursement &&
              selectedData.reimbursement.required && (
                <View style={{ marginTop: 26 }} wrap={false}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    Expense Reimbursement
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    Client shall reimburse all reasonable expenses that have
                    been pre-authorized in writing by the Client in advance.
                    Payment for such expenses shall be made within
                    <Text style={{ fontWeight: 600 }}>
                      {` ${
                        selectedData &&
                        selectedData.payment_terms &&
                        selectedData.payment_terms.payment_due
                      } `}
                    </Text>
                    of receipt of an itemized invoice.
                  </Text>
                </View>
              )}

            {selectedData &&
              selectedData.number_of_termination_request &&
              selectedData.number_of_termination_request.required && (
                <View style={{ marginTop: 26 }} wrap={false}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    Feedback
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    Client will have the opportunity to provide feedback up to
                    <Text style={{ fontWeight: 600 }}>
                      {` ${selectedData.number_of_termination_request.num_request} `}
                    </Text>
                    times and Contractor will make revisions up to
                    <Text style={{ fontWeight: 600 }}>
                      {` ${selectedData.number_of_termination_request.num_request} `}
                    </Text>
                    times based on the provided feedback.
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    a.Clients as well as contractors must establish a process
                    for providing feedback, such as scheduled review meetings or
                    periodic feedback sessions.
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    b. Both the parties define the preferred method of feedback
                    communication, whether it's in person, email, or through the
                    project management system.
                  </Text>

                  <Text
                    style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                  >
                    c. Client is expected to provide feedback on deliverables or
                    milestones within a specific time frame.
                  </Text>
                </View>
              )}

            {selectedData &&
              selectedData.changes &&
              selectedData.changes.required &&
              termsAndConditions(changesHeading, changesParagraph)}

            {selectedData &&
              selectedData.liabilityLimitation &&
              selectedData.liabilityLimitation.required &&
              termsAndConditions(
                liabilityLimitationHeading,
                liabilityLimitationParagraph
              )}

            {selectedData &&
              selectedData.rightToAuthorship &&
              selectedData.rightToAuthorship.required && (
                <View style={{ marginTop: 26 }} wrap={false}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    {rightToAuthorshipHeading}
                  </Text>

                  {selectedData &&
                  selectedData.rightToAuthorship &&
                  selectedData.rightToAuthorship.paragraph ? (
                    <Text
                      style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                    >
                      {selectedData.rightToAuthorship.paragraph}
                    </Text>
                  ) : selectedData &&
                    selectedData.rightToAuthorship &&
                    selectedData.rightToAuthorship.retain_rights ? (
                    <View>
                      <Text
                        style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                      >
                        The parties acknowledge that the Contractor is the
                        creator of the deliverables. The Client is not obligated
                        to proactively display the Contractor's name alongside
                        the deliverables but must not mislead others by
                        attributing the creation of the deliverables to anyone
                        other than the Contractor. The Client agrees that the
                        Contractor may use the work product as part of their
                        portfolio, websites, galleries, and other media solely
                        for the purpose of showcasing the Contractor's work,
                        without any other purpose.
                      </Text>

                      <Text
                        style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                      >
                        The Contractor shall not publish any confidential or
                        non-public work without obtaining the prior written
                        consent of the Client.
                      </Text>
                    </View>
                  ) : (
                    <View>
                      <Text
                        style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                      >
                        Both parties mutually agree that the Client must
                        appropriately acknowledge the Contractor as the creator
                        of the deliverables when requested. The Client is not
                        obligated to proactively display the Contractor's name
                        alongside the deliverables, but the Client must not
                        mislead others by attributing the creation of the
                        deliverables to anyone other than the Contractor.
                      </Text>

                      <Text
                        style={{ fontSize: 11, lineHeight: 1.4, marginTop: 10 }}
                      >
                        The Contractor shall refrain from publishing any
                        confidential or non-public work without obtaining the
                        prior written consent of the Client.
                      </Text>
                    </View>
                  )}
                </View>
              )}

            {selectedData &&
              selectedData.governingLaw &&
              selectedData.governingLaw.required &&
              termsAndConditions(governingLawHeading, governingLawParagraph)}

            {selectedData &&
              selectedData.forceMejeure &&
              selectedData.forceMejeure.required &&
              termsAndConditions(forceMajeureHeading, forceMajeureParagraph)}

            {selectedData &&
              selectedData.notices &&
              selectedData.notices.required &&
              termsAndConditions(noticeHeading, noticeParagraph)}

            {selectedData &&
              selectedData.add_sexual_harassment_clause &&
              selectedData.add_sexual_harassment_clause.required &&
              termsAndConditions(
                appropriateConductHeading,
                appropriateConductPara
              )}

            {selectedData &&
              selectedData.privacy_data &&
              selectedData.privacy_data.required && (
                <View style={{ marginTop: 26 }}>
                  <Text style={{ fontSize: 14, fontWeight: "500" }}>
                    Data Privacy and Security
                  </Text>

                  <Text style={{ fontSize: 13, marginTop: 10 }}>
                    A. Definitions:
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    1. "Borrower Data" means all information, including Personal
                    Data, related to the Client ("Borrower").
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    2. "Client Data" refers to Personal Data, Employment Data,
                    and other information provided by or processed for the
                    Client by the Contractor or third parties on behalf of the
                    Client.
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    3. "Data Protection Laws" encompasses data privacy and
                    protection laws, including the European Community Data
                    Protection Directive and other applicable laws.
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    4. "Notices" includes filings, communications, press
                    releases, or reports related to any Security Breach.
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    5. "Personal Data" covers any information related to an
                    identified or identifiable natural person, including names,
                    addresses, Social Security numbers, financial information,
                    and health-related data.
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    6. "Processing" refers to any operation performed on Client
                    Data, such as collection, storage, or disclosure.
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    7. "Security Breach" indicates any unauthorized access, use,
                    or loss of Client Data compromising its security,
                    confidentiality, or integrity.
                  </Text>

                  <Text style={{ fontSize: 13, marginTop: 10 }}>
                    B. Data Processing:
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    Contractor will process Client Data only as required for the
                    Project's services or as instructed by the Client in
                    writing. For Employment Data, Contractor will adhere to
                    Client policy or an agreed-upon policy.
                  </Text>

                  <Text style={{ fontSize: 13, marginTop: 10 }}>
                    C. Data Security:
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    Contractor shall provide Client with data security system
                    information upon request. Contractor will comply with the
                    Client's applicable data security policies and implement
                    measures to ensure data security, confidentiality, and
                    prevention of unauthorized access.
                  </Text>

                  <Text style={{ fontSize: 13, marginTop: 10 }}>
                    D. European Community:
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    If Personal Data is transferred outside the European
                    Community, both parties will comply with applicable Data
                    Protection Laws, including executing data transfer
                    agreements or notifying local Data Protection Authorities.
                  </Text>

                  <Text style={{ fontSize: 13, marginTop: 10 }}>
                    E. Agreement with Third Parties:
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    Contractor will ensure that any third parties involved in
                    providing services maintain adequate data security measures
                    consistent with Contractor's obligations.
                  </Text>

                  <Text style={{ fontSize: 13, marginTop: 10 }}>
                    F. Security Breach Notification and Costs:
                  </Text>

                  <Text
                    style={{
                      fontSize: 11,
                      lineHeight: 1.4,
                      marginTop: 10,
                      marginLeft: 12,
                    }}
                  >
                    Contractor will promptly notify Client of any Security
                    Breach involving Client Data and take necessary actions to
                    investigate, prevent, and mitigate the impact. Contractor
                    will provide relevant information for Client's compliance
                    with applicable laws and reimburse Client for costs incurred
                    due to a Security Breach. Any voluntary actions will be
                    equitably allocated between the parties.
                  </Text>
                </View>
              )}

            {selectedData &&
              selectedData.miscellaneous &&
              selectedData.miscellaneous.required &&
              termsAndConditions(miscellaneousHeading, miscellaneousParagraph)}

            {selectedData &&
              selectedData.entire_contract &&
              selectedData.entire_contract.required &&
              termsAndConditions(
                entireContractHeading,
                entireContractParagraph
              )}

            {selectedData.add_clause &&
              selectedData.add_clause.length > 0 &&
              selectedData.add_clause
                .filter((clause) => clause.required)
                .map((clause) => (
                  <View key={clause._id}>
                    {termsAndConditions(clause.heading, clause.paragraph)}
                  </View>
                ))}
          </Page>
        )}
    </Document>
  );
};

export default AgreementPdf;
