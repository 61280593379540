import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const SetupBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.875rem;
  border-radius: 0.75rem;
  border: solid 1px #b2b6c5;
  background-color: ${Colors.white};

  .deleteBtn {
    display: none;
  }

  & .services_container:hover .deleteBtn {
    display: flex;
    cursor: pointer;
  }

  @media (max-width: 576px) {
    padding: 1.875rem 0.875rem;
  }
`;

const Selected = styled.div`
  height: 3.15rem;
  padding: 0 1.25rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${Colors.backgroundPink};
  color: ${Colors.primary};
`;

const NotSelected = styled(Selected)`
  color: ${Colors.font2} !important;
  background-color: ${Colors.tableHeadColor};
`;

const SelectedDocType = styled.div`
  height: 3.15rem;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  border-radius: 0.75rem;
  display: flex;
  width: 100%;
  min-width: 10.937rem;
  justify-content: center;
  cursor: pointer;
  background-color: ${Colors.primary};
  color: ${Colors.white};
`;

const NotSelectedDocType = styled(SelectedDocType)`
  color: ${Colors.primary};
  background-color: ${Colors.backgroundPink};

  &:hover {
    background-color: ${Colors.primary};
    color: ${Colors.white};
  }
`;

const inputBox = styled.div`
  width: 100%;
  height: 3.15rem;
  border-radius: 0.75rem;
  padding: 1rem;
  background: ${Colors.white};
  border: 1px solid
    ${(props) => (props.invalid ? Colors.red : Colors.borderInput)};
  transition: border 0.4s ease;
  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

const SubmitButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  min-width: fit-content;
  min-width: 6rem;
  padding: 0 1.5rem;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

export default {
  SetupBox,
  SelectedDocType,
  NotSelectedDocType,
  Selected,
  NotSelected,
  inputBox,
  SubmitButton,
};
