import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const UsersBox = styled.div`
  width: 100%;
  max-width: 18rem;
  min-width: 12rem;
  box-sizing: border-box;
  position: relative;
  height: 12rem;
  overflow: hidden;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
`;

const TableHead = styled.div`
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
`;

const TableCell = styled.div`
  width: 100%;
  padding: 1rem;
  margin: 0 0 2px 0;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
  &:hover {
    background: ${Colors.hoverTableRow};
  }
`;

const ListBox = styled.div`
  width: 100%;
  height: 365px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

const UsersTypeBox = styled.div`
  width: 100%;
  max-width: 25rem;
  box-sizing: border-box;
  position: relative;
  max-height: fit-content;
  height: auto;
  overflow: hidden;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
`;

const TopCountriesBox = styled.div`
  width: 100%;
  max-width: 25rem;
  box-sizing: border-box;
  position: relative;
  min-height: 15rem;
  height: auto;
  // height: fit-content;
  overflow: hidden;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
`;

const FeaturesBox1 = styled.div`
  width: 100%;
  max-width: 24.25rem;
  min-width: 12rem;
  box-sizing: border-box;
  position: relative;
  min-height: 12rem;
  overflow: hidden;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
`;

const FeaturesBox2 = styled.div`
  width: 100%;
  min-width: 16rem;
  box-sizing: border-box;
  position: relative;
  min-height: 10rem;
  overflow: hidden;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
`;

export default {
  UsersBox,
  TableHead,
  TableCell,
  ListBox,
  UsersTypeBox,
  TopCountriesBox,
  FeaturesBox1,
  FeaturesBox2,
};
