import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateConfidentialPC } from "../../../../../../store/service/proposalAndContractService";
import ConfidentialModal from "../../PageModals/ConfidentialModal";
import Colors from "../../../../../../Constants/Colors";

const Confidential = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const defaultHeading = "Confidentiality & Non-Disclosure";
  const defaultParagraph = `Both parties shall maintain strict confidentiality of all Confidential Information (as defined in the subsequent sentence) shared by either party under this Agreement. "Confidential Information" includes non-public information marked as confidential by the disclosing party and any information that should reasonably be considered confidential in the context of disclosure, whether or not marked as "Confidential". If a party is legally required to disclose Confidential Information due to a judicial or governmental order, it shall promptly notify the other party to explore alternative legal remedies for maintaining confidentiality. Each party receiving Confidential Information is responsible for any breach caused by its employees, affiliates, representatives, or agents. The receiving party agrees to indemnify and hold the disclosing party harmless from any liabilities, claims, damages, losses, costs, and expenses resulting from such a breach. The obligations under this section shall remain in effect for three years following the termination of this Agreement.
`;

  const heading =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.heading
      ? selectedData &&
        selectedData.confidential &&
        selectedData.confidential.heading
      : defaultHeading;
  const paragraph =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.paragraph
      ? selectedData &&
        selectedData.confidential &&
        selectedData.confidential.paragraph
      : defaultParagraph;

  const isConfidentialityRequired =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.required
      ? true
      : false;

  const toggleConfidentialityViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      confidential: {
        required: !isConfidentialityRequired,
        heading,
        paragraph,
      },
    };
    dispatch(updateConfidentialPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isConfidentialityRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleConfidentialityViewHandler}>
            {isConfidentialityRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isConfidentialityRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3 overflow-hidden">
          <span
            className={`fw-medium ${
              isConfidentialityRequired ? "" : "disabled"
            }`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg overflow-hidden ${isConfidentialityRequired ? "" : "disabled"}`}
          style={{ whiteSpace: "break-spaces" }}
        >
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <ConfidentialModal
          paragraph={paragraph}
          heading={heading}
          defaultHeading={defaultHeading}
          defaultParagraph={defaultParagraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Confidential;
