import React from "react";
import TextStyles from "../../../../../Constants/TextStyles";

import setupBrandImg from "../../../../../Assets/frontendImages/faq-setup-your-brand.webp";
import Screenshot from "../../../../UIs/Screenshot";
import SideContentList from "../SideContentList";

function SetupYourBrand({ idList }) {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          Set Up Your Brand
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Branding is essential for making your business stand out. In Zodot,
          you can personalize your brand logo and color to reflect your identity
          across invoices, proposals, and emails.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          Follow the below steps to set up your brand -
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px id={idList[0]} className="fw-semibold mb-1">
          1. {idList[0]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">Click on Settings from the sidebar.</li>
          <li className="mb-1">
            Click on Set up your brand under the Business tab.
          </li>
        </ul>

        <TextStyles.FontSize16px id={idList[1]} className="fw-semibold mb-1">
          2. {idList[1]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click the Brand color dropdown and select a color that aligns with
            your brand's theme. This color will be applied across proposals,
            invoices, and emails.
          </li>
        </ul>

        <TextStyles.FontSize16px id={idList[2]} className="fw-semibold mb-1">
          3. {idList[2]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click on the "Upload Logo" to add your business logo.
          </li>
          <li className="mb-1">Ensure your file meets these requirements:</li>
          <div>✔ Format: JPEG or PNG</div>
          <div>✔ Size: Maximum 2MB</div>
          <div>✔ Dimensions: Up to 512 x 512 pixels</div>
        </ul>

        <TextStyles.FontSize16px id={idList[3]} className="fw-semibold mb-1">
          4. {idList[3]}
        </TextStyles.FontSize16px>

        <ul className="mb-3">
          <li className="mb-1">
            Click on the Brand Alignment dropdown and select the alignment
            (Center, Right or Left ) for your brand logo.
          </li>
        </ul>

        <TextStyles.FontSize16px id={idList[4]} className="fw-semibold mb-1">
          5. {idList[4]}
        </TextStyles.FontSize16px>

        <ul className="mb-4">
          <li className="mb-1">
            A sample preview will appear on the right side of the screen which
            will help you to see how your brand logo and brand color will be
            displayed across invoices, proposals, and emails.
          </li>
        </ul>

        <Screenshot src={setupBrandImg} alt="setup brand img" />

        <TextStyles.FontSize16px className="mb-3">
          <span role="img" aria-labelledby="star-emoji">
            ✨
          </span>{" "}
          <span className="fw-semibold">Pro Tip:</span> A well-designed brand
          identity builds credibility and strengthens your business presence!
        </TextStyles.FontSize16px>
      </div>

      <SideContentList idList={idList} />
    </>
  );
}

export default SetupYourBrand;
