import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageModals";
import { updateCancellationFeePC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";

const CancellationFeeModal = ({ show, handleClose, heading, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const [cancellationAmount, setCancellationAmount] = useState(
    selectedData &&
      selectedData.cancellation_fee &&
      selectedData.cancellation_fee.cancellation_fee_amount 
      ? parseFloat(
          selectedData.cancellation_fee.cancellation_fee_amount
        ).toFixed(2)
      : ""
  );

  const paragraphWithCancellatonFee = `If the Client terminates this Agreement earlier without cause, Contractor will charge a cancellation fee of ${selectedData.currency.symbol}${cancellationAmount}.`;

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraphWithCancellatonFee,
      cancellation_fee_amount: cancellationAmount,
    },
  });

  const amount = watch("cancellation_fee_amount");
  useEffect(() => {
    setCancellationAmount(() => amount);
  }, [amount]);

  useEffect(() => {
    setValue("paragraph", paragraphWithCancellatonFee);
  }, [amount, cancellationAmount]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      cancellation_fee: {
        required: true,
        cancellation_fee_deposit_require: true,
        cancellation_fee_amount: item.cancellation_fee_amount,
      },
    };

    dispatch(updateCancellationFeePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              style={{ opacity: 0.5 }}
              {...register("heading")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={2}
              style={{ opacity: 0.5 }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              readOnly
            />
          </TextStyles.FontSize14px>

            <div className="mb-3">
              <TextStyles.FontSize14px className="three-dots mb-2">
                Cancellation fee
              </TextStyles.FontSize14px>

              <TextStyles.FontSize14px>
                <Styled.inputBox style={{ maxWidth: "8.2rem" }}>
                  <span>
                    {selectedData &&
                      selectedData.currency &&
                      selectedData.currency.symbol}
                  </span>
                  <input
                    className="w-100"
                    type="number"
                    id="cancellation_fee_amount"
                    placeholder="00.00"
                    name="cancellation_fee_amount"
                    {...register("cancellation_fee_amount", {
                      pattern: {
                        value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                        message: "Please enter a valid number.",
                      },
                    })}
                  />
                </Styled.inputBox>

              {errors.cancellation_fee_amount && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.cancellation_fee_amount.message}
                </TextStyles.InValidFeedback>
              )}
              </TextStyles.FontSize14px>
            </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CancellationFeeModal;
