import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const NonCompete = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.add_non_complete &&
    selectedData.add_non_complete.heading
      ? selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.heading
      : "Non-Compete";
  const paragraph =
    selectedData &&
    selectedData.add_non_complete &&
    selectedData.add_non_complete.paragraph
      ? selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.paragraph
      : "Contractor agrees that throughout the duration of providing Services and for [XX] months after the termination of Services or expiration of this Agreement, within a [XX-mile radius of Client's primary business location], Contractor shall refrain from engaging in any activities directly and substantially competitive with Client's current business, which is narrowly defined as [description of Client's primary current business].";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.add_non_complete &&
          selectedData.add_non_complete.required === true
            ? "inline"
            : "none",
      }}
    >
          <Styled.ReviewContentBox>
            <TextStyles.FontSize20px className="mb-3 fw-medium overflow-hidden">
              {heading}
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px className="lh-lg overflow-hidden">
              {paragraph}
            </TextStyles.FontSize14px>
          </Styled.ReviewContentBox>
    </div>
  );
};

export default NonCompete;
