import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateReimbursementPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";
import ExpenseReimbursementModal from "../../PageModals/ExpenseReimbursementModal";

const ExpenseReimbursement = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showExpReimbursementModal, setShowExpReimbursementModalModal] =
    useState(false);
  const dispatch = useDispatch();

  const isReimbursementRequired =
    selectedData &&
    selectedData.reimbursement &&
    selectedData.reimbursement.required
      ? true
      : false;

  const toggleReimbursementViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      reimbursement: {
        required: !isReimbursementRequired,
      },
    };
    dispatch(updateReimbursementPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isReimbursementRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleReimbursementViewHandler}>
            {isReimbursementRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isReimbursementRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowExpReimbursementModalModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${isReimbursementRequired ? "" : "disabled"}`}
          >
            Expense Reimbursement
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isReimbursementRequired ? "" : "disabled"}`}
        >
          Client shall reimburse all reasonable expenses that have been
          pre-authorized in writing by the Client in advance. Payment for such
          expenses shall be made within
          <span className="mx-1 fw-semibold">
            {selectedData &&
              selectedData.payment_terms &&
              selectedData.payment_terms.payment_due}
          </span>
          of receipt of an itemized invoice.
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showExpReimbursementModal && (
        <ExpenseReimbursementModal
          show={showExpReimbursementModal}
          handleClose={() => setShowExpReimbursementModalModal(false)}
        />
      )}
    </div>
  );
};

export default ExpenseReimbursement;
