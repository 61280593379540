import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updatePaymentTermsPC } from "../../../../../../store/service/proposalAndContractService";

import PaymentDueModal from "../../PageModals/PaymentDueModal.jsx";
import Colors from "../../../../../../Constants/Colors";

const PaymentTerms = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const paymentDueDays =
    selectedData &&
    selectedData.payment_terms &&
    selectedData.payment_terms.payment_due
      ? selectedData.payment_terms.payment_due
      : "15 days";

  const heading = "Payment Terms";

  const isPaymentTermsRequired =
    selectedData &&
    selectedData.payment_terms &&
    selectedData.payment_terms.required
      ? true
      : false;

  const togglePaymentTermsViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      payment_terms: {
        required: !isPaymentTermsRequired,
        payment_due: paymentDueDays,
      },
    };
    dispatch(updatePaymentTermsPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isPaymentTermsRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={togglePaymentTermsViewHandler}>
            {isPaymentTermsRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isPaymentTermsRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span
            className={`fw-medium ${isPaymentTermsRequired ? "" : "disabled"}`}
          >
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isPaymentTermsRequired ? "" : "disabled"}`}
        >
          Client acknowledges the significance of prompt payments to independent
          contractors and aims to sustain a constructive working relationship
          with the Contractor for seamless project progress. Payments for each
          invoice submitted by the Contractor to the Client shall be settled
          within
          <span className="mx-1 fw-semibold">{paymentDueDays}</span>
          of receipt. In the event of delayed payments, the Contractor retains
          the right to suspend work until the payment is received.
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <PaymentDueModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default PaymentTerms;
