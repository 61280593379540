import React, { useState, useEffect } from "react";
import styles from "./TermsOfservice.module.css";
import Footer from "../Footer/Footer";
import UpButton from "../UIs/UpButton";
import { Helmet } from "react-helmet";

const menuData = [
  {
    key: "1",
    value: "Introduction",
  },
  {
    key: "2",
    value: "Definitions",
  },
  {
    key: "3",
    value: "Zodot is not a substitute for a lawyer or law firm",
  },
  {
    key: "4",
    value: "Your obligations and responsibilities",
  },
  {
    key: "5",
    value: "Data protection",
  },
  {
    key: "6",
    value: "Intellectual property",
  },
  {
    key: "7",
    value: "Warranties and acknowledgements",
  },
  {
    key: "8",
    value: "Limitation of liability",
  },
  {
    key: "9",
    value: "Termination",
  },
  {
    key: "10",
    value: "Help desk",
  },
  {
    key: "11",
    value: "Arbitration clause & class action waiver",
  },
  {
    key: "12",
    value: "General",
  },
];

const TermsOfservices = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const element = document.getElementById("Introduction");
    let visibleHieght;
    if (element) {
      const yOffset = -150; // Adjust this value to set the scroll margin
      visibleHieght =
        element.getBoundingClientRect().top + window.scrollY + yOffset;
    }
    if (window.scrollY > visibleHieght) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleMenuItemClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -180; // Adjust this value to set the scroll margin
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const isProduction =
    process.env.REACT_APP_PAGE_INDEXING_MODE === "production";

  return (
    <div>
      <Helmet>
        <title>Terms of Service - Zodot</title>
        <meta
          content="Go through the Zodot Terms of Service to understand the terms and conditions for using our services."
          name="description"
        />
        {isProduction ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://zodot.co/terms-of-service" />
      </Helmet>
      <div className={styles.section}>
        <div className={styles.heading_section}>
          <div
            className="container-fluid py-3 py-md-4"
            style={{ maxWidth: 900 }}
          >
            <h1 className={styles.heading}>Zodot Terms of Service</h1>
            <div className={styles.heading_text}>
              Last updated: May 22, 2024
            </div>
          </div>
        </div>
        <div className="container-fluid py-4 py-md-5" style={{ maxWidth: 900 }}>
          <div className={`${styles.menu_title} mb-3`}>Table of contents</div>
          {menuData &&
            menuData.map((item, index) => {
              return (
                <div key={index}>
                  <div className="ps-5 mb-3">
                    <span
                      className={`${styles.menu_counter}`}
                    >{`${item.key}.`}</span>
                    <span
                      className={`${styles.menu_item}`}
                      onClick={(e) => {
                        handleMenuItemClick(item.value);
                      }}
                    >
                      {item.value}
                    </span>
                  </div>
                </div>
              );
            })}

          <div className="content" id="Introduction">
            <div className={styles.content_title}>1. Introduction</div>
            <div className={`${styles.content_text} mb-4`}>
              Welcome to Zodot, The ultimate end-to-end business management SaaS
              platform! Whether you're tracking time, managing projects, or
              invoicing clients, Zodot has everything you need. Perfect for
              freelancers, small businesses, and entrepreneurs. Please read
              these Terms of Service (the “Terms”), together with Zodot’s
              Privacy Policy (available at https://zodot.co/privacy-policy)
              (“Privacy Policy”), fully and carefully before accessing or using
              https://zodot.co, the ultimate end-to-end business management SaaS
              platform, or any content, and/or services made available by Zodot
              (all together the “Services” or “business management”). The Terms
              set forth the legally binding terms and conditions governing your
              access to and use of the Services.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              By registering to use and/or using the Services in any manner,
              You, just as if You had done so in writing, (i) acknowledge that
              You have read and understood the Terms, (ii) represent and warrant
              that You are at least eighteen (18) years of age, and if
              applicable, have the authority to enter into these Terms on behalf
              of any person or entity for whom You are accessing or using the
              Services, and (iii) agree to these Terms and all other rules,
              policies, and procedures that may be published by us from time to
              time. You also acknowledge that you have read and understood the
              Privacy Policy. The Services will evolve based on user feedback.
              We may amend these Terms from time to time. You may be required to
              click “accept” or “agree” to show your consent to an amended
              version of these Terms.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              You are solely responsible for ensuring that these Terms comply
              with all laws, rules, and regulations applicable to you. The right
              to access the Services is revoked where these Terms or use of the
              Services is prohibited or to the extent offering, sale, or
              provision of the Services conflicts with any applicable law, rule,
              or regulation.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN
              TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION CLAUSE & CLASS
              ACTION WAIVER SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU
              AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU
              WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR
              CLASS-WIDE ARBITRATION.
            </div>
          </div>
          <div className="content" id="Definitions">
            <div className={styles.content_title}>2. Definitions</div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>“Zodot”,</strong>
              “Zodot”, “we” or “us” means Zodot LLC. and all current and future
              global subsidiaries, affiliates, and/or successors of Zodot LLC.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “Applicable Data Protection Laws”
              </strong>
              means the legislation protecting the fundamental rights and
              freedoms of individuals regarding their right to privacy and
              Processing of Personal Data, including the GDPR and supplementing
              local laws.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “Business Purpose”
              </strong>
              means a use of Personal Data for our or the user’s operational or
              other notified purposes which is necessary and proportionate to
              achieve such purpose, and is compatible with the context in which
              the Personal Data was collected. Where “Business Purposes” are
              specifically defined under applicable law, that definition shall
              apply to these Terms.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “Controller”
              </strong>
              shall mean any party to these Terms which alone or jointly with
              others determines the purposes and means of the Processing of
              Personal Data.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>“Data”</strong>
              means any data and/or information inputted by You or on Your
              behalf into the Zodot Service, including but not limited to data
              and/or information contained in any contracts and documents You
              work on through the Services.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “Data Subject”
              </strong>
              means a natural person who can be identified, whether directly or
              indirectly, including by reference to an identification number,
              location data, online identification, or to one or more factors
              specific to his physical, physiological, mental, economic,
              cultural or social identity (and to the extent that a corporate
              entity receives the same or similar protection as natural persons
              under applicable data protection laws, shall also include such
              corporate entities).
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “EU General Data Protection Regulation” or “GDPR”
              </strong>
              means Regulation (EU) 2016/679 of the European Parliament and of
              the Council of April 27, 2016, on the protection of natural
              persons through the Processing of personal data and the free
              movement of such data;
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “Intellectual Property Right”
              </strong>
              means any patent, trademark, service mark, copyright, moral right,
              right in a design, know-how, and any other intellectual or
              industrial property rights, anywhere in the world whether or not
              registered.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “Personal Data”
              </strong>
              means any information relating to an identified or identifiable
              Data Subject;
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “Processing”
              </strong>
              means any operation or set of operations which is/are performed
              upon Personal Data, whether or not by automatic means, such as
              collection, recording, organization, consultation, use, disclosure
              by transmission, dissemination or otherwise making available,
              alignment or combination, blocking, erasure or destruction.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                “Processor”
              </strong>
              shall mean any party to these Terms which Processes Personal Data
              on behalf of the Controller.
            </div>
          </div>
          <div
            className="content"
            id="Zodot is not a substitute for a lawyer or law firm"
          >
            <div className={styles.content_title}>
              3. Zodot is not a substitute for a lawyer or law firm
            </div>
            <div className={`${styles.content_text} mb-4`}>
              YOU HEREBY ACKNOWLEDGE AND AGREE THAT NO ATTORNEY-CLIENT
              RELATIONSHIP EXISTS, OR WILL EVER BE CREATED, BETWEEN YOU AND
              ZODOT UNDER ANY CIRCUMSTANCES.ZODOT, ITS SERVICES, AND ITS FORMS,
              TEMPLATES, AND OTHER CONTENT ARE NOT A LAW FIRM OR A SUBSTITUTE
              FOR A LAW FIRM. ZODOT CANNOT AND DOES NOT RENDER ANY LEGAL
              SERVICES TO YOU, AND THE SERVICES ARE NOT, AND SHOULD NEVER BE, A
              SUBSTITUTE FOR LEGAL ADVICE OR LEGAL SERVICES. WITHOUT LIMITING
              THE FOREGOING, YOU ACKNOWLEDGE AND AGREE THAT ANY FORMS,
              TEMPLATES, OR CONTENT PROVIDED THROUGH THE SERVICES ARE PRESENTED
              “AS IS” WITHOUT WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND AND DO
              NOT CONSTITUTE LEGAL ADVICE, AND THAT ANY SERVICE THROUGH WHICH
              YOU USE A FORM OR TEMPLATE TO GENERATE AND/OR SEND A CONTRACT OR A
              DEMAND LETTER IS NOT A LEGAL SERVICE. NO INFORMATION PROVIDED
              THROUGH THE SERVICE IS INTENDED TO BE, AND MUST NOT BE TAKEN TO
              BE, FACTUAL OR ACCURATE LEGAL ADVICE. YOUR RELIANCE ON ANY FORM,
              TEMPLATE, OR OTHER CONTENT PROVIDED THROUGH THE SERVICES IS SOLELY
              AT YOUR OWN RISK. YOUR COMMUNICATIONS WITH ZODOT ARE NOT ATTORNEY
              WORK PRODUCT AND ARE NOT PROTECTED UNDER ANY PRIVILEGE, INCLUDING
              ATTORNEY-CLIENT PRIVILEGE.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              YOU HEREBY ACKNOWLEDGE AND AGREE THAT ZODOT IS NOT, AND WILL NOT
              BE, A PARTY TO ANY LEGAL DISPUTE THAT MAY ARISE BETWEEN YOU AND
              ANY OTHER PERSON OR ENTITY TO WHOM YOU MAY HAVE SENT A CONTRACT,
              INVOICE, OR OTHER DOCUMENT THAT YOU MAY HAVE CREATED USING A FORM,
              TEMPLATE, OR OTHER CONTENT PROVIDED BY ZODOT.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              The Services allow You to create Your own documents to memorialize
              certain details between You and Your clients. We do not review or
              edit the documents You create for completeness, grammar, spelling,
              consistency, accuracy, or legal merit. We do not draw legal
              conclusions, provide legal advice, opine on Your legal rights or
              options, or apply the facts You enter to the law. If You need
              legal advice, or if Your back office service needs are substantial
              or complex, we encourage You to hire a lawyer. Our forms and
              templates may not work well for complex agreements. You agree to
              completely and accurately fill out any questionnaires required of
              those documents. You represent and warrant that any document You
              electronically sign via the Zodot Service will be truthful.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              YOU AGREE TO REVIEW AND EDIT FOR YOUR PURPOSES ANY DOCUMENTS YOU
              CREATE USING THE SERVICES AND YOU SHALL BE SOLELY RESPONSIBLE FOR
              THOSE DOCUMENTS. Federal, state, or local law (statutory, common,
              or otherwise), constitution, treaty, convention, ordinance, code,
              rule, regulation, order, injunction, judgment, decree, ruling, or
              other similar requirement enacted, adopted, promulgated, or
              applied by a governmental authority, consumer contracts
              (collectively, “Law”) and publicly available facts about entities
              that issue consumer contracts are voluminous and ever-changing.
              Accordingly, we cannot and do not guarantee that any information
              provided through the Services is current. Law relevant to
              consumers varies depending on the relevant jurisdiction (for
              example, the applicable U.S. state), and our documents are not,
              and cannot be, necessarily customized to Your jurisdiction. Your
              purchase and use of the documents made available through the
              Services for Your purposes is not the practice of the law by us or
              You. Unlike a lawyer’s advice, the documents made available
              through the Services are not customized to Your particular
              circumstances or needs.
            </div>
          </div>
          <div className="content" id="Your obligations and responsibilities">
            <div className={styles.content_title}>
              4. Your obligations and responsibilities
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                General obligations:
              </strong>
              You must only use the Services for Your own lawful internal
              business purposes, by these Terms and any additional or amended
              terms and conditions posted by us and/or noticed to you by us.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Registration:
              </strong>
              You must provide accurate and complete information and keep Your
              account information updated. You shall not: (i) select or use as a
              username a name of another person with the intent to impersonate
              that person; (ii) use as a username a name subject to any rights
              of a person other than You without appropriate authorization; or
              (iii) use, as a username, a name that is otherwise offensive,
              vulgar or obscene. You may never use another person’s user account
              or registration information for the Services without permission.
              You must notify us immediately of any change in Your eligibility
              to use the Services, breach of security, or unauthorized use of
              your account. You should never publish, distribute, or post login
              information for your account. You shall have the ability to delete
              Your account, either directly or through a request made to one of
              our employees or affiliates.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Access conditions:
              </strong>
              You must ensure that all usernames and passwords required to
              access the Services are kept secure and confidential. You must
              immediately notify us of any unauthorized use of Your passwords or
              any other breach of security and we will reset Your password. You
              must take all other actions that we will reasonably deem necessary
              to maintain or enhance the security of our computing systems and
              networks and Your access to the Services.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Prohibited Uses:
              </strong>
              As a condition of use, You promise not to use the Services for any
              purpose that is prohibited by these Terms. You are responsible for
              all of Your activity in connection with the Services. When
              accessing and using the Services, You shall not (and shall not
              permit any third party to) either (i) take any action or (ii)
              upload, download, post, submit, transmit, input, or otherwise
              distribute or facilitate distribution of any Data on or through
              the Services that:
              <ul className="my-3">
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    infringes any patent, trademark, trade secret, copyright,
                    right of publicity, or other right of any other person or
                    entity or violates any law or contractual duty;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    You know is false, misleading, untruthful or inaccurate;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    is offensive, unlawful, threatening, abusive, harassing,
                    defamatory, libelous, deceptive, fraudulent, invasive of
                    another’s privacy, tortious, obscene, vulgar, pornographic,
                    offensive, profane, contains or depicts nudity, contains or
                    depicts sexual activity, or is otherwise inappropriate as
                    determined by us in our sole discretion;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    constitutes unauthorized or unsolicited advertising, junk or
                    bulk e-mail (“spamming”);
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    contains software viruses or any other computer codes,
                    files, or programs that are designed or intended to disrupt,
                    damage, limit, or interfere with the proper function of any
                    software, hardware, or telecommunications equipment or to
                    damage or obtain unauthorized access to any system, data,
                    password or any other of our information or any third party;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    may damage our, or any other person or entity’s, computing
                    devices or software or may in any way which may impair the
                    functionality of our Services or other systems used to
                    deliver the Services or impair the ability of any other user
                    to use the Services;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    constitutes an attempt to modify, copy, adapt, reproduce,
                    disassemble, decompile, or reverse engineer any computer
                    programs used to deliver the Services or to operate the
                    Services except as is strictly necessary to use either of
                    them for normal operation;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    constitutes an attempt to undermine the security or
                    integrity of our computing systems or networks or, where the
                    Services are hosted by a third party, that third party’s
                    computing systems and networks;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    uses any robot, spider, crawler, or other automatic device,
                    process, software or queries that intercepts, “mines,”
                    scrapes, or otherwise accesses the Site to monitor, extract,
                    copy, or collect information or data from or through the
                    Site, or engage in any manual process to do the same;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    removes any copyright notice, identification, or any other
                    proprietary notices;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    constitutes an attempt to gain unauthorized access to any
                    materials other than those to which You have been given
                    express permission to access or to the computer system on
                    which the Services are hosted;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    impersonates any person or entity, including any of our
                    employees or representatives; or
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    includes anyone’s identification documents or sensitive
                    financial information
                  </div>
                </li>
              </ul>
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Usage Limitations:
              </strong>
              Your use of the Services may be subject to limitations, including
              but not limited to monthly transaction volumes and the number of
              calls You are permitted to make against our application
              programming interface. Any such limitations will be specified
              within the applicable Services.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Communication Conditions:
              </strong>
              As a condition of these Terms, if You use any communication tools
              available through the Services (such as any forum, chat room, or
              message center), You agree only to use such communication tools
              for lawful and legitimate purposes. You must not use any such
              communication tool for posting or disseminating any material
              unrelated to the use of the Services, including (but not limited
              to): offers of goods or services for sale, unsolicited commercial
              e-mail, files that may damage any other person’s computing devices
              or software, content that may be offensive to any other users of
              the Services, or material in violation of any law (including
              material that is protected by copyright or trade secrets which You
              do not have the right to use). When You make any communication on
              the Services, You represent that You are permitted to make such
              communication. We are under no obligation to ensure that the
              communications on the Services are legitimate or that they are
              related only to the use of the Services. As with any other mobile-
              or web-based forum, You must exercise caution when using the
              communication tools available on the Services. However, we do
              reserve the right to remove any communication at any time at its
              sole discretion.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>Indemnity:</strong>
              You agree to defend, indemnify, and hold harmless Zodot, its
              affiliates, and their employees, contractors, directors,
              suppliers, and representatives from all liabilities, claims, and
              expenses, including reasonable attorneys’ fees, arising from your
              use or misuse of the Services, your violation of the Terms, an
              infringement by you or others using your account, and any breach
              of your obligations to Zodot. We reserve the right to assume the
              exclusive defense of any matter subject to indemnification, and
              you agree to cooperate with us in such cases.
            </div>
          </div>
          <div className="content" id="Data protection">
            <div className={styles.content_title}>5. Data protection</div>
            <div className={`${styles.content_text} mb-4`}>
              Zodot shall process Personal Data as Controller under Applicable
              Data Protection Laws. To learn more about how we use Personal
              Data, please refer to our Privacy Policy, available through the
              link mentioned at the beginning of these Terms. To the extent that
              the Personal Data includes Data Subjects other than the user, the
              user undertakes to inform such Data Subjects of our external
              Privacy Policy before the sharing of such Personal Data.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              About Service Data, the user shall act as Controller and Zodot as
              Processor to make such Service Data available to the user and
              prepare reports and documents integrating such Service Data. The
              user acknowledges that they are responsible for compliance with
              their obligations as Controller in this respect.
            </div>

            <div className={`${styles.content_text} mb-4`}>
              Concerning the Processing of Service Data, we shall:
              <ul className="my-3">
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Process such Service Data only on behalf of the user for one
                    or more Business Purposes and on documented instructions
                    from the user, including about transfers of Personal Data to
                    a third country, unless permitted or required to do so by
                    Applicable Data Protection Laws or other European Union,
                    Member State, or other law to which Zodot is subject; in the
                    event of a conflicting legal requirement, Zodot shall inform
                    the user of that legal requirement before Processing, unless
                    that law prohibits such information on important grounds of
                    public interest;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Not sell Service Data, nor retain, use, or disclose Service
                    Data for any purpose other than the Business Purposes
                    outlined in these Terms or other applicable agreements with
                    the user;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Only transfer Service Data to countries outside of the
                    European Economic Area and Israel if it has provided
                    appropriate safeguards as required by Applicable Data
                    Protection Laws;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Ensure that persons authorized to Process such Service Data
                    have committed themselves to confidentiality or are under an
                    appropriate statutory obligation of confidentiality;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Implement appropriate technical and organizational measures
                    to ensure a level of security appropriate to the risk,
                    taking into account, in particular, the risks that are
                    presented by Processing, in particular from accidental or
                    unlawful destruction, loss, alteration, unauthorized
                    disclosure of, or access to Service Data transmitted,
                    stored, or otherwise Processed, and ensure that any natural
                    person acting under the authority of Zodot who has access to
                    Service Data does not process them except on instructions
                    from the user unless he or she is required to do so by Union
                    or Member State law;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Respect the following conditions for engaging another
                    sub-processor:
                    <ul className="my-3">
                      <li>
                        <div className={`${styles.content_text} mb-2`}>
                          We shall be authorized to engage other sub-processors,
                          subject to informing the user of the intended
                          change(s) concerning the addition or replacement of
                          sub-processors, thereby allowing the user to object to
                          such changes;
                        </div>
                      </li>
                      <li>
                        <div className={`${styles.content_text} mb-2`}>
                          Where we engage another sub-processor for carrying out
                          specific Processing activities on behalf of the user,
                          we shall impose on that other processor the same data
                          protection obligations as set out in this clause, by
                          way of a contract or other legal act under Union or
                          Member State law. Where that other sub-processor fails
                          to fulfill its data protection obligations, we shall
                          remain fully liable to the user for the performance of
                          that other processor’s obligations.
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Taking into account the nature of the Processing, assist the
                    user by appropriate technical and organizational measures,
                    insofar as this is possible, for the fulfillment of the
                    user's obligation to respond to requests for exercising the
                    Data Subject’s rights under Applicable Data Protection Laws;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Taking into account the nature of Processing and the
                    information available to Zodot, assist the user in ensuring
                    compliance with the user's obligations under Applicable Data
                    Protection Laws regarding the security of processing, to the
                    notification of any breach of Personal Data to supervisory
                    authorities and Data Subjects where relevant, to the
                    carrying out of data protection impact assessments where
                    required, and to prior consultation of the supervisory
                    authority;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    At the choice of the user, delete or return all such Service
                    Data to the user after the end of the provision of the Zodot
                    Service, and delete existing copies unless Applicable Data
                    Protection Laws require storage of the Service Data;
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Make available to the user all information necessary to
                    demonstrate compliance with these obligations set out in
                    this clause and allow for and assist with audits, including
                    inspections, conducted by the user or another auditor
                    mandated by the user. Such audits may only take place at
                    most once per year and during working hours, subject to a
                    minimum of two weeks’ prior notice. The user undertakes to
                    carry out such an audit through a qualified third-party
                    independent auditor selected by the user and obliged in
                    writing to maintain confidentiality towards us unless this
                    third-party independent auditor is subject to the general
                    statutory duty of professional secrecy.
                  </div>
                </li>
              </ul>
            </div>

            <div className={`${styles.content_text} mb-4`}>
              For the avoidance of doubt, concerning the Processing of Service
              Data, we shall be entitled to a reasonable fee from the user for
              any assistance described herein, and we shall not be liable to the
              user for any breach of Applicable Data Protection Laws that is
              attributable in whole or in part to the user or the user's
              instructions.
            </div>
          </div>
          <div className="content" id="Intellectual property">
            <div className={styles.content_title}>6. Intellectual property</div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>General:</strong>
              Except as expressly provided herein, Zodot retains all
              Intellectual Property Rights relating to the Services. Any
              suggestions, ideas, enhancement requests, feedback, or other
              information provided by you relating to the Services ("Feedback")
              shall be assigned to us. We may use or disseminate Feedback
              freely. You will not copy, distribute, reproduce, or use any of
              our Intellectual Property except as permitted under these Terms.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Ownership of Data:
              </strong>
              You retain all Intellectual Property Rights in your data. You
              grant us a license to use, copy, transmit, store, and back up your
              data to enable your access and use of the Services and for any
              other purpose related to providing the Services to you. You also
              grant us the right to use the data anonymously and in aggregate to
              improve the Services and provide them to other customers.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Backup of Data:
              </strong>
              You must maintain copies of all data inputted into the Services.
              While we adhere to industry-standard policies and procedures to
              prevent data loss, we do not guarantee that there will be no loss
              of data. We expressly exclude liability for any loss of data,
              regardless of cause.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Third-Party Applications and Your Data:
              </strong>
              The Services may permit you to link to certain third-party
              websites, services, or resources ("Third-Party Applications"). If
              you choose to enable Third-Party Applications for use with the
              Services, you do so at your own risk. These Third-Party
              Applications are not under our control. We may allow providers of
              Third-Party Applications to access your data as required for their
              interoperation with the Services. We are not responsible for any
              damage or loss caused by or related to the use of or reliance on
              any Third-Party Application, or for any disclosure, modification,
              or deletion of your data by any Third-Party Applications. We urge
              you to read the privacy policies of any Third-Party Applications
              before enabling their use with the Services.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Third-Party Content:
              </strong>
              Content from other users, suppliers, advertisers, and other third
              parties, including Third-Party Applications, may be made available
              to you through the Services. Since Zodot does not control such
              content, we are not responsible for it. Zodot does not guarantee
              the accuracy, currency, suitability, or quality of such content
              and assumes no responsibility for unintended, offensive, indecent,
              objectionable, inaccurate, misleading, or unlawful content made
              available by third parties. By using the Services, you may be
              exposed to third-party websites and content. Zodot makes no
              warranty, representation, endorsement, or guarantee regarding the
              quality, content, nature, or reliability of Third-Party
              Applications accessible through the Services. These links are
              provided for your convenience only, and the inclusion of links
              does not imply endorsement or association. The Services may
              contain links to websites operated by Zodot under different terms.
              It is your responsibility to review the privacy policies and terms
              of service of any other website or mobile application you visit.
              YOU AGREE THAT IN NO EVENT WILL ZODOT BE LIABLE TO YOU IN
              CONNECTION WITH ANY WEBSITES, CONTENT, MATERIALS, OR PRACTICES OF
              ANY THIRD PARTY.
            </div>
          </div>
          <div className="content" id="Warranties and acknowledgements">
            <div className={styles.content_title}>
              7. Warranties and acknowledgements
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>Authority:</strong>
              You warrant that if you have registered to use the Services on
              behalf of another person or entity, you have the authority to
              agree to these Terms on their behalf and to bind them to perform
              any obligations under these Terms, without limiting your
              obligations.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Acknowledgment:
              </strong>
              You acknowledge that:
              <ul className="my-3">
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    You are authorized to access the processed information and
                    Data available through your use of our Services (whether
                    that information and Data is your own or that of someone
                    else).
                  </div>
                </li>

                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    We have no responsibility to any person other than you, and
                    nothing in this Agreement confers a benefit on any person
                    other than you. If you use our Services on behalf of or for
                    the benefit of anyone other than yourself, you acknowledge
                    and agree that:
                    <ul className="my-3">
                      <li>
                        <div className={`${styles.content_text} mb-2`}>
                          You are responsible for ensuring that you have the
                          right to do so and authorizing any person or entity
                          given access to Data.
                        </div>
                      </li>
                      <li>
                        <div className={`${styles.content_text} mb-2`}>
                          We have no obligation to provide any person or entity
                          access to such information or Data without your
                          authorization and may refer any requests for
                          information to you.
                        </div>
                      </li>
                      <li>
                        <div className={`${styles.content_text} mb-2`}>
                          You will indemnify us against any claims or loss
                          relating to:
                          <ul className="my-3">
                            <li>
                              <div className={`${styles.content_text} mb-2`}>
                                Our refusal to provide any person access to your
                                information or Data per these Terms,
                              </div>
                            </li>
                            <li>
                              <div className={`${styles.content_text} mb-2`}>
                                Our provision of information or Data to any
                                person with your authorization.
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Service Interruptions:
              </strong>
              We do not warrant that use of the Services will be uninterrupted
              or error-free. The operation and availability of the systems used
              for accessing the Service, including public telephone services,
              computer networks, and the Internet, can be unpredictable and may
              interfere with or prevent access to the Services. We are not
              responsible for any such interference or prevention of your access
              or use of the Services.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Non-Accounting Service:
              </strong>
              We are not your accountant, and use of the Services does not
              constitute receipt of accounting advice. If you have any
              accounting questions, please contact an accountant.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Suitability and Compliance:
              </strong>
              It is your sole responsibility to determine that the Services meet
              the needs of your business and are suitable for the purposes for
              which they are used. You remain solely responsible for complying
              with all applicable accounting, tax, and other laws. It is your
              responsibility to ensure that the storage and access to your Data
              via the Services comply with laws applicable to you (including
              laws requiring you to retain records).
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Consumer Guarantees:
              </strong>
              You warrant and represent that you are acquiring the right to
              access and use the Services for business purposes and that, to the
              maximum extent permitted by law, any statutory consumer guarantees
              or legislation intended to protect non-business consumers do not
              apply to the supply of the Services or these Terms.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Warranty Disclaimer:
              </strong>
              THE SERVICES PROVIDED BY ZODOT ARE PROVIDED “AS IS”, “AS
              AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTIES,
              REPRESENTATIONS, OR CONDITIONS OF ANY KIND. ZODOT HEREBY DISCLAIMS
              ALL EXPRESS, IMPLIED, OR STATUTORY WARRANTIES, REPRESENTATIONS, OR
              CONDITIONS, WHETHER WRITTEN OR ORAL, INCLUDING ANY IMPLIED
              WARRANTIES OF MERCHANTABILITY, TITLE, NONINFRINGEMENT, SECURITY,
              RELIABILITY, COMPLETENESS, QUIET ENJOYMENT, ACCURACY, QUALITY,
              INTEGRATION, OR FITNESS FOR A PARTICULAR PURPOSE. ZODOT DOES NOT
              WARRANT THAT THE SERVICES WILL OPERATE WITHOUT INTERRUPTION OR BE
              ERROR-FREE. CERTAIN STATE LAWS MAY NOT ALLOW LIMITATIONS ON
              IMPLIED WARRANTIES; IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
              ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO
              YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </div>
          </div>
          <div className="content" id="Limitation of liability">
            <div className={styles.content_title}>
              8. Limitation of liability
            </div>
            <div className={`${styles.content_text} mb-4`}>
              To the fullest extent permitted by applicable law, Zodot excludes
              all liability and responsibility to you (or any other person) in
              contract, tort (including negligence), strict liability, or any
              other legal or equitable theory concerning the services, for any:
              <ul className={`${styles.numbered_list} my-3`}>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Loss, including loss of information, data, cost of
                    procurement of substitute goods or services, profits, and
                    savings.
                  </div>
                </li>

                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Bugs, viruses, Trojan horses, or the like (regardless of the
                    source of origination).
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Special, indirect, incidental, punitive, compensatory, or
                    consequential damages resulting from any use of, or reliance
                    on, the services.
                  </div>
                </li>
              </ul>
            </div>
            <div className={`${styles.content_text} mb-4`}>
              Certain state laws may not allow for the limitation or exclusion
              of liability or for the limitation or exclusion of certain
              damages. If these laws apply to you, some or all of the above
              disclaimers, exclusions, or limitations may not apply to you. In
              such cases, Zodot's liability will be limited to the fullest
              extent permitted by applicable law. Nothing in these terms shall
              affect any non-waivable statutory rights that apply to you.
            </div>
          </div>
          <div className="content" id="Termination">
            <div className={styles.content_title}>9. Termination</div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Closing your Zodot account:
              </strong>
              You may close your Zodot account at any time.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Termination by Zodot:
              </strong>
              We reserve the right to suspend or permanently disable accounts
              due to a breach of these Terms of Service or due to any illegal,
              fraudulent, or inappropriate use of the Services. Users with
              disabled accounts will not be able to use the Services. Users who
              have violated our Terms of Service and had their account disabled
              may contact our Customer Support team for more information
              surrounding the violation and the status of the account.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Accrued Rights:
              </strong>
              Termination of this Agreement is without prejudice to any rights
              and obligations of the parties accrued up to and including the
              date of termination. Upon termination of this Agreement, you will:
              <ul className={`my-3`}>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Remain liable for any accrued charges and amounts that
                    become due for payment before or after termination.
                  </div>
                </li>

                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Remain obligated to refrain from any actions prohibited by
                    the Terms that survive termination.
                  </div>
                </li>
                <li>
                  <div className={`${styles.content_text} mb-2`}>
                    Immediately cease to use our Services.
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="content" id="Help desk">
            <div className={styles.content_title}>10. Help desk</div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Technical Problems:
              </strong>
              Technical Problems: If you encounter technical problems, please
              check the support provided on Zodot's website or email us at
              <a
                className={`${styles.email} ms-1`}
                href="mailto:hello@zodot.co"
              >
                hello@zodot.co
              </a>
              .
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Service Availability:
              </strong>
              We aim to have the Services available from 10:00 am to 3:00 am EST
              during business days. However, there may be occasions when the
              Services are unavailable for maintenance or other development
              activities. If we have to interrupt the Services for longer
              periods than expected, we will make reasonable efforts to notify
              you in advance of such activity.
            </div>
          </div>
          <div
            className="content"
            id="Arbitration clause & class action waiver"
          >
            <div className={styles.content_title}>
              11. Arbitration clause & class action waiver
            </div>
            <div className={`${styles.content_text} mb-4`}>
              You agree that any disputes between you and us (whether or not
              involving a third party) regarding your relationship with us,
              including disputes related to these Terms of Use, your use of the
              Services, and/or rights of privacy and/or publicity, will be
              resolved by binding, individual arbitration under the streamlined
              arbitration rules and procedures of Judicial Arbitration and
              Mediation Services, Inc. (“JAMS”), by one commercial arbitrator
              with substantial experience in resolving intellectual property and
              commercial contract disputes. The arbitrator will be selected from
              the appropriate list of JAMS arbitrators by such rules, and the
              judgment upon the award rendered by the arbitrator may be entered
              in any court of competent jurisdiction.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              Despite the obligation to arbitrate disputes, each party retains
              the right to seek injunctive or other equitable relief from any
              court of competent jurisdiction. Discovery and rights to appeal in
              arbitration are generally more limited than in a lawsuit, and
              other rights that you and we would have in court may not be
              available in arbitration.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              As an alternative, you may bring your claim in your local “small
              claims” court, if permitted by that small claims court’s rules and
              within its jurisdiction, unless the action is transferred,
              removed, or appealed to a different court. Claims must be brought
              only on your behalf, and neither you nor Zodot will participate in
              a class action or class-wide arbitration for any claims covered by
              this agreement to arbitrate. You are giving up your right to
              participate as a class representative or class member in any class
              claim against us, including any right to class arbitration or any
              consolidation of individual arbitrations.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              You also agree not to participate in claims brought in a private
              attorney general or representative capacity, or consolidated
              claims involving another person’s account, if we are a party to
              the proceeding. The arbitrator will not have the authority to
              award damages, remedies, or awards that conflict with these Terms.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              You agree that any claim or cause of action arising out of,
              related to, or connected with the use of the Services or these
              Terms must be filed within one year after such claim or action
              arose, or be forever banned. If the prohibition against class
              actions and other claims brought on behalf of third parties
              contained above is found to be unenforceable, then all of the
              preceding language in this Arbitration Clause & Class Action
              Waiver section will be null and void. This arbitration agreement
              will survive the termination of your relationship with us.
            </div>
          </div>
          <div className="content" id="General">
            <div className={styles.content_title}>12. General</div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Entire Agreement:
              </strong>
              These Terms, which incorporate by reference the Privacy Policy and
              any additional or amended terms and conditions applicable to the
              Services, constitute the entire agreement between you and us
              regarding the Services, and supersede all prior or contemporaneous
              communications and proposals (whether oral, written, or
              electronic) between you and us regarding the Services.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Modification:
              </strong>
              We reserve the right, at our sole discretion, to modify or replace
              any of these Terms, or change, suspend, or discontinue the
              Services at any time by posting a notice on Zodot's website or by
              sending you to notice through the Services, via email, or by
              another appropriate means of electronic communication. You are
              responsible for checking these Terms periodically for changes. If
              you disagree with any changes we make, you can close your account
              at any time according to these Terms. Otherwise, you agree to be
              bound by the updated terms, which will apply to your continued use
              of the Services.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Force Majeure:
              </strong>
              We shall not be liable for any failure to perform our obligations
              under these Terms where such failure results from any cause beyond
              our reasonable control, including, without limitation, mechanical,
              electronic, or communications failure or degradation.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Assignment:
              </strong>
              These Terms are personal to you, and you may not assign, transfer,
              or sublicense your license and/or rights under these Terms without
              our written consent. We may assign, transfer, or delegate any of
              our rights and obligations under these Terms without consent.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>Agency:</strong>
              No agency, partnership, joint venture, or employment relationship
              is created as a result of these Terms.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>No Waiver:</strong>
              Our failure to enforce any part of these Terms shall not
              constitute a waiver of our right to later enforce that or any
              other part of these Terms. Waiver of compliance in any particular
              instance does not mean that we will waive compliance in the
              future. For any waiver of compliance with these Terms to be
              binding, we must provide you with written notice of such waiver
              through one of our authorized representatives.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>Headings: </strong>
              The section and paragraph headings in these Terms are for
              convenience only and shall not affect their interpretation.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Governing Law and Jurisdiction:
              </strong>
              These Terms shall be governed by and construed under the laws of
              the State of New York, including its conflict of law rules, and
              the laws of the United States. You agree that any dispute arising
              from or relating to the subject matter of these Terms shall be
              governed by the exclusive jurisdiction and venue of the state and
              federal courts in New York County, New York.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>
                Severability:
              </strong>
              If any provision of these Terms is found to be unenforceable or
              invalid, that provision will be limited or eliminated to the
              minimum extent necessary so that these Terms will otherwise remain
              in full force and effect and enforceable. The failure of either
              party to exercise in any respect any right provided for herein
              shall not be deemed a waiver of any further rights hereunder.
            </div>
            <div className={`${styles.content_text} mb-4`}>
              <strong className={styles.content_text_strong}>Notices:</strong>
              Any notice given under these Terms by either party to the other
              must be in writing by email and will be deemed to have been given
              on transmission. Notices to us must be sent to
              <a
                className={`${styles.email} mx-1`}
                href="mailto:hello@zodot.co"
              >
                hello@zodot.co
              </a>
              or any other email address notified by email to you by us. Notices
              to you will be sent to the email address that you provided when
              setting up your access to the Services.
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        className={styles.up_arrow}
        style={{ display: isVisible ? "block" : "none" }}
        onClick={scrollToTop}
      >
        <UpButton />
      </div>
    </div>
  );
};

export default TermsOfservices;
