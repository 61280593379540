import React from "react";
import { Link } from "react-router-dom";

import TextStyles from "../../../../../Constants/TextStyles";

import getStartedImg from "../../../../../Assets/frontendImages/faq-get-started.webp";
import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";
import Screenshot from "../../../../UIs/Screenshot";

const GetStartedLink = styled(Link)`
  display: block;
  width: max-content;
  text-decoration: none;
  color: ${Colors.primary};
`;

function HowToGetStarted() {
  return (
    <>
      <div>
        <TextStyles.FontSize24px className="fw-medium mb-3">
          How to Get Started
        </TextStyles.FontSize24px>

        <TextStyles.FontSize16px className="mb-3">
          Zodot makes it easy for freelancers and small business owners to
          manage their business efficiently.
        </TextStyles.FontSize16px>

        <TextStyles.FontSize16px className="mb-3">
          When you first sign up or log in to Zodot, you will see a setup screen
          prompting you to complete your account setup. Follow these simple
          steps to complete your account setup -
        </TextStyles.FontSize16px>

        <div className="mb-3">
          <GetStartedLink to="/help-center/get-started/add-your-user-information">
            1. Add Your User Information
          </GetStartedLink>

          <GetStartedLink to="/help-center/get-started/add-your-business-info">
            2. Add Your Business Info
          </GetStartedLink>

          <GetStartedLink to="/help-center/get-started/configure-your-preferred-currency">
            3. Configure Your Preferred Currency
          </GetStartedLink>

          <GetStartedLink to="/help-center/get-started/set-up-your-brand">
            4. Setup Your Brand
          </GetStartedLink>
        </div>

        <Screenshot src={getStartedImg} alt="how-to-get-started" />

        <TextStyles.FontSize16px>
          After setting up your Zodot account, you can efficiently manage
          clients, projects, invoices, and time tracking which helps you to
          improve your productivity.
        </TextStyles.FontSize16px>
      </div>

      <div></div>
    </>
  );
}

export default HowToGetStarted;
