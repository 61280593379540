import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import { getFileFromUrl } from "../../../../../Commons/utils";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import fileImage from "../../../../../../../Assets/assetsnew/file-add-symbol-new.svg";

const File = ({ data }) => {
  const DownloadForOfflineOutlined = (item) => {
    let filename = item.file_name;
    let indexofUnderscore = filename.indexOf("_");
    let file_name = filename.substring(indexofUnderscore + 1);
    getFileFromUrl(item.file_url, file_name).then((res) => {
      const href = URL.createObjectURL(res);
      const aElement = document.createElement("a");
      aElement.href = href;
      aElement.setAttribute("download", file_name);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
  };

  return (
    <Styled.ReviewBox className="mt-4">
      <div className="d-flex align-items-center gap-3 mb-4">
        <img src={fileImage} alt="fileImage" style={{ maxWidth: "2.2rem" }} />

        <TextStyles.FontSize18px className="fw-medium">
          File
        </TextStyles.FontSize18px>
      </div>

      <TextStyles.FontSize14px className="mb-3">
        <div className="mb-2 fw-medium">File title</div>
        <Styled.Text>{data.file_title}</Styled.Text>
      </TextStyles.FontSize14px>

      <TextStyles.FontSize14px>
        <div className="mb-2 fw-medium">
          Attached {data.files.length > 1 ? "files" : "file"}
        </div>

        {data && data.files && (
        <div className="d-flex flex-column gap-2">
          {data.files.map((item, index) => {
            let filename = item.file_name;
            let indexofUnderscore = filename.indexOf("_");
            let file_name = filename.substring(indexofUnderscore + 1);
            return (
              <Styled.FileDownloadContainer
                className="d-flex align-items-center m-0"
                key={index}
                onClick={() => DownloadForOfflineOutlined(item)}
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  <InsertDriveFileOutlinedIcon className="pe-2" />
                  {file_name} - {item.size / 1000000} MB
                </span>
              </Styled.FileDownloadContainer>
            );
          })}
        </div>
        )}
      </TextStyles.FontSize14px>
    </Styled.ReviewBox>
  );
};

export default File;
