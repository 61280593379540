import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LandingPage from "./LandingPage/LandingPage.jsx";
import Features from "./Features/Features.jsx";
import Services from "./Services/Services.jsx";
import Footer from "../Footer/Footer.jsx";
import Testinomials from "./Testinomials/Testinomials.jsx";
import Workload from "./Workload/Workload.jsx";
import CTAOne from "./CTAOne/CTAOne.jsx";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const history = useHistory();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      history.push("/db/home");
    }
  }, []);

  const isProduction =
    process.env.REACT_APP_PAGE_INDEXING_MODE === "production";

  return (
    <div>
      <Helmet>
        <title>Streamline Your Freelance Work with Zodot For Free</title>
        <meta
          content="Streamline your freelance operations with Zodot: a free cloud-based tool for easy invoicing, time tracking, and project management."
          name="description"
        />
        {isProduction ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://zodot.co" />
      </Helmet>
      <LandingPage />
      <Features />
      <CTAOne />
      <Services />
      <Testinomials />
      <Workload />
      <Footer />
    </div>
  );
};

export default HomePage;
