import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import tableBtn from "../../../../../../../Assets/assetsnew/table-image-new.svg";

const Table = ({ data }) => {
  return (
    <Styled.ReviewBox className="mt-4">
      <div className="d-flex align-items-center gap-3 mb-4">
        <img
          src={tableBtn}
          alt="tableImageBtn"
          style={{ maxWidth: "2.2rem" }}
        />

        <TextStyles.FontSize18px className="fw-medium">
          Table
        </TextStyles.FontSize18px>
      </div>

      <TextStyles.FontSize14px>
        <div className="mb-2 fw-medium">Table title</div>
        <Styled.Text className="mb-3">
          {data.table_title}
        </Styled.Text>

        <div className="mb-2 fw-medium">Table description</div>
        {data.input_array && (
          <div className="d-flex flex-column gap-3">
            {data.input_array.map((item, index) => (
              <div
                className="d-flex flex-wrap gap-3 flex-md-nowrap"
                key={index}
              >
                <Styled.Text className="w-100">{item.input_one}</Styled.Text>
                <Styled.Text className="w-100">{item.input_two}</Styled.Text>
              </div>
            ))}
          </div>
        )}
      </TextStyles.FontSize14px>
    </Styled.ReviewBox>
  );
};

export default Table;
