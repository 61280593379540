import React from "react";
import { useForm, Controller } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";

import LoaderSpin from "../../Commons/LoaderSpin";

import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
  createClient,
  fetchClientsForOption,
} from "../../../../store/service/clientService";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import SelectCountry from "../../Commons/SelectCountry";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../Commons/utils";

export default function createClinets({
  show,
  handleClose,
  setUpdateRefresh,
  updateRefresh,
  setSelectedClient,
  section,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    reset,
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const isLoading = useSelector((state) => state.clients.isLoading);

  const formSubmit = async (data) => {
    const Obj = {
      contact_email: data.email,
      company_name: data.company,
      contact_name: data.name,
      street: data.street,
      suite: data.suite,
      city: data.city,
      zip_code: data.zipcode,
      country: data.country ? data.country.name : null,
      state: data.state,
    };
    dispatch(createClient(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        if (setUpdateRefresh) {
          setUpdateRefresh(!updateRefresh);
        }
        if (setSelectedClient) {
          dispatch(fetchClientsForOption());
          setSelectedClient({
            _id: data.payload.data._id,
            label: data.payload.data.company_name,
          });
        }

        if (section === "navbar") {
          if (history.location.pathname !== "/db/clients") {
            history.push("/db/clients");
          }
        }
        handleClose();
        message.success(`${data.payload.message}`);
        reset();
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeFullscreenModal"
    >
      <div className="modal-header d-flex justify-content-center">
        <TextStyles.FontSize14px
          style={{
            position: "absolute",
            left: "1rem",
            top: "1.25rem",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <ClearIcon
            sx={{
              fontSize: 30,
              color: Colors.font2,
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </TextStyles.FontSize14px>
        <TextStyles.FontSize20px
          className="d-flex justify-content-center"
          style={{ fontWeight: 500, fontSize: "1.5rem" }}
        >
          Create client
        </TextStyles.FontSize20px>
      </div>
      <div className="modal-body d-flex justify-content-center">
        <div style={{ maxWidth: 600, width: "100%" }}>
          <form onSubmit={handleSubmit(formSubmit)}>
            <TextStyles.FontSize14px className="my-3">
              <label htmlFor="compnay_name" className="mb-2">
                Company name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.company}
                id="company_name"
                type="text"
                placeholder="Company name"
                name="company"
                {...register("company", {
                  required: "Company name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Company name is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.company && (
                <TextStyles.InValidFeedback>
                  {errors.company.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="contact_name" className="mb-2">
                Contact name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.name}
                id="contact_name"
                type="text"
                placeholder="Contact name"
                name="name"
                {...register("name", {
                  required: "Contact name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Contact name is required.";
                    }
                    return true;
                  },
                })}
              />
              {errors.name && (
                <TextStyles.InValidFeedback>
                  {errors.name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="contact_email" className="mb-2">
                Contact email
              </label>
              <TextStyles.InputRectangle
                invalid={errors.email}
                id="contact_email"
                type="email"
                placeholder="Contact email"
                name="email"
                {...register("email", {
                  required: "Email is required.",
                  validate: validateEmail,
                  onBlur: (e) =>
                    setValue("email", e.target.value.trim().toLowerCase()),
                })}
              />
              {errors.email && (
                <TextStyles.InValidFeedback>
                  {errors.email.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="street" className="mb-2">
                    Street
                  </label>
                  <TextStyles.InputRectangle
                    id="street"
                    type="text"
                    placeholder="Street"
                    name="street"
                    {...register("street")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="suite" className="mb-2">
                    Suite
                  </label>
                  <TextStyles.InputRectangle
                    id="suite"
                    type="text"
                    placeholder="Suite"
                    name="suite"
                    {...register("suite")}
                  />
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="city" className="mb-2">
                    City
                  </label>
                  <TextStyles.InputRectangle
                    id="city"
                    type="text"
                    placeholder="City"
                    name="city"
                    {...register("city")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="zipcode" className="mb-2">
                    Zip code
                  </label>
                  <TextStyles.InputRectangle
                    id="zipcode"
                    type="text"
                    placeholder="Zip code"
                    name="zipcode"
                    {...register("zipcode")}
                  />
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="state" className="mb-2">
                    State
                  </label>
                  <TextStyles.InputRectangle
                    id="state"
                    type="text"
                    placeholder="State"
                    name="state"
                    {...register("state")}
                  />
                </TextStyles.FontSize14px>
              </div>
              <div className="col-12 col-sm-6">
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="country" className="mb-2">
                    Country
                  </label>
                  <div>
                    <Controller
                      control={control}
                      id="country"
                      name="country"
                      render={({ field: { onChange, value } }) => (
                        <SelectCountry
                          placeholder="Select country"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="pb-4 pt-2">
              <TextStyles.Button disabled={isLoading}>
                {isLoading ? <LoaderSpin /> : "Create client"}
              </TextStyles.Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
