import { postRequest, getRequest } from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchNewlyUsers = createAsyncThunk(
  "admin/fetchNewlyUsers",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_NEWLY_USERS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchUsersCount = createAsyncThunk(
  "admin/fetchUsersCount",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_USERS_COUNTS.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchUsersByUserType = createAsyncThunk(
  "admin/fetchUsersByUserType",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_USERS_BY_USERTYPE.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchUsersByCountry = createAsyncThunk(
  "admin/fetchUsersByCountry",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.FETCH_USERS_BY_COUNTRY.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchUsersData = createAsyncThunk(
  "admin/fetchUsersData",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.FETCH_USERS_DATA.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchUsers = createAsyncThunk("admin/users", async (data) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    postRequest(URL.FETCH_USERS.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const fetchDeletedUsers = createAsyncThunk(
  "admin/deletedUsers",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.FETCH_DELETED_USERS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteDeletedUser = createAsyncThunk(
  "admin/deleteDeletedUser",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.DELETE_DELETED_USER.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const downloadUsers = createAsyncThunk(
  "admin/download_users",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.DOWNLOAD_USERS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const downloadNewlyUsers = createAsyncThunk(
  "admin/download_newly_users",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.DOWNLOAD_NEWLY_USERS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
