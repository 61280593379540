import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import { useForm, Controller } from "react-hook-form";

import { updateBillingSchedulePC } from "../../../../../store/service/proposalAndContractService";

import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import dollorBrand from "../../../../../Assets/assetsnew/close-dollor-brand.svg";
import dollorWhite from "../../../../../Assets/assetsnew/close-dollor-white.svg";
import repeatBtn from "../../../../../Assets/assetsnew/repeat-deposit.svg";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import Styled from "./BillingSchedulesStyles";
import CustomDatePickerOne from "../../../Commons/DatePickers/CustomDatePickerOne";
import SelectEndInvoiceType from "../../../Commons/SelectInvoiceType";
import moment from "moment";

const EditBillingSchedule = ({ selectedData, show, handleClose }) => {
  const disptach = useDispatch();
  const billing_scheduled = selectedData && selectedData.billing_scheduled;
  const [selector, setSelector] = useState(
    billing_scheduled && billing_scheduled.invoice_frequency
  );
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const { register, reset, watch, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      billingScheduled: {
        deposit: {
          require_deposit:
            billing_scheduled &&
            billing_scheduled.deposit &&
            billing_scheduled.deposit.require_deposit,
          value:
            billing_scheduled &&
            billing_scheduled.deposit &&
            billing_scheduled.deposit.value,
        },
        issue_invoice:
          billing_scheduled &&
          billing_scheduled.issue_invoice &&
          moment(billing_scheduled.issue_invoice).toDate(),
        weekly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.start_date &&
            moment(billing_scheduled.weekly_invoice.start_date).toDate(),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.weekly_invoice &&
            billing_scheduled.weekly_invoice.end_date &&
            moment(billing_scheduled.weekly_invoice.end_date).toDate(),
        },
        biWeekly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.start_date &&
            moment(billing_scheduled.biWeekly_invoice.start_date).toDate(),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.biWeekly_invoice &&
            billing_scheduled.biWeekly_invoice.end_date &&
            moment(billing_scheduled.biWeekly_invoice.end_date).toDate(),
        },
        monthly_invoice: {
          start_date:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.start_date &&
            moment(billing_scheduled.monthly_invoice.start_date).toDate(),
          invoices_type:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.invoices_type,
          num_invoices:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.num_invoices,
          end_date:
            billing_scheduled &&
            billing_scheduled.monthly_invoice &&
            billing_scheduled.monthly_invoice.end_date &&
            moment(billing_scheduled.monthly_invoice.end_date).toDate(),
        },
      },
    },
  });

  useEffect(() => {
    setValue("billingScheduled.invoice_frequency", selector);
  }, [selector]);

  const selectDeposit = watch("billingScheduled.deposit.require_deposit");

  useEffect(() => {
    if (selectDeposit) {
      setValue(
        "billingScheduled.deposit.value",
        billing_scheduled &&
          billing_scheduled.deposit &&
          billing_scheduled.deposit.value
      );
    } else {
      setValue("billingScheduled.deposit.value", undefined);
    }
  }, [selectDeposit]);

  useEffect(() => {
    if (selector === "Once") {
      setValue(
        "billingScheduled.issue_invoice",
        billing_scheduled &&
          billing_scheduled.issue_invoice &&
          moment(billing_scheduled.issue_invoice).toDate()
      );
    } else {
      setValue("billingScheduled.issue_invoice", undefined);
    }
  }, [selector]);

  const invoices_type = watch("billingScheduled.weekly_invoice.invoices_type");

  useEffect(() => {
    if (invoices_type === "On") {
      setValue("billingScheduled.weekly_invoice.num_invoices", undefined);
    } else if (invoices_type === "After") {
      setValue("billingScheduled.weekly_invoice.end_date", undefined);
    }
  }, [invoices_type]);

  useEffect(() => {
    if (selector === "Weekly") {
      setValue(
        "billingScheduled.weekly_invoice.start_date",
        billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.start_date &&
          moment(billing_scheduled.weekly_invoice.start_date).toDate()
      );
      setValue(
        "billingScheduled.weekly_invoice.invoices_type",
        billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.invoices_type
      );
      setValue(
        "billingScheduled.weekly_invoice.num_invoices",
        billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.num_invoices
      );
      setValue(
        "billingScheduled.weekly_invoice.end_date",
        billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.end_date &&
          moment(billing_scheduled.weekly_invoice.end_date).toDate()
      );
    } else {
      setValue("billingScheduled.weekly_invoice.start_date", undefined);
      setValue("billingScheduled.weekly_invoice.end_date", undefined);
      setValue("billingScheduled.weekly_invoice.invoices_type", "Never");
      setValue("billingScheduled.weekly_invoice.num_invoices", undefined);
    }
  }, [selector]);

  const invoices_type1 = watch(
    "billingScheduled.biWeekly_invoice.invoices_type"
  );

  useEffect(() => {
    if (invoices_type1 === "On") {
      setValue("billingScheduled.biWeekly_invoice.num_invoices", undefined);
    } else if (invoices_type === "After") {
      setValue("billingScheduled.biWeekly_invoice.end_date", undefined);
    }
  }, [invoices_type1]);

  useEffect(() => {
    if (selector === "Bi-weekly") {
      setValue(
        "billingScheduled.biWeekly_invoice.start_date",
        billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.start_date &&
          moment(billing_scheduled.biWeekly_invoice.start_date).toDate()
      );
      setValue(
        "billingScheduled.biWeekly_invoice.invoices_type",
        billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.invoices_type
      );
      setValue(
        "billingScheduled.biWeekly_invoice.num_invoices",
        billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.num_invoices
      );
      setValue(
        "billingScheduled.biWeekly_invoice.end_date",
        billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.end_date &&
          moment(billing_scheduled.biWeekly_invoice.end_date).toDate()
      );
    } else {
      setValue("billingScheduled.biWeekly_invoice.start_date", undefined);
      setValue("billingScheduled.biWeekly_invoice.end_date", undefined);
      setValue("billingScheduled.biWeekly_invoice.invoices_type", "Never");
      setValue("billingScheduled.biWeekly_invoice.num_invoices", undefined);
    }
  }, [selector]);

  const invoices_type2 = watch(
    "billingScheduled.monthly_invoice.invoices_type"
  );

  useEffect(() => {
    if (invoices_type2 === "On") {
      setValue("billingScheduled.monthly_invoice.num_invoices", undefined);
    } else if (invoices_type2 === "After") {
      setValue("billingScheduled.monthly_invoice.end_date", undefined);
    }
  }, [invoices_type2]);

  useEffect(() => {
    if (selector === "Monthly") {
      setValue(
        "billingScheduled.monthly_invoice.start_date",
        billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.start_date &&
          moment(billing_scheduled.monthly_invoice.start_date).toDate()
      );
      setValue(
        "billingScheduled.monthly_invoice.invoices_type",
        billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.invoices_type
      );
      setValue(
        "billingScheduled.monthly_invoice.num_invoices",
        billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.num_invoices
      );
      setValue(
        "billingScheduled.monthly_invoice.end_date",
        billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.end_date &&
          moment(billing_scheduled.monthly_invoice.end_date).toDate()
      );
    } else {
      setValue("billingScheduled.monthly_invoice.start_date", undefined);
      setValue("billingScheduled.monthly_invoice.end_date", undefined);
      setValue("billingScheduled.monthly_invoice.invoices_type", "Never");
      setValue("billingScheduled.monthly_invoice.num_invoices", undefined);
    }
  }, [selector]);

  const formsubmit = (data) => {
    const Obj = {
      pc_id: selectedData._id,
      billing_scheduled: data.billingScheduled,
    };
    disptach(updateBillingSchedulePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight600px"
      fullscreen
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>
            Edit Billing Schedule
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="d-flex flex-column justify-content-center align-items-center pe-3">
              {selectDeposit ? (
                <>
                  <img src={dollorBrand} alt="dollarBtn" />
                  <div
                    style={{
                      height: "100%",
                      border: `1px solid  ${Colors.primary}`,
                    }}
                  />
                  {selector === "Weekly" ||
                  selector === "Bi-weekly" ||
                  selector === "Monthly" ? (
                    <img src={repeatBtn} alt="dollarBtn" />
                  ) : (
                    <img src={dollorBrand} alt="dollarBtn" />
                  )}
                </>
              ) : (
                <>
                  <img src={dollorWhite} alt="dollarBtn" />
                  <div
                    style={{
                      height: "100%",
                      border: "0.5px solid rgb(205 205 206)",
                    }}
                  />
                  {selector === "Weekly" ||
                  selector === "Bi-weekly" ||
                  selector === "Monthly" ? (
                    <img src={repeatBtn} alt="dollarBtn" />
                  ) : (
                    <img src={dollorBrand} alt="dollarBtn" />
                  )}
                </>
              )}
            </div>
            <TextStyles.FontSize14px className="d-flex flex-column">
              <TextStyles.FontSize16px className="d-flex justify-content-start mb-3 mt-1">
                I will invoice
              </TextStyles.FontSize16px>
              <div className="d-flex flex-row flex-wrap gap-2">
                <div onClick={() => setSelector("Once")} className="me-2">
                  {selector === "Once" ? (
                    <Styled.Selected>Once</Styled.Selected>
                  ) : (
                    <Styled.NotSelected>Once</Styled.NotSelected>
                  )}
                </div>
                <div onClick={() => setSelector("Weekly")} className="me-2">
                  {selector === "Weekly" ? (
                    <Styled.Selected>Weekly</Styled.Selected>
                  ) : (
                    <Styled.NotSelected>Weekly</Styled.NotSelected>
                  )}
                </div>
                <div onClick={() => setSelector("Bi-weekly")} className="me-2">
                  {selector === "Bi-weekly" ? (
                    <Styled.Selected>Bi-weekly</Styled.Selected>
                  ) : (
                    <Styled.NotSelected>Bi-weekly</Styled.NotSelected>
                  )}
                </div>
                <div onClick={() => setSelector("Monthly")} className="me-2">
                  {selector === "Monthly" ? (
                    <Styled.Selected>Monthly</Styled.Selected>
                  ) : (
                    <Styled.NotSelected>Monthly</Styled.NotSelected>
                  )}
                </div>
              </div>

              <TextStyles.FontSize16px className="d-flex justify-content-start pt-3">
                Deposit
              </TextStyles.FontSize16px>
              <TextStyles.CheckBox
                className="form-check mt-3"
                style={{ display: "flex" }}
              >
                <input
                  style={{ cursor: "pointer" }}
                  className="form-check-input"
                  type="checkbox"
                  id="require_deposit"
                  name="require_deposit"
                  {...register("billingScheduled.deposit.require_deposit")}
                />
                <TextStyles.GreyFont14px
                  className="form-check-label"
                  htmlFor="flexCheckChecked"
                >
                  I require a deposit
                </TextStyles.GreyFont14px>
              </TextStyles.CheckBox>
              {selectDeposit && (
                <div>
                  <TextStyles.FontSize14px
                    className="mt-3"
                    style={{ display: "flex" }}
                  >
                    <Styled.inputBox style={{ maxWidth: 120 }}>
                      <span>
                        {selectedData &&
                          selectedData.currency &&
                          selectedData.currency.symbol}
                      </span>
                      <input
                        style={{ width: 60 }}
                        disabled={selectDeposit ? false : true}
                        type="number"
                        id="value"
                        min={"0"}
                        placeholder="00.00"
                        name="value"
                        {...register("billingScheduled.deposit.value")}
                      />
                    </Styled.inputBox>
                  </TextStyles.FontSize14px>

                  <TextStyles.GreyFont14px className="d-flex mt-3">
                    <span>
                      <AccessAlarmIcon fontSize="small" />
                    </span>
                    <span className="ps-2">
                      You'll be reminded to invoice the deposit after you create
                      this project
                    </span>
                  </TextStyles.GreyFont14px>
                </div>
              )}
              <TextStyles.FontSize16px
                className="mt-4 pb-1"
                style={{ display: "flex" }}
              >
                {selector === "Weekly"
                  ? "Weekly invoices"
                  : selector === "Bi-weekly"
                  ? "Bi-weekly invoices"
                  : selector === "Monthly"
                  ? "Monthly invoices"
                  : "Final invoice"}
              </TextStyles.FontSize16px>
            </TextStyles.FontSize14px>
          </div>
          <div style={{ paddingLeft: 50 }}>
            {selector === "Once" && (
              <div className="d-flex flex-column mt-3">
                <TextStyles.FontSize14px>
                  <label htmlFor="start_date" className="mb-2">
                    Issue invoice
                  </label>
                  <div style={{ maxWidth: 200 }}>
                    <Controller
                      name={"billingScheduled.issue_invoice"}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CustomDatePickerOne
                            onChange={onChange}
                            selected={value}
                            placeholder="Select date"
                            allowClear={true}
                          />
                        );
                      }}
                    />
                  </div>
                </TextStyles.FontSize14px>
              </div>
            )}
            {selector === "Weekly" && (
              <div>
                <div className="d-flex flex-column mt-3">
                  <TextStyles.FontSize14px>
                    <label htmlFor="start_date" className="mb-2">
                      Start invoices
                    </label>
                    <div style={{ maxWidth: 200 }}>
                      <Controller
                        name={"billingScheduled.weekly_invoice.start_date"}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CustomDatePickerOne
                              onChange={onChange}
                              selected={value}
                              placeholder="Select date"
                              allowClear={true}
                            />
                          );
                        }}
                      />
                    </div>
                  </TextStyles.FontSize14px>
                </div>

                <TextStyles.FontSize14px className="mt-3">
                  <label htmlFor="start_date" className="mb-2">
                    End invoices
                  </label>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <Controller
                      control={control}
                      name="invoices_type"
                      {...register(
                        "billingScheduled.weekly_invoice.invoices_type"
                      )}
                      render={({ field: { onChange, value } }) => (
                        <SelectEndInvoiceType
                          placeholder="Select"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />

                    {invoices_type === "After" && (
                      <>
                        <Styled.inputBox style={{ width: 60 }}>
                          <input
                            type="number"
                            style={{ width: 30, textAlign: "center" }}
                            id="weekly_num_invoices"
                            placeholder="0"
                            min={"0"}
                            name="num_invoices"
                            {...register(
                              "billingScheduled.weekly_invoice.num_invoices"
                            )}
                          />
                        </Styled.inputBox>
                        <TextStyles.FontSize14px>
                          invoices
                        </TextStyles.FontSize14px>
                      </>
                    )}

                    {invoices_type === "On" && (
                      <div style={{ maxWidth: 200 }}>
                        <Controller
                          name={"billingScheduled.weekly_invoice.end_date"}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <CustomDatePickerOne
                                onChange={onChange}
                                selected={value}
                                placeholder="Select date"
                                allowClear={true}
                              />
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </TextStyles.FontSize14px>
              </div>
            )}

            {selector === "Bi-weekly" && (
              <div>
                <div className="d-flex flex-column mt-3">
                  <TextStyles.FontSize14px>
                    <label htmlFor="start_date" className="mb-2">
                      Start invoices
                    </label>
                    <div style={{ maxWidth: 200 }}>
                      <Controller
                        name={"billingScheduled.biWeekly_invoice.start_date"}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CustomDatePickerOne
                              onChange={onChange}
                              selected={value}
                              placeholder="Select date"
                              allowClear={true}
                            />
                          );
                        }}
                      />
                    </div>
                  </TextStyles.FontSize14px>
                </div>

                <TextStyles.FontSize14px className="mt-3">
                  <label htmlFor="start_date" className="mb-2">
                    End invoices
                  </label>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <Controller
                      control={control}
                      name="invoices_type"
                      {...register(
                        "billingScheduled.biWeekly_invoice.invoices_type"
                      )}
                      render={({ field: { onChange, value } }) => (
                        <SelectEndInvoiceType
                          placeholder="Select"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />

                    {invoices_type1 === "After" && (
                      <>
                        <Styled.inputBox style={{ width: 60 }}>
                          <input
                            type="number"
                            style={{ width: 30, textAlign: "center" }}
                            id="biWeekly_num_invoices"
                            placeholder="0"
                            min={"0"}
                            name="num_invoices"
                            {...register(
                              "billingScheduled.biWeekly_invoice.num_invoices"
                            )}
                          />
                        </Styled.inputBox>
                        <TextStyles.FontSize14px>
                          invoices
                        </TextStyles.FontSize14px>
                      </>
                    )}

                    {invoices_type1 === "On" && (
                      <div style={{ maxWidth: 200 }}>
                        <Controller
                          name={"billingScheduled.biWeekly_invoice.end_date"}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <CustomDatePickerOne
                                onChange={onChange}
                                selected={value}
                                placeholder="Select date"
                                allowClear={true}
                              />
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </TextStyles.FontSize14px>
              </div>
            )}

            {selector === "Monthly" && (
              <div>
                <div className="d-flex flex-column mt-3">
                  <TextStyles.FontSize14px>
                    <label htmlFor="start_date" className="mb-2">
                      Start invoices
                    </label>
                    <div style={{ maxWidth: 200 }}>
                      <Controller
                        name={"billingScheduled.monthly_invoice.start_date"}
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <CustomDatePickerOne
                              onChange={onChange}
                              selected={value}
                              placeholder="Select date"
                              allowClear={true}
                            />
                          );
                        }}
                      />
                    </div>
                  </TextStyles.FontSize14px>
                </div>

                <TextStyles.FontSize14px className="mt-3">
                  <label htmlFor="start_date" className="mb-2">
                    End invoices
                  </label>
                  <div className="d-flex flex-wrap align-items-center gap-3">
                    <Controller
                      control={control}
                      name="invoices_type"
                      {...register(
                        "billingScheduled.monthly_invoice.invoices_type"
                      )}
                      render={({ field: { onChange, value } }) => (
                        <SelectEndInvoiceType
                          placeholder="Select"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />

                    {invoices_type2 === "After" && (
                      <>
                        <Styled.inputBox style={{ width: 60 }}>
                          <input
                            type="number"
                            style={{ width: 30, textAlign: "center" }}
                            id="monthly_num_invoices"
                            placeholder="0"
                            min={"0"}
                            name="num_invoices"
                            {...register(
                              "billingScheduled.monthly_invoice.num_invoices"
                            )}
                          />
                        </Styled.inputBox>
                        <TextStyles.FontSize14px>
                          invoices
                        </TextStyles.FontSize14px>
                      </>
                    )}

                    {invoices_type2 === "On" && (
                      <div style={{ maxWidth: 200 }}>
                        <Controller
                          name={"billingScheduled.monthly_invoice.end_date"}
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <CustomDatePickerOne
                                onChange={onChange}
                                selected={value}
                                placeholder="Select date"
                                allowClear={true}
                              />
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </TextStyles.FontSize14px>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-around align-items-center">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditBillingSchedule;
