import React from "react";
import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const StyledTypingLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 50%;
  padding: 1rem;
`;

const Dot = styled.div`
  border-radius: 50%;
  height: 0.8rem;
  width: 0.8rem;
  background-color: ${(props) => props.color || Colors.primary};
  opacity: 0;
  animation: blink 1s infinite;

  &:nth-child(1) {
    animation-delay: 0.3333s;
  }
  &:nth-child(2) {
    animation-delay: 0.6666s;
  }
  &:nth-child(3) {
    animation-delay: 0.9999s;
  }

  @keyframes blink {
    50% {
      opacity: 1;
    }
  }
`;

function TypingLoader() {
  return (
    <StyledTypingLoader>
      <Dot />
      <Dot />
      <Dot />
    </StyledTypingLoader>
  );
}

export default TypingLoader;
