import { createSlice } from "@reduxjs/toolkit";

const stateFaqs = {
  query: "",
  filteredFaqs: [],
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState: stateFaqs,
  reducers: {
    updateFaqs(state, action) {
      state.query = action.payload.query;
      state.filteredFaqs = action.payload.faqs;
    },
  },
});

export const { updateFaqs } = faqsSlice.actions;
export default faqsSlice.reducer;
