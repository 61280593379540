import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { deleteAddSectionFilePC } from "../../../../../../store/service/proposalAndContractService";
import EditAddSectionFileModal from "./EditAddSectionFileModal";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import RemoveModal from "../../../../Commons/RemoveModal";
import { getFileFromUrl } from "../../../../Commons/utils";
import Colors from "../../../../../../Constants/Colors";

import fileImage from "../../../../../../Assets/assetsnew/file-add-symbol-new.svg";

const iconStyles = {
  cursor: "pointer",
  color: Colors.font2,
  opacity: 0.8,
  "&:hover": {
    opacity: 1,
  },
};

const File = ({ data, position, pc_id }) => {
  const dispatch = useDispatch();
  const [editModal, setEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const DownloadForOfflineOutlined = (item) => {
    let filename = item.file_name;
    let indexofUnderscore = filename.indexOf("_");
    let file_name = filename.substring(indexofUnderscore + 1);
    getFileFromUrl(item.file_url, file_name).then((res) => {
      const href = URL.createObjectURL(res);
      const aElement = document.createElement("a");
      aElement.href = href;
      aElement.setAttribute("download", file_name);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
  };

  const deleteHandler = () => {
    const Obj = {
      pc_id: pc_id,
      _id: data._id,
      files: data.files.map((d) => d.file_name),
      position: position,
    };

    dispatch(deleteAddSectionFilePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div className="mt-4">
      <Styled.ReviewBox>
        <div className="edit-delete-wrapper">
          <BorderColorOutlinedIcon
            onClick={() => setEditModal(true)}
            sx={iconStyles}
          />
          <CloseOutlinedIcon
            onClick={() => {
              setShowRemoveModal(true);
              setConfirmDialog({
                onConfirm: () => deleteHandler(),
              });
            }}
            sx={{ ...iconStyles, marginLeft: 1 }}
          />
        </div>

        <div className="d-flex align-items-center gap-3 mb-4">
          <img src={fileImage} alt="fileImage" style={{ maxWidth: "2.2rem" }} />

          <TextStyles.FontSize18px className="fw-medium">
            File
          </TextStyles.FontSize18px>
        </div>

        <TextStyles.FontSize14px className="mb-3">
          <div className="mb-2 fw-medium">File title</div>
          <Styled.Text>{data.file_title}</Styled.Text>
        </TextStyles.FontSize14px>

        <TextStyles.FontSize14px>
          <div className="mb-2 fw-medium">
            Attached {data.files.length > 1 ? "files" : "file"}
          </div>

          {data &&
            data.files &&
            <div className="p-0 m-0 d-flex flex-column gap-2">
            {data.files.map((item, index) => {
              let filename = item.file_name;
              let indexofUnderscore = filename.indexOf("_");
              let file_name = filename.substring(indexofUnderscore + 1);
              return (
                <Styled.FileDownloadContainer
                  className="d-flex align-items-center"
                  key={index}
                  onClick={() => DownloadForOfflineOutlined(item)}
                  style={{ minWidth: 100, marginBottom: 0 }}
                >
                  <span className="three-dots">
                    <InsertDriveFileOutlinedIcon className="pe-2" />
                    {file_name} - {item.size / 1000000} MB
                  </span>
                </Styled.FileDownloadContainer>
              );
            })}
            </div>
          }
        </TextStyles.FontSize14px>
      </Styled.ReviewBox>
      {editModal && (
        <EditAddSectionFileModal
          show={editModal}
          handleClose={() => setEditModal(false)}
          editData={data}
          pc_id={pc_id}
          position={position}
        />
      )}
      <RemoveModal
        title={"files"}
        confirmDialog={confirmDialog}
        showRemoveModal={showRemoveModal}
        handleCloseRemoveModal={handleCloseRemoveModal}
      />
    </div>
  );
};

export default File;
