import React from "react";
import { Helmet } from "react-helmet";

import SearchBox from "./SearchBox";
import OurFeatures from "./OurFeatures";
import PopularTopics from "./PopularTopics";
import Footer from "../Footer/Footer";

const HelpCenter = () => {
  const isProduction =
    process.env.REACT_APP_PAGE_INDEXING_MODE === "production";

  return (
    <>
      <Helmet>
        <title>Zodot Help Center – Get Support & Answers</title>
        <meta
          content="Find answers to your questions and get support for invoicing, contracts, and time tracking on Zodot. Explore guides and FAQs to get started easily."
          name="description"
        />
        {isProduction ? (
          <meta name="robots" content="index, follow" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://zodot.co/help-center" />
      </Helmet>

      <SearchBox />
      <PopularTopics />
      <OurFeatures />
      <Footer />
    </>
  );
};

export default HelpCenter;
