import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { Modal } from "react-bootstrap";
import { updateBusinessInfo } from "../../../../store/service/AuthService";
import ClearIcon from "@mui/icons-material/Clear";
import LoaderSpin from "../../Commons/LoaderSpin";
import TextStyles from "../../../../Constants/TextStyles";
import SelectSTDCode from "../../Commons/SelectSTDCode";
import Colors from "../../../../Constants/Colors";
import SelectCountry from "../../Commons/SelectCountry";
import Constant from "../../Commons/Constant";

const EditBusinessInfo = ({ data, businessInfoData, show, handleClose }) => {
  const [selectedNameAs, setSelectedNameAs] = useState(
    businessInfoData && businessInfoData.select_name_as === "Business"
      ? false
      : true
  );

  const isLoading = useSelector((state) => state.auth.isLoading);
  const dispatch = useDispatch();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: {
      select_name_as: selectedNameAs,
      business_name:
        businessInfoData && businessInfoData.business_name
          ? businessInfoData && businessInfoData.business_name
          : data && `${data.firstName} ${data.lastName}`,
      phone_code: businessInfoData && businessInfoData.phone_code,
      phone_number: businessInfoData && businessInfoData.phone_number,
      street: businessInfoData && businessInfoData.street,
      suite: businessInfoData && businessInfoData.suite,
      city: businessInfoData && businessInfoData.city,
      zip_code: businessInfoData && businessInfoData.zip_code,
      country:
        businessInfoData &&
        businessInfoData.country &&
        Constant.countryOptions.filter(
          (data) => data.name === businessInfoData.country
        )[0],
      state: businessInfoData && businessInfoData.state,
      tax_id_label: businessInfoData && businessInfoData.tax_id_label,
      tax_id_number: businessInfoData && businessInfoData.tax_id_number,
    },
  });

  const selectNameAs = watch("select_name_as");

  useEffect(() => {
    setSelectedNameAs(selectNameAs);
  }, [selectNameAs]);

  useEffect(() => {
    if (selectedNameAs) {
      setValue("business_name", data && `${data.firstName} ${data.lastName}`);
    } else {
      setValue(
        "business_name",
        businessInfoData && businessInfoData.business_name
      );
    }
  }, [selectedNameAs]);

  const formsubmit = async (res) => {
    const Obj = {
      select_name_as: res.select_name_as === true ? "User" : "Business",
      business_name: res.business_name,
      phone_code: res.phone_code,
      phone_number: res.phone_number,
      street: res.street,
      suite: res.suite,
      city: res.city,
      zip_code: res.zip_code,
      country: res.country ? res.country.name : null,
      state: res.state,
      tax_id_label: res.tax_id_label,
      tax_id_number: res.tax_id_number,
    };

    dispatch(updateBusinessInfo(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(`${data.payload.message}`);
        handleClose();
        reset();
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight500px"
      fullscreen
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center justify-content-between">
          <TextStyles.FontSize20px className="font-weight-500">
            Business Information
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body>
          <TextStyles.CheckBox
            className="form-check d-flex align-items-center three-dots mb-3"
            style={{ minWidth: 100 }}
            size={"1.2rem"}
            paddingLeft={"0.6rem"}
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckChecked"
              {...register("select_name_as")}
            />
            <label
              className="form-check-label three-dots"
              htmlFor="flexCheckChecked"
            >
              I’m a Sole Proprietor
            </label>
          </TextStyles.CheckBox>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="business_name" className="mb-2">
              Business name
            </label>
            <TextStyles.InputRectangle
              invalid={errors.business_name}
              id="business_name"
              type="text"
              name="business_name"
              readOnly={selectedNameAs}
              placeholder="Business name"
              {...register("business_name", {
                required: "Business name is required.",
                validate: (value) => {
                  let trimedLength = value.trim().length;
                  if (trimedLength === 0) {
                    return "Business name is required.";
                  }
                  return true;
                },
              })}
            />
            {errors.business_name && (
              <TextStyles.InValidFeedback>
                {errors.business_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="phone_number" className="mb-2">
              Phone number
            </label>
            <div className="d-flex flex-wrap flex-sm-nowrap  justify-content-between align-items-center gap-3">
              <div className="w-100">
                <Controller
                  control={control}
                  defaultValue={businessInfoData && businessInfoData.phone_code}
                  name="phone_code"
                  render={({ field: { onChange, value } }) => (
                    <SelectSTDCode
                      placeholder="Select code"
                      onChange={onChange}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
              <div className="w-100">
                <TextStyles.InputRectangle
                  style={{ width: "100%" }}
                  invalid={errors.phone_number}
                  id="phone_number"
                  type="tel"
                  name="phone_number"
                  placeholder=" Phone number"
                  {...register("phone_number", {
                    pattern: {
                      value: /^[\d\s-]{7,16}$/,
                      message: "Please enter valid phone number.",
                    },
                  })}
                />
              </div>
            </div>

            {errors.phone_number && (
              <TextStyles.InValidFeedback>
                {errors.phone_number.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center gap-3 mb-3">
            <div className="w-100">
              <label htmlFor="street_address" className="mb-2">
                Street address
              </label>
              <TextStyles.InputRectangle
                id="street_address"
                type="text"
                placeholder="Street address"
                name="street"
                {...register("street")}
              />
            </div>
            <div className="w-100">
              <label htmlFor="suite_address" className="mb-2">
                Suite address
              </label>
              <TextStyles.InputRectangle
                id="suite_address"
                type="text"
                placeholder="Suite address"
                name="suite"
                {...register("suite")}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center gap-3 mb-3">
            <div className="w-100">
              <label htmlFor="city" className="mb-2">
                City
              </label>
              <TextStyles.InputRectangle
                id="city"
                type="text"
                placeholder="City"
                name="city"
                {...register("city")}
              />
            </div>
            <div className="w-100">
              <label htmlFor="zip_code" className="mb-2">
                Zip code
              </label>
              <TextStyles.InputRectangle
                id="zip_code"
                type="text"
                placeholder="Zip code"
                name="zip_code"
                {...register("zip_code")}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center gap-3 mb-3">
            <div className="w-100">
              <label htmlFor="state" className="mb-2">
                State
              </label>
              <TextStyles.InputRectangle
                id="state"
                type="text"
                placeholder="State"
                name="state"
                {...register("state")}
              />
            </div>
            <div className="w-100">
              <label htmlFor="country" className="mb-2">
                Country
              </label>
              <Controller
                control={control}
                id="country"
                name="country"
                render={({ field: { onChange, value } }) => (
                  <SelectCountry
                    placeholder="Select country"
                    onChange={onChange}
                    defaultValue={value}
                  />
                )}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="d-flex flex-wrap flex-sm-nowrap justify-content-between align-items-center gap-3 mb-3">
            <div className="w-100">
              <label htmlFor="tax_id_label" className="mb-2">
                Tax ID label
              </label>
              <TextStyles.InputRectangle
                id="tax_id_label"
                type="text"
                placeholder="Tax ID label"
                name="tax_id_label"
                {...register("tax_id_label")}
              />
            </div>
            <div className="w-100">
              <label htmlFor="tax_id_number" className="mb-2">
                Tax ID number
              </label>
              <TextStyles.InputRectangle
                id="tax_id_number"
                type="text"
                placeholder="Tax ID number"
                name="tax_id_number"
                {...register("tax_id_number")}
              />
            </div>
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Save changes"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditBusinessInfo;
