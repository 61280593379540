import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const Responsibility = (props) => {
  const selectedData = props && props.selectedData;
  const heading =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.heading
      ? selectedData &&
        selectedData.responsibility &&
        selectedData.responsibility.heading
      : "Responsibility of Contractor";
  const paragraph =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.paragraph
      ? selectedData &&
        selectedData.responsibility &&
        selectedData.responsibility.paragraph
      : `A. Contractor agrees to deliver the services in accordance with the Delivery Schedule provided for in Schedule A attached hereto. Contractor’s failure to meet the dates provided for in this Schedule A shall constitute a material breach of this Agreement.\n\nB. Contractor shall or shall cause one of its clients to attend Clients’ regularly scheduled meetings relating to the status of the Project. Moreover, Contractor shall provide Client with a written report each month on the status of the development of the services.
`;

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.responsibility &&
          selectedData.responsibility.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-3 fw-medium overflow-hidden">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="lh-lg overflow-hidden" style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default Responsibility;
