import React from "react";
import signatureImg from "../../../../../../Assets/assetsnew/signature-symbol.svg";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "./SignatureStyles";

const Signature = ({
  selectedData,
  data,
  businessInfoData,
  primaryContact,
}) => {
  return (
    <Styled.ReviewBox className="my-4">
      <TextStyles.FontSize18px className="d-flex gap-2 gap-md-3 align-items-center">
        <img src={signatureImg} alt="signatureImg" />
        <span className="fw-medium">Signature</span>
      </TextStyles.FontSize18px>

      {/* <div className="d-flex flex-wrap flex-sm-nowrap gap-2justify-content-sm-between align-items-center"> */}
      <div className="d-sm-flex justify-content-between">
        {/* <div className="d-flex flex-column justify-content-start w-100"> */}
        <div>
          <div
            style={{
              fontWeight: 400,
              fontSize: 36,
              height: 130,
            }}
            // className="d-flex justify-content-start"
            className="align-content-end"
          >
            {/* <div className="d-flex align-items-end h-100"> */}
              {selectedData &&
                selectedData.client_signature &&
                selectedData.client_signature.text && (
                  <div style={{ fontFamily: "licorice" }}>
                    {selectedData.client_signature.text}
                  </div>
                )}
              {selectedData &&
                selectedData.client_signature &&
                selectedData.client_signature.image &&
                selectedData.client_signature.image.image_name && (
                  <img
                    src={selectedData.client_signature.image.image_url}
                    alt="imageBtn"
                    style={{ maxHeight: 120, maxWidth: 200 }}
                  />
                )}
            {/* </div> */}
          </div>

          {/* <TextStyles.FontSize16px className="d-flex justify-content-start"> */}
          <TextStyles.FontSize16px>
            {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.company_name}
          </TextStyles.FontSize16px>

          {/* <TextStyles.GreyFont14px className="d-flex justify-content-start"> */}
          <TextStyles.GreyFont14px>
            {primaryContact && primaryContact.contact_name}
          </TextStyles.GreyFont14px>
        </div>

        {/* <div className="d-flex flex-column ms-auto justify-content-end w-100"> */}
        <div className="text-end">
          <div
            style={{
              fontWeight: 400,
              fontSize: 36,
              height: 130,
            }}
            // className="d-flex justify-content-end"
            className="align-content-end"
          >
            {/* <div className="d-flex align-items-end h-100"> */}
              {selectedData &&
                selectedData.signature &&
                selectedData.signature.text && (
                  <div style={{ fontFamily: "licorice" }}>
                    {selectedData.signature.text}
                  </div>
                )}

              {selectedData &&
                selectedData.signature &&
                selectedData.signature.image &&
                selectedData.signature.image.image_name && (
                  <img
                    src={selectedData.signature.image.image_url}
                    alt="imageBtn"
                    style={{ maxHeight: 120, maxWidth: 200 }}
                    className="mt-3 mt-sm-0"
                  />
                )}
            {/* </div> */}
          </div>

          {/* <TextStyles.FontSize16px className="d-flex justify-content-end"> */}
          <TextStyles.FontSize16px>
            {businessInfoData && businessInfoData.business_name
              ? businessInfoData.business_name
              : data && `${data.firstName} ${data.lastName}`}
          </TextStyles.FontSize16px>

          {/* <TextStyles.GreyFont14px className="d-flex justify-content-end"> */}
          <TextStyles.GreyFont14px>
            {businessInfoData &&
            businessInfoData.business_name !==
              `${data.firstName} ${data.lastName}`
              ? data && `${data.firstName} ${data.lastName}`
              : ""}
          </TextStyles.GreyFont14px>
        </div>
      </div>
    </Styled.ReviewBox>
  );
};

export default Signature;
