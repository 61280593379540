import React, { Component } from "react";
import styles from "./ErrorBoundary.module.css";
import errorImage from "../Assets/frontendImages/server_error_image.svg";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error("Error caught by ErrorBoundary:", error, info);
  }

  contactSupportHandler = () => {
    window.location.href = "/contact";
  };

  refreshPageHandler = () => {
    window.location.reload(true);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.error_container}>
          <img src={errorImage} alt="Server Error" className={styles.image} />
          <div className={styles.heading}>
            Oops! Something went wrong on our end.
          </div>
          <div className={styles.sub_heading}>
            Try refreshing the page or
            <span
              onClick={this.contactSupportHandler}
              className={styles.contact_support}
            >
              Contact Support
            </span>
            if the issue persists.
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <button className={styles.button} onClick={this.refreshPageHandler}>
              Refresh Page
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
