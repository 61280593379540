import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { message } from "antd";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageModals";
import { updatePaymentTermsPC } from "../../../../../store/service/proposalAndContractService";
import SelectDueDays from "../../../Commons/SelectDueDays";
import Colors from "../../../../../Constants/Colors";

const PaymentTermsModal = ({ show, handleClose, heading, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const [dueDays, setDueDays] = useState(
    selectedData &&
      selectedData.payment_terms &&
      selectedData.payment_terms.payment_due
  );

  const paragraph = `Client acknowledges the significance of prompt payments to independent contractors and aims to sustain a constructive working relationship with the Contractor for seamless project progress. Payments for each invoice submitted by the Contractor to the Client shall be settled within ${dueDays} of receipt. In the event of delayed payments, the Contractor retains the right to suspend work until the payment is received.`;

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
      payment_due:
        selectedData &&
        selectedData.payment_terms &&
        selectedData.payment_terms.payment_due &&
        selectedData.payment_terms.payment_due === "7 days"
          ? "7 days"
          : selectedData &&
            selectedData.payment_terms &&
            selectedData.payment_terms.payment_due &&
            selectedData.payment_terms.payment_due === "15 days"
          ? "15 days"
          : selectedData &&
            selectedData.payment_terms &&
            selectedData.payment_terms.payment_due &&
            selectedData.payment_terms.payment_due === "30 days"
          ? "30 days"
          : selectedData &&
            selectedData.payment_terms &&
            selectedData.payment_terms.payment_due &&
            selectedData.payment_terms.payment_due === "45 days"
          ? "45 days"
          : selectedData &&
            selectedData.payment_terms &&
            selectedData.payment_terms.payment_due &&
            selectedData.payment_terms.payment_due !== "7 days" &&
            selectedData.payment_terms.payment_due !== "15 days" &&
            selectedData.payment_terms.payment_due !== "30 days" &&
            selectedData.payment_terms.payment_due !== "45 days"
          ? "Custom"
          : "Upon receipt",
      custom_due_days:
        selectedData &&
        selectedData.payment_terms &&
        selectedData.payment_terms.payment_due &&
        selectedData.payment_terms.payment_due !== "7 days" &&
        selectedData.payment_terms.payment_due !== "15 days" &&
        selectedData.payment_terms.payment_due !== "30 days" &&
        selectedData.payment_terms.payment_due !== "45 days"
          ? selectedData.payment_terms.payment_due.split(" ")[0]
          : null,
    },
  });

  const paymentDue = watch("payment_due");
  const custumDueDays = watch("custom_due_days");

  useEffect(() => {
    if (paymentDue !== "Custom") {
      setDueDays(paymentDue);
    } else {
      if (custumDueDays === "1") {
        setDueDays(`${custumDueDays} day`);
      } else {
        setDueDays(`${custumDueDays} days`);
      }
    }
  }, [paymentDue, custumDueDays]);

  useEffect(() => {
    setValue("paragraph", paragraph);
  }, [dueDays]);

  useEffect(() => {
    if (paymentDue === "Custom") return;
    clearErrors("custom_due_days");
  }, [paymentDue]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      payment_terms: {
        required: true,
        payment_due:
          item.payment_due === "Custom" && item.custom_due_days === "1"
            ? `${item.custom_due_days} day`
            : item.payment_due === "Custom"
            ? `${item.custom_due_days} days`
            : item.payment_due !== ("Custom" && "Upon receipt")
            ? item.payment_due
            : "15 days",
      },
    };
    dispatch(updatePaymentTermsPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        message.success("Clause updated successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered={true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit Contract</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Headline
            </label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              style={{ opacity: 0.5 }}
              {...register("heading")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline" className="mb-2">
              Paragraph
            </label>
            <TextStyles.Textarea
              className="lh-lg"
              rows={6}
              style={{ opacity: 0.5 }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              readOnly
            />
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="my-3">
            <label htmlFor="payment_due" className="mb-2">
              Payment due
            </label>

            <div className="d-flex flex-wrap gap-3 align-items-center">
              <Controller
                control={control}
                name="payment_due"
                render={({ field: { onChange, value } }) => (
                  <SelectDueDays
                    width="145px"
                    onChange={onChange}
                    defaultValue={value}
                  />
                )}
              />

              {paymentDue === "Custom" && (
                <>
                  <TextStyles.FontSize14px>After</TextStyles.FontSize14px>
                  <Styled.inputBox>
                    <input
                      type="text"
                      name="custom_due_days"
                      style={{
                        width: 24,
                        textAlign: "center",
                        background: "transparent",
                      }}
                      placeholder="00"
                      {...register("custom_due_days", {
                        required:
                          paymentDue === "Custom"
                            ? "Please enter custom due days."
                            : false,
                        min: {
                          value: 1,
                          message: "Please enter more than 0.",
                        },
                        pattern: {
                          value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                  </Styled.inputBox>
                  <TextStyles.FontSize14px>
                    {custumDueDays === "1" ? "day" : "days"}
                  </TextStyles.FontSize14px>
                </>
              )}
            </div>
            {errors.custom_due_days && (
              <TextStyles.InValidFeedback className="mt-2">
                {errors.custom_due_days.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PaymentTermsModal;
